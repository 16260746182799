import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import useCharts from '@/hooks/useCharts'
import { useEffect, useRef, useState } from 'react'
import LoadingCard from '../LoadingCard'
import { europeNum } from '@/utils/general'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  }
}))

function StringAnalysisChart ({ labelsTheme = 'dark', name = null, data = {}, height = null, width = null, className }) {
  // Oggetto che contiene i colori delle scritte del grafico in base al tema scelto
  const graphTheme = {
    light: '#263238',
    dark: '#ffffff'
  }

  const classes = useStyles()
  // Prendo le istanze di core e am4Charts disponibili nel context
  const { chartsCore, amCharts } = useCharts()
  // Creo una ref del grafico
  const chartRef = useRef(null)
  // Variabile che determina la prima renderizzazione a schermo
  const [firstPaint, setFirstPaint] = useState(true)

  // Colore delle labels del grafico
  const labelsColor = graphTheme[labelsTheme] || '#ffffff'

  // Variabile che rappresenta l'id del div da selezionare come container del grafico
  const graphContainerId = name ? `string-analysis-chart-${name}` : 'string-analysis-chart'

  // Funzione per la formattazione dei dati nel formato richiesto dalla libreria grafica
  const formatData = (dataObj) => {
    const labels = dataObj?.cc?.labels || []
    return labels.map((label, index) => ({
      category: label,
      underThreshold: dataObj?.underThreshold?.length > 0 ? dataObj.underThreshold[index] : false,
      cc: dataObj?.cc?.data?.length > 0 ? dataObj.cc.data[index] : 0,
      ccFormatted: dataObj?.cc?.data?.length > 0 ? europeNum(dataObj.cc.data[index], 2) ? `${europeNum(dataObj.cc.data[index], 2)} A` : 0 : 0,
      energy: dataObj?.energy?.data?.length > 0 ? dataObj.energy.data[index] : 0,
      energyFormatted: dataObj?.energy?.data?.length > 0 ? europeNum(dataObj.energy.data[index], 2) ? `${europeNum(dataObj.energy.data[index], 2)} kWh/modulo` : 0 : 0
    }))
  }

  // Funzione che crea gli assi del grafico
  const createAxis = (chartInstance) => {
    // Asse delle date
    const dateAxis = chartInstance.xAxes.push(new amCharts.CategoryAxis())
    dateAxis.renderer.minGridDistance = 5
    dateAxis.dataFields.category = 'category'
    const dateAxisLabels = dateAxis.renderer.labels.template
    dateAxisLabels.fontWeight = 400
    dateAxisLabels.fontFamily = 'Roboto, sans-serif'
    dateAxisLabels.fontSize = 12
    dateAxisLabels.fill = labelsColor
    dateAxisLabels.maxWidth = 40
    dateAxis.zoomable = false
    // Se lo schermo è piccolo inclicno le labels a 45 gradi
    dateAxis.events.on('sizechanged', function (ev) {
      const axis = ev.target
      const cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex)
      if (cellWidth < axis.renderer.labels.template.maxWidth) {
        axis.renderer.labels.template.rotation = -45
        axis.renderer.labels.template.horizontalCenter = 'right'
        axis.renderer.labels.template.verticalCenter = 'middle'
      } else {
        axis.renderer.labels.template.rotation = 0
        axis.renderer.labels.template.horizontalCenter = 'middle'
        axis.renderer.labels.template.verticalCenter = 'top'
      }
    })

    // Asse delle correnti
    const ccMax = data.cc?.max || 0
    const ccMiddle = data.cc?.middle || 0

    const ccAxis = chartInstance.yAxes.push(new amCharts.ValueAxis())
    ccAxis.min = 0
    ccAxis.max = ccMax * 1.1
    ccAxis.renderer.minGridDistance = 20
    ccAxis.strictMinMax = true
    ccAxis.renderer.grid.template.disabled = true
    ccAxis.title.text = 'Correnti di stringa (A)'
    ccAxis.title.fontSize = 12
    ccAxis.title.fontWeight = 500
    ccAxis.title.fontFamily = 'Roboto, sans-serif'
    ccAxis.title.fill = labelsColor
    ccAxis.renderer.labels.template.fontWeight = 400
    ccAxis.renderer.labels.template.fontFamily = 'Roboto, sans-serif'
    ccAxis.renderer.labels.template.fontSize = 12
    ccAxis.renderer.labels.template.fill = labelsColor
    ccAxis.renderer.opposite = false
    ccAxis.tooltip.disabled = true
    // Asse che rappresenta la soglia delle correnti medie
    const ccRange = ccAxis.axisRanges.create()
    ccRange.value = ccMiddle
    ccRange.grid.strokeOpacity = 1
    ccRange.grid.strokeWidth = 2
    ccRange.grid.stroke = '#66ff00'

    // ccRange.label.text = 'Corrente Media'
    ccRange.label.inside = true
    ccRange.label.fill = ccRange.grid.stroke
    ccRange.label.align = 'left'
    ccRange.label.verticalCenter = 'bottom'
    ccRange.label.fillOpacity = 0.8

    ccAxis.renderer.gridContainer.zIndex = 1

    // Asse delle energia
    const energyMax = data.energy?.max || 0
    const energyMiddle = data.energy?.middle || 0

    const energyAxis = chartInstance.yAxes.push(new amCharts.ValueAxis())
    // energyAxis.min = 0
    energyAxis.max = energyMax * 1.5
    energyAxis.renderer.minGridDistance = 20
    energyAxis.strictMinMax = true
    energyAxis.renderer.grid.template.disabled = true
    energyAxis.title.text = 'Energia Specifica Modulo\n(kWh/modulo)'
    energyAxis.title.textAlign = 'middle'
    energyAxis.title.fontSize = 12
    energyAxis.title.fontWeight = 500
    energyAxis.title.fontFamily = 'Roboto, sans-serif'
    energyAxis.title.fill = labelsColor
    energyAxis.renderer.labels.template.fontWeight = 400
    energyAxis.renderer.labels.template.fontFamily = 'Roboto, sans-serif'
    energyAxis.renderer.labels.template.fontSize = 12
    energyAxis.renderer.labels.template.fill = labelsColor
    energyAxis.renderer.opposite = true
    energyAxis.tooltip.disabled = true

    // Asse che rappresenta la soglia dell' energia
    const energyRange = energyAxis.axisRanges.create()
    energyRange.value = energyMiddle
    energyRange.grid.strokeOpacity = 1
    energyRange.grid.strokeWidth = 2
    energyRange.grid.stroke = '#ff2f23'
    // energyRange.label.text = 'Energia Media'
    energyRange.label.zIndex = -1
    energyRange.label.inside = true
    energyRange.label.fill = energyRange.grid.stroke
    energyRange.label.align = 'right'
    energyRange.label.verticalCenter = 'bottom'
    energyRange.label.fillOpacity = 0.8

    energyAxis.renderer.gridContainer.zIndex = 1

    return { ccAxis, energyAxis }
  }

  // Funzione che crea un tooltip unificato per tutte le serie da graficare
  const createTooltip = (chartInstance, currentSerie) => {
    // Abilito i tooltip
    // currentSerie.tooltipText = [`[bold]${seriesLabels[`size${serieIndex + 1}`]}[/]: {valueY}`]
    currentSerie.tooltipText = '{category}'
    // definisco un adapter che compone il contenuto del tooltip
    currentSerie.adapter.add('tooltipText', function (ev) {
      let text = '[bold]{category}[/]\n\n'

      chartInstance.series.each(function (item) {
        if (!item.isHidden) {
          text += '[' + item.stroke.hex + ']●[/] ' + item.name + ': [bold]{' + item.dataFields.formattedValue + '}[/]\n'
        }
      })
      return text
    })
    // Definisco una grandezza per il tooltip e lo faccio andare a capo in caso di testi più lunghi
    currentSerie.tooltip.label.width = 250
    currentSerie.tooltip.label.wrap = true
    // Disabilito il colore del tooltip in base al colore della serie e lo definisco in maniera programmatica
    currentSerie.tooltip.getFillFromObject = false
    currentSerie.tooltip.background.fill = new chartsCore.InterfaceColorSet().getFor('alternativeBackground')
    currentSerie.tooltip.background.stroke = new chartsCore.InterfaceColorSet().getFor('alternativeBackground')
    currentSerie.tooltip.background.opacity = 0.8
    // Definisco gli stili per il testo all'interno del tooltip
    currentSerie.tooltip.label.fill = chartsCore.color('#fff')
    currentSerie.tooltip.label.fontFamily = 'Roboto, sans-serif'
    currentSerie.tooltip.label.fontSize = 12
  }

  // Funzione in cui definisco tutte le serie da graficare
  const createSeries = (chartInstance, ccAxis, energyAxis) => {
    const ccSerie = chartInstance.series.push(new amCharts.ColumnSeries())
    if (!firstPaint) {
      ccSerie.showOnInit = false
    }
    ccSerie.dataFields.categoryX = 'category'
    ccSerie.name = 'Corrente'
    ccSerie.dataFields.valueY = 'cc'
    ccSerie.propertyFields.underThreshold = 'underThreshold'
    ccSerie.dataFields.formattedValue = 'ccFormatted'
    // Disabilito il colore del tooltip in base al colore della ccSerie e lo definisco in maniera programmatica
    ccSerie.tooltip.getFillFromObject = false
    ccSerie.tooltip.getStrokeFromObject = false
    ccSerie.yAxis = ccAxis
    ccSerie.tooltip.background.fill = chartsCore.color('#FFF')
    ccSerie.tooltip.background.strokeWidth = 3
    ccSerie.strokeWidth = 2
    ccSerie.stroke = chartsCore.color('#7cd44e')
    ccSerie.columns.template.fillOpacity = 0.8
    ccSerie.columns.template.width = chartsCore.percent(60)
    ccSerie.columns.template.adapter.add('fill', function (fill, target) {
      return target.dataItem.properties.underThreshold ? '#ffb2bc' : '#7cd44e'
    })
    ccSerie.columns.template.adapter.add('stroke', function (stroke, target) {
      return target.dataItem.properties.underThreshold ? '#ffb2bc' : '#7cd44e'
    })

    const energySerie = chartInstance.series.push(new amCharts.LineSeries())
    if (!firstPaint) {
      energySerie.showOnInit = false
    }
    energySerie.dataFields.categoryX = 'category'
    energySerie.name = 'Energia Modulo'
    energySerie.dataFields.valueY = 'energy'
    energySerie.dataFields.formattedValue = 'energyFormatted'
    energySerie.stroke = chartsCore.color('#18e7c8')
    // Disabilito il colore del tooltip in base al colore della energySerie e lo definisco in maniera programmatica
    energySerie.tooltip.getFillFromObject = false
    energySerie.tooltip.getStrokeFromObject = false
    energySerie.yAxis = energyAxis
    energySerie.tooltip.background.fill = chartsCore.color('#FFF')
    energySerie.tooltip.background.strokeWidth = 3
    energySerie.strokeWidth = 3

    const bullet = energySerie.bullets.push(new amCharts.Bullet())
    bullet.width = 20
    bullet.height = 15
    bullet.horizontalCenter = 'middle'
    bullet.verticalCenter = 'middle'

    const labelBullet = energySerie.bullets.push(new amCharts.LabelBullet())
    labelBullet.label.fill = chartsCore.color('#ffffff')
    labelBullet.label.fontSize = 11
    labelBullet.label.fontFamily = 'Roboto, sans-serif'
    labelBullet.label.fontWeight = 800
    labelBullet.label.adapter.add('text', function (text, target) {
      const energyValue = target?.dataItem?.valueY || 0
      return europeNum(energyValue, 1)
    })
    labelBullet.width = 20
    labelBullet.height = 15

    const rectangle = bullet.createChild(chartsCore.Rectangle)
    rectangle.stroke = 'white'
    rectangle.fill = chartsCore.color('#18e7c8')
    rectangle.opacity = 0.9
    rectangle.strokeWidth = 1
    rectangle.width = 23
    rectangle.height = 15

    createTooltip(chartInstance, ccSerie)
  }

  // Funzione adapter per il colore del marker
  const markerFillAdapter = (fill, target) => {
    const colorMap = {
      Corrente: '#7cd44e',
      'Energia Modulo': '#18e7c8'
    }
    const serieName = target.dataItem?.name || null
    if (serieName) {
      return colorMap[serieName] || fill
    } else {
      return fill
    }
  }

  // Funzione che crea la legenda del grafico
  const createLegend = (chartInstance) => {
    // Istanzio un nuovo oggetto Legend
    chartInstance.legend = new amCharts.Legend()
    // Uso il marker di base
    chartInstance.legend.useDefaultMarker = true
    // Definisco la larghezza massima e la posizione della legenda
    chartInstance.legend.position = 'bottom'
    chartInstance.legend.itemContainers.template.marginTop = -5
    // Definisco gli stili delle labels della legenda
    chartInstance.legend.labels.template.fill = chartsCore.color(labelsColor)
    chartInstance.legend.labels.template.fontFamily = 'Roboto, sans-serif'
    chartInstance.legend.labels.template.fontSize = 12
    // Modifico il marker di default con delle modifiche custom
    const markerTemplate = chartInstance.legend.markers.template
    const marker = markerTemplate.children.getIndex(0)

    markerTemplate.width = 12
    markerTemplate.height = 12
    marker.cornerRadius(12, 12, 12, 12)
    marker.adapter.add('fill', markerFillAdapter)

    // Quando faccio click sulla legenda, tengo traccia dello stato e riassegno il colore ai marker della legenda, che non seguono un tema predefinito
    const markerColumnActiveState = marker.states.getKey('active')
    markerColumnActiveState.adapter.add('fill', function (fill, target) {
      // All'interno del target vado a prendere l'oggetto sprite che contiene il colore dell'attuale marker selezionato
      if (target.sprite?.fill) {
        return chartsCore.color(target.sprite?.fill)
      } else {
        // Se non lo trovo ritorno il colore di fill per un marker disabilitato che è #999999
        return fill
      }
    })
  }

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      // Prendo il div con l'id indicato per creare il grafico
      const chart = chartsCore.create(graphContainerId, amCharts.XYChart)
      // Converto i separatori decimali e delle migliaia al formato europeo
      chart.language.locale._decimalSeparator = ','
      chart.language.locale._thousandSeparator = '.'
      // Se non è la prima renderizzazione, disabilito le animazioni
      if (!firstPaint) {
        chart.showOnInit = false
      }
      // Assegno i dati al grafico dopo averli formattati
      chart.data = formatData(data)
      // Aggiungo il cursore
      chart.cursor = new amCharts.XYCursor()
      // Disabilito il bottone di zoom out
      chart.zoomOutButton.disabled = true
      // Creo gli assi del grafico
      const { ccAxis, energyAxis } = createAxis(chart)
      // Creo la serie da graficare
      createSeries(chart, ccAxis, energyAxis)
      // Aggiungo la legenda
      createLegend(chart)
      // Assegno il grafico alla ref
      chartRef.current = chart
      // Dopo che è stato renderizzato la prima volta, setto la variabile a false
      if (firstPaint) {
        setFirstPaint(false)
      }
      return () => {
        chartRef.current.dispose()
      }
    }
  }, [data])

  return (
    <div className={clsx(classes.root, className)}>
      {Object.keys(data).length > 0
        ? (
          <div
            id={graphContainerId}
            style={{
              width: width || '100%',
              height: height || '100%'
            }}
          />
          )
        : (<LoadingCard />)}
    </div>
  )
}
export default StringAnalysisChart
