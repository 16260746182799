import { Link as RouterLink } from 'react-router-dom'
import { Box, Button, Container, Typography, useTheme, useMediaQuery, makeStyles } from '@material-ui/core'
import Page from '@components/Page'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.light,
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80
  },
  image: {
    maxWidth: '100%',
    width: 540,
    maxHeight: 300,
    height: 'auto'
  }
}))

const ErrorBoundaryView = () => {
  const classes = useStyles()
  const theme = useTheme()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Page className={classes.root} title='Errore di visualizzazione'>
      <Container maxWidth='lg' style={{ marginTop: '-40px' }}>
        <Box mb={6} display='flex' justifyContent='center'>
          <img alt='Under development' className={classes.image} src='/static/images/platform-maintenance.svg' />
        </Box>
        <Typography align='center' variant={mobileDevice ? 'h3' : 'h1'} color='secondary'>
          Errore di UI
        </Typography>
        <Typography align='center' variant='subtitle1' color='secondary'>
          Ops, l&apos;applicazione è crashata per un errore; per favore ricarica la pagina o torna alla home.
          <br />
          Se l&apos;errore persiste, contattare gli sviluppatori.
        </Typography>
        <Box mt={6} display='flex' justifyContent='center'>
          <Button color='primary' component={RouterLink} to='/app/overview/maps' variant='contained'>
            Torna alla Home
          </Button>
        </Box>
      </Container>
    </Page>
  )
}

export default ErrorBoundaryView
