import { useEffect, useState } from 'react'
import { Grid, InputBase, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { useDispatch } from '@/store'
import { /* modifyBaselineValue, */ modifyBaselineConfig, modifyPercentageLoss, setBaseline } from '@slices/pv/plantCreation'
import { europeNum } from '@utils/general'
import { basicColors } from '@theme'
import { withStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: '500px'
  },
  input: {
    minWidth: '80px',
    textAlign: 'center',
    padding: 0,
    color: basicColors.blueGrayDark
  }
}))

const LightTableCell = withStyles(() => ({
  head: {
    backgroundColor: basicColors.primary,
    color: 'white',
    fontWeight: '600'
  },
  body: {
    backgroundColor: basicColors.lightBlue2,
    color: basicColors.blueGrayDark
  }
}))(TableCell)

const DarkTableCell = withStyles(() => ({
  head: {
    backgroundColor: basicColors.blueGrayDark,
    color: 'white',
    fontWeight: '600'
  },
  body: {
    backgroundColor: basicColors.darkGreen,
    color: 'white',
    fontWeight: '600'
  }
}))(TableCell)

function BaselineTable ({ baseline, baselineConfig, disabled = false, className }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  // console.log('baseline: ', baseline)
  // console.log('baseline - baselineConfig: ', baselineConfig)

  // Valori di somma di irraggiamento e producibilità
  const [irradiationSum, setIrradiationSum] = useState(0)
  const [producibilitySum, setProducibilitySum] = useState(0)
  const [producibilitySumPerYear, setProducibilitySumPerYear] = useState([0])

  // UseEffect per il calcolo delle somme di irraggiamento e producibilità della configurazione di baseline
  useEffect(() => {
    const totalIrradiation = baselineConfig.reduce((acc, configEl) => {
      acc += Number(configEl.irradiation)
      return acc
    }, 0)
    const totalProducibility = baselineConfig.reduce((acc, configEl) => {
      acc += Number(configEl.producibility)
      return acc
    }, 0)

    setIrradiationSum(totalIrradiation)
    setProducibilitySum(totalProducibility)
  }, [baselineConfig])

  // useEffect per il calcolo della somma di producibilità per ogni anno
  useEffect(() => {
    const yearsNum = baseline && baseline.months && baseline.months.length > 0
      ? baseline.months[0].producibility
          ? baseline.months[0].producibility.length
          : 1
      : 1
    const yearProducibilitySum = []
    for (let i = 0; i < yearsNum; i++) {
      const currentYearSum = baseline.months.reduce((acc, month) => {
        acc += Number(month.producibility[i])
        return acc
      }, 0)
      yearProducibilitySum.push(currentYearSum)
    }

    setProducibilitySumPerYear(yearProducibilitySum)
  }, [baseline])

  // Funzione che modifica i valori di percentuale di perdita
  const changeBaselinePercentageLoss = (index, value) => {
    recalculateBaselinePercentageLoss(index, value)
    dispatch(modifyPercentageLoss(index, value))
  }

  // Funzione che modifica il valore dei campi di configurazione della baseline
  const changeBaselineConfig = (index, name, value) => {
    if (name === 'producibility') {
      recalculateBaselineConfig(index, value)
    }
    dispatch(modifyBaselineConfig(index, name, value))
  }

  // Funzione che modifica i valori di producibilità di baseline
  /*  const changeBaseline = (monthIndex, producibilityIndex, name, value) => {
      dispatch(modifyBaselineValue(monthIndex, producibilityIndex, name, value))
    } */

  // Funzione che in caso di modifica di perdita in baseline ricalcola l'anno in base a questo nuovo valore
  const recalculateBaselinePercentageLoss = (yearIndex, percentageLossValue) => {
    // Aggiorno l'array delle perdite percentuali con il valore modificato
    const percentageLossArray = baseline.percentageLoss.map((el, index) => index === yearIndex ? Number(percentageLossValue) : el)

    const newMonths = baseline.months.map((month, monthIndex) => {
      let configValue = baselineConfig[monthIndex].producibility

      const newProducibilityArray = month.producibility.map((prodEl, prodIndex) => {
        const newProducibility = (Number(configValue) - (Number(configValue) * Number(percentageLossArray[prodIndex]) / 100))
        configValue = newProducibility

        return isNaN(newProducibility) ? 0 : Number(newProducibility.toFixed(2))
      })

      return { ...month, producibility: newProducibilityArray }
    })

    const newBaseline = {
      ...baseline,
      months: newMonths
    }

    dispatch(setBaseline(newBaseline))
  }

  // Funzione che in caso di modifica di producibility in baselineConfig
  // ricalcola tutto l'anno/anni interessati
  const recalculateBaselineConfig = (monthIndex, configValue) => {
    if (baseline.months && monthIndex < baseline.months.length) {
      // 1. Prendo l'array delle perdite percentuali
      const percentageLossArray = baseline.percentageLoss
      // 2. Prendo l'oggetto mese all'indice passato
      const currentMonth = baseline.months[monthIndex]
      let value = configValue
      // 3. Ricalcolo un nuovo array di producibility moltiplicando il nuovo valore per la perdita corrispondente
      const newProducibilityArray = currentMonth.producibility.map((prodEl, prodIndex) => {
        const newProducibility = (Number(value) - (Number(value) * Number(percentageLossArray[prodIndex]) / 100))
        value = newProducibility
        return isNaN(newProducibility) ? 0 : Number(newProducibility.toFixed(2))
      })
      // 4. Assegno il nuovo valore
      const newMonths = baseline.months.map((month, index) => index === monthIndex ? { ...currentMonth, producibility: newProducibilityArray } : month)
      const newBaseline = {
        ...baseline,
        months: newMonths
      }

      // 5. Faccio un set della baseline
      dispatch(setBaseline(newBaseline))
    }
  }

  return (
    <Grid container spacing={2} className={className}>
      <Grid item xs={12} md={5}>
        <Paper style={{ width: '100%', overflowX: 'auto' }} variant='outlined' elevation={2}>
          <Table style={{ width: '100%' }} size='small'>
            <TableHead>
              <TableRow>
                <DarkTableCell padding='none' />
                <DarkTableCell align='center' padding='none'>Irr.</DarkTableCell>
                <DarkTableCell align='center' padding='none'>Prod.</DarkTableCell>
              </TableRow>
              <TableRow>
                <DarkTableCell />
                <DarkTableCell align='center'>kWh/mq</DarkTableCell>
                <DarkTableCell align='center'>MWh/anno</DarkTableCell>
              </TableRow>
              <TableRow>
                <DarkTableCell>Totale</DarkTableCell>
                <LightTableCell align='center'>{europeNum(irradiationSum || 0)}</LightTableCell>
                <LightTableCell align='center'>{europeNum(producibilitySum || 0)}</LightTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {baselineConfig.map((configEl, index) => (
                <TableRow key={configEl.month}>
                  <DarkTableCell>{configEl.month}</DarkTableCell>
                  <LightTableCell align='center'>
                    {disabled
                      ? configEl.irradiation || ''
                      : (
                        <InputBase
                          classes={{ input: classes.input }}
                          disabled={disabled}
                          fullWidth
                          type='number'
                          placeholder='0'
                          onChange={(e) => changeBaselineConfig(index, 'irradiation', e.target.value)}
                          value={configEl.irradiation || ''}
                        />
                        )}
                  </LightTableCell>
                  <LightTableCell align='center'>
                    {disabled
                      ? (isNaN(configEl.producibility) ? 0 : configEl.producibility) || ''
                      : (
                        <InputBase
                          classes={{ input: classes.input }}
                          disabled={disabled}
                          fullWidth
                          type='number'
                          placeholder='0'
                          onChange={(e) => changeBaselineConfig(index, 'producibility', e.target.value)}
                          value={(isNaN(configEl.producibility) ? 0 : configEl.producibility) || ''}
                        />
                        )}
                  </LightTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Grid item xs={12} md={7}>
        <Paper variant='outlined' elevation={2} className={classes.root}>
          <Table size='small' className={classes.table}>
            <TableHead>
              <TableRow>
                <DarkTableCell style={{ position: 'sticky', left: 0, zIndex: 1 }} />
                {baseline && baseline.percentageLoss && baseline.percentageLoss.length > 0
                  ? baseline.percentageLoss.map((el, index) => (
                    <DarkTableCell key={`year-${index}`} align='center' padding='none' variant='head'>
                      {`Anno ${index}`}
                    </DarkTableCell>))
                  : null}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <DarkTableCell style={{ position: 'sticky', left: 0, zIndex: 1 }} variant='head'>Perdita %</DarkTableCell>
                {baseline && baseline.percentageLoss && baseline.percentageLoss.length > 0
                  ? baseline.percentageLoss.map((el, index) => (
                    <LightTableCell key={`year-${index}`} align='center'>
                      {disabled
                        ? (isNaN(el) ? 0 : el) || ''
                        : (
                          <InputBase
                            classes={{ input: classes.input }}
                            disabled={disabled}
                            fullWidth
                            type='number'
                            placeholder='0'
                            onChange={(e) => changeBaselinePercentageLoss(index, e.target.value)}
                            value={(isNaN(el) ? 0 : el) || ''}
                          />
                          )}
                    </LightTableCell>
                    ))
                  : null}
              </TableRow>
              <TableRow>
                <DarkTableCell style={{ position: 'sticky', left: 0, zIndex: 1 }} variant='head'>MWh/anno</DarkTableCell>
                {producibilitySumPerYear.map((el, index) => (
                  <LightTableCell variant='head' key={`producibilitySum-${index}`} align='center'>
                    {europeNum(el || 0, 2)}
                  </LightTableCell>
                ))}
              </TableRow>
              {baseline && baseline.months && baseline.months.map((singleMonth, monthIndex) => (
                <TableRow key={singleMonth.month}>
                  <DarkTableCell style={{ position: 'sticky', left: 0, zIndex: 1 }}>{singleMonth.month}</DarkTableCell>
                  {
                    singleMonth.producibility && singleMonth.producibility.map((prodEl, prodIndex) => (
                      <LightTableCell key={`prod-${singleMonth.month}-${prodIndex}`} align='center'>
                        {disabled
                          ? prodEl || ''
                          : (
                            <InputBase
                              classes={{ input: classes.input }}
                              disabled
                              fullWidth
                              type='number'
                              placeholder='0'
                              // onChange={(e) => changeBaseline(monthIndex, prodIndex, 'producibility', e.target.value)}
                              value={prodEl || ''}
                            />
                            )}
                      </LightTableCell>
                    ))
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  )
}
export default BaselineTable
