import { useEffect } from 'react'
import clsx from 'clsx'
import {
  Box,
  Card,
  CardContent,
  Grid,
  makeStyles,
  MenuItem,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Formik } from 'formik'
import { calculateOrientationsSurfaces, useCreateStringDistributionTable } from './utils'
import { useSelector } from '@/store'
import {
  changeContractValue,
  changeContractValueAndOrientations,
  changeDistributionPower,
  changeModuleValue,
  selectDistributionDatalogger
} from '@slices/pv/plantCreation'
import { useDispatch } from 'react-redux'
import { getDevices } from '@utils/plantOperations'
import { europeNum } from '@utils/general'

const useStyles = makeStyles(theme => ({
  root: {},
  stepDescription: {
    color: theme.palette.text.secondary
  },
  cardDescription: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2)
  },
  peakPowerInputError: {
    '& label, & label.Mui-focused': {
      color: theme.palette.error.light
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.error.main
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.error.main
      },
      '&:hover fieldset': {
        borderColor: theme.palette.error.light
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.error.light
      }
    }
  },
  peakPowerInputSuccess: {
    '& label, & label.Mui-focused': {
      color: theme.palette.success.light
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.success.main
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.success.main
      },
      '&:hover fieldset': {
        borderColor: theme.palette.success.light
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.success.light
      }
    }
  }
}))

function PlantFeatures ({ className, ...rest }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { anagraphic, orientations, planimetry, module, distribution, peakPower, addedDataloggers, selectedDataloggerDistribution } = useSelector(state => state.pvPlantCreation)
  const { peakPower: anagraphicPeakPower } = anagraphic

  const handleChangeDistributionPower = (event) => {
    dispatch(changeDistributionPower(event.target.value))
  }

  const handleChangeDatalogger = (event) => {
    dispatch(selectDistributionDatalogger(event.target.value))
  }

  const handleChange = (data, name) => {
    if (name === 'moduleType') {
      dispatch(changeModuleValue(data, name))
    } else {
      dispatch(changeContractValue(data, name))
    }
  }

  const headerCols = selectedDataloggerDistribution !== '' ? distribution[selectedDataloggerDistribution]?.headerCols : null
  const rows = selectedDataloggerDistribution !== '' ? distribution[selectedDataloggerDistribution]?.rows : null

  const {
    tableHeader: distributionHeader,
    tableRows: distributionRows
  } = useCreateStringDistributionTable(headerCols, rows)

  useEffect(() => {
    let plantPowerPeak = 0
    Object.keys(distribution).forEach(key => {
      const currentDistributionPower = typeof distribution[key].power === 'string'
        ? Number(distribution[key].power.replaceAll(',', '.'))
        : distribution[key].power
      // console.log('distribution: ', distribution)
      // console.log('distribution ower: ', distribution[key].power)
      // console.log('currentDistributionPower: ', currentDistributionPower)
      if (currentDistributionPower) {
        plantPowerPeak += currentDistributionPower
      }
    })

    // Con la distribuzione aggiornata, devo ricalcolare la superficie e aggiornare gli orientamenti
    const moduleEfficency = module.rows && module.rows.length > 0 ? Number(module.rows[0].efficency) / 100 : 0
    const moduleMaxPower = module.rows && module.rows.length > 0 ? Number(module.rows[0].maxPower) : 0

    const newOrientations = calculateOrientationsSurfaces(moduleEfficency, moduleMaxPower, distribution, planimetry.config, orientations)
    dispatch(changeContractValueAndOrientations(plantPowerPeak.toFixed(2), 'peakPower', newOrientations))

    // dispatch(changeContractValue(plantPowerPeak.toFixed(2), 'peakPower'))
    // eslint-disable-next-line
  }, [distribution])

  useEffect(() => {
    const inverters = addedDataloggers && getDevices({ dataloggers: addedDataloggers, type: 'Inverter' })
    const firstInverter = inverters.length > 0 ? inverters[0] : null
    if (firstInverter) {
      dispatch(selectDistributionDatalogger(firstInverter.uuid))
    }
  }, [addedDataloggers, dispatch])

  return (
    <>
      <Formik>{() => (
        <form
          noValidate
          {...rest}
          className={clsx(classes.root, className)}
        >
          <Box my={3}>
            <Box mb={1}>
              <Typography className={classes.stepDescription} variant='body2'>
                Riempi le seguenti informazioni per continuare
              </Typography>
            </Box>
            <Box my={2}>
              <TextField
                classes={{ root: Number(anagraphicPeakPower) === Number(peakPower) ? classes.peakPowerInputSuccess : classes.peakPowerInputError }}
                fullWidth
                name='peakPower'
                type='number'
                size='small'
                label={`Potenza di picco impianto (${europeNum((anagraphicPeakPower || 0), 3)} kWp)`}
                value={peakPower}
                onChange={(e) => handleChange(e.target.value, 'peakPower')}
                variant='outlined'
                disabled
              />
            </Box>
            <Box my={1}>
              <Card>
                <CardContent>
                  <Grid alignItems='center' container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Typography component='div' className={classes.cardDescription} variant='body2'>
                        Distribuzione per stringa
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        select
                        size='small'
                        label='Inverter'
                        value={selectedDataloggerDistribution || ''}
                        onChange={handleChangeDatalogger}
                        variant='outlined'
                      >
                        {addedDataloggers && getDevices({ dataloggers: addedDataloggers, type: 'Inverter' }).map(inverter => (
                          <MenuItem value={inverter.uuid} key={inverter.uuid}>
                            {inverter.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        size='small'
                        type='number'
                        name='distributionPower'
                        label='Potenza di Picco (kWp)'
                        onChange={handleChangeDistributionPower}
                        value={(distribution[selectedDataloggerDistribution] && distribution[selectedDataloggerDistribution].power) || ''}
                        variant='outlined'
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                {distributionHeader && distributionRows
                  ? (
                    <PerfectScrollbar>
                      <Box minWidth={700}>
                        <Table size='small'>
                          <TableHead>
                            <TableRow>
                              {distributionHeader}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {distributionRows}
                          </TableBody>
                        </Table>
                      </Box>
                    </PerfectScrollbar>
                    )
                  : null}
              </Card>
            </Box>
          </Box>
        </form>
      )}
      </Formik>
    </>
  )
}
export default PlantFeatures
