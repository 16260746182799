// Funzione che prende in ingresso il valore massimo di una serie, il valore di un elemento della serie e la dimensione effettiva del grafico e ritorna la posizione sul grafico del valore
export const getValuePosition = (maxValue, value, chartDimension) => {
  return chartDimension * value / maxValue
}

// Funzione che prende in ingresso un valore e genera una linea verticale nel grafico
export const generateVerticalLine = (x, y, startY) => {
  return `M ${x} ${startY} V ${y}`
}

// Funzione che genera il valore d di un path svg per una barra verticale
export const generateVerticalBarPath = (x, y, barWidth, startY) => {
  return `M ${x} ${startY} V ${y} H ${x + barWidth} V ${startY} Z`
}

// Funzione che genera la spezzata dal punto precedente alle coordinate passate come parametro
export const generateLine = (x, y) => {
  return `L ${x} ${y}`
}

// Funzione che formatta le labels per l'asse x dei grafici
export const formatLabels = (labels, formatFn) => {
  return labels.map(label => formatFn(label))
}

// Funzione che calcola il valore massimo di una serie
export const getSerieMaxValue = (serie = []) => {
  if (serie?.length > 0) {
    return Math.max(...serie.filter(el => el !== null).map(serieEl => serieEl.value))
  }

  return 0
}

// Funzione che trasforma un punto globale dello schermo in un punto nel grafico svg
export const convertToSVGPoint = (svgContainer = null, x = 0, y = 0) => {
  if (svgContainer) {
    const point = svgContainer.createSVGPoint()
    point.x = x
    point.y = y
    const svgPoint = point.matrixTransform(svgContainer.getScreenCTM().inverse())

    return svgPoint
  }

  return null
}
