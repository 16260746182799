/* export const basePlanimetry = {
  draw: [
    {
      id: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
      type: 'group',
      angle: 0,
      left: -1.3209042865532865,
      top: 213.3350946377916,
      scaleX: 0.7653088105029673,
      scaleY: 0.7653088105029673,
      selectable: false,
      objects: [
        {
          type: 'path',
          angle: 0,
          top: -245.75,
          left: -605.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#636631',
          strokeWidth: 1,
          path: [
            [
              'M',
              0,
              490.5
            ],
            [
              'L',
              0,
              0
            ],
            [
              'L',
              31.5,
              47.5
            ],
            [
              'L',
              67.5,
              47.5
            ],
            [
              'L',
              129.5,
              110.5
            ],
            [
              'L',
              149.5,
              110.5
            ],
            [
              'L',
              185,
              71.5
            ],
            [
              'L',
              250,
              47.5
            ],
            [
              'L',
              367.5,
              71.5
            ],
            [
              'L',
              399,
              123
            ],
            [
              'L',
              431.5,
              154.5
            ],
            [
              'L',
              453,
              224
            ],
            [
              'L',
              930.5,
              234
            ],
            [
              'L',
              1115,
              426.5
            ],
            [
              'L',
              1156,
              375
            ],
            [
              'L',
              1210.5,
              367.5
            ],
            [
              'L',
              1210.5,
              490.5
            ],
            [
              'L',
              0,
              490.5
            ],
            [
              'Z'
            ]
          ],
          width: 1210.5,
          height: 490.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -206.25,
          left: 181.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#CDCDCD',
          strokeLinejoin: 'bevel',
          strokeWidth: 2,
          path: [
            [
              'M',
              403.654,
              41.4199
            ],
            [
              'L',
              417.649,
              1.96021
            ],
            [
              'L',
              429.144,
              1.96021
            ],
            [
              'L',
              443.138,
              41.4199
            ],
            [
              'M',
              403.654,
              41.4199
            ],
            [
              'L',
              366.17,
              75.3853
            ],
            [
              'M',
              403.654,
              41.4199
            ],
            [
              'L',
              443.138,
              75.3853
            ],
            [
              'M',
              403.654,
              41.4199
            ],
            [
              'L',
              403.654,
              75.3853
            ],
            [
              'M',
              403.654,
              41.4199
            ],
            [
              'L',
              443.138,
              41.4199
            ],
            [
              'M',
              366.17,
              75.3853
            ],
            [
              'L',
              366.17,
              90.37
            ],
            [
              'M',
              366.17,
              75.3853
            ],
            [
              'L',
              480.622,
              75.3853
            ],
            [
              'M',
              403.654,
              121.338
            ],
            [
              'L',
              378.665,
              199.758
            ],
            [
              'L',
              385.162,
              199.758
            ],
            [
              'L',
              423.146,
              147.811
            ],
            [
              'M',
              403.654,
              121.338
            ],
            [
              'L',
              423.146,
              147.811
            ],
            [
              'M',
              403.654,
              121.338
            ],
            [
              'L',
              403.654,
              113.846
            ],
            [
              'M',
              423.146,
              147.811
            ],
            [
              'L',
              461.63,
              199.758
            ],
            [
              'L',
              468.128,
              199.758
            ],
            [
              'L',
              443.138,
              121.338
            ],
            [
              'M',
              423.146,
              147.811
            ],
            [
              'L',
              443.138,
              121.338
            ],
            [
              'M',
              403.654,
              113.846
            ],
            [
              'L',
              443.138,
              75.3853
            ],
            [
              'M',
              403.654,
              113.846
            ],
            [
              'L',
              403.654,
              75.3853
            ],
            [
              'M',
              443.138,
              75.3853
            ],
            [
              'L',
              443.138,
              41.4199
            ],
            [
              'M',
              443.138,
              75.3853
            ],
            [
              'L',
              443.138,
              113.846
            ],
            [
              'M',
              443.138,
              75.3853
            ],
            [
              'L',
              492.618,
              113.846
            ],
            [
              'L',
              492.618,
              130.829
            ],
            [
              'M',
              403.654,
              75.3853
            ],
            [
              'L',
              354.175,
              113.846
            ],
            [
              'L',
              354.175,
              130.829
            ],
            [
              'M',
              403.654,
              75.3853
            ],
            [
              'L',
              443.138,
              113.846
            ],
            [
              'M',
              403.654,
              75.3853
            ],
            [
              'L',
              443.138,
              41.4199
            ],
            [
              'M',
              443.138,
              41.4199
            ],
            [
              'L',
              480.622,
              75.3853
            ],
            [
              'M',
              480.622,
              75.3853
            ],
            [
              'L',
              480.622,
              90.37
            ],
            [
              'M',
              443.138,
              121.338
            ],
            [
              'L',
              443.138,
              113.846
            ]
          ],
          width: 138.44299999999998,
          height: 197.79779000000002,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -115.25,
          left: 91.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: 'black',
          strokeLinejoin: 'round',
          strokeWidth: 2,
          path: [
            [
              'M',
              263.712,
              161.298
            ],
            [
              'L',
              263.712,
              93.3669
            ],
            [
              'L',
              769.503,
              93.3669
            ],
            [
              'M',
              284.703,
              161.298
            ],
            [
              'L',
              284.703,
              114.845
            ],
            [
              'L',
              769.503,
              114.845
            ],
            [
              'M',
              306.195,
              161.298
            ],
            [
              'L',
              306.195,
              133.326
            ],
            [
              'L',
              769.503,
              133.326
            ]
          ],
          width: 505.79100000000005,
          height: 67.9311,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -55.25,
          left: -65.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#FDFFCD',
          strokeWidth: 1,
          path: [
            [
              'M',
              108.776,
              152.806
            ],
            [
              'L',
              242.721,
              152.806
            ],
            [
              'L',
              242.721,
              217.74
            ],
            [
              'L',
              108.776,
              217.74
            ],
            [
              'L',
              108.776,
              152.806
            ],
            [
              'Z'
            ]
          ],
          width: 133.945,
          height: 64.934,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -42.25,
          left: -51.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#29FFFE',
          strokeWidth: 1,
          path: [
            [
              'M',
              124.769,
              165.793
            ],
            [
              'L',
              133.766,
              165.793
            ],
            [
              'L',
              133.766,
              199.758
            ],
            [
              'L',
              124.769,
              199.758
            ],
            [
              'L',
              124.769,
              165.793
            ],
            [
              'Z'
            ]
          ],
          width: 8.996999999999986,
          height: 33.965,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -42.25,
          left: -31.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#29FFFE',
          strokeWidth: 1,
          path: [
            [
              'M',
              144.761,
              165.793
            ],
            [
              'L',
              153.758,
              165.793
            ],
            [
              'L',
              153.758,
              199.758
            ],
            [
              'L',
              144.761,
              199.758
            ],
            [
              'L',
              144.761,
              165.793
            ],
            [
              'Z'
            ]
          ],
          width: 8.997000000000014,
          height: 33.965,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -42.25,
          left: -11.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#29FFFE',
          strokeWidth: 1,
          path: [
            [
              'M',
              162.754,
              165.793
            ],
            [
              'L',
              171.75,
              165.793
            ],
            [
              'L',
              171.75,
              199.758
            ],
            [
              'L',
              162.754,
              199.758
            ],
            [
              'L',
              162.754,
              165.793
            ],
            [
              'Z'
            ]
          ],
          width: 8.99600000000001,
          height: 33.965,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -42.25,
          left: 8.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#29FFFE',
          strokeWidth: 1,
          path: [
            [
              'M',
              181.746,
              165.793
            ],
            [
              'L',
              190.742,
              165.793
            ],
            [
              'L',
              190.742,
              199.758
            ],
            [
              'L',
              181.746,
              199.758
            ],
            [
              'L',
              181.746,
              165.793
            ],
            [
              'Z'
            ]
          ],
          width: 8.995999999999981,
          height: 33.965,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -42.25,
          left: 28.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#29FFFE',
          strokeWidth: 1,
          path: [
            [
              'M',
              200.738,
              165.793
            ],
            [
              'L',
              209.734,
              165.793
            ],
            [
              'L',
              209.734,
              199.758
            ],
            [
              'L',
              200.738,
              199.758
            ],
            [
              'L',
              200.738,
              165.793
            ],
            [
              'Z'
            ]
          ],
          width: 8.99600000000001,
          height: 33.965,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -42.25,
          left: 48.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#29FFFE',
          strokeWidth: 1,
          path: [
            [
              'M',
              219.73,
              165.793
            ],
            [
              'L',
              228.727,
              165.793
            ],
            [
              'L',
              228.727,
              199.758
            ],
            [
              'L',
              219.73,
              199.758
            ],
            [
              'L',
              219.73,
              165.793
            ],
            [
              'Z'
            ]
          ],
          width: 8.997000000000014,
          height: 33.965,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -131.25,
          left: -172.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#D9D9D9',
          strokeWidth: 1,
          path: [
            [
              'M',
              0.320923,
              107.852
            ],
            [
              'L',
              2.81989,
              76.8838
            ],
            [
              'L',
              10.3168,
              76.8838
            ],
            [
              'L',
              7.81782,
              107.852
            ],
            [
              'L',
              79.7881,
              326.129
            ],
            [
              'L',
              93.2825,
              326.129
            ],
            [
              'L',
              93.2825,
              217.241
            ],
            [
              'L',
              256.715,
              217.241
            ],
            [
              'L',
              256.715,
              189.769
            ],
            [
              'L',
              317.69,
              189.769
            ],
            [
              'L',
              317.69,
              217.241
            ],
            [
              'L',
              480.622,
              217.241
            ],
            [
              'L',
              480.622,
              365.589
            ],
            [
              'L',
              93.2825,
              365.589
            ],
            [
              'L',
              93.2825,
              337.118
            ],
            [
              'L',
              75.7897,
              337.118
            ],
            [
              'L',
              0.320923,
              107.852
            ],
            [
              'Z'
            ]
          ],
          width: 480.301077,
          height: 288.7052,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -47.25,
          left: 87.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#D9D9D9',
          strokeWidth: 1,
          path: [
            [
              'M',
              259.714,
              162.297
            ],
            [
              'C',
              259.714,
              159.814,
              261.728,
              157.801,
              264.212,
              157.801
            ],
            [
              'C',
              266.696,
              157.801,
              268.71,
              159.814,
              268.71,
              162.297
            ],
            [
              'L',
              268.71,
              190.768
            ],
            [
              'L',
              259.714,
              190.768
            ],
            [
              'L',
              259.714,
              162.297
            ],
            [
              'Z'
            ]
          ],
          width: 8.995999999999981,
          height: 32.96700000000001,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -47.25,
          left: 108.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#D9D9D9',
          strokeWidth: 1,
          path: [
            [
              'M',
              280.705,
              162.297
            ],
            [
              'C',
              280.705,
              159.814,
              282.719,
              157.801,
              285.203,
              157.801
            ],
            [
              'C',
              287.687,
              157.801,
              289.701,
              159.814,
              289.701,
              162.297
            ],
            [
              'L',
              289.701,
              190.768
            ],
            [
              'L',
              280.705,
              190.768
            ],
            [
              'L',
              280.705,
              162.297
            ],
            [
              'Z'
            ]
          ],
          width: 8.996000000000038,
          height: 32.96700000000001,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -47.25,
          left: 129.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#D9D9D9',
          strokeWidth: 1,
          path: [
            [
              'M',
              301.696,
              162.297
            ],
            [
              'C',
              301.696,
              159.814,
              303.71,
              157.801,
              306.195,
              157.801
            ],
            [
              'C',
              308.679,
              157.801,
              310.693,
              159.814,
              310.693,
              162.297
            ],
            [
              'L',
              310.693,
              190.768
            ],
            [
              'L',
              301.696,
              190.768
            ],
            [
              'L',
              301.696,
              162.297
            ],
            [
              'Z'
            ]
          ],
          width: 8.996999999999957,
          height: 32.96700000000001,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -20.25,
          left: 86.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              258.714,
              187.771
            ],
            [
              'L',
              269.71,
              187.771
            ],
            [
              'L',
              269.71,
              188.77
            ],
            [
              'L',
              258.714,
              188.77
            ],
            [
              'L',
              258.714,
              187.771
            ],
            [
              'Z'
            ]
          ],
          width: 10.995999999999981,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -23.25,
          left: 86.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              258.714,
              184.774
            ],
            [
              'L',
              269.71,
              184.774
            ],
            [
              'L',
              269.71,
              185.773
            ],
            [
              'L',
              258.714,
              185.773
            ],
            [
              'L',
              258.714,
              184.774
            ],
            [
              'Z'
            ]
          ],
          width: 10.995999999999981,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -26.25,
          left: 86.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              258.714,
              181.777
            ],
            [
              'L',
              269.71,
              181.777
            ],
            [
              'L',
              269.71,
              182.776
            ],
            [
              'L',
              258.714,
              182.776
            ],
            [
              'L',
              258.714,
              181.777
            ],
            [
              'Z'
            ]
          ],
          width: 10.995999999999981,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -29.25,
          left: 86.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              258.714,
              178.78
            ],
            [
              'L',
              269.71,
              178.78
            ],
            [
              'L',
              269.71,
              179.779
            ],
            [
              'L',
              258.714,
              179.779
            ],
            [
              'L',
              258.714,
              178.78
            ],
            [
              'Z'
            ]
          ],
          width: 10.995999999999981,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -32.25,
          left: 86.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              258.714,
              175.783
            ],
            [
              'L',
              269.71,
              175.783
            ],
            [
              'L',
              269.71,
              176.782
            ],
            [
              'L',
              258.714,
              176.782
            ],
            [
              'L',
              258.714,
              175.783
            ],
            [
              'Z'
            ]
          ],
          width: 10.995999999999981,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -35.25,
          left: 86.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              258.714,
              172.786
            ],
            [
              'L',
              269.71,
              172.786
            ],
            [
              'L',
              269.71,
              173.785
            ],
            [
              'L',
              258.714,
              173.785
            ],
            [
              'L',
              258.714,
              172.786
            ],
            [
              'Z'
            ]
          ],
          width: 10.995999999999981,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -38.25,
          left: 86.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              258.714,
              169.789
            ],
            [
              'L',
              269.71,
              169.789
            ],
            [
              'L',
              269.71,
              170.788
            ],
            [
              'L',
              258.714,
              170.788
            ],
            [
              'L',
              258.714,
              169.789
            ],
            [
              'Z'
            ]
          ],
          width: 10.995999999999981,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -41.25,
          left: 86.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              258.714,
              166.792
            ],
            [
              'L',
              269.71,
              166.792
            ],
            [
              'L',
              269.71,
              167.791
            ],
            [
              'L',
              258.714,
              167.791
            ],
            [
              'L',
              258.714,
              166.792
            ],
            [
              'Z'
            ]
          ],
          width: 10.995999999999981,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -44.25,
          left: 86.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              258.714,
              163.795
            ],
            [
              'L',
              269.71,
              163.795
            ],
            [
              'L',
              269.71,
              164.794
            ],
            [
              'L',
              258.714,
              164.794
            ],
            [
              'L',
              258.714,
              163.795
            ],
            [
              'Z'
            ]
          ],
          width: 10.995999999999981,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -20.25,
          left: 107.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              279.705,
              187.771
            ],
            [
              'L',
              290.701,
              187.771
            ],
            [
              'L',
              290.701,
              188.77
            ],
            [
              'L',
              279.705,
              188.77
            ],
            [
              'L',
              279.705,
              187.771
            ],
            [
              'Z'
            ]
          ],
          width: 10.996000000000038,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -23.25,
          left: 107.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              279.705,
              184.774
            ],
            [
              'L',
              290.701,
              184.774
            ],
            [
              'L',
              290.701,
              185.773
            ],
            [
              'L',
              279.705,
              185.773
            ],
            [
              'L',
              279.705,
              184.774
            ],
            [
              'Z'
            ]
          ],
          width: 10.996000000000038,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -26.25,
          left: 107.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              279.705,
              181.777
            ],
            [
              'L',
              290.701,
              181.777
            ],
            [
              'L',
              290.701,
              182.776
            ],
            [
              'L',
              279.705,
              182.776
            ],
            [
              'L',
              279.705,
              181.777
            ],
            [
              'Z'
            ]
          ],
          width: 10.996000000000038,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -29.25,
          left: 107.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              279.705,
              178.78
            ],
            [
              'L',
              290.701,
              178.78
            ],
            [
              'L',
              290.701,
              179.779
            ],
            [
              'L',
              279.705,
              179.779
            ],
            [
              'L',
              279.705,
              178.78
            ],
            [
              'Z'
            ]
          ],
          width: 10.996000000000038,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -32.25,
          left: 107.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              279.705,
              175.783
            ],
            [
              'L',
              290.701,
              175.783
            ],
            [
              'L',
              290.701,
              176.782
            ],
            [
              'L',
              279.705,
              176.782
            ],
            [
              'L',
              279.705,
              175.783
            ],
            [
              'Z'
            ]
          ],
          width: 10.996000000000038,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -35.25,
          left: 107.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              279.705,
              172.786
            ],
            [
              'L',
              290.701,
              172.786
            ],
            [
              'L',
              290.701,
              173.785
            ],
            [
              'L',
              279.705,
              173.785
            ],
            [
              'L',
              279.705,
              172.786
            ],
            [
              'Z'
            ]
          ],
          width: 10.996000000000038,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -38.25,
          left: 107.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              279.705,
              169.789
            ],
            [
              'L',
              290.701,
              169.789
            ],
            [
              'L',
              290.701,
              170.788
            ],
            [
              'L',
              279.705,
              170.788
            ],
            [
              'L',
              279.705,
              169.789
            ],
            [
              'Z'
            ]
          ],
          width: 10.996000000000038,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -41.25,
          left: 107.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              279.705,
              166.792
            ],
            [
              'L',
              290.701,
              166.792
            ],
            [
              'L',
              290.701,
              167.791
            ],
            [
              'L',
              279.705,
              167.791
            ],
            [
              'L',
              279.705,
              166.792
            ],
            [
              'Z'
            ]
          ],
          width: 10.996000000000038,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -44.25,
          left: 107.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              279.705,
              163.795
            ],
            [
              'L',
              290.701,
              163.795
            ],
            [
              'L',
              290.701,
              164.794
            ],
            [
              'L',
              279.705,
              164.794
            ],
            [
              'L',
              279.705,
              163.795
            ],
            [
              'Z'
            ]
          ],
          width: 10.996000000000038,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -20.25,
          left: 128.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              300.697,
              187.771
            ],
            [
              'L',
              311.692,
              187.771
            ],
            [
              'L',
              311.692,
              188.77
            ],
            [
              'L',
              300.697,
              188.77
            ],
            [
              'L',
              300.697,
              187.771
            ],
            [
              'Z'
            ]
          ],
          width: 10.995000000000005,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -23.25,
          left: 128.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              300.697,
              184.774
            ],
            [
              'L',
              311.692,
              184.774
            ],
            [
              'L',
              311.692,
              185.773
            ],
            [
              'L',
              300.697,
              185.773
            ],
            [
              'L',
              300.697,
              184.774
            ],
            [
              'Z'
            ]
          ],
          width: 10.995000000000005,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -26.25,
          left: 128.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              300.697,
              181.777
            ],
            [
              'L',
              311.692,
              181.777
            ],
            [
              'L',
              311.692,
              182.776
            ],
            [
              'L',
              300.697,
              182.776
            ],
            [
              'L',
              300.697,
              181.777
            ],
            [
              'Z'
            ]
          ],
          width: 10.995000000000005,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -29.25,
          left: 128.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              300.697,
              178.78
            ],
            [
              'L',
              311.692,
              178.78
            ],
            [
              'L',
              311.692,
              179.779
            ],
            [
              'L',
              300.697,
              179.779
            ],
            [
              'L',
              300.697,
              178.78
            ],
            [
              'Z'
            ]
          ],
          width: 10.995000000000005,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -32.25,
          left: 128.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              300.697,
              175.783
            ],
            [
              'L',
              311.692,
              175.783
            ],
            [
              'L',
              311.692,
              176.782
            ],
            [
              'L',
              300.697,
              176.782
            ],
            [
              'L',
              300.697,
              175.783
            ],
            [
              'Z'
            ]
          ],
          width: 10.995000000000005,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -35.25,
          left: 128.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              300.697,
              172.786
            ],
            [
              'L',
              311.692,
              172.786
            ],
            [
              'L',
              311.692,
              173.785
            ],
            [
              'L',
              300.697,
              173.785
            ],
            [
              'L',
              300.697,
              172.786
            ],
            [
              'Z'
            ]
          ],
          width: 10.995000000000005,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -38.25,
          left: 128.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              300.697,
              169.789
            ],
            [
              'L',
              311.692,
              169.789
            ],
            [
              'L',
              311.692,
              170.788
            ],
            [
              'L',
              300.697,
              170.788
            ],
            [
              'L',
              300.697,
              169.789
            ],
            [
              'Z'
            ]
          ],
          width: 10.995000000000005,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -41.25,
          left: 128.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              300.697,
              166.792
            ],
            [
              'L',
              311.692,
              166.792
            ],
            [
              'L',
              311.692,
              167.791
            ],
            [
              'L',
              300.697,
              167.791
            ],
            [
              'L',
              300.697,
              166.792
            ],
            [
              'Z'
            ]
          ],
          width: 10.995000000000005,
          height: 0.9989999999999952,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -44.25,
          left: 128.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#BFBEBE',
          strokeWidth: 1,
          path: [
            [
              'M',
              300.697,
              163.795
            ],
            [
              'L',
              311.692,
              163.795
            ],
            [
              'L',
              311.692,
              164.794
            ],
            [
              'L',
              300.697,
              164.794
            ],
            [
              'L',
              300.697,
              163.795
            ],
            [
              'Z'
            ]
          ],
          width: 10.995000000000005,
          height: 0.9990000000000236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -159.25,
          left: -500.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#00CDFB',
          strokeWidth: 1,
          path: [
            [
              'M',
              67,
              0
            ],
            [
              'L',
              0.5,
              0
            ],
            [
              'L',
              24.5,
              24.5
            ],
            [
              'L',
              322.5,
              72.0773
            ],
            [
              'L',
              396.847,
              298.748
            ],
            [
              'L',
              450.193,
              298.748
            ],
            [
              'L',
              450.193,
              312.666
            ],
            [
              'L',
              479.109,
              312.666
            ],
            [
              'L',
              479.109,
              341
            ],
            [
              'L',
              528.965,
              341
            ],
            [
              'L',
              528.965,
              317.14
            ],
            [
              'L',
              991.631,
              324.099
            ],
            [
              'L',
              1010,
              340.5
            ],
            [
              'L',
              1034.5,
              310
            ],
            [
              'L',
              980.5,
              310
            ],
            [
              'L',
              987,
              317
            ],
            [
              'L',
              556.885,
              309.187
            ],
            [
              'L',
              556.885,
              274.888
            ],
            [
              'L',
              450.193,
              274.888
            ],
            [
              'L',
              450.193,
              287.812
            ],
            [
              'L',
              405.322,
              287.812
            ],
            [
              'L',
              330.5,
              63.1297
            ],
            [
              'L',
              44.5,
              24.5
            ],
            [
              'L',
              67,
              0
            ],
            [
              'Z'
            ]
          ],
          width: 1034,
          height: 341,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -3.25,
          left: 37.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#FF2721',
          strokeLinejoin: 'round',
          strokeWidth: 4,
          path: [
            [
              'M',
              0.733582,
              83.6788
            ],
            [
              'L',
              73.1454,
              83.6788
            ],
            [
              'L',
              73.1454,
              12.6794
            ]
          ],
          width: 72.411818,
          height: 70.9994,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'circle',
          angle: 0,
          top: -3.25,
          left: 112.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'center',
          originY: 'center',
          radius: 10,
          fill: '#FF2721',
          strokeWidth: 1,
          width: 20,
          height: 20,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'rect',
          angle: 0,
          top: 45.75,
          left: -35.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#626466',
          strokeWidth: 1,
          width: 75,
          height: 48,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 93.75,
          left: -4.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#626466',
          strokeWidth: 1,
          path: [
            [
              'M',
              38.7518,
              48.6544
            ],
            [
              'L',
              52.746,
              48.6544
            ],
            [
              'L',
              52.746,
              115.586
            ],
            [
              'L',
              45.7489,
              127.574
            ],
            [
              'L',
              38.7518,
              115.586
            ],
            [
              'L',
              38.7518,
              48.6544
            ],
            [
              'Z'
            ]
          ],
          width: 13.9942,
          height: 78.9196,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 125.75,
          left: -42.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#626466',
          strokeWidth: 1,
          path: [
            [
              'M',
              25.3789,
              79.2169
            ],
            [
              'C',
              23.3303,
              78.8342,
              21.0972,
              78.6239,
              18.76,
              78.6239
            ],
            [
              'C',
              8.823,
              78.6239,
              0.767456,
              82.4256,
              0.767456,
              87.1152
            ],
            [
              'C',
              0.767456,
              91.8049,
              8.823,
              95.6066,
              18.76,
              95.6066
            ],
            [
              'C',
              20.3632,
              95.6066,
              21.9174,
              95.5076,
              23.3967,
              95.3219
            ],
            [
              'C',
              18.7334,
              93.4897,
              15.7612,
              90.7174,
              15.7612,
              87.6147
            ],
            [
              'C',
              15.7612,
              84.0923,
              19.592,
              80.9957,
              25.3789,
              79.2169
            ],
            [
              'Z'
            ]
          ],
          width: 24.611444000000002,
          height: 16.982699999999994,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 125.75,
          left: 24.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#626466',
          strokeWidth: 1,
          path: [
            [
              'M',
              65.7407,
              79.2169
            ],
            [
              'C',
              67.7894,
              78.8342,
              70.0225,
              78.6239,
              72.3597,
              78.6239
            ],
            [
              'C',
              82.2967,
              78.6239,
              90.3522,
              82.4256,
              90.3522,
              87.1152
            ],
            [
              'C',
              90.3522,
              91.8049,
              82.2967,
              95.6066,
              72.3597,
              95.6066
            ],
            [
              'C',
              70.7565,
              95.6066,
              69.2023,
              95.5076,
              67.723,
              95.3219
            ],
            [
              'C',
              72.3863,
              93.4897,
              75.3585,
              90.7174,
              75.3585,
              87.6147
            ],
            [
              'C',
              75.3585,
              84.0923,
              71.5277,
              80.9957,
              65.7407,
              79.2169
            ],
            [
              'Z'
            ]
          ],
          width: 24.611499999999992,
          height: 16.982699999999994,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 127.75,
          left: -24.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#626466',
          strokeWidth: 1,
          path: [
            [
              'M',
              43.7497,
              80.6218
            ],
            [
              'C',
              30.8291,
              80.6218,
              18.76,
              83.1429,
              18.76,
              87.4852
            ],
            [
              'C',
              18.76,
              91.8274,
              30.4594,
              94.1081,
              43.7497,
              94.1081
            ],
            [
              'L',
              43.7497,
              80.6218
            ],
            [
              'Z'
            ]
          ],
          width: 24.989699999999996,
          height: 13.4863,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 127.75,
          left: -15.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#626466',
          strokeWidth: 1,
          path: [
            [
              'M',
              46.7485,
              80.6218
            ],
            [
              'C',
              60.0387,
              80.6218,
              71.7381,
              83.1429,
              71.7381,
              87.4852
            ],
            [
              'C',
              71.7381,
              91.8274,
              60.0387,
              94.1081,
              46.7485,
              94.1081
            ],
            [
              'L',
              46.7485,
              80.6218
            ],
            [
              'Z'
            ],
            [
              'M',
              23.6099,
              87.5021
            ],
            [
              'C',
              23.6099,
              87.4964,
              23.6098,
              87.4908,
              23.6098,
              87.4852
            ],
            [
              'C',
              23.6098,
              87.4796,
              23.6099,
              87.4739,
              23.6099,
              87.4683
            ],
            [
              'L',
              23.6099,
              87.5021
            ],
            [
              'Z'
            ]
          ],
          width: 48.1283,
          height: 13.4863,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'rect',
          angle: 0,
          top: 49.75,
          left: -28.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#DB9B2E',
          strokeWidth: 1,
          rx: 5.5,
          width: 11,
          height: 38,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'rect',
          angle: 0,
          top: 49.75,
          left: -12.25,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#DB9B2E',
          strokeWidth: 1,
          rx: 5.5,
          width: 11,
          height: 38,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'rect',
          angle: 0,
          top: 49.75,
          left: 4.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#DB9B2E',
          strokeWidth: 1,
          rx: 5.5,
          width: 11,
          height: 38,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'rect',
          angle: 0,
          top: 49.75,
          left: 22.75,
          groupId: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
          originX: 'left',
          originY: 'top',
          fill: '#DB9B2E',
          strokeWidth: 1,
          rx: 5.5,
          width: 11,
          height: 38,
          scaleX: 1,
          scaleY: 1
        }
      ]
    }
  ],
  config: [
    {
      id: '44d0d120-6dcd-4faf-9741-f93db04e51f8',
      canvasLibraryType: 'mountain',
      data: {
        draw: {}
      },
      devices: []
    }
  ]
} */

export const basePlanimetry = {
  draw: [
    {
      id: '09296bcc-61e4-4689-aee1-f997e272cdb0',
      type: 'group',
      angle: 0,
      left: 27.5,
      top: 44.234375,
      scaleX: 1,
      scaleY: 1,
      objects: [
        {
          type: 'path',
          angle: 0,
          top: -286.3275122330023,
          left: -1291.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#00ABE6',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              594,
              2.49985
            ],
            [
              'C',
              248,
              2.49985,
              144,
              -13.5,
              19,
              53.9999
            ],
            [
              'L',
              315,
              301.5
            ],
            [
              'L',
              530,
              264.5
            ],
            [
              'L',
              733,
              180
            ],
            [
              'C',
              806.333,
              161.167,
              1004.8,
              123.1,
              1212,
              121.5
            ],
            [
              'L',
              1791.5,
              121.5
            ],
            [
              'C',
              1934.5,
              144.833,
              2247.6,
              211.3,
              2356,
              290.5
            ],
            [
              'C',
              2393.33,
              315.5,
              2482.4,
              392.6,
              2540,
              501
            ],
            [
              'L',
              2612,
              501
            ],
            [
              'C',
              2581.6,
              421.8,
              2491.33,
              327.667,
              2450,
              290.5
            ],
            [
              'C',
              2315.5,
              157.5,
              1919,
              69.4999,
              1791.5,
              42.4999
            ],
            [
              'L',
              1481,
              23.9999
            ],
            [
              'C',
              1022.2,
              0.399851,
              698.5,
              -0.166814,
              594,
              2.49985
            ],
            [
              'Z'
            ]
          ],
          width: 2593,
          height: 500.15502446600465,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -153.17248776699768,
          left: -655.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#FFF6E0',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              734.5,
              152.5
            ],
            [
              'L',
              655,
              153
            ],
            [
              'L',
              695,
              134
            ],
            [
              'L',
              734.5,
              152.5
            ],
            [
              'Z'
            ]
          ],
          width: 79.5,
          height: 19,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -193.67248776699768,
          left: -1157.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#FFF6E0',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              167.5,
              253
            ],
            [
              'L',
              153,
              240.5
            ],
            [
              'L',
              153,
              115.5
            ],
            [
              'L',
              217.5,
              93.5
            ],
            [
              'L',
              232.5,
              105.5
            ],
            [
              'L',
              233,
              137
            ],
            [
              'L',
              260.5,
              127.5
            ],
            [
              'L',
              275.5,
              140
            ],
            [
              'L',
              275.5,
              195.5
            ],
            [
              'L',
              324,
              179.5
            ],
            [
              'L',
              339,
              191
            ],
            [
              'L',
              339,
              316.5
            ],
            [
              'L',
              274,
              338
            ],
            [
              'L',
              259.5,
              327.5
            ],
            [
              'L',
              259.5,
              272.5
            ],
            [
              'L',
              215,
              237
            ],
            [
              'L',
              210.5,
              237
            ],
            [
              'L',
              210.5,
              246.5
            ],
            [
              'C',
              205,
              252.167,
              196.7,
              267.7,
              207.5,
              284.5
            ],
            [
              'L',
              196,
              275.5
            ],
            [
              'L',
              196,
              224.5
            ],
            [
              'L',
              167.5,
              203
            ],
            [
              'L',
              167.5,
              253
            ],
            [
              'Z'
            ]
          ],
          width: 186,
          height: 244.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -193.67248776699768,
          left: -1157.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              153,
              115.5
            ],
            [
              'L',
              167.5,
              127.5
            ],
            [
              'M',
              153,
              115.5
            ],
            [
              'L',
              153,
              240.5
            ],
            [
              'L',
              167.5,
              253
            ],
            [
              'L',
              167.5,
              203
            ],
            [
              'M',
              153,
              115.5
            ],
            [
              'L',
              217.5,
              93.5
            ],
            [
              'L',
              232.5,
              105.5
            ],
            [
              'M',
              167.5,
              127.5
            ],
            [
              'L',
              232.5,
              105.5
            ],
            [
              'M',
              167.5,
              127.5
            ],
            [
              'L',
              167.5,
              203
            ],
            [
              'M',
              232.5,
              105.5
            ],
            [
              'L',
              233,
              137
            ],
            [
              'M',
              233,
              137
            ],
            [
              'L',
              196,
              149.5
            ],
            [
              'M',
              233,
              137
            ],
            [
              'L',
              260.5,
              127.5
            ],
            [
              'L',
              275.5,
              140
            ],
            [
              'M',
              196,
              149.5
            ],
            [
              'L',
              196,
              224.5
            ],
            [
              'M',
              196,
              149.5
            ],
            [
              'L',
              210.5,
              161.5
            ],
            [
              'M',
              196,
              224.5
            ],
            [
              'L',
              167.5,
              203
            ],
            [
              'M',
              196,
              224.5
            ],
            [
              'L',
              196,
              275.5
            ],
            [
              'L',
              207.5,
              284.5
            ],
            [
              'C',
              196.7,
              267.7,
              205,
              252.167,
              210.5,
              246.5
            ],
            [
              'L',
              210.5,
              237
            ],
            [
              'M',
              275.5,
              140
            ],
            [
              'L',
              210.5,
              161.5
            ],
            [
              'M',
              275.5,
              140
            ],
            [
              'L',
              275.5,
              195.5
            ],
            [
              'M',
              210.5,
              161.5
            ],
            [
              'L',
              210.5,
              237
            ],
            [
              'M',
              275.5,
              195.5
            ],
            [
              'L',
              259.5,
              201
            ],
            [
              'M',
              275.5,
              195.5
            ],
            [
              'L',
              324,
              179.5
            ],
            [
              'L',
              339,
              191
            ],
            [
              'M',
              259.5,
              201
            ],
            [
              'L',
              259.5,
              272.5
            ],
            [
              'M',
              259.5,
              201
            ],
            [
              'L',
              274,
              213
            ],
            [
              'M',
              259.5,
              272.5
            ],
            [
              'L',
              215,
              237
            ],
            [
              'L',
              210.5,
              237
            ],
            [
              'M',
              259.5,
              272.5
            ],
            [
              'L',
              259.5,
              327.5
            ],
            [
              'L',
              274,
              338
            ],
            [
              'M',
              339,
              191
            ],
            [
              'L',
              274,
              213
            ],
            [
              'M',
              339,
              191
            ],
            [
              'L',
              339,
              316.5
            ],
            [
              'L',
              274,
              338
            ],
            [
              'M',
              274,
              213
            ],
            [
              'L',
              274,
              338
            ]
          ],
          width: 186,
          height: 244.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -83.17248776699768,
          left: -1143.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#ffffff',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              167.5,
              253
            ],
            [
              'L',
              195.5,
              275
            ],
            [
              'L',
              195.5,
              225
            ],
            [
              'L',
              168,
              204
            ],
            [
              'L',
              167.5,
              253
            ],
            [
              'Z'
            ]
          ],
          width: 28,
          height: 71,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -234.17248776699768,
          left: -1309.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#ffffff',
          strokeWidth: 1,
          path: [
            [
              'M',
              153,
              240.5
            ],
            [
              'L',
              1,
              117.5
            ],
            [
              'L',
              1,
              68
            ],
            [
              'L',
              1,
              53
            ],
            [
              'L',
              18,
              53
            ],
            [
              'L',
              153,
              162
            ],
            [
              'L',
              153,
              176.25
            ],
            [
              'L',
              153,
              190.5
            ],
            [
              'L',
              153,
              240.5
            ],
            [
              'Z'
            ]
          ],
          width: 152,
          height: 187.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -234.17248776699768,
          left: -1309.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              1,
              53
            ],
            [
              'L',
              18,
              53
            ],
            [
              'L',
              153,
              162
            ],
            [
              'L',
              153,
              176.25
            ],
            [
              'M',
              1,
              53
            ],
            [
              'L',
              1,
              68
            ],
            [
              'M',
              1,
              53
            ],
            [
              'L',
              153,
              176.25
            ],
            [
              'M',
              153,
              190.5
            ],
            [
              'L',
              153,
              240.5
            ],
            [
              'L',
              1,
              117.5
            ],
            [
              'L',
              1,
              68
            ],
            [
              'M',
              153,
              190.5
            ],
            [
              'L',
              1,
              68
            ],
            [
              'M',
              153,
              190.5
            ],
            [
              'L',
              153,
              176.25
            ]
          ],
          width: 152,
          height: 187.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -8.172487766997676,
          left: -895.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#ffffff',
          strokeWidth: 1,
          path: [
            [
              'M',
              425,
              341.5
            ],
            [
              'L',
              415,
              335
            ],
            [
              'L',
              415,
              288.5
            ],
            [
              'L',
              476,
              279
            ],
            [
              'L',
              485.5,
              285.5
            ],
            [
              'L',
              485.5,
              332.5
            ],
            [
              'L',
              425,
              341.5
            ],
            [
              'Z'
            ]
          ],
          width: 70.5,
          height: 62.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -8.172487766997676,
          left: -895.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              415,
              288.5
            ],
            [
              'L',
              425,
              295
            ],
            [
              'M',
              415,
              288.5
            ],
            [
              'L',
              415,
              335
            ],
            [
              'L',
              425,
              341.5
            ],
            [
              'M',
              415,
              288.5
            ],
            [
              'L',
              476,
              279
            ],
            [
              'L',
              485.5,
              285.5
            ],
            [
              'M',
              425,
              295
            ],
            [
              'L',
              425,
              341.5
            ],
            [
              'M',
              425,
              295
            ],
            [
              'L',
              485.5,
              285.5
            ],
            [
              'M',
              425,
              341.5
            ],
            [
              'L',
              485.5,
              332.5
            ],
            [
              'L',
              485.5,
              285.5
            ]
          ],
          width: 70.5,
          height: 62.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 16.827512233002324,
          left: -863.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#D9D9D9',
          strokeWidth: 1,
          path: [
            [
              'M',
              447.5,
              304.5
            ],
            [
              'L',
              451,
              304
            ],
            [
              'L',
              470,
              316
            ],
            [
              'L',
              466,
              316.671
            ],
            [
              'L',
              460.5,
              317.5
            ],
            [
              'L',
              460.5,
              347
            ],
            [
              'L',
              447.5,
              338.5
            ],
            [
              'L',
              447.5,
              304.5
            ],
            [
              'Z'
            ]
          ],
          width: 22.5,
          height: 43,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 16.827512233002324,
          left: -863.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              447.5,
              304.5
            ],
            [
              'L',
              451,
              304
            ],
            [
              'L',
              470,
              316
            ],
            [
              'L',
              466,
              316.671
            ],
            [
              'M',
              447.5,
              304.5
            ],
            [
              'L',
              466,
              316.671
            ],
            [
              'M',
              447.5,
              304.5
            ],
            [
              'L',
              447.5,
              338.5
            ],
            [
              'L',
              460.5,
              347
            ],
            [
              'L',
              460.5,
              317.5
            ],
            [
              'L',
              466,
              316.671
            ]
          ],
          width: 22.5,
          height: 43,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -12.172487766997676,
          left: -908.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#D9D9D9',
          strokeWidth: 1,
          path: [
            [
              'M',
              402,
              275.5
            ],
            [
              'L',
              405.5,
              275
            ],
            [
              'L',
              424.5,
              287
            ],
            [
              'L',
              420.5,
              287.671
            ],
            [
              'L',
              415,
              288.5
            ],
            [
              'L',
              415,
              318
            ],
            [
              'L',
              402,
              309.5
            ],
            [
              'L',
              402,
              275.5
            ],
            [
              'Z'
            ]
          ],
          width: 22.5,
          height: 43,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -12.172487766997676,
          left: -908.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              402,
              275.5
            ],
            [
              'L',
              405.5,
              275
            ],
            [
              'L',
              424.5,
              287
            ],
            [
              'L',
              420.5,
              287.671
            ],
            [
              'M',
              402,
              275.5
            ],
            [
              'L',
              420.5,
              287.671
            ],
            [
              'M',
              402,
              275.5
            ],
            [
              'L',
              402,
              309.5
            ],
            [
              'L',
              415,
              318
            ],
            [
              'L',
              415,
              288.5
            ],
            [
              'L',
              420.5,
              287.671
            ]
          ],
          width: 22.5,
          height: 43,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 20.827512233002324,
          left: -850.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#ffffff',
          strokeWidth: 1,
          path: [
            [
              'M',
              470.5,
              370.5
            ],
            [
              'L',
              460.5,
              364
            ],
            [
              'L',
              460.5,
              317.5
            ],
            [
              'L',
              521.5,
              308
            ],
            [
              'L',
              531,
              314.5
            ],
            [
              'L',
              531,
              361.5
            ],
            [
              'L',
              470.5,
              370.5
            ],
            [
              'Z'
            ]
          ],
          width: 70.5,
          height: 62.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 20.827512233002324,
          left: -850.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              460.5,
              317.5
            ],
            [
              'L',
              470.5,
              324
            ],
            [
              'M',
              460.5,
              317.5
            ],
            [
              'L',
              460.5,
              364
            ],
            [
              'L',
              470.5,
              370.5
            ],
            [
              'M',
              460.5,
              317.5
            ],
            [
              'L',
              521.5,
              308
            ],
            [
              'L',
              531,
              314.5
            ],
            [
              'M',
              470.5,
              324
            ],
            [
              'L',
              470.5,
              370.5
            ],
            [
              'M',
              470.5,
              324
            ],
            [
              'L',
              531,
              314.5
            ],
            [
              'M',
              470.5,
              370.5
            ],
            [
              'L',
              531,
              361.5
            ],
            [
              'L',
              531,
              314.5
            ]
          ],
          width: 70.5,
          height: 62.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 48.827512233002324,
          left: -806.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#ffffff',
          strokeWidth: 1,
          path: [
            [
              'M',
              514.5,
              398.5
            ],
            [
              'L',
              504.5,
              392
            ],
            [
              'L',
              504.5,
              345.5
            ],
            [
              'L',
              565.5,
              336
            ],
            [
              'L',
              575,
              342.5
            ],
            [
              'L',
              575,
              389.5
            ],
            [
              'L',
              514.5,
              398.5
            ],
            [
              'Z'
            ]
          ],
          width: 70.5,
          height: 62.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 48.827512233002324,
          left: -806.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              504.5,
              345.5
            ],
            [
              'L',
              514.5,
              352
            ],
            [
              'M',
              504.5,
              345.5
            ],
            [
              'L',
              504.5,
              392
            ],
            [
              'L',
              514.5,
              398.5
            ],
            [
              'M',
              504.5,
              345.5
            ],
            [
              'L',
              565.5,
              336
            ],
            [
              'L',
              575,
              342.5
            ],
            [
              'M',
              514.5,
              352
            ],
            [
              'L',
              514.5,
              398.5
            ],
            [
              'M',
              514.5,
              352
            ],
            [
              'L',
              575,
              342.5
            ],
            [
              'M',
              514.5,
              398.5
            ],
            [
              'L',
              575,
              389.5
            ],
            [
              'L',
              575,
              342.5
            ]
          ],
          width: 70.5,
          height: 62.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 45.327512233002324,
          left: -818.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#D9D9D9',
          strokeWidth: 1,
          path: [
            [
              'M',
              492.5,
              333
            ],
            [
              'L',
              496,
              332.5
            ],
            [
              'L',
              514,
              344
            ],
            [
              'L',
              510,
              344.5
            ],
            [
              'L',
              509.886,
              344.425
            ],
            [
              'L',
              504,
              345.423
            ],
            [
              'L',
              504,
              374.5
            ],
            [
              'L',
              492.5,
              366.5
            ],
            [
              'L',
              492.5,
              333
            ],
            [
              'Z'
            ]
          ],
          width: 21.5,
          height: 42,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 45.327512233002324,
          left: -818.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              492.5,
              333
            ],
            [
              'L',
              496,
              332.5
            ],
            [
              'L',
              514,
              344
            ],
            [
              'L',
              510,
              344.5
            ],
            [
              'L',
              509.886,
              344.425
            ],
            [
              'M',
              492.5,
              333
            ],
            [
              'L',
              509.886,
              344.425
            ],
            [
              'M',
              492.5,
              333
            ],
            [
              'L',
              492.5,
              366.5
            ],
            [
              'L',
              504,
              374.5
            ],
            [
              'L',
              504,
              345.423
            ],
            [
              'L',
              509.886,
              344.425
            ]
          ],
          width: 21.5,
          height: 42,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -50.172487766997676,
          left: -1108.0102291423016,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#ffffff',
          strokeWidth: 1,
          path: [
            [
              'M',
              259.501,
              325
            ],
            [
              'L',
              208.001,
              283.5
            ],
            [
              'C',
              204.139,
              278.065,
              202.719,
              272.51,
              202.74,
              267.335
            ],
            [
              'C',
              202.763,
              261.884,
              204.383,
              256.854,
              206.432,
              252.826
            ],
            [
              'C',
              207.879,
              249.983,
              209.539,
              247.639,
              211.001,
              246
            ],
            [
              'L',
              211.001,
              237
            ],
            [
              'L',
              215.501,
              237
            ],
            [
              'L',
              259.501,
              272
            ],
            [
              'L',
              259.501,
              276.5
            ],
            [
              'L',
              259.501,
              285
            ],
            [
              'L',
              259.501,
              288.5
            ],
            [
              'L',
              259.501,
              296
            ],
            [
              'L',
              259.501,
              313.5
            ],
            [
              'L',
              259.501,
              325
            ],
            [
              'Z'
            ]
          ],
          width: 56.76122914230163,
          height: 88,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -50.172487766997676,
          left: -1108.01,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              202.74,
              267.335
            ],
            [
              'L',
              259.501,
              313.5
            ],
            [
              'M',
              259.501,
              313.5
            ],
            [
              'L',
              259.501,
              325
            ],
            [
              'L',
              208.001,
              283.5
            ],
            [
              'C',
              197.201,
              268.3,
              205.501,
              252.167,
              211.001,
              246
            ],
            [
              'M',
              259.501,
              313.5
            ],
            [
              'L',
              259.501,
              296
            ],
            [
              'M',
              206.432,
              252.826
            ],
            [
              'L',
              259.501,
              296
            ],
            [
              'M',
              259.501,
              296
            ],
            [
              'L',
              259.501,
              288.5
            ],
            [
              'M',
              209.5,
              248
            ],
            [
              'L',
              259.501,
              288.5
            ],
            [
              'M',
              259.501,
              288.5
            ],
            [
              'L',
              259.501,
              285
            ],
            [
              'M',
              211.001,
              246
            ],
            [
              'C',
              211.401,
              246.4,
              243.501,
              272.167,
              259.501,
              285
            ],
            [
              'M',
              211.001,
              246
            ],
            [
              'L',
              211.001,
              237
            ],
            [
              'M',
              259.501,
              285
            ],
            [
              'L',
              259.501,
              276.5
            ],
            [
              'M',
              211.001,
              237
            ],
            [
              'L',
              259.501,
              276.5
            ],
            [
              'M',
              211.001,
              237
            ],
            [
              'L',
              215.501,
              237
            ],
            [
              'L',
              259.501,
              272
            ],
            [
              'L',
              259.501,
              276.5
            ]
          ],
          width: 56.76099999999997,
          height: 88,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -8.672487766997676,
          left: -971.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#ffffff',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              381,
              313
            ],
            [
              'L',
              339,
              317
            ],
            [
              'L',
              339,
              284.5
            ],
            [
              'L',
              381,
              281.5
            ],
            [
              'L',
              401.5,
              278.5
            ],
            [
              'L',
              401.5,
              309.5
            ],
            [
              'L',
              381,
              313
            ],
            [
              'Z'
            ]
          ],
          width: 62.5,
          height: 38.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -185.67248776699768,
          left: -761.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#FFF6E0',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              549.5,
              279.5
            ],
            [
              'L',
              549.5,
              101.5
            ],
            [
              'L',
              646,
              156.5
            ],
            [
              'L',
              654,
              153.5
            ],
            [
              'L',
              652.5,
              337.5
            ],
            [
              'L',
              549.5,
              279.5
            ],
            [
              'Z'
            ]
          ],
          width: 104.5,
          height: 236,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -102.68190521278026,
          left: -761.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#ffffff',
          strokeWidth: 1,
          path: [
            [
              'M',
              596,
              222
            ],
            [
              'L',
              596,
              248
            ],
            [
              'L',
              596,
              304.5
            ],
            [
              'L',
              596,
              305.5
            ],
            [
              'L',
              580.5,
              296.833
            ],
            [
              'L',
              565,
              288.167
            ],
            [
              'L',
              549.5,
              279.5
            ],
            [
              'L',
              549.5,
              277
            ],
            [
              'L',
              549.5,
              221
            ],
            [
              'L',
              549.5,
              195.5
            ],
            [
              'C',
              552.309,
              187.212,
              554.831,
              184.273,
              557,
              184.503
            ],
            [
              'C',
              561.007,
              184.928,
              563.81,
              196.173,
              565,
              204.5
            ],
            [
              'C',
              567.809,
              196.353,
              570.331,
              193.476,
              572.5,
              193.72
            ],
            [
              'C',
              576.507,
              194.172,
              579.31,
              205.282,
              580.5,
              213.5
            ],
            [
              'C',
              583.353,
              205.621,
              585.863,
              202.633,
              588,
              202.611
            ],
            [
              'C',
              592.156,
              202.568,
              594.899,
              213.745,
              596,
              222
            ],
            [
              'Z'
            ]
          ],
          width: 46.5,
          height: 121.00941744578259,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -102.68190521278026,
          left: -761.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              580.5,
              213.5
            ],
            [
              'L',
              580.5,
              239
            ],
            [
              'M',
              580.5,
              213.5
            ],
            [
              'C',
              583.353,
              205.621,
              585.863,
              202.633,
              588,
              202.611
            ],
            [
              'M',
              580.5,
              213.5
            ],
            [
              'C',
              579.31,
              205.282,
              576.507,
              194.172,
              572.5,
              193.72
            ],
            [
              'M',
              565,
              204.5
            ],
            [
              'L',
              564.731,
              287
            ],
            [
              'M',
              565,
              204.5
            ],
            [
              'C',
              567.809,
              196.353,
              570.331,
              193.476,
              572.5,
              193.72
            ],
            [
              'M',
              565,
              204.5
            ],
            [
              'C',
              563.81,
              196.173,
              561.007,
              184.928,
              557,
              184.503
            ],
            [
              'M',
              596,
              248
            ],
            [
              'L',
              596,
              222
            ],
            [
              'C',
              594.899,
              213.745,
              592.156,
              202.568,
              588,
              202.611
            ],
            [
              'M',
              596,
              248
            ],
            [
              'L',
              596,
              304.5
            ],
            [
              'M',
              596,
              248
            ],
            [
              'L',
              580.5,
              296.833
            ],
            [
              'M',
              596,
              248
            ],
            [
              'L',
              588,
              243.355
            ],
            [
              'M',
              549.5,
              221
            ],
            [
              'L',
              549.5,
              195.5
            ],
            [
              'C',
              552.309,
              187.212,
              554.831,
              184.273,
              557,
              184.503
            ],
            [
              'M',
              549.5,
              221
            ],
            [
              'L',
              549.5,
              277
            ],
            [
              'M',
              549.5,
              221
            ],
            [
              'L',
              564.731,
              287
            ],
            [
              'M',
              549.5,
              221
            ],
            [
              'L',
              557.25,
              225.5
            ],
            [
              'M',
              565,
              288.167
            ],
            [
              'L',
              549.5,
              279.5
            ],
            [
              'L',
              549.5,
              277
            ],
            [
              'M',
              565,
              288.167
            ],
            [
              'L',
              580.5,
              296.833
            ],
            [
              'M',
              565,
              288.167
            ],
            [
              'L',
              564.731,
              287
            ],
            [
              'M',
              549.5,
              277
            ],
            [
              'L',
              565,
              230
            ],
            [
              'M',
              565,
              230
            ],
            [
              'L',
              580.5,
              296.833
            ],
            [
              'M',
              565,
              230
            ],
            [
              'L',
              572.75,
              234.5
            ],
            [
              'M',
              565,
              230
            ],
            [
              'L',
              557.25,
              225.5
            ],
            [
              'M',
              580.5,
              296.833
            ],
            [
              'L',
              596,
              305.5
            ],
            [
              'L',
              596,
              304.5
            ],
            [
              'M',
              580.5,
              296.833
            ],
            [
              'L',
              580.5,
              239
            ],
            [
              'M',
              564.731,
              287
            ],
            [
              'L',
              580.5,
              239
            ],
            [
              'M',
              580.5,
              239
            ],
            [
              'L',
              596,
              304.5
            ],
            [
              'M',
              580.5,
              239
            ],
            [
              'L',
              588,
              243.355
            ],
            [
              'M',
              580.5,
              239
            ],
            [
              'L',
              572.75,
              234.5
            ],
            [
              'M',
              588,
              202.611
            ],
            [
              'L',
              588,
              243.355
            ],
            [
              'M',
              572.5,
              193.72
            ],
            [
              'L',
              572.75,
              234.5
            ],
            [
              'M',
              557,
              184.503
            ],
            [
              'L',
              557.25,
              225.5
            ]
          ],
          width: 46.5,
          height: 121.00941744578259,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -71.17290145097596,
          left: -704.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#ffffff',
          strokeWidth: 1,
          path: [
            [
              'M',
              652.5,
              253.51
            ],
            [
              'L',
              652.5,
              279.51
            ],
            [
              'L',
              652.5,
              336.01
            ],
            [
              'L',
              652.5,
              337.01
            ],
            [
              'L',
              637,
              328.343
            ],
            [
              'L',
              621.5,
              319.676
            ],
            [
              'L',
              606,
              311.01
            ],
            [
              'L',
              606,
              308.51
            ],
            [
              'L',
              606,
              252.51
            ],
            [
              'L',
              606,
              227.01
            ],
            [
              'C',
              608.809,
              218.722,
              611.331,
              215.782,
              613.5,
              216.012
            ],
            [
              'C',
              617.507,
              216.438,
              620.31,
              227.683,
              621.5,
              236.01
            ],
            [
              'C',
              624.309,
              227.862,
              626.831,
              224.985,
              629,
              225.23
            ],
            [
              'C',
              633.007,
              225.682,
              635.81,
              236.791,
              637,
              245.01
            ],
            [
              'C',
              639.853,
              237.131,
              642.363,
              234.142,
              644.5,
              234.12
            ],
            [
              'C',
              648.656,
              234.077,
              651.399,
              245.254,
              652.5,
              253.51
            ],
            [
              'Z'
            ]
          ],
          width: 46.5,
          height: 121.01041368397827,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -71.17290145097596,
          left: -704.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              637,
              245.01
            ],
            [
              'L',
              637,
              270.51
            ],
            [
              'M',
              637,
              245.01
            ],
            [
              'C',
              639.853,
              237.131,
              642.363,
              234.142,
              644.5,
              234.12
            ],
            [
              'M',
              637,
              245.01
            ],
            [
              'C',
              635.81,
              236.791,
              633.007,
              225.682,
              629,
              225.23
            ],
            [
              'M',
              621.5,
              236.01
            ],
            [
              'L',
              621.231,
              318.51
            ],
            [
              'M',
              621.5,
              236.01
            ],
            [
              'C',
              624.309,
              227.862,
              626.831,
              224.985,
              629,
              225.23
            ],
            [
              'M',
              621.5,
              236.01
            ],
            [
              'C',
              620.31,
              227.683,
              617.507,
              216.438,
              613.5,
              216.012
            ],
            [
              'M',
              652.5,
              279.51
            ],
            [
              'L',
              652.5,
              253.51
            ],
            [
              'C',
              651.399,
              245.254,
              648.656,
              234.077,
              644.5,
              234.12
            ],
            [
              'M',
              652.5,
              279.51
            ],
            [
              'L',
              652.5,
              336.01
            ],
            [
              'M',
              652.5,
              279.51
            ],
            [
              'L',
              637,
              328.343
            ],
            [
              'M',
              652.5,
              279.51
            ],
            [
              'L',
              644.5,
              274.864
            ],
            [
              'M',
              606,
              252.51
            ],
            [
              'L',
              606,
              227.01
            ],
            [
              'C',
              608.809,
              218.722,
              611.331,
              215.782,
              613.5,
              216.012
            ],
            [
              'M',
              606,
              252.51
            ],
            [
              'L',
              606,
              308.51
            ],
            [
              'M',
              606,
              252.51
            ],
            [
              'L',
              621.231,
              318.51
            ],
            [
              'M',
              606,
              252.51
            ],
            [
              'L',
              613.75,
              257.01
            ],
            [
              'M',
              621.5,
              319.676
            ],
            [
              'L',
              606,
              311.01
            ],
            [
              'L',
              606,
              308.51
            ],
            [
              'M',
              621.5,
              319.676
            ],
            [
              'L',
              637,
              328.343
            ],
            [
              'M',
              621.5,
              319.676
            ],
            [
              'L',
              621.231,
              318.51
            ],
            [
              'M',
              606,
              308.51
            ],
            [
              'L',
              621.5,
              261.51
            ],
            [
              'M',
              621.5,
              261.51
            ],
            [
              'L',
              637,
              328.343
            ],
            [
              'M',
              621.5,
              261.51
            ],
            [
              'L',
              629.25,
              266.01
            ],
            [
              'M',
              621.5,
              261.51
            ],
            [
              'L',
              613.75,
              257.01
            ],
            [
              'M',
              637,
              328.343
            ],
            [
              'L',
              652.5,
              337.01
            ],
            [
              'L',
              652.5,
              336.01
            ],
            [
              'M',
              637,
              328.343
            ],
            [
              'L',
              637,
              270.51
            ],
            [
              'M',
              621.231,
              318.51
            ],
            [
              'L',
              637,
              270.51
            ],
            [
              'M',
              637,
              270.51
            ],
            [
              'L',
              652.5,
              336.01
            ],
            [
              'M',
              637,
              270.51
            ],
            [
              'L',
              644.5,
              274.864
            ],
            [
              'M',
              637,
              270.51
            ],
            [
              'L',
              629.25,
              266.01
            ],
            [
              'M',
              644.5,
              234.12
            ],
            [
              'L',
              644.5,
              274.864
            ],
            [
              'M',
              629,
              225.23
            ],
            [
              'L',
              629.25,
              266.01
            ],
            [
              'M',
              613.5,
              216.012
            ],
            [
              'L',
              613.75,
              257.01
            ]
          ],
          width: 46.5,
          height: 121.01041368397827,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -39.672487766997676,
          left: -929.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#00ABE6',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              496.5,
              264.5
            ],
            [
              'L',
              549,
              247.5
            ],
            [
              'L',
              652.5,
              303.5
            ],
            [
              'L',
              652.5,
              338
            ],
            [
              'L',
              627,
              358.5
            ],
            [
              'L',
              575,
              390.5
            ],
            [
              'L',
              514.5,
              399.5
            ],
            [
              'L',
              504,
              392.697
            ],
            [
              'L',
              470.5,
              370.991
            ],
            [
              'L',
              460.5,
              364.511
            ],
            [
              'L',
              425.5,
              341.833
            ],
            [
              'L',
              415,
              335.03
            ],
            [
              'L',
              381,
              313
            ],
            [
              'L',
              401.5,
              310
            ],
            [
              'L',
              415,
              318.5
            ],
            [
              'L',
              415,
              335.03
            ],
            [
              'L',
              425.5,
              341.833
            ],
            [
              'L',
              447.5,
              339
            ],
            [
              'L',
              460.5,
              347.5
            ],
            [
              'L',
              460.5,
              364.511
            ],
            [
              'L',
              470.5,
              370.991
            ],
            [
              'L',
              492.5,
              367.5
            ],
            [
              'L',
              504,
              375.5
            ],
            [
              'L',
              504,
              392.697
            ],
            [
              'L',
              514.5,
              399.5
            ],
            [
              'L',
              575,
              390.5
            ],
            [
              'L',
              575.5,
              342.5
            ],
            [
              'L',
              566,
              335.5
            ],
            [
              'L',
              531.5,
              341
            ],
            [
              'L',
              531.5,
              314.5
            ],
            [
              'L',
              521.5,
              307.5
            ],
            [
              'L',
              486,
              313
            ],
            [
              'L',
              486,
              297
            ],
            [
              'L',
              486,
              285
            ],
            [
              'L',
              476,
              278.483
            ],
            [
              'L',
              424,
              286.5
            ],
            [
              'L',
              409.5,
              277.305
            ],
            [
              'L',
              496.5,
              264.5
            ],
            [
              'Z'
            ]
          ],
          width: 271.5,
          height: 152,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -39.672487766997676,
          left: -929.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              549,
              247.5
            ],
            [
              'L',
              496.5,
              264.5
            ],
            [
              'L',
              409.5,
              277.305
            ],
            [
              'L',
              424,
              286.5
            ],
            [
              'L',
              476,
              278.483
            ],
            [
              'L',
              486,
              285
            ],
            [
              'L',
              486,
              297
            ],
            [
              'M',
              549,
              247.5
            ],
            [
              'L',
              652.5,
              303.5
            ],
            [
              'M',
              549,
              247.5
            ],
            [
              'L',
              549,
              278.483
            ],
            [
              'L',
              496.5,
              295.5
            ],
            [
              'L',
              486,
              297
            ],
            [
              'M',
              575,
              390.5
            ],
            [
              'L',
              514.5,
              399.5
            ],
            [
              'L',
              504,
              392.697
            ],
            [
              'M',
              575,
              390.5
            ],
            [
              'L',
              627,
              358.5
            ],
            [
              'L',
              652.5,
              338
            ],
            [
              'L',
              652.5,
              303.5
            ],
            [
              'M',
              575,
              390.5
            ],
            [
              'L',
              575.5,
              342.5
            ],
            [
              'L',
              566,
              335.5
            ],
            [
              'L',
              531.5,
              341
            ],
            [
              'L',
              531.5,
              314.5
            ],
            [
              'L',
              521.5,
              307.5
            ],
            [
              'L',
              486,
              313
            ],
            [
              'L',
              486,
              297
            ],
            [
              'M',
              652.5,
              303.5
            ],
            [
              'L',
              629,
              325
            ],
            [
              'L',
              598.5,
              345
            ],
            [
              'L',
              575.5,
              357.5
            ],
            [
              'M',
              415,
              335.03
            ],
            [
              'L',
              381,
              313
            ],
            [
              'L',
              401.5,
              310
            ],
            [
              'L',
              415,
              318.5
            ],
            [
              'L',
              415,
              335.03
            ],
            [
              'Z'
            ],
            [
              'M',
              415,
              335.03
            ],
            [
              'L',
              425.5,
              341.833
            ],
            [
              'M',
              425.5,
              341.833
            ],
            [
              'L',
              447.5,
              339
            ],
            [
              'L',
              460.5,
              347.5
            ],
            [
              'L',
              460.5,
              364.511
            ],
            [
              'M',
              425.5,
              341.833
            ],
            [
              'L',
              460.5,
              364.511
            ],
            [
              'M',
              460.5,
              364.511
            ],
            [
              'L',
              470.5,
              370.991
            ],
            [
              'M',
              470.5,
              370.991
            ],
            [
              'L',
              492.5,
              367.5
            ],
            [
              'L',
              504,
              375.5
            ],
            [
              'L',
              504,
              392.697
            ],
            [
              'M',
              470.5,
              370.991
            ],
            [
              'L',
              504,
              392.697
            ]
          ],
          width: 271.5,
          height: 152,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -219.17248776699768,
          left: -770.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#EE480C',
          strokeWidth: 1,
          path: [
            [
              'M',
              694,
              129
            ],
            [
              'L',
              645.5,
              151.5
            ],
            [
              'L',
              645.5,
              156.5
            ],
            [
              'L',
              540.5,
              96
            ],
            [
              'L',
              540.5,
              91
            ],
            [
              'L',
              589,
              68
            ],
            [
              'L',
              694,
              129
            ],
            [
              'Z'
            ]
          ],
          width: 153.5,
          height: 88.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -219.17248776699768,
          left: -770.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              645.5,
              151.5
            ],
            [
              'L',
              694,
              129
            ],
            [
              'L',
              589,
              68
            ],
            [
              'L',
              540.5,
              91
            ],
            [
              'M',
              645.5,
              151.5
            ],
            [
              'L',
              540.5,
              91
            ],
            [
              'M',
              645.5,
              151.5
            ],
            [
              'L',
              645.5,
              156.5
            ],
            [
              'L',
              540.5,
              96
            ],
            [
              'L',
              540.5,
              91
            ]
          ],
          width: 153.5,
          height: 88.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -157.67248776699768,
          left: -664.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#D9D9D9',
          strokeWidth: 1,
          path: [
            [
              'M',
              744,
              151.5
            ],
            [
              'L',
              695,
              129.5
            ],
            [
              'L',
              646,
              151.5
            ],
            [
              'L',
              646,
              156
            ],
            [
              'L',
              654,
              152.408
            ],
            [
              'L',
              695,
              134
            ],
            [
              'L',
              733.977,
              151.5
            ],
            [
              'L',
              744,
              156
            ],
            [
              'L',
              744,
              151.5
            ],
            [
              'Z'
            ]
          ],
          width: 98,
          height: 26.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -134.67248776699768,
          left: -656.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#D9D9D9',
          strokeWidth: 1,
          path: [
            [
              'M',
              667.5,
              250.5
            ],
            [
              'L',
              667.5,
              255.5
            ],
            [
              'L',
              654,
              255.5
            ],
            [
              'L',
              654,
              153
            ],
            [
              'L',
              734.5,
              152.5
            ],
            [
              'L',
              735,
              255.5
            ],
            [
              'L',
              733.977,
              255.5
            ],
            [
              'L',
              679.5,
              255.5
            ],
            [
              'L',
              679.5,
              250.5
            ],
            [
              'L',
              726.5,
              250.5
            ],
            [
              'L',
              726.5,
              214
            ],
            [
              'L',
              661.5,
              214
            ],
            [
              'L',
              661.5,
              250.5
            ],
            [
              'L',
              667.5,
              250.5
            ],
            [
              'Z'
            ]
          ],
          width: 81,
          height: 103,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -157.67248776699768,
          left: -664.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              744,
              151.5
            ],
            [
              'L',
              695,
              129.5
            ],
            [
              'L',
              646,
              151.5
            ],
            [
              'L',
              646,
              156
            ],
            [
              'L',
              654,
              152.408
            ],
            [
              'L',
              695,
              134
            ],
            [
              'L',
              733.977,
              151.5
            ],
            [
              'L',
              744,
              156
            ],
            [
              'L',
              744,
              151.5
            ],
            [
              'Z'
            ]
          ],
          width: 98,
          height: 26.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -134.67248776699768,
          left: -656.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              667.5,
              250.5
            ],
            [
              'L',
              667.5,
              255.5
            ],
            [
              'L',
              654,
              255.5
            ],
            [
              'L',
              654,
              153
            ],
            [
              'L',
              734.5,
              152.5
            ],
            [
              'L',
              735,
              255.5
            ],
            [
              'L',
              733.977,
              255.5
            ],
            [
              'L',
              679.5,
              255.5
            ],
            [
              'L',
              679.5,
              250.5
            ],
            [
              'L',
              726.5,
              250.5
            ],
            [
              'L',
              726.5,
              214
            ],
            [
              'L',
              661.5,
              214
            ],
            [
              'L',
              661.5,
              250.5
            ],
            [
              'L',
              667.5,
              250.5
            ],
            [
              'Z'
            ]
          ],
          width: 81,
          height: 103,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -129.17248776699768,
          left: -649.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#ffffff',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              727,
              158
            ],
            [
              'L',
              661.5,
              158
            ],
            [
              'L',
              661.5,
              208
            ],
            [
              'L',
              727,
              208
            ],
            [
              'L',
              727,
              158
            ],
            [
              'Z'
            ]
          ],
          width: 65.5,
          height: 50,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -30.672487766997676,
          left: -657.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#ffffff',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              653,
              256.5
            ],
            [
              'L',
              744.5,
              256.5
            ],
            [
              'L',
              744.5,
              290
            ],
            [
              'L',
              854.5,
              290
            ],
            [
              'L',
              854.5,
              256.5
            ],
            [
              'L',
              1028,
              256.5
            ],
            [
              'L',
              1028,
              277
            ],
            [
              'L',
              1134,
              277
            ],
            [
              'L',
              1134,
              348
            ],
            [
              'L',
              1012,
              337.5
            ],
            [
              'L',
              653,
              337.5
            ],
            [
              'L',
              653,
              256.5
            ],
            [
              'Z'
            ]
          ],
          width: 481,
          height: 91.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -7.172487766997676,
          left: -565.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#D9D9D9',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              854,
              280
            ],
            [
              'L',
              745,
              280
            ],
            [
              'L',
              745,
              289.5
            ],
            [
              'L',
              854,
              289.5
            ],
            [
              'L',
              854,
              280
            ],
            [
              'Z'
            ]
          ],
          width: 109,
          height: 9.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -30.672487766997676,
          left: -360.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              950.5,
              264.5
            ],
            [
              'L',
              950.5,
              271
            ],
            [
              'M',
              950.5,
              264.5
            ],
            [
              'L',
              950.5,
              261.5
            ],
            [
              'M',
              950.5,
              264.5
            ],
            [
              'L',
              953,
              264.5
            ],
            [
              'M',
              995,
              264.5
            ],
            [
              'L',
              995,
              271
            ],
            [
              'M',
              995,
              264.5
            ],
            [
              'L',
              991,
              264.5
            ],
            [
              'M',
              995,
              264.5
            ],
            [
              'L',
              995,
              260.5
            ],
            [
              'M',
              950.5,
              271
            ],
            [
              'L',
              950.5,
              337.5
            ],
            [
              'L',
              995,
              337.5
            ],
            [
              'L',
              995,
              271
            ],
            [
              'M',
              950.5,
              271
            ],
            [
              'L',
              995,
              271
            ],
            [
              'M',
              955.5,
              256.5
            ],
            [
              'L',
              950.5,
              256.5
            ],
            [
              'L',
              950.5,
              261.5
            ],
            [
              'M',
              955.5,
              256.5
            ],
            [
              'L',
              950.5,
              261.5
            ],
            [
              'M',
              955.5,
              256.5
            ],
            [
              'L',
              961,
              256.5
            ],
            [
              'M',
              961,
              256.5
            ],
            [
              'L',
              953,
              264.5
            ],
            [
              'M',
              961,
              256.5
            ],
            [
              'L',
              966,
              256.5
            ],
            [
              'M',
              953,
              264.5
            ],
            [
              'L',
              958.5,
              264.5
            ],
            [
              'M',
              966,
              256.5
            ],
            [
              'L',
              958.5,
              264.5
            ],
            [
              'M',
              966,
              256.5
            ],
            [
              'L',
              972,
              256.5
            ],
            [
              'M',
              958.5,
              264.5
            ],
            [
              'L',
              964,
              264.5
            ],
            [
              'M',
              972,
              256.5
            ],
            [
              'L',
              964,
              264.5
            ],
            [
              'M',
              972,
              256.5
            ],
            [
              'L',
              977,
              256.5
            ],
            [
              'M',
              964,
              264.5
            ],
            [
              'L',
              969,
              264.5
            ],
            [
              'M',
              977,
              256.5
            ],
            [
              'L',
              969,
              264.5
            ],
            [
              'M',
              977,
              256.5
            ],
            [
              'L',
              982.5,
              256.5
            ],
            [
              'M',
              969,
              264.5
            ],
            [
              'L',
              975,
              264.5
            ],
            [
              'M',
              982.5,
              256.5
            ],
            [
              'L',
              975,
              264.5
            ],
            [
              'M',
              982.5,
              256.5
            ],
            [
              'L',
              987.5,
              256.5
            ],
            [
              'M',
              975,
              264.5
            ],
            [
              'L',
              980,
              264.5
            ],
            [
              'M',
              987.5,
              256.5
            ],
            [
              'L',
              980,
              264.5
            ],
            [
              'M',
              987.5,
              256.5
            ],
            [
              'L',
              993,
              256.5
            ],
            [
              'M',
              980,
              264.5
            ],
            [
              'L',
              985.5,
              264.5
            ],
            [
              'M',
              993,
              256.5
            ],
            [
              'L',
              995,
              256.5
            ],
            [
              'L',
              995,
              260.5
            ],
            [
              'M',
              993,
              256.5
            ],
            [
              'L',
              985.5,
              264.5
            ],
            [
              'M',
              985.5,
              264.5
            ],
            [
              'L',
              991,
              264.5
            ],
            [
              'M',
              991,
              264.5
            ],
            [
              'L',
              995,
              260.5
            ]
          ],
          width: 44.5,
          height: 81,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -67.67248776699768,
          left: -455.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              855,
              288
            ],
            [
              'L',
              855,
              315
            ],
            [
              'M',
              855,
              288
            ],
            [
              'L',
              874.5,
              315
            ],
            [
              'M',
              855,
              288
            ],
            [
              'C',
              855.701,
              288,
              860.191,
              288,
              865,
              288
            ],
            [
              'M',
              874.5,
              288
            ],
            [
              'L',
              874.5,
              315
            ],
            [
              'M',
              874.5,
              288
            ],
            [
              'L',
              855,
              315
            ],
            [
              'M',
              874.5,
              288
            ],
            [
              'C',
              872.005,
              288,
              868.422,
              288,
              865,
              288
            ],
            [
              'M',
              874.5,
              315
            ],
            [
              'L',
              855,
              315
            ],
            [
              'M',
              865,
              288
            ],
            [
              'L',
              865,
              219.5
            ]
          ],
          width: 19.5,
          height: 95.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -73.67248776699768,
          left: -176.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#D9D9D9',
          strokeWidth: 1,
          path: [
            [
              'M',
              1134,
              276.5
            ],
            [
              'L',
              1134,
              256
            ],
            [
              'L',
              1154.5,
              256
            ],
            [
              'L',
              1154.5,
              222
            ],
            [
              'L',
              1134,
              222
            ],
            [
              'L',
              1134,
              223
            ],
            [
              'L',
              1140,
              223
            ],
            [
              'L',
              1140,
              255.5
            ],
            [
              'L',
              1134,
              255.5
            ],
            [
              'L',
              1134,
              223
            ],
            [
              'L',
              1134,
              222
            ],
            [
              'L',
              1154.5,
              222
            ],
            [
              'L',
              1154.5,
              219.39
            ],
            [
              'L',
              1139,
              219.39
            ],
            [
              'L',
              1154.5,
              213.5
            ],
            [
              'L',
              1170,
              219.39
            ],
            [
              'L',
              1154.5,
              219.39
            ],
            [
              'L',
              1154.5,
              222
            ],
            [
              'L',
              1175,
              222
            ],
            [
              'L',
              1175,
              223
            ],
            [
              'L',
              1175,
              255.5
            ],
            [
              'L',
              1168.5,
              255.5
            ],
            [
              'L',
              1168.5,
              223
            ],
            [
              'L',
              1175,
              223
            ],
            [
              'L',
              1175,
              222
            ],
            [
              'L',
              1154.5,
              222
            ],
            [
              'L',
              1154.5,
              256
            ],
            [
              'L',
              1255.5,
              256
            ],
            [
              'L',
              1255.5,
              337.5
            ],
            [
              'L',
              1164.5,
              337.5
            ],
            [
              'L',
              1164.5,
              348.5
            ],
            [
              'L',
              1144.5,
              348.5
            ],
            [
              'L',
              1134,
              347.5
            ],
            [
              'L',
              1134,
              276.5
            ],
            [
              'Z'
            ]
          ],
          width: 121.5,
          height: 135,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -90.17248776699768,
          left: -181.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              1164.5,
              337.5
            ],
            [
              'L',
              1255.5,
              337.5
            ],
            [
              'L',
              1255.5,
              256
            ],
            [
              'L',
              1154.5,
              256
            ],
            [
              'M',
              1164.5,
              337.5
            ],
            [
              'L',
              1164.5,
              348.5
            ],
            [
              'M',
              1164.5,
              337.5
            ],
            [
              'L',
              1164.5,
              283.5
            ],
            [
              'M',
              1164.5,
              348.5
            ],
            [
              'L',
              1144.5,
              348.5
            ],
            [
              'M',
              1164.5,
              348.5
            ],
            [
              'L',
              1144.5,
              283.5
            ],
            [
              'M',
              1144.5,
              348.5
            ],
            [
              'L',
              1134,
              347.5
            ],
            [
              'L',
              1134,
              276.5
            ],
            [
              'L',
              1134,
              256
            ],
            [
              'L',
              1154.5,
              256
            ],
            [
              'M',
              1144.5,
              348.5
            ],
            [
              'L',
              1144.5,
              283.5
            ],
            [
              'M',
              1144.5,
              348.5
            ],
            [
              'L',
              1164.5,
              283.5
            ],
            [
              'M',
              1144.5,
              283.5
            ],
            [
              'L',
              1154.5,
              283.5
            ],
            [
              'L',
              1164.5,
              283.5
            ],
            [
              'M',
              1154.5,
              197
            ],
            [
              'L',
              1154.5,
              213.5
            ],
            [
              'M',
              1134,
              223
            ],
            [
              'L',
              1134,
              255.5
            ],
            [
              'L',
              1140,
              255.5
            ],
            [
              'L',
              1140,
              223
            ],
            [
              'L',
              1134,
              223
            ],
            [
              'Z'
            ],
            [
              'M',
              1134,
              223
            ],
            [
              'L',
              1134,
              222
            ],
            [
              'L',
              1175,
              222
            ],
            [
              'L',
              1175,
              223
            ],
            [
              'M',
              1129.5,
              223
            ],
            [
              'L',
              1154.5,
              213.5
            ],
            [
              'M',
              1154.5,
              213.5
            ],
            [
              'L',
              1170,
              219.39
            ],
            [
              'M',
              1179.5,
              223
            ],
            [
              'L',
              1170,
              219.39
            ],
            [
              'M',
              1170,
              219.39
            ],
            [
              'L',
              1154.5,
              219.39
            ],
            [
              'M',
              1139,
              219.39
            ],
            [
              'L',
              1154.5,
              219.39
            ],
            [
              'M',
              1154.5,
              219.39
            ],
            [
              'L',
              1154.5,
              256
            ],
            [
              'M',
              1175,
              223
            ],
            [
              'L',
              1175,
              255.5
            ],
            [
              'L',
              1168.5,
              255.5
            ],
            [
              'L',
              1168.5,
              223
            ],
            [
              'L',
              1175,
              223
            ],
            [
              'Z'
            ]
          ],
          width: 126,
          height: 151.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -3.1724877669976763,
          left: -55.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              1258,
              284
            ],
            [
              'L',
              1255.5,
              284
            ],
            [
              'L',
              1255.5,
              337.5
            ],
            [
              'L',
              1258,
              337.5
            ],
            [
              'M',
              1258,
              284
            ],
            [
              'L',
              1258,
              337.5
            ],
            [
              'M',
              1258,
              284
            ],
            [
              'L',
              1262.5,
              284
            ],
            [
              'M',
              1258,
              337.5
            ],
            [
              'L',
              1262.5,
              337.5
            ],
            [
              'M',
              1262.5,
              284
            ],
            [
              'L',
              1262.5,
              337.5
            ],
            [
              'M',
              1262.5,
              284
            ],
            [
              'L',
              1268,
              284
            ],
            [
              'M',
              1262.5,
              337.5
            ],
            [
              'L',
              1268,
              337.5
            ],
            [
              'M',
              1268,
              337.5
            ],
            [
              'L',
              1268,
              284
            ],
            [
              'M',
              1268,
              337.5
            ],
            [
              'L',
              1273,
              337.5
            ],
            [
              'M',
              1268,
              284
            ],
            [
              'L',
              1273,
              284
            ],
            [
              'M',
              1273,
              284
            ],
            [
              'L',
              1273,
              337.5
            ],
            [
              'M',
              1273,
              284
            ],
            [
              'L',
              1278,
              284
            ],
            [
              'M',
              1273,
              337.5
            ],
            [
              'L',
              1278,
              337.5
            ],
            [
              'M',
              1278,
              337.5
            ],
            [
              'L',
              1278,
              284
            ],
            [
              'M',
              1278,
              337.5
            ],
            [
              'L',
              1283,
              337.5
            ],
            [
              'M',
              1278,
              284
            ],
            [
              'L',
              1283,
              284
            ],
            [
              'M',
              1283,
              284
            ],
            [
              'L',
              1283,
              337.5
            ],
            [
              'M',
              1283,
              284
            ],
            [
              'L',
              1288.5,
              284
            ],
            [
              'M',
              1283,
              337.5
            ],
            [
              'L',
              1288.5,
              337.5
            ],
            [
              'M',
              1288.5,
              284
            ],
            [
              'L',
              1288.5,
              337.5
            ],
            [
              'M',
              1288.5,
              284
            ],
            [
              'L',
              1293,
              284
            ],
            [
              'M',
              1288.5,
              337.5
            ],
            [
              'L',
              1293,
              337.5
            ],
            [
              'M',
              1293,
              284
            ],
            [
              'L',
              1293,
              337.5
            ],
            [
              'M',
              1293,
              284
            ],
            [
              'L',
              1298.5,
              284
            ],
            [
              'M',
              1293,
              337.5
            ],
            [
              'L',
              1298.5,
              337.5
            ],
            [
              'M',
              1298.5,
              284
            ],
            [
              'L',
              1298.5,
              337.5
            ],
            [
              'M',
              1298.5,
              284
            ],
            [
              'L',
              1303.5,
              284
            ],
            [
              'M',
              1298.5,
              337.5
            ],
            [
              'L',
              1303.5,
              337.5
            ],
            [
              'M',
              1303.5,
              284
            ],
            [
              'L',
              1303.5,
              337.5
            ],
            [
              'M',
              1303.5,
              284
            ],
            [
              'L',
              1308.5,
              284
            ],
            [
              'M',
              1303.5,
              337.5
            ],
            [
              'L',
              1308.5,
              337.5
            ],
            [
              'M',
              1308.5,
              284
            ],
            [
              'L',
              1308.5,
              337.5
            ],
            [
              'M',
              1308.5,
              284
            ],
            [
              'L',
              1313.5,
              284
            ],
            [
              'M',
              1308.5,
              337.5
            ],
            [
              'L',
              1313.5,
              337.5
            ],
            [
              'M',
              1313.5,
              284
            ],
            [
              'L',
              1313.5,
              337.5
            ],
            [
              'M',
              1313.5,
              284
            ],
            [
              'L',
              1319,
              284
            ],
            [
              'M',
              1313.5,
              337.5
            ],
            [
              'L',
              1319,
              337.5
            ],
            [
              'M',
              1319,
              284
            ],
            [
              'L',
              1319,
              337.5
            ],
            [
              'M',
              1319,
              284
            ],
            [
              'L',
              1324,
              284
            ],
            [
              'M',
              1319,
              337.5
            ],
            [
              'L',
              1324,
              337.5
            ],
            [
              'M',
              1324,
              284
            ],
            [
              'L',
              1324,
              337.5
            ],
            [
              'M',
              1324,
              284
            ],
            [
              'L',
              1329,
              284
            ],
            [
              'M',
              1324,
              337.5
            ],
            [
              'L',
              1329,
              337.5
            ],
            [
              'M',
              1329,
              284
            ],
            [
              'L',
              1329,
              337.5
            ],
            [
              'M',
              1329,
              284
            ],
            [
              'L',
              1334,
              284
            ],
            [
              'M',
              1329,
              337.5
            ],
            [
              'L',
              1334,
              337.5
            ],
            [
              'M',
              1334,
              284
            ],
            [
              'L',
              1334,
              337.5
            ],
            [
              'M',
              1334,
              284
            ],
            [
              'L',
              1339,
              284
            ],
            [
              'M',
              1334,
              337.5
            ],
            [
              'L',
              1339,
              337.5
            ],
            [
              'M',
              1339,
              284
            ],
            [
              'L',
              1339,
              337.5
            ],
            [
              'M',
              1339,
              284
            ],
            [
              'L',
              1344.5,
              284
            ],
            [
              'M',
              1339,
              337.5
            ],
            [
              'L',
              1344.5,
              337.5
            ],
            [
              'M',
              1344.5,
              284
            ],
            [
              'L',
              1344.5,
              337.5
            ],
            [
              'M',
              1344.5,
              284
            ],
            [
              'L',
              1349.5,
              284
            ],
            [
              'M',
              1344.5,
              337.5
            ],
            [
              'L',
              1349.5,
              337.5
            ],
            [
              'M',
              1349.5,
              284
            ],
            [
              'L',
              1349.5,
              337.5
            ],
            [
              'M',
              1349.5,
              284
            ],
            [
              'L',
              1354.5,
              284
            ],
            [
              'M',
              1349.5,
              337.5
            ],
            [
              'L',
              1354.5,
              337.5
            ],
            [
              'M',
              1354.5,
              284
            ],
            [
              'L',
              1354.5,
              337.5
            ],
            [
              'M',
              1354.5,
              284
            ],
            [
              'L',
              1359.5,
              284
            ],
            [
              'M',
              1354.5,
              337.5
            ],
            [
              'L',
              1359.5,
              337.5
            ],
            [
              'M',
              1359.5,
              284
            ],
            [
              'L',
              1359.5,
              337.5
            ],
            [
              'M',
              1359.5,
              284
            ],
            [
              'L',
              1364.5,
              284
            ],
            [
              'M',
              1359.5,
              337.5
            ],
            [
              'L',
              1364.5,
              337.5
            ],
            [
              'M',
              1364.5,
              284
            ],
            [
              'L',
              1364.5,
              337.5
            ],
            [
              'M',
              1364.5,
              284
            ],
            [
              'L',
              1369.5,
              284
            ],
            [
              'M',
              1364.5,
              337.5
            ],
            [
              'L',
              1369.5,
              337.5
            ],
            [
              'M',
              1369.5,
              284
            ],
            [
              'L',
              1369.5,
              337.5
            ],
            [
              'M',
              1369.5,
              284
            ],
            [
              'L',
              1374.25,
              284
            ],
            [
              'M',
              1369.5,
              337.5
            ],
            [
              'L',
              1374.25,
              337.5
            ],
            [
              'M',
              1374.25,
              284
            ],
            [
              'L',
              1379,
              284
            ],
            [
              'L',
              1379,
              337.5
            ],
            [
              'L',
              1374.25,
              337.5
            ],
            [
              'M',
              1374.25,
              284
            ],
            [
              'L',
              1374.25,
              337.5
            ]
          ],
          width: 123.5,
          height: 53.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -17.172487766997676,
          left: 68.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#ffffff',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              1379,
              270
            ],
            [
              'L',
              1379,
              337.5
            ],
            [
              'L',
              1663,
              337.5
            ],
            [
              'L',
              1663,
              346.5
            ],
            [
              'L',
              1737.5,
              346.5
            ],
            [
              'L',
              1737.5,
              351
            ],
            [
              'L',
              1777.5,
              351
            ],
            [
              'L',
              1781.5,
              346.5
            ],
            [
              'L',
              1788.5,
              346.5
            ],
            [
              'L',
              1788.5,
              355.5
            ],
            [
              'L',
              1799,
              355.5
            ],
            [
              'L',
              1799,
              363.5
            ],
            [
              'L',
              1810,
              363.5
            ],
            [
              'L',
              1810,
              371
            ],
            [
              'L',
              1830.5,
              371
            ],
            [
              'L',
              1830.5,
              275
            ],
            [
              'L',
              1816,
              275
            ],
            [
              'L',
              1816,
              270
            ],
            [
              'L',
              1743,
              270
            ],
            [
              'L',
              1743,
              303
            ],
            [
              'L',
              1655,
              303
            ],
            [
              'L',
              1655,
              286
            ],
            [
              'L',
              1650.5,
              280.5
            ],
            [
              'L',
              1635.5,
              274
            ],
            [
              'L',
              1620.5,
              270
            ],
            [
              'L',
              1379,
              270
            ],
            [
              'Z'
            ]
          ],
          width: 451.5,
          height: 101,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 36.827512233002324,
          left: 451.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              1762,
              324
            ],
            [
              'L',
              1781,
              324
            ],
            [
              'M',
              1762,
              324
            ],
            [
              'L',
              1762,
              351
            ],
            [
              'M',
              1762,
              324
            ],
            [
              'L',
              1779.5,
              348.188
            ],
            [
              'M',
              1781,
              324
            ],
            [
              'L',
              1781,
              346.5
            ],
            [
              'L',
              1779.5,
              348.188
            ],
            [
              'M',
              1781,
              324
            ],
            [
              'L',
              1762,
              351
            ],
            [
              'M',
              1762,
              351
            ],
            [
              'L',
              1777,
              351
            ],
            [
              'L',
              1779.5,
              348.188
            ]
          ],
          width: 19,
          height: 27,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -26.672487766997676,
          left: 460.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              1771.5,
              324
            ],
            [
              'L',
              1771.5,
              260.5
            ]
          ],
          height: 63.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -0.6724877669976763,
          left: 344.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#D9D9D9',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              1742.5,
              286.5
            ],
            [
              'L',
              1655.5,
              286.5
            ],
            [
              'L',
              1655.5,
              302.5
            ],
            [
              'L',
              1742.5,
              302.5
            ],
            [
              'L',
              1742.5,
              286.5
            ],
            [
              'Z'
            ]
          ],
          width: 87,
          height: 16,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 50.827512233002324,
          left: 344.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              1655,
              356
            ],
            [
              'L',
              1655,
              351.5
            ],
            [
              'M',
              1655,
              356
            ],
            [
              'L',
              1664.5,
              347
            ],
            [
              'M',
              1655,
              356
            ],
            [
              'L',
              1661,
              356
            ],
            [
              'M',
              1780,
              356
            ],
            [
              'L',
              1775,
              356
            ],
            [
              'M',
              1780,
              356
            ],
            [
              'L',
              1788.5,
              347
            ],
            [
              'M',
              1780,
              356
            ],
            [
              'L',
              1780,
              361.5
            ],
            [
              'M',
              1810,
              364
            ],
            [
              'L',
              1804,
              364
            ],
            [
              'M',
              1810,
              364
            ],
            [
              'L',
              1801,
              373
            ],
            [
              'M',
              1810,
              364
            ],
            [
              'L',
              1810,
              369.5
            ],
            [
              'M',
              1799,
              364
            ],
            [
              'L',
              1799,
              358.5
            ],
            [
              'M',
              1799,
              364
            ],
            [
              'L',
              1791,
              372
            ],
            [
              'M',
              1799,
              364
            ],
            [
              'L',
              1804,
              364
            ],
            [
              'M',
              1788.5,
              347
            ],
            [
              'L',
              1783.5,
              347
            ],
            [
              'M',
              1788.5,
              347
            ],
            [
              'L',
              1788.5,
              353
            ],
            [
              'M',
              1657,
              338
            ],
            [
              'L',
              1655,
              338
            ],
            [
              'L',
              1655,
              340
            ],
            [
              'M',
              1657,
              338
            ],
            [
              'L',
              1655,
              340
            ],
            [
              'M',
              1657,
              338
            ],
            [
              'L',
              1662,
              338
            ],
            [
              'M',
              1655,
              340
            ],
            [
              'L',
              1655,
              345
            ],
            [
              'M',
              1662,
              338
            ],
            [
              'L',
              1663,
              338
            ],
            [
              'L',
              1663,
              343.5
            ],
            [
              'M',
              1662,
              338
            ],
            [
              'L',
              1655,
              345
            ],
            [
              'M',
              1655,
              345
            ],
            [
              'L',
              1655,
              351.5
            ],
            [
              'M',
              1663,
              343.5
            ],
            [
              'L',
              1663,
              347
            ],
            [
              'L',
              1664.5,
              347
            ],
            [
              'M',
              1663,
              343.5
            ],
            [
              'L',
              1655,
              351.5
            ],
            [
              'M',
              1664.5,
              347
            ],
            [
              'L',
              1669.5,
              347
            ],
            [
              'M',
              1669.5,
              347
            ],
            [
              'L',
              1661,
              356
            ],
            [
              'M',
              1669.5,
              347
            ],
            [
              'L',
              1675,
              347
            ],
            [
              'M',
              1661,
              356
            ],
            [
              'L',
              1666.5,
              356
            ],
            [
              'M',
              1675,
              347
            ],
            [
              'L',
              1666.5,
              356
            ],
            [
              'M',
              1675,
              347
            ],
            [
              'L',
              1680.5,
              347
            ],
            [
              'M',
              1666.5,
              356
            ],
            [
              'L',
              1672,
              356
            ],
            [
              'M',
              1680.5,
              347
            ],
            [
              'L',
              1672,
              356
            ],
            [
              'M',
              1680.5,
              347
            ],
            [
              'L',
              1686,
              347
            ],
            [
              'M',
              1672,
              356
            ],
            [
              'L',
              1677,
              356
            ],
            [
              'M',
              1686,
              347
            ],
            [
              'L',
              1677,
              356
            ],
            [
              'M',
              1686,
              347
            ],
            [
              'L',
              1691.5,
              347
            ],
            [
              'M',
              1677,
              356
            ],
            [
              'L',
              1682.5,
              356
            ],
            [
              'M',
              1691.5,
              347
            ],
            [
              'L',
              1682.5,
              356
            ],
            [
              'M',
              1691.5,
              347
            ],
            [
              'L',
              1696.5,
              347
            ],
            [
              'M',
              1682.5,
              356
            ],
            [
              'L',
              1688,
              356
            ],
            [
              'M',
              1696.5,
              347
            ],
            [
              'L',
              1688,
              356
            ],
            [
              'M',
              1696.5,
              347
            ],
            [
              'L',
              1702.5,
              347
            ],
            [
              'M',
              1688,
              356
            ],
            [
              'L',
              1693.5,
              356
            ],
            [
              'M',
              1702.5,
              347
            ],
            [
              'L',
              1693.5,
              356
            ],
            [
              'M',
              1702.5,
              347
            ],
            [
              'L',
              1707.5,
              347
            ],
            [
              'M',
              1693.5,
              356
            ],
            [
              'L',
              1698.5,
              356
            ],
            [
              'M',
              1707.5,
              347
            ],
            [
              'L',
              1698.5,
              356
            ],
            [
              'M',
              1707.5,
              347
            ],
            [
              'L',
              1713,
              347
            ],
            [
              'M',
              1698.5,
              356
            ],
            [
              'L',
              1704,
              356
            ],
            [
              'M',
              1713,
              347
            ],
            [
              'L',
              1704,
              356
            ],
            [
              'M',
              1713,
              347
            ],
            [
              'L',
              1718,
              347
            ],
            [
              'M',
              1704,
              356
            ],
            [
              'L',
              1709,
              356
            ],
            [
              'M',
              1718,
              347
            ],
            [
              'L',
              1709,
              356
            ],
            [
              'M',
              1718,
              347
            ],
            [
              'L',
              1723.5,
              347
            ],
            [
              'M',
              1709,
              356
            ],
            [
              'L',
              1715,
              356
            ],
            [
              'M',
              1723.5,
              347
            ],
            [
              'L',
              1715,
              356
            ],
            [
              'M',
              1723.5,
              347
            ],
            [
              'L',
              1729.5,
              347
            ],
            [
              'M',
              1715,
              356
            ],
            [
              'L',
              1720.5,
              356
            ],
            [
              'M',
              1729.5,
              347
            ],
            [
              'L',
              1720.5,
              356
            ],
            [
              'M',
              1729.5,
              347
            ],
            [
              'L',
              1734.5,
              347
            ],
            [
              'M',
              1720.5,
              356
            ],
            [
              'L',
              1725.5,
              356
            ],
            [
              'M',
              1734.5,
              347
            ],
            [
              'L',
              1737.5,
              347
            ],
            [
              'L',
              1737.5,
              350
            ],
            [
              'M',
              1734.5,
              347
            ],
            [
              'L',
              1725.5,
              356
            ],
            [
              'M',
              1725.5,
              356
            ],
            [
              'L',
              1731.5,
              356
            ],
            [
              'M',
              1737.5,
              350
            ],
            [
              'L',
              1737.5,
              351.5
            ],
            [
              'L',
              1741,
              351.5
            ],
            [
              'M',
              1737.5,
              350
            ],
            [
              'L',
              1731.5,
              356
            ],
            [
              'M',
              1731.5,
              356
            ],
            [
              'L',
              1736.5,
              356
            ],
            [
              'M',
              1741,
              351.5
            ],
            [
              'L',
              1736.5,
              356
            ],
            [
              'M',
              1741,
              351.5
            ],
            [
              'L',
              1746.5,
              351.5
            ],
            [
              'M',
              1736.5,
              356
            ],
            [
              'L',
              1742,
              356
            ],
            [
              'M',
              1746.5,
              351.5
            ],
            [
              'L',
              1742,
              356
            ],
            [
              'M',
              1746.5,
              351.5
            ],
            [
              'L',
              1751.5,
              351.5
            ],
            [
              'M',
              1742,
              356
            ],
            [
              'L',
              1747,
              356
            ],
            [
              'M',
              1751.5,
              351.5
            ],
            [
              'L',
              1747,
              356
            ],
            [
              'M',
              1751.5,
              351.5
            ],
            [
              'L',
              1757,
              351.5
            ],
            [
              'M',
              1747,
              356
            ],
            [
              'L',
              1752.5,
              356
            ],
            [
              'M',
              1757,
              351.5
            ],
            [
              'L',
              1752.5,
              356
            ],
            [
              'M',
              1757,
              351.5
            ],
            [
              'L',
              1762.5,
              351.5
            ],
            [
              'M',
              1752.5,
              356
            ],
            [
              'L',
              1758,
              356
            ],
            [
              'M',
              1762.5,
              351.5
            ],
            [
              'L',
              1758,
              356
            ],
            [
              'M',
              1762.5,
              351.5
            ],
            [
              'L',
              1768,
              351.5
            ],
            [
              'M',
              1758,
              356
            ],
            [
              'L',
              1763.5,
              356
            ],
            [
              'M',
              1768,
              351.5
            ],
            [
              'L',
              1763.5,
              356
            ],
            [
              'M',
              1768,
              351.5
            ],
            [
              'L',
              1772.75,
              351.5
            ],
            [
              'L',
              1773.5,
              351.5
            ],
            [
              'M',
              1763.5,
              356
            ],
            [
              'L',
              1769,
              356
            ],
            [
              'M',
              1773.5,
              351.5
            ],
            [
              'L',
              1777.5,
              351.5
            ],
            [
              'L',
              1781.5,
              347
            ],
            [
              'L',
              1783.5,
              347
            ],
            [
              'M',
              1773.5,
              351.5
            ],
            [
              'L',
              1769,
              356
            ],
            [
              'M',
              1769,
              356
            ],
            [
              'L',
              1775,
              356
            ],
            [
              'M',
              1783.5,
              347
            ],
            [
              'L',
              1775,
              356
            ],
            [
              'M',
              1788.5,
              353
            ],
            [
              'L',
              1788.5,
              356
            ],
            [
              'L',
              1790.5,
              356
            ],
            [
              'M',
              1788.5,
              353
            ],
            [
              'L',
              1780,
              361.5
            ],
            [
              'M',
              1780,
              361.5
            ],
            [
              'L',
              1780,
              364
            ],
            [
              'L',
              1782.5,
              364
            ],
            [
              'M',
              1790.5,
              356
            ],
            [
              'L',
              1782.5,
              364
            ],
            [
              'M',
              1790.5,
              356
            ],
            [
              'L',
              1796,
              356
            ],
            [
              'M',
              1782.5,
              364
            ],
            [
              'L',
              1788,
              364
            ],
            [
              'M',
              1796,
              356
            ],
            [
              'L',
              1799,
              356
            ],
            [
              'L',
              1799,
              358.5
            ],
            [
              'M',
              1796,
              356
            ],
            [
              'L',
              1788,
              364
            ],
            [
              'M',
              1788,
              364
            ],
            [
              'L',
              1790.5,
              364
            ],
            [
              'L',
              1790.5,
              367
            ],
            [
              'M',
              1799,
              358.5
            ],
            [
              'L',
              1790.5,
              367
            ],
            [
              'M',
              1790.5,
              367
            ],
            [
              'L',
              1790.5,
              372
            ],
            [
              'L',
              1791,
              372
            ],
            [
              'M',
              1791,
              372
            ],
            [
              'L',
              1796,
              372
            ],
            [
              'M',
              1804,
              364
            ],
            [
              'L',
              1796,
              372
            ],
            [
              'M',
              1796,
              372
            ],
            [
              'L',
              1801,
              372
            ],
            [
              'L',
              1801,
              373
            ],
            [
              'M',
              1801,
              373
            ],
            [
              'L',
              1801,
              378.815
            ],
            [
              'M',
              1810,
              369.5
            ],
            [
              'L',
              1810,
              372
            ],
            [
              'L',
              1812.5,
              372
            ],
            [
              'M',
              1810,
              369.5
            ],
            [
              'L',
              1801,
              378.815
            ],
            [
              'M',
              1801,
              378.815
            ],
            [
              'L',
              1801,
              380.5
            ],
            [
              'L',
              1804,
              380.5
            ],
            [
              'M',
              1812.5,
              372
            ],
            [
              'L',
              1804,
              380.5
            ],
            [
              'M',
              1812.5,
              372
            ],
            [
              'L',
              1817.5,
              372
            ],
            [
              'M',
              1804,
              380.5
            ],
            [
              'L',
              1809.5,
              380.5
            ],
            [
              'M',
              1817.5,
              372
            ],
            [
              'L',
              1809.5,
              380.5
            ],
            [
              'M',
              1817.5,
              372
            ],
            [
              'L',
              1823,
              372
            ],
            [
              'M',
              1809.5,
              380.5
            ],
            [
              'L',
              1815,
              380.5
            ],
            [
              'M',
              1823,
              372
            ],
            [
              'L',
              1815,
              380.5
            ],
            [
              'M',
              1823,
              372
            ],
            [
              'L',
              1828.5,
              372
            ],
            [
              'M',
              1815,
              380.5
            ],
            [
              'L',
              1820.5,
              380.5
            ],
            [
              'M',
              1828.5,
              372
            ],
            [
              'L',
              1820.5,
              380.5
            ],
            [
              'M',
              1828.5,
              372
            ],
            [
              'L',
              1833.5,
              372
            ],
            [
              'M',
              1820.5,
              380.5
            ],
            [
              'L',
              1825,
              380.5
            ],
            [
              'M',
              1833.5,
              372
            ],
            [
              'L',
              1825,
              380.5
            ],
            [
              'M',
              1833.5,
              372
            ],
            [
              'L',
              1839,
              372
            ],
            [
              'M',
              1825,
              380.5
            ],
            [
              'L',
              1830.5,
              380.5
            ],
            [
              'M',
              1839,
              372
            ],
            [
              'L',
              1839.5,
              372
            ],
            [
              'L',
              1839.5,
              377.5
            ],
            [
              'M',
              1839,
              372
            ],
            [
              'L',
              1830.5,
              380.5
            ],
            [
              'M',
              1830.5,
              380.5
            ],
            [
              'L',
              1836.5,
              380.5
            ],
            [
              'M',
              1839.5,
              377.5
            ],
            [
              'L',
              1839.5,
              380.5
            ],
            [
              'L',
              1836.5,
              380.5
            ],
            [
              'M',
              1839.5,
              377.5
            ],
            [
              'L',
              1836.5,
              380.5
            ]
          ],
          width: 184.5,
          height: 42.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -30.672487766997676,
          left: 474.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              1839.5,
              371.5
            ],
            [
              'L',
              1830.5,
              371.5
            ],
            [
              'L',
              1830.5,
              369
            ],
            [
              'M',
              1839.5,
              371.5
            ],
            [
              'L',
              1906,
              455.5
            ],
            [
              'L',
              1907.74,
              453
            ],
            [
              'M',
              1839.5,
              371.5
            ],
            [
              'L',
              1841.28,
              369
            ],
            [
              'M',
              1863,
              338.5
            ],
            [
              'L',
              1929,
              422.5
            ],
            [
              'L',
              1927.61,
              424.5
            ],
            [
              'M',
              1863,
              338.5
            ],
            [
              'L',
              1855,
              338.5
            ],
            [
              'M',
              1863,
              338.5
            ],
            [
              'L',
              1861.58,
              340.5
            ],
            [
              'M',
              1855,
              338.5
            ],
            [
              'L',
              1855,
              335
            ],
            [
              'M',
              1855,
              338.5
            ],
            [
              'L',
              1851.5,
              338.5
            ],
            [
              'M',
              1830.5,
              338.5
            ],
            [
              'L',
              1830.5,
              338
            ],
            [
              'M',
              1830.5,
              338.5
            ],
            [
              'L',
              1835.5,
              338.5
            ],
            [
              'M',
              1830.5,
              338.5
            ],
            [
              'L',
              1830.5,
              340.5
            ],
            [
              'M',
              1825,
              270.5
            ],
            [
              'L',
              1825,
              256.5
            ],
            [
              'L',
              1814,
              256.5
            ],
            [
              'L',
              1785.5,
              346.5
            ],
            [
              'L',
              1788,
              346.5
            ],
            [
              'L',
              1816,
              259
            ],
            [
              'L',
              1822.5,
              259
            ],
            [
              'L',
              1822.5,
              270.5
            ],
            [
              'M',
              1825,
              270.5
            ],
            [
              'L',
              1822.5,
              270.5
            ],
            [
              'M',
              1825,
              270.5
            ],
            [
              'L',
              1827.5,
              270.5
            ],
            [
              'M',
              1822.5,
              270.5
            ],
            [
              'L',
              1822,
              270.5
            ],
            [
              'M',
              1822,
              270.5
            ],
            [
              'L',
              1816.5,
              270.5
            ],
            [
              'L',
              1816.5,
              274.5
            ],
            [
              'L',
              1818,
              274.5
            ],
            [
              'M',
              1822,
              270.5
            ],
            [
              'L',
              1818,
              274.5
            ],
            [
              'M',
              1818,
              274.5
            ],
            [
              'L',
              1823.5,
              274.5
            ],
            [
              'M',
              1827.5,
              270.5
            ],
            [
              'L',
              1823.5,
              274.5
            ],
            [
              'M',
              1827.5,
              270.5
            ],
            [
              'L',
              1833,
              270.5
            ],
            [
              'M',
              1823.5,
              274.5
            ],
            [
              'L',
              1829,
              274.5
            ],
            [
              'M',
              1833,
              270.5
            ],
            [
              'L',
              1829,
              274.5
            ],
            [
              'M',
              1833,
              270.5
            ],
            [
              'L',
              1838,
              270.5
            ],
            [
              'M',
              1829,
              274.5
            ],
            [
              'L',
              1830.5,
              274.5
            ],
            [
              'L',
              1830.5,
              278
            ],
            [
              'M',
              1838,
              270.5
            ],
            [
              'L',
              1830.5,
              278
            ],
            [
              'M',
              1838,
              270.5
            ],
            [
              'L',
              1843.5,
              270.5
            ],
            [
              'M',
              1830.5,
              278
            ],
            [
              'L',
              1830.5,
              283.5
            ],
            [
              'M',
              1843.5,
              270.5
            ],
            [
              'L',
              1830.5,
              283.5
            ],
            [
              'M',
              1843.5,
              270.5
            ],
            [
              'L',
              1849.25,
              270.5
            ],
            [
              'M',
              1830.5,
              283.5
            ],
            [
              'L',
              1830.5,
              289
            ],
            [
              'M',
              1849.25,
              270.5
            ],
            [
              'L',
              1830.5,
              289
            ],
            [
              'M',
              1849.25,
              270.5
            ],
            [
              'L',
              1854.5,
              270.5
            ],
            [
              'M',
              1830.5,
              289
            ],
            [
              'L',
              1830.5,
              294.5
            ],
            [
              'M',
              1854.5,
              270.5
            ],
            [
              'L',
              1855,
              270.5
            ],
            [
              'L',
              1855,
              275.5
            ],
            [
              'M',
              1854.5,
              270.5
            ],
            [
              'L',
              1830.5,
              294.5
            ],
            [
              'M',
              1830.5,
              294.5
            ],
            [
              'L',
              1830.5,
              300
            ],
            [
              'M',
              1855,
              275.5
            ],
            [
              'L',
              1830.5,
              300
            ],
            [
              'M',
              1855,
              275.5
            ],
            [
              'L',
              1855,
              281
            ],
            [
              'M',
              1830.5,
              300
            ],
            [
              'L',
              1830.5,
              305.5
            ],
            [
              'M',
              1855,
              281
            ],
            [
              'L',
              1830.5,
              305.5
            ],
            [
              'M',
              1855,
              281
            ],
            [
              'L',
              1855,
              286.5
            ],
            [
              'M',
              1830.5,
              305.5
            ],
            [
              'L',
              1830.5,
              311
            ],
            [
              'M',
              1855,
              286.5
            ],
            [
              'L',
              1830.5,
              311
            ],
            [
              'M',
              1855,
              286.5
            ],
            [
              'L',
              1855,
              292
            ],
            [
              'M',
              1830.5,
              311
            ],
            [
              'L',
              1830.5,
              316.5
            ],
            [
              'M',
              1855,
              292
            ],
            [
              'L',
              1830.5,
              316.5
            ],
            [
              'M',
              1855,
              292
            ],
            [
              'L',
              1855,
              297
            ],
            [
              'M',
              1830.5,
              316.5
            ],
            [
              'L',
              1830.5,
              321.5
            ],
            [
              'M',
              1855,
              297
            ],
            [
              'L',
              1855,
              297.5
            ],
            [
              'L',
              1855,
              302.5
            ],
            [
              'M',
              1855,
              297
            ],
            [
              'L',
              1830.5,
              321.5
            ],
            [
              'M',
              1830.5,
              321.5
            ],
            [
              'L',
              1830.5,
              327
            ],
            [
              'M',
              1855,
              302.5
            ],
            [
              'L',
              1830.5,
              327
            ],
            [
              'M',
              1855,
              302.5
            ],
            [
              'L',
              1855,
              308
            ],
            [
              'M',
              1830.5,
              327
            ],
            [
              'L',
              1830.5,
              332.5
            ],
            [
              'M',
              1855,
              308
            ],
            [
              'L',
              1830.5,
              332.5
            ],
            [
              'M',
              1855,
              308
            ],
            [
              'L',
              1855,
              313.5
            ],
            [
              'M',
              1830.5,
              332.5
            ],
            [
              'L',
              1830.5,
              338
            ],
            [
              'M',
              1855,
              313.5
            ],
            [
              'L',
              1830.5,
              338
            ],
            [
              'M',
              1855,
              313.5
            ],
            [
              'L',
              1855,
              319
            ],
            [
              'M',
              1855,
              319
            ],
            [
              'L',
              1835.5,
              338.5
            ],
            [
              'M',
              1855,
              319
            ],
            [
              'L',
              1855,
              324
            ],
            [
              'M',
              1835.5,
              338.5
            ],
            [
              'L',
              1840.5,
              338.5
            ],
            [
              'M',
              1855,
              324
            ],
            [
              'L',
              1840.5,
              338.5
            ],
            [
              'M',
              1855,
              324
            ],
            [
              'L',
              1855,
              329.5
            ],
            [
              'M',
              1840.5,
              338.5
            ],
            [
              'L',
              1846,
              338.5
            ],
            [
              'M',
              1855,
              329.5
            ],
            [
              'L',
              1846,
              338.5
            ],
            [
              'M',
              1855,
              329.5
            ],
            [
              'L',
              1855,
              335
            ],
            [
              'M',
              1846,
              338.5
            ],
            [
              'L',
              1851.5,
              338.5
            ],
            [
              'M',
              1855,
              335
            ],
            [
              'L',
              1851.5,
              338.5
            ],
            [
              'M',
              1830.5,
              340.5
            ],
            [
              'L',
              1861.58,
              340.5
            ],
            [
              'M',
              1830.5,
              340.5
            ],
            [
              'L',
              1830.5,
              343
            ],
            [
              'M',
              1861.58,
              340.5
            ],
            [
              'L',
              1859.8,
              343
            ],
            [
              'M',
              1861.58,
              340.5
            ],
            [
              'L',
              1927.61,
              424.5
            ],
            [
              'M',
              1830.5,
              343
            ],
            [
              'L',
              1859.8,
              343
            ],
            [
              'M',
              1830.5,
              343
            ],
            [
              'L',
              1830.5,
              346.5
            ],
            [
              'M',
              1859.8,
              343
            ],
            [
              'L',
              1857.3,
              346.5
            ],
            [
              'M',
              1859.8,
              343
            ],
            [
              'L',
              1926.21,
              426.5
            ],
            [
              'M',
              1830.5,
              346.5
            ],
            [
              'L',
              1857.3,
              346.5
            ],
            [
              'M',
              1830.5,
              346.5
            ],
            [
              'L',
              1830.5,
              350.5
            ],
            [
              'M',
              1857.3,
              346.5
            ],
            [
              'L',
              1854.45,
              350.5
            ],
            [
              'M',
              1857.3,
              346.5
            ],
            [
              'L',
              1923.42,
              430.5
            ],
            [
              'M',
              1830.5,
              350.5
            ],
            [
              'L',
              1854.45,
              350.5
            ],
            [
              'M',
              1830.5,
              350.5
            ],
            [
              'L',
              1830.5,
              355
            ],
            [
              'M',
              1854.45,
              350.5
            ],
            [
              'L',
              1851.25,
              355
            ],
            [
              'M',
              1854.45,
              350.5
            ],
            [
              'L',
              1920.5,
              434.696
            ],
            [
              'M',
              1830.5,
              355
            ],
            [
              'L',
              1851.25,
              355
            ],
            [
              'M',
              1830.5,
              355
            ],
            [
              'L',
              1830.5,
              359
            ],
            [
              'M',
              1851.25,
              355
            ],
            [
              'L',
              1848.4,
              359
            ],
            [
              'M',
              1851.25,
              355
            ],
            [
              'L',
              1917.85,
              438.5
            ],
            [
              'M',
              1830.5,
              359
            ],
            [
              'L',
              1848.4,
              359
            ],
            [
              'M',
              1830.5,
              359
            ],
            [
              'L',
              1830.5,
              363
            ],
            [
              'M',
              1848.4,
              359
            ],
            [
              'L',
              1845.55,
              363
            ],
            [
              'M',
              1848.4,
              359
            ],
            [
              'L',
              1914.71,
              443
            ],
            [
              'M',
              1830.5,
              363
            ],
            [
              'L',
              1845.55,
              363
            ],
            [
              'M',
              1830.5,
              363
            ],
            [
              'L',
              1830.5,
              366.5
            ],
            [
              'M',
              1845.55,
              363
            ],
            [
              'L',
              1843.06,
              366.5
            ],
            [
              'M',
              1845.55,
              363
            ],
            [
              'L',
              1911.92,
              447
            ],
            [
              'M',
              1830.5,
              366.5
            ],
            [
              'L',
              1843.06,
              366.5
            ],
            [
              'M',
              1830.5,
              366.5
            ],
            [
              'L',
              1830.5,
              369
            ],
            [
              'M',
              1843.06,
              366.5
            ],
            [
              'L',
              1841.28,
              369
            ],
            [
              'M',
              1843.06,
              366.5
            ],
            [
              'L',
              1909.48,
              450.5
            ],
            [
              'M',
              1830.5,
              369
            ],
            [
              'L',
              1841.28,
              369
            ],
            [
              'M',
              1841.28,
              369
            ],
            [
              'L',
              1907.74,
              453
            ],
            [
              'M',
              1907.74,
              453
            ],
            [
              'L',
              1909.48,
              450.5
            ],
            [
              'M',
              1909.48,
              450.5
            ],
            [
              'L',
              1911.92,
              447
            ],
            [
              'M',
              1911.92,
              447
            ],
            [
              'L',
              1914.71,
              443
            ],
            [
              'M',
              1914.71,
              443
            ],
            [
              'L',
              1917.85,
              438.5
            ],
            [
              'M',
              1917.85,
              438.5
            ],
            [
              'L',
              1920.5,
              434.696
            ],
            [
              'M',
              1920.5,
              434.696
            ],
            [
              'L',
              1923.42,
              430.5
            ],
            [
              'M',
              1923.42,
              430.5
            ],
            [
              'L',
              1926.21,
              426.5
            ],
            [
              'M',
              1926.21,
              426.5
            ],
            [
              'L',
              1927.61,
              424.5
            ]
          ],
          width: 143.5,
          height: 199,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -10.792414678590774,
          left: 524.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#ffffff',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              1835,
              334.5
            ],
            [
              'L',
              1851,
              334.5
            ],
            [
              'L',
              1851,
              298.5
            ],
            [
              'C',
              1847,
              259,
              1835,
              281,
              1835,
              298.5
            ],
            [
              'L',
              1835,
              334.5
            ],
            [
              'Z'
            ]
          ],
          width: 16,
          height: 58.1199269115931,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 85.82751223300232,
          left: 529.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#CF5C4E',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              1906,
              464
            ],
            [
              'L',
              1840,
              381
            ],
            [
              'L',
              1840,
              373
            ],
            [
              'L',
              1906,
              456.5
            ],
            [
              'L',
              2004,
              456.5
            ],
            [
              'L',
              2004,
              466.5
            ],
            [
              'L',
              1912.5,
              466.5
            ],
            [
              'L',
              1906,
              464
            ],
            [
              'Z'
            ]
          ],
          width: 164,
          height: 93.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 31.827512233002324,
          left: 544.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#CF5C4E',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              1868.5,
              325
            ],
            [
              'L',
              1855.5,
              319
            ],
            [
              'L',
              1855.5,
              338
            ],
            [
              'L',
              1863,
              338
            ],
            [
              'L',
              1929,
              421.5
            ],
            [
              'L',
              1947,
              421.5
            ],
            [
              'L',
              1942,
              410
            ],
            [
              'L',
              1919.5,
              385
            ],
            [
              'L',
              1875,
              344
            ],
            [
              'L',
              1868.5,
              325
            ],
            [
              'Z'
            ]
          ],
          width: 91.5,
          height: 102.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -6.172487766997676,
          left: 595.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              1908.5,
              452.883
            ],
            [
              'L',
              1906,
              456.5
            ],
            [
              'L',
              2065,
              456.5
            ],
            [
              'C',
              2060.74,
              456.292,
              2056.74,
              454.942,
              2053.39,
              452.883
            ],
            [
              'M',
              1908.5,
              452.883
            ],
            [
              'L',
              2053.39,
              452.883
            ],
            [
              'M',
              1908.5,
              452.883
            ],
            [
              'L',
              1910.24,
              450.363
            ],
            [
              'M',
              2053.39,
              452.883
            ],
            [
              'C',
              2052.17,
              452.13,
              2051.03,
              451.283,
              2050,
              450.363
            ],
            [
              'M',
              2050,
              450.363
            ],
            [
              'L',
              1910.24,
              450.363
            ],
            [
              'M',
              2050,
              450.363
            ],
            [
              'C',
              2048.86,
              449.349,
              2047.86,
              448.246,
              2047,
              447.083
            ],
            [
              'M',
              1910.24,
              450.363
            ],
            [
              'L',
              1912.51,
              447.083
            ],
            [
              'M',
              2047,
              447.083
            ],
            [
              'L',
              1912.51,
              447.083
            ],
            [
              'M',
              2047,
              447.083
            ],
            [
              'C',
              2046.16,
              445.938,
              2045.47,
              444.735,
              2044.95,
              443.5
            ],
            [
              'M',
              1912.51,
              447.083
            ],
            [
              'L',
              1914.99,
              443.5
            ],
            [
              'M',
              2044.95,
              443.5
            ],
            [
              'L',
              1914.99,
              443.5
            ],
            [
              'M',
              2044.95,
              443.5
            ],
            [
              'C',
              2044.33,
              442.03,
              2043.95,
              440.515,
              2043.86,
              439
            ],
            [
              'M',
              1914.99,
              443.5
            ],
            [
              'L',
              1918.1,
              439
            ],
            [
              'M',
              2043.86,
              439
            ],
            [
              'L',
              1918.1,
              439
            ],
            [
              'M',
              2043.86,
              439
            ],
            [
              'C',
              2043.79,
              437.656,
              2043.93,
              436.312,
              2044.33,
              435
            ],
            [
              'M',
              1918.1,
              439
            ],
            [
              'L',
              1920.86,
              435
            ],
            [
              'M',
              2044.33,
              435
            ],
            [
              'L',
              1920.86,
              435
            ],
            [
              'M',
              2044.33,
              435
            ],
            [
              'C',
              2044.75,
              433.619,
              2045.45,
              432.273,
              2046.47,
              431
            ],
            [
              'M',
              1920.86,
              435
            ],
            [
              'L',
              1923.62,
              431
            ],
            [
              'M',
              2046.47,
              431
            ],
            [
              'C',
              2047.46,
              429.752,
              2048.76,
              428.574,
              2050.39,
              427.5
            ],
            [
              'M',
              2046.47,
              431
            ],
            [
              'L',
              1969,
              431
            ],
            [
              'M',
              1923.62,
              431
            ],
            [
              'L',
              1926.04,
              427.5
            ],
            [
              'M',
              1923.62,
              431
            ],
            [
              'L',
              1969,
              431
            ],
            [
              'M',
              2050.39,
              427.5
            ],
            [
              'C',
              2052.1,
              426.371,
              2054.19,
              425.357,
              2056.68,
              424.5
            ],
            [
              'M',
              2050.39,
              427.5
            ],
            [
              'L',
              1979.17,
              427.75
            ],
            [
              'M',
              1926.04,
              427.5
            ],
            [
              'L',
              1928.12,
              424.5
            ],
            [
              'M',
              1926.04,
              427.5
            ],
            [
              'C',
              1934.81,
              427.539,
              1945.75,
              427.582,
              1957.5,
              427.619
            ],
            [
              'M',
              1928.12,
              424.5
            ],
            [
              'L',
              1929.5,
              422.5
            ],
            [
              'L',
              1952.92,
              422.5
            ],
            [
              'M',
              1928.12,
              424.5
            ],
            [
              'L',
              1954.77,
              424.5
            ],
            [
              'M',
              2056.68,
              424.5
            ],
            [
              'C',
              2059.07,
              423.677,
              2061.83,
              422.998,
              2065,
              422.5
            ],
            [
              'L',
              1985.1,
              422.5
            ],
            [
              'M',
              2056.68,
              424.5
            ],
            [
              'L',
              1983.5,
              424.5
            ],
            [
              'L',
              1983.5,
              422.5
            ],
            [
              'L',
              1983.5,
              281
            ],
            [
              'M',
              1983.5,
              281
            ],
            [
              'L',
              1985.5,
              281
            ],
            [
              'L',
              1985.5,
              422
            ],
            [
              'C',
              1985.37,
              422.165,
              1985.24,
              422.332,
              1985.1,
              422.5
            ],
            [
              'M',
              1983.5,
              281
            ],
            [
              'L',
              1980,
              281
            ],
            [
              'M',
              1980,
              281
            ],
            [
              'L',
              1969,
              281
            ],
            [
              'M',
              1980,
              281
            ],
            [
              'L',
              1980.49,
              422.5
            ],
            [
              'L',
              1980.49,
              424.5
            ],
            [
              'L',
              1980.5,
              426.834
            ],
            [
              'M',
              1980.5,
              426.834
            ],
            [
              'C',
              1980.07,
              427.149,
              1979.63,
              427.456,
              1979.17,
              427.75
            ],
            [
              'M',
              1980.5,
              426.834
            ],
            [
              'C',
              1982.34,
              425.472,
              1983.9,
              423.942,
              1985.1,
              422.5
            ],
            [
              'M',
              1969,
              431
            ],
            [
              'L',
              1969,
              427.75
            ],
            [
              'L',
              1969,
              424.5
            ],
            [
              'L',
              1969,
              422.5
            ],
            [
              'L',
              1969,
              281
            ],
            [
              'M',
              1969,
              281
            ],
            [
              'L',
              1957.5,
              281
            ],
            [
              'M',
              1957.5,
              281
            ],
            [
              'L',
              1954,
              281
            ],
            [
              'M',
              1957.5,
              281
            ],
            [
              'L',
              1957.5,
              422.5
            ],
            [
              'L',
              1957.5,
              424.5
            ],
            [
              'L',
              1957.5,
              427.619
            ],
            [
              'M',
              1957.5,
              427.619
            ],
            [
              'C',
              1957.82,
              427.62,
              1958.15,
              427.621,
              1958.47,
              427.622
            ],
            [
              'M',
              1954,
              423.703
            ],
            [
              'L',
              1954,
              422.5
            ],
            [
              'L',
              1954,
              281
            ],
            [
              'M',
              1954,
              423.703
            ],
            [
              'C',
              1954.26,
              423.978,
              1954.51,
              424.243,
              1954.77,
              424.5
            ],
            [
              'M',
              1954,
              423.703
            ],
            [
              'C',
              1953.64,
              423.32,
              1953.28,
              422.919,
              1952.92,
              422.5
            ],
            [
              'M',
              1954,
              281
            ],
            [
              'L',
              1952.5,
              281
            ],
            [
              'L',
              1952.5,
              422
            ],
            [
              'C',
              1952.64,
              422.169,
              1952.78,
              422.336,
              1952.92,
              422.5
            ],
            [
              'M',
              1954.77,
              424.5
            ],
            [
              'C',
              1956.01,
              425.734,
              1957.24,
              426.768,
              1958.47,
              427.622
            ],
            [
              'M',
              1958.47,
              427.622
            ],
            [
              'C',
              1966.24,
              433.024,
              1973.72,
              431.259,
              1979.17,
              427.75
            ]
          ],
          width: 159,
          height: 175.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 30.827512233002324,
          left: 693.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              2013,
              457.5
            ],
            [
              'L',
              2013,
              420
            ],
            [
              'L',
              2013,
              415
            ],
            [
              'M',
              2013,
              457.5
            ],
            [
              'L',
              2005.5,
              465
            ],
            [
              'M',
              2013,
              457.5
            ],
            [
              'L',
              2018.5,
              457.5
            ],
            [
              'M',
              2007,
              318
            ],
            [
              'L',
              2004.5,
              318
            ],
            [
              'L',
              2004.5,
              320.5
            ],
            [
              'M',
              2007,
              318
            ],
            [
              'L',
              2004.5,
              320.5
            ],
            [
              'M',
              2007,
              318
            ],
            [
              'L',
              2012.5,
              318
            ],
            [
              'M',
              2004.5,
              320.5
            ],
            [
              'L',
              2004.5,
              326
            ],
            [
              'M',
              2012.5,
              318
            ],
            [
              'L',
              2004.5,
              326
            ],
            [
              'M',
              2012.5,
              318
            ],
            [
              'L',
              2017.5,
              318
            ],
            [
              'M',
              2004.5,
              326
            ],
            [
              'L',
              2004.5,
              331
            ],
            [
              'M',
              2017.5,
              318
            ],
            [
              'L',
              2004.5,
              331
            ],
            [
              'M',
              2017.5,
              318
            ],
            [
              'L',
              2023,
              318
            ],
            [
              'M',
              2004.5,
              331
            ],
            [
              'L',
              2004.5,
              336.5
            ],
            [
              'M',
              2023,
              318
            ],
            [
              'L',
              2015,
              326.5
            ],
            [
              'M',
              2023,
              318
            ],
            [
              'L',
              2028.5,
              318
            ],
            [
              'M',
              2015,
              326.5
            ],
            [
              'L',
              2013,
              326.5
            ],
            [
              'L',
              2013,
              328
            ],
            [
              'M',
              2015,
              326.5
            ],
            [
              'L',
              2020.5,
              326.5
            ],
            [
              'M',
              2028.5,
              318
            ],
            [
              'L',
              2020.5,
              326.5
            ],
            [
              'M',
              2028.5,
              318
            ],
            [
              'L',
              2034,
              318
            ],
            [
              'M',
              2020.5,
              326.5
            ],
            [
              'L',
              2025.5,
              326.5
            ],
            [
              'M',
              2034,
              318
            ],
            [
              'L',
              2025.5,
              326.5
            ],
            [
              'M',
              2034,
              318
            ],
            [
              'L',
              2039,
              318
            ],
            [
              'M',
              2025.5,
              326.5
            ],
            [
              'L',
              2031,
              326.5
            ],
            [
              'M',
              2039,
              318
            ],
            [
              'L',
              2031,
              326.5
            ],
            [
              'M',
              2039,
              318
            ],
            [
              'L',
              2044.5,
              318
            ],
            [
              'M',
              2031,
              326.5
            ],
            [
              'L',
              2036,
              326.5
            ],
            [
              'M',
              2044.5,
              318
            ],
            [
              'L',
              2036,
              326.5
            ],
            [
              'M',
              2044.5,
              318
            ],
            [
              'L',
              2050,
              318
            ],
            [
              'M',
              2036,
              326.5
            ],
            [
              'L',
              2041.5,
              326.5
            ],
            [
              'M',
              2050,
              318
            ],
            [
              'L',
              2041.5,
              326.5
            ],
            [
              'M',
              2050,
              318
            ],
            [
              'L',
              2055,
              318
            ],
            [
              'M',
              2041.5,
              326.5
            ],
            [
              'L',
              2047,
              326.5
            ],
            [
              'M',
              2055,
              318
            ],
            [
              'L',
              2047,
              326.5
            ],
            [
              'M',
              2055,
              318
            ],
            [
              'L',
              2061,
              318
            ],
            [
              'M',
              2047,
              326.5
            ],
            [
              'L',
              2052,
              326.5
            ],
            [
              'M',
              2061,
              318
            ],
            [
              'L',
              2052,
              326.5
            ],
            [
              'M',
              2061,
              318
            ],
            [
              'L',
              2066,
              318
            ],
            [
              'M',
              2052,
              326.5
            ],
            [
              'L',
              2058,
              326.5
            ],
            [
              'M',
              2066,
              318
            ],
            [
              'L',
              2058,
              326.5
            ],
            [
              'M',
              2066,
              318
            ],
            [
              'L',
              2071.5,
              318
            ],
            [
              'M',
              2058,
              326.5
            ],
            [
              'L',
              2063,
              326.5
            ],
            [
              'M',
              2071.5,
              318
            ],
            [
              'L',
              2063,
              326.5
            ],
            [
              'M',
              2071.5,
              318
            ],
            [
              'L',
              2077,
              318
            ],
            [
              'M',
              2063,
              326.5
            ],
            [
              'L',
              2069,
              326.5
            ],
            [
              'M',
              2077,
              318
            ],
            [
              'L',
              2069,
              326.5
            ],
            [
              'M',
              2077,
              318
            ],
            [
              'L',
              2082.5,
              318
            ],
            [
              'M',
              2069,
              326.5
            ],
            [
              'L',
              2074,
              326.5
            ],
            [
              'M',
              2082.5,
              318
            ],
            [
              'L',
              2074,
              326.5
            ],
            [
              'M',
              2082.5,
              318
            ],
            [
              'L',
              2088,
              318
            ],
            [
              'M',
              2074,
              326.5
            ],
            [
              'L',
              2079.5,
              326.5
            ],
            [
              'M',
              2088,
              318
            ],
            [
              'L',
              2079.5,
              326.5
            ],
            [
              'M',
              2088,
              318
            ],
            [
              'L',
              2093,
              318
            ],
            [
              'M',
              2079.5,
              326.5
            ],
            [
              'L',
              2085,
              326.5
            ],
            [
              'M',
              2093,
              318
            ],
            [
              'L',
              2085,
              326.5
            ],
            [
              'M',
              2093,
              318
            ],
            [
              'L',
              2098.5,
              318
            ],
            [
              'M',
              2085,
              326.5
            ],
            [
              'L',
              2090,
              326.5
            ],
            [
              'M',
              2098.5,
              318
            ],
            [
              'L',
              2090,
              326.5
            ],
            [
              'M',
              2098.5,
              318
            ],
            [
              'L',
              2104,
              318
            ],
            [
              'M',
              2090,
              326.5
            ],
            [
              'L',
              2095.5,
              326.5
            ],
            [
              'M',
              2104,
              318
            ],
            [
              'L',
              2095.5,
              326.5
            ],
            [
              'M',
              2104,
              318
            ],
            [
              'L',
              2109.5,
              318
            ],
            [
              'M',
              2095.5,
              326.5
            ],
            [
              'L',
              2101,
              326.5
            ],
            [
              'M',
              2109.5,
              318
            ],
            [
              'L',
              2101,
              326.5
            ],
            [
              'M',
              2109.5,
              318
            ],
            [
              'L',
              2115,
              318
            ],
            [
              'M',
              2101,
              326.5
            ],
            [
              'L',
              2106.5,
              326.5
            ],
            [
              'M',
              2115,
              318
            ],
            [
              'L',
              2106.5,
              326.5
            ],
            [
              'M',
              2115,
              318
            ],
            [
              'L',
              2120,
              318
            ],
            [
              'M',
              2106.5,
              326.5
            ],
            [
              'L',
              2112,
              326.5
            ],
            [
              'M',
              2120,
              318
            ],
            [
              'L',
              2112,
              326.5
            ],
            [
              'M',
              2120,
              318
            ],
            [
              'L',
              2125.5,
              318
            ],
            [
              'M',
              2112,
              326.5
            ],
            [
              'L',
              2117.5,
              326.5
            ],
            [
              'M',
              2125.5,
              318
            ],
            [
              'L',
              2117.5,
              326.5
            ],
            [
              'M',
              2125.5,
              318
            ],
            [
              'L',
              2131,
              318
            ],
            [
              'M',
              2117.5,
              326.5
            ],
            [
              'L',
              2122.5,
              326.5
            ],
            [
              'M',
              2131,
              318
            ],
            [
              'L',
              2122.5,
              326.5
            ],
            [
              'M',
              2131,
              318
            ],
            [
              'L',
              2136,
              318
            ],
            [
              'M',
              2122.5,
              326.5
            ],
            [
              'L',
              2127.5,
              326.5
            ],
            [
              'M',
              2136,
              318
            ],
            [
              'L',
              2127.5,
              326.5
            ],
            [
              'M',
              2136,
              318
            ],
            [
              'L',
              2141.5,
              318
            ],
            [
              'M',
              2127.5,
              326.5
            ],
            [
              'L',
              2133,
              326.5
            ],
            [
              'M',
              2141.5,
              318
            ],
            [
              'L',
              2133,
              326.5
            ],
            [
              'M',
              2141.5,
              318
            ],
            [
              'L',
              2147,
              318
            ],
            [
              'M',
              2133,
              326.5
            ],
            [
              'L',
              2139,
              326.5
            ],
            [
              'M',
              2147,
              318
            ],
            [
              'L',
              2139,
              326.5
            ],
            [
              'M',
              2147,
              318
            ],
            [
              'L',
              2152.5,
              318
            ],
            [
              'M',
              2139,
              326.5
            ],
            [
              'L',
              2144.5,
              326.5
            ],
            [
              'M',
              2152.5,
              318
            ],
            [
              'L',
              2144.5,
              326.5
            ],
            [
              'M',
              2152.5,
              318
            ],
            [
              'L',
              2158,
              318
            ],
            [
              'M',
              2144.5,
              326.5
            ],
            [
              'L',
              2149.5,
              326.5
            ],
            [
              'M',
              2158,
              318
            ],
            [
              'L',
              2149.5,
              326.5
            ],
            [
              'M',
              2158,
              318
            ],
            [
              'L',
              2163.5,
              318
            ],
            [
              'M',
              2149.5,
              326.5
            ],
            [
              'L',
              2155,
              326.5
            ],
            [
              'M',
              2163.5,
              318
            ],
            [
              'L',
              2168,
              318
            ],
            [
              'L',
              2168,
              319.5
            ],
            [
              'M',
              2163.5,
              318
            ],
            [
              'L',
              2155,
              326.5
            ],
            [
              'M',
              2155,
              326.5
            ],
            [
              'L',
              2159.5,
              326.5
            ],
            [
              'L',
              2159.5,
              327.5
            ],
            [
              'M',
              2168,
              319.5
            ],
            [
              'L',
              2159.5,
              327.5
            ],
            [
              'M',
              2168,
              319.5
            ],
            [
              'L',
              2168,
              324.5
            ],
            [
              'M',
              2159.5,
              327.5
            ],
            [
              'L',
              2159.5,
              333
            ],
            [
              'M',
              2168,
              324.5
            ],
            [
              'L',
              2159.5,
              333
            ],
            [
              'M',
              2168,
              324.5
            ],
            [
              'L',
              2168,
              330
            ],
            [
              'M',
              2159.5,
              333
            ],
            [
              'L',
              2159.5,
              338
            ],
            [
              'M',
              2168,
              330
            ],
            [
              'L',
              2159.5,
              338
            ],
            [
              'M',
              2168,
              330
            ],
            [
              'L',
              2168,
              335.5
            ],
            [
              'M',
              2159.5,
              338
            ],
            [
              'L',
              2159.5,
              344
            ],
            [
              'M',
              2168,
              335.5
            ],
            [
              'L',
              2159.5,
              344
            ],
            [
              'M',
              2168,
              335.5
            ],
            [
              'L',
              2168,
              341
            ],
            [
              'M',
              2159.5,
              344
            ],
            [
              'L',
              2159.5,
              349
            ],
            [
              'M',
              2168,
              341
            ],
            [
              'L',
              2159.5,
              349
            ],
            [
              'M',
              2168,
              341
            ],
            [
              'L',
              2168,
              346.5
            ],
            [
              'M',
              2159.5,
              349
            ],
            [
              'L',
              2159.5,
              355
            ],
            [
              'M',
              2168,
              346.5
            ],
            [
              'L',
              2159.5,
              355
            ],
            [
              'M',
              2168,
              346.5
            ],
            [
              'L',
              2168,
              351.5
            ],
            [
              'M',
              2159.5,
              355
            ],
            [
              'L',
              2159.5,
              360
            ],
            [
              'M',
              2168,
              351.5
            ],
            [
              'L',
              2159.5,
              360
            ],
            [
              'M',
              2168,
              351.5
            ],
            [
              'L',
              2168,
              357
            ],
            [
              'M',
              2159.5,
              360
            ],
            [
              'L',
              2159.5,
              365.5
            ],
            [
              'M',
              2168,
              357
            ],
            [
              'L',
              2159.5,
              365.5
            ],
            [
              'M',
              2168,
              357
            ],
            [
              'L',
              2168,
              362.5
            ],
            [
              'M',
              2159.5,
              365.5
            ],
            [
              'L',
              2159.5,
              371
            ],
            [
              'M',
              2168,
              362.5
            ],
            [
              'L',
              2159.5,
              371
            ],
            [
              'M',
              2168,
              362.5
            ],
            [
              'L',
              2168,
              368
            ],
            [
              'M',
              2159.5,
              371
            ],
            [
              'L',
              2159.5,
              376.5
            ],
            [
              'M',
              2168,
              368
            ],
            [
              'L',
              2159.5,
              376.5
            ],
            [
              'M',
              2168,
              368
            ],
            [
              'L',
              2168,
              373.5
            ],
            [
              'M',
              2159.5,
              376.5
            ],
            [
              'L',
              2159.5,
              381.5
            ],
            [
              'M',
              2168,
              373.5
            ],
            [
              'L',
              2159.5,
              381.5
            ],
            [
              'M',
              2168,
              373.5
            ],
            [
              'L',
              2168,
              378.5
            ],
            [
              'M',
              2159.5,
              381.5
            ],
            [
              'L',
              2159.5,
              387
            ],
            [
              'M',
              2168,
              378.5
            ],
            [
              'L',
              2159.5,
              387
            ],
            [
              'M',
              2168,
              378.5
            ],
            [
              'L',
              2168,
              384.5
            ],
            [
              'M',
              2159.5,
              387
            ],
            [
              'L',
              2159.5,
              393
            ],
            [
              'M',
              2168,
              384.5
            ],
            [
              'L',
              2159.5,
              393
            ],
            [
              'M',
              2168,
              384.5
            ],
            [
              'L',
              2168,
              389.5
            ],
            [
              'M',
              2159.5,
              393
            ],
            [
              'L',
              2159.5,
              398
            ],
            [
              'M',
              2168,
              389.5
            ],
            [
              'L',
              2159.5,
              398
            ],
            [
              'M',
              2168,
              389.5
            ],
            [
              'L',
              2168,
              395
            ],
            [
              'M',
              2159.5,
              398
            ],
            [
              'L',
              2159.5,
              403
            ],
            [
              'M',
              2168,
              395
            ],
            [
              'L',
              2159.5,
              403
            ],
            [
              'M',
              2168,
              395
            ],
            [
              'L',
              2168,
              400
            ],
            [
              'M',
              2159.5,
              403
            ],
            [
              'L',
              2159.5,
              408
            ],
            [
              'M',
              2168,
              400
            ],
            [
              'L',
              2159.5,
              408
            ],
            [
              'M',
              2168,
              400
            ],
            [
              'L',
              2168,
              406
            ],
            [
              'M',
              2159.5,
              408
            ],
            [
              'L',
              2159.5,
              414
            ],
            [
              'M',
              2168,
              406
            ],
            [
              'L',
              2159.5,
              414
            ],
            [
              'M',
              2168,
              406
            ],
            [
              'L',
              2168,
              411
            ],
            [
              'M',
              2159.5,
              414
            ],
            [
              'L',
              2159.5,
              419.5
            ],
            [
              'M',
              2168,
              411
            ],
            [
              'L',
              2159.5,
              419.5
            ],
            [
              'M',
              2168,
              411
            ],
            [
              'L',
              2168,
              416.5
            ],
            [
              'M',
              2159.5,
              419.5
            ],
            [
              'L',
              2159.5,
              424.5
            ],
            [
              'M',
              2168,
              416.5
            ],
            [
              'L',
              2159.5,
              424.5
            ],
            [
              'M',
              2168,
              416.5
            ],
            [
              'L',
              2168,
              422
            ],
            [
              'M',
              2159.5,
              424.5
            ],
            [
              'L',
              2159.5,
              430
            ],
            [
              'M',
              2168,
              422
            ],
            [
              'L',
              2159.5,
              430
            ],
            [
              'M',
              2168,
              422
            ],
            [
              'L',
              2168,
              427.5
            ],
            [
              'M',
              2159.5,
              430
            ],
            [
              'L',
              2159.5,
              435.5
            ],
            [
              'M',
              2168,
              427.5
            ],
            [
              'L',
              2159.5,
              435.5
            ],
            [
              'M',
              2168,
              427.5
            ],
            [
              'L',
              2168,
              432.5
            ],
            [
              'M',
              2159.5,
              435.5
            ],
            [
              'L',
              2159.5,
              441
            ],
            [
              'M',
              2168,
              432.5
            ],
            [
              'L',
              2159.5,
              441
            ],
            [
              'M',
              2168,
              432.5
            ],
            [
              'L',
              2168,
              438
            ],
            [
              'M',
              2159.5,
              441
            ],
            [
              'L',
              2159.5,
              446.5
            ],
            [
              'M',
              2168,
              438
            ],
            [
              'L',
              2159.5,
              446.5
            ],
            [
              'M',
              2168,
              438
            ],
            [
              'L',
              2168,
              443.5
            ],
            [
              'M',
              2159.5,
              446.5
            ],
            [
              'L',
              2159.5,
              452
            ],
            [
              'M',
              2168,
              443.5
            ],
            [
              'L',
              2159.5,
              452
            ],
            [
              'M',
              2168,
              443.5
            ],
            [
              'L',
              2168,
              449
            ],
            [
              'M',
              2159.5,
              452
            ],
            [
              'L',
              2159.5,
              457
            ],
            [
              'M',
              2168,
              449
            ],
            [
              'L',
              2159.5,
              457
            ],
            [
              'M',
              2168,
              449
            ],
            [
              'L',
              2168,
              454.5
            ],
            [
              'M',
              2159.5,
              457
            ],
            [
              'L',
              2159.5,
              457.5
            ],
            [
              'L',
              2158.5,
              457.5
            ],
            [
              'M',
              2168,
              454.5
            ],
            [
              'L',
              2157,
              465
            ],
            [
              'M',
              2168,
              454.5
            ],
            [
              'L',
              2168,
              459.75
            ],
            [
              'M',
              2157,
              465
            ],
            [
              'L',
              2163,
              465
            ],
            [
              'M',
              2157,
              465
            ],
            [
              'L',
              2151,
              465
            ],
            [
              'M',
              2168,
              459.75
            ],
            [
              'L',
              2168,
              465
            ],
            [
              'L',
              2163,
              465
            ],
            [
              'M',
              2168,
              459.75
            ],
            [
              'L',
              2163,
              465
            ],
            [
              'M',
              2158.5,
              457.5
            ],
            [
              'L',
              2151,
              465
            ],
            [
              'M',
              2158.5,
              457.5
            ],
            [
              'L',
              2153,
              457.5
            ],
            [
              'M',
              2151,
              465
            ],
            [
              'L',
              2145.5,
              465
            ],
            [
              'M',
              2153,
              457.5
            ],
            [
              'L',
              2145.5,
              465
            ],
            [
              'M',
              2153,
              457.5
            ],
            [
              'L',
              2147.5,
              457.5
            ],
            [
              'M',
              2145.5,
              465
            ],
            [
              'L',
              2140,
              465
            ],
            [
              'M',
              2147.5,
              457.5
            ],
            [
              'L',
              2140,
              465
            ],
            [
              'M',
              2147.5,
              457.5
            ],
            [
              'L',
              2142.5,
              457.5
            ],
            [
              'M',
              2140,
              465
            ],
            [
              'L',
              2135,
              465
            ],
            [
              'M',
              2142.5,
              457.5
            ],
            [
              'L',
              2135,
              465
            ],
            [
              'M',
              2142.5,
              457.5
            ],
            [
              'L',
              2137.5,
              457.5
            ],
            [
              'M',
              2135,
              465
            ],
            [
              'L',
              2130,
              465
            ],
            [
              'M',
              2137.5,
              457.5
            ],
            [
              'L',
              2130,
              465
            ],
            [
              'M',
              2137.5,
              457.5
            ],
            [
              'L',
              2131.5,
              457.5
            ],
            [
              'M',
              2130,
              465
            ],
            [
              'L',
              2124,
              465
            ],
            [
              'M',
              2131.5,
              457.5
            ],
            [
              'L',
              2124,
              465
            ],
            [
              'M',
              2131.5,
              457.5
            ],
            [
              'L',
              2126.5,
              457.5
            ],
            [
              'M',
              2124,
              465
            ],
            [
              'L',
              2119.5,
              465
            ],
            [
              'M',
              2126.5,
              457.5
            ],
            [
              'L',
              2119.5,
              465
            ],
            [
              'M',
              2126.5,
              457.5
            ],
            [
              'L',
              2121.5,
              457.5
            ],
            [
              'M',
              2119.5,
              465
            ],
            [
              'L',
              2114,
              465
            ],
            [
              'M',
              2121.5,
              457.5
            ],
            [
              'L',
              2114,
              465
            ],
            [
              'M',
              2121.5,
              457.5
            ],
            [
              'L',
              2116,
              457.5
            ],
            [
              'M',
              2114,
              465
            ],
            [
              'L',
              2109,
              465
            ],
            [
              'L',
              2109,
              464.5
            ],
            [
              'M',
              2116,
              457.5
            ],
            [
              'L',
              2109,
              464.5
            ],
            [
              'M',
              2116,
              457.5
            ],
            [
              'L',
              2110.5,
              457.5
            ],
            [
              'M',
              2109,
              464.5
            ],
            [
              'L',
              2109,
              459
            ],
            [
              'M',
              2110.5,
              457.5
            ],
            [
              'L',
              2109,
              457.5
            ],
            [
              'L',
              2109,
              459
            ],
            [
              'M',
              2110.5,
              457.5
            ],
            [
              'L',
              2109,
              459
            ],
            [
              'M',
              2013,
              328
            ],
            [
              'L',
              2004.5,
              336.5
            ],
            [
              'M',
              2013,
              328
            ],
            [
              'L',
              2013,
              334
            ],
            [
              'M',
              2004.5,
              336.5
            ],
            [
              'L',
              2004.5,
              342
            ],
            [
              'M',
              2013,
              334
            ],
            [
              'L',
              2004.5,
              342
            ],
            [
              'M',
              2013,
              334
            ],
            [
              'L',
              2013,
              339
            ],
            [
              'M',
              2004.5,
              342
            ],
            [
              'L',
              2004.5,
              347.5
            ],
            [
              'M',
              2013,
              339
            ],
            [
              'L',
              2004.5,
              347.5
            ],
            [
              'M',
              2013,
              339
            ],
            [
              'L',
              2013,
              344.5
            ],
            [
              'M',
              2004.5,
              347.5
            ],
            [
              'L',
              2004.5,
              353
            ],
            [
              'M',
              2013,
              344.5
            ],
            [
              'L',
              2004.5,
              353
            ],
            [
              'M',
              2013,
              344.5
            ],
            [
              'L',
              2013,
              350
            ],
            [
              'M',
              2004.5,
              353
            ],
            [
              'L',
              2004.5,
              358.5
            ],
            [
              'M',
              2013,
              350
            ],
            [
              'L',
              2004.5,
              358.5
            ],
            [
              'M',
              2013,
              350
            ],
            [
              'L',
              2013,
              355.5
            ],
            [
              'M',
              2004.5,
              358.5
            ],
            [
              'L',
              2004.5,
              364
            ],
            [
              'M',
              2013,
              355.5
            ],
            [
              'L',
              2004.5,
              364
            ],
            [
              'M',
              2013,
              355.5
            ],
            [
              'L',
              2013,
              360.5
            ],
            [
              'M',
              2004.5,
              364
            ],
            [
              'L',
              2004.5,
              369
            ],
            [
              'M',
              2013,
              360.5
            ],
            [
              'L',
              2004.5,
              369
            ],
            [
              'M',
              2013,
              360.5
            ],
            [
              'L',
              2013,
              366
            ],
            [
              'M',
              2004.5,
              369
            ],
            [
              'L',
              2004.5,
              374.5
            ],
            [
              'M',
              2013,
              366
            ],
            [
              'L',
              2004.5,
              374.5
            ],
            [
              'M',
              2013,
              366
            ],
            [
              'L',
              2013,
              371
            ],
            [
              'M',
              2004.5,
              374.5
            ],
            [
              'L',
              2004.5,
              380
            ],
            [
              'M',
              2013,
              371
            ],
            [
              'L',
              2004.5,
              380
            ],
            [
              'M',
              2013,
              371
            ],
            [
              'L',
              2013,
              376.5
            ],
            [
              'M',
              2004.5,
              380
            ],
            [
              'L',
              2004.5,
              385
            ],
            [
              'M',
              2013,
              376.5
            ],
            [
              'L',
              2004.5,
              385
            ],
            [
              'M',
              2013,
              376.5
            ],
            [
              'L',
              2013,
              382.5
            ],
            [
              'M',
              2004.5,
              385
            ],
            [
              'L',
              2004.5,
              390.5
            ],
            [
              'M',
              2013,
              382.5
            ],
            [
              'L',
              2004.5,
              390.5
            ],
            [
              'M',
              2013,
              382.5
            ],
            [
              'L',
              2013,
              387.5
            ],
            [
              'M',
              2004.5,
              390.5
            ],
            [
              'L',
              2004.5,
              396
            ],
            [
              'M',
              2013,
              387.5
            ],
            [
              'L',
              2004.5,
              396
            ],
            [
              'M',
              2013,
              387.5
            ],
            [
              'L',
              2013,
              392.5
            ],
            [
              'M',
              2004.5,
              396
            ],
            [
              'L',
              2004.5,
              401.5
            ],
            [
              'M',
              2013,
              392.5
            ],
            [
              'L',
              2004.5,
              401.5
            ],
            [
              'M',
              2013,
              392.5
            ],
            [
              'L',
              2013,
              398.5
            ],
            [
              'M',
              2004.5,
              401.5
            ],
            [
              'L',
              2004.5,
              406.5
            ],
            [
              'M',
              2013,
              398.5
            ],
            [
              'L',
              2004.5,
              406.5
            ],
            [
              'M',
              2013,
              398.5
            ],
            [
              'L',
              2013,
              404
            ],
            [
              'M',
              2004.5,
              406.5
            ],
            [
              'L',
              2004.5,
              412.5
            ],
            [
              'M',
              2013,
              404
            ],
            [
              'L',
              2004.5,
              412.5
            ],
            [
              'M',
              2013,
              404
            ],
            [
              'L',
              2013,
              409.5
            ],
            [
              'M',
              2004.5,
              412.5
            ],
            [
              'L',
              2004.5,
              418
            ],
            [
              'M',
              2013,
              409.5
            ],
            [
              'L',
              2004.5,
              418
            ],
            [
              'M',
              2013,
              409.5
            ],
            [
              'L',
              2013,
              415
            ],
            [
              'M',
              2004.5,
              418
            ],
            [
              'L',
              2004.5,
              422
            ],
            [
              'L',
              2004.5,
              423
            ],
            [
              'M',
              2013,
              415
            ],
            [
              'L',
              2004.5,
              423
            ],
            [
              'M',
              2004.5,
              423
            ],
            [
              'L',
              2004.5,
              465
            ],
            [
              'L',
              2005.5,
              465
            ],
            [
              'M',
              2005.5,
              465
            ],
            [
              'L',
              2011,
              465
            ],
            [
              'M',
              2018.5,
              457.5
            ],
            [
              'L',
              2011,
              465
            ],
            [
              'M',
              2018.5,
              457.5
            ],
            [
              'L',
              2024,
              457.5
            ],
            [
              'M',
              2011,
              465
            ],
            [
              'L',
              2016.5,
              465
            ],
            [
              'M',
              2024,
              457.5
            ],
            [
              'L',
              2016.5,
              465
            ],
            [
              'M',
              2024,
              457.5
            ],
            [
              'L',
              2030,
              457.5
            ],
            [
              'M',
              2016.5,
              465
            ],
            [
              'L',
              2022.5,
              465
            ],
            [
              'M',
              2030,
              457.5
            ],
            [
              'L',
              2022.5,
              465
            ],
            [
              'M',
              2030,
              457.5
            ],
            [
              'L',
              2034.5,
              457.5
            ],
            [
              'M',
              2022.5,
              465
            ],
            [
              'L',
              2027,
              465
            ],
            [
              'M',
              2034.5,
              457.5
            ],
            [
              'L',
              2027,
              465
            ],
            [
              'M',
              2034.5,
              457.5
            ],
            [
              'L',
              2040,
              457.5
            ],
            [
              'M',
              2027,
              465
            ],
            [
              'L',
              2033,
              465
            ],
            [
              'M',
              2040,
              457.5
            ],
            [
              'L',
              2033,
              465
            ],
            [
              'M',
              2040,
              457.5
            ],
            [
              'L',
              2045.5,
              457.5
            ],
            [
              'M',
              2033,
              465
            ],
            [
              'L',
              2038,
              465
            ],
            [
              'M',
              2045.5,
              457.5
            ],
            [
              'L',
              2038,
              465
            ],
            [
              'M',
              2045.5,
              457.5
            ],
            [
              'L',
              2051,
              457.5
            ],
            [
              'M',
              2038,
              465
            ],
            [
              'L',
              2043,
              465
            ],
            [
              'M',
              2051,
              457.5
            ],
            [
              'L',
              2043,
              465
            ],
            [
              'M',
              2051,
              457.5
            ],
            [
              'L',
              2056.5,
              457.5
            ],
            [
              'M',
              2043,
              465
            ],
            [
              'L',
              2049,
              465
            ],
            [
              'M',
              2056.5,
              457.5
            ],
            [
              'L',
              2049,
              465
            ],
            [
              'M',
              2056.5,
              457.5
            ],
            [
              'L',
              2061.25,
              457.5
            ],
            [
              'M',
              2049,
              465
            ],
            [
              'L',
              2054,
              465
            ],
            [
              'M',
              2061.25,
              457.5
            ],
            [
              'L',
              2066,
              457.5
            ],
            [
              'L',
              2066,
              459
            ],
            [
              'M',
              2061.25,
              457.5
            ],
            [
              'L',
              2054,
              465
            ],
            [
              'M',
              2054,
              465
            ],
            [
              'L',
              2060,
              465
            ],
            [
              'M',
              2066,
              459
            ],
            [
              'L',
              2066,
              465
            ],
            [
              'L',
              2060,
              465
            ],
            [
              'M',
              2066,
              459
            ],
            [
              'L',
              2060,
              465
            ]
          ],
          width: 163.5,
          height: 147,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 178.32751223300232,
          left: 693.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              2016,
              465.5
            ],
            [
              'L',
              2010.5,
              465.5
            ],
            [
              'M',
              2016,
              465.5
            ],
            [
              'L',
              2004,
              477.5
            ],
            [
              'M',
              2016,
              465.5
            ],
            [
              'L',
              2016,
              471.5
            ],
            [
              'M',
              2010.5,
              465.5
            ],
            [
              'L',
              2004,
              465.5
            ],
            [
              'L',
              2004,
              472
            ],
            [
              'M',
              2010.5,
              465.5
            ],
            [
              'L',
              2004,
              472
            ],
            [
              'M',
              2004,
              472
            ],
            [
              'L',
              2004,
              477.5
            ],
            [
              'M',
              2004,
              477.5
            ],
            [
              'L',
              2004,
              483
            ],
            [
              'M',
              2016,
              471.5
            ],
            [
              'L',
              2004,
              483
            ],
            [
              'M',
              2016,
              471.5
            ],
            [
              'L',
              2016,
              476.5
            ],
            [
              'M',
              2004,
              483
            ],
            [
              'L',
              2004,
              488.5
            ],
            [
              'M',
              2016,
              476.5
            ],
            [
              'L',
              2004,
              488.5
            ],
            [
              'M',
              2016,
              476.5
            ],
            [
              'L',
              2016,
              482
            ],
            [
              'M',
              2004,
              488.5
            ],
            [
              'L',
              2004,
              494
            ],
            [
              'M',
              2016,
              482
            ],
            [
              'L',
              2004,
              494
            ],
            [
              'M',
              2016,
              482
            ],
            [
              'L',
              2016,
              487.5
            ],
            [
              'M',
              2004,
              494
            ],
            [
              'L',
              2004,
              499.5
            ],
            [
              'M',
              2016,
              487.5
            ],
            [
              'L',
              2004,
              499.5
            ],
            [
              'M',
              2016,
              487.5
            ],
            [
              'L',
              2016,
              493
            ],
            [
              'M',
              2004,
              499.5
            ],
            [
              'L',
              2004,
              504.5
            ],
            [
              'M',
              2016,
              493
            ],
            [
              'L',
              2004,
              504.5
            ],
            [
              'M',
              2016,
              493
            ],
            [
              'L',
              2016,
              498.5
            ],
            [
              'M',
              2004,
              504.5
            ],
            [
              'L',
              2004,
              510.5
            ],
            [
              'M',
              2016,
              498.5
            ],
            [
              'L',
              2004,
              510.5
            ],
            [
              'M',
              2016,
              498.5
            ],
            [
              'L',
              2016,
              504
            ],
            [
              'M',
              2004,
              510.5
            ],
            [
              'L',
              2004,
              515.5
            ],
            [
              'M',
              2016,
              504
            ],
            [
              'L',
              2004,
              515.5
            ],
            [
              'M',
              2016,
              504
            ],
            [
              'L',
              2016,
              509.5
            ],
            [
              'M',
              2004,
              515.5
            ],
            [
              'L',
              2004,
              521
            ],
            [
              'M',
              2016,
              509.5
            ],
            [
              'L',
              2004,
              521
            ],
            [
              'M',
              2016,
              509.5
            ],
            [
              'L',
              2016,
              514.5
            ],
            [
              'M',
              2004,
              521
            ],
            [
              'L',
              2004,
              526
            ],
            [
              'M',
              2016,
              514.5
            ],
            [
              'L',
              2004,
              526
            ],
            [
              'M',
              2016,
              514.5
            ],
            [
              'L',
              2016,
              520
            ],
            [
              'M',
              2004,
              526
            ],
            [
              'L',
              2004,
              531.5
            ],
            [
              'M',
              2016,
              520
            ],
            [
              'L',
              2004,
              531.5
            ],
            [
              'M',
              2016,
              520
            ],
            [
              'L',
              2016,
              525.5
            ],
            [
              'M',
              2004,
              531.5
            ],
            [
              'L',
              2004,
              537
            ],
            [
              'M',
              2016,
              525.5
            ],
            [
              'L',
              2004,
              537
            ],
            [
              'M',
              2016,
              525.5
            ],
            [
              'L',
              2016,
              530.5
            ],
            [
              'M',
              2004,
              537
            ],
            [
              'L',
              2004,
              542.5
            ],
            [
              'M',
              2016,
              530.5
            ],
            [
              'L',
              2004,
              542.5
            ],
            [
              'M',
              2016,
              530.5
            ],
            [
              'L',
              2016,
              536
            ],
            [
              'M',
              2004,
              542.5
            ],
            [
              'L',
              2004,
              547.5
            ],
            [
              'M',
              2016,
              536
            ],
            [
              'L',
              2004,
              547.5
            ],
            [
              'M',
              2016,
              536
            ],
            [
              'L',
              2016,
              541
            ],
            [
              'M',
              2004,
              547.5
            ],
            [
              'L',
              2004,
              553
            ],
            [
              'M',
              2016,
              541
            ],
            [
              'L',
              2004,
              553
            ],
            [
              'M',
              2016,
              541
            ],
            [
              'L',
              2016,
              547
            ],
            [
              'M',
              2004,
              553
            ],
            [
              'L',
              2004,
              558.5
            ],
            [
              'M',
              2016,
              547
            ],
            [
              'L',
              2004,
              558.5
            ],
            [
              'M',
              2016,
              547
            ],
            [
              'L',
              2016,
              552
            ],
            [
              'M',
              2004,
              558.5
            ],
            [
              'L',
              2004,
              564
            ],
            [
              'M',
              2016,
              552
            ],
            [
              'L',
              2004,
              564
            ],
            [
              'M',
              2016,
              552
            ],
            [
              'L',
              2016,
              557.5
            ],
            [
              'M',
              2004,
              564
            ],
            [
              'L',
              2004,
              569.5
            ],
            [
              'M',
              2016,
              557.5
            ],
            [
              'L',
              2016,
              560.5
            ],
            [
              'L',
              2018.5,
              560.5
            ],
            [
              'M',
              2016,
              557.5
            ],
            [
              'L',
              2004,
              569.5
            ],
            [
              'M',
              2004,
              569.5
            ],
            [
              'L',
              2004,
              572.5
            ],
            [
              'L',
              2006.5,
              572.5
            ],
            [
              'M',
              2018.5,
              560.5
            ],
            [
              'L',
              2006.5,
              572.5
            ],
            [
              'M',
              2018.5,
              560.5
            ],
            [
              'L',
              2023.5,
              560.5
            ],
            [
              'M',
              2006.5,
              572.5
            ],
            [
              'L',
              2012,
              572.5
            ],
            [
              'M',
              2023.5,
              560.5
            ],
            [
              'L',
              2012,
              572.5
            ],
            [
              'M',
              2023.5,
              560.5
            ],
            [
              'L',
              2029,
              560.5
            ],
            [
              'M',
              2012,
              572.5
            ],
            [
              'L',
              2017,
              572.5
            ],
            [
              'M',
              2029,
              560.5
            ],
            [
              'L',
              2017,
              572.5
            ],
            [
              'M',
              2029,
              560.5
            ],
            [
              'L',
              2034.5,
              560.5
            ],
            [
              'M',
              2017,
              572.5
            ],
            [
              'L',
              2022.5,
              572.5
            ],
            [
              'M',
              2034.5,
              560.5
            ],
            [
              'L',
              2022.5,
              572.5
            ],
            [
              'M',
              2034.5,
              560.5
            ],
            [
              'L',
              2039.5,
              560.5
            ],
            [
              'M',
              2022.5,
              572.5
            ],
            [
              'L',
              2027.5,
              572.5
            ],
            [
              'M',
              2039.5,
              560.5
            ],
            [
              'L',
              2027.5,
              572.5
            ],
            [
              'M',
              2039.5,
              560.5
            ],
            [
              'L',
              2045.5,
              560.5
            ],
            [
              'M',
              2027.5,
              572.5
            ],
            [
              'L',
              2033.5,
              572.5
            ],
            [
              'M',
              2045.5,
              560.5
            ],
            [
              'L',
              2033.5,
              572.5
            ],
            [
              'M',
              2045.5,
              560.5
            ],
            [
              'L',
              2050.5,
              560.5
            ],
            [
              'M',
              2033.5,
              572.5
            ],
            [
              'L',
              2038.5,
              572.5
            ],
            [
              'M',
              2050.5,
              560.5
            ],
            [
              'L',
              2038.5,
              572.5
            ],
            [
              'M',
              2050.5,
              560.5
            ],
            [
              'L',
              2056,
              560.5
            ],
            [
              'M',
              2038.5,
              572.5
            ],
            [
              'L',
              2044,
              572.5
            ],
            [
              'M',
              2056,
              560.5
            ],
            [
              'L',
              2044,
              572.5
            ],
            [
              'M',
              2056,
              560.5
            ],
            [
              'L',
              2061.5,
              560.5
            ],
            [
              'M',
              2044,
              572.5
            ],
            [
              'L',
              2049.5,
              572.5
            ],
            [
              'M',
              2061.5,
              560.5
            ],
            [
              'L',
              2049.5,
              572.5
            ],
            [
              'M',
              2061.5,
              560.5
            ],
            [
              'L',
              2067,
              560.5
            ],
            [
              'M',
              2049.5,
              572.5
            ],
            [
              'L',
              2055,
              572.5
            ],
            [
              'M',
              2067,
              560.5
            ],
            [
              'L',
              2055,
              572.5
            ],
            [
              'M',
              2067,
              560.5
            ],
            [
              'L',
              2072,
              560.5
            ],
            [
              'M',
              2055,
              572.5
            ],
            [
              'L',
              2060.5,
              572.5
            ],
            [
              'M',
              2072,
              560.5
            ],
            [
              'L',
              2060.5,
              572.5
            ],
            [
              'M',
              2072,
              560.5
            ],
            [
              'L',
              2077.5,
              560.5
            ],
            [
              'M',
              2060.5,
              572.5
            ],
            [
              'L',
              2065.5,
              572.5
            ],
            [
              'M',
              2077.5,
              560.5
            ],
            [
              'L',
              2065.5,
              572.5
            ],
            [
              'M',
              2077.5,
              560.5
            ],
            [
              'L',
              2083,
              560.5
            ],
            [
              'M',
              2065.5,
              572.5
            ],
            [
              'L',
              2071,
              572.5
            ],
            [
              'M',
              2083,
              560.5
            ],
            [
              'L',
              2071,
              572.5
            ],
            [
              'M',
              2083,
              560.5
            ],
            [
              'L',
              2088.5,
              560.5
            ],
            [
              'M',
              2071,
              572.5
            ],
            [
              'L',
              2076.5,
              572.5
            ],
            [
              'M',
              2088.5,
              560.5
            ],
            [
              'L',
              2076.5,
              572.5
            ],
            [
              'M',
              2088.5,
              560.5
            ],
            [
              'L',
              2093.5,
              560.5
            ],
            [
              'M',
              2076.5,
              572.5
            ],
            [
              'L',
              2082,
              572.5
            ],
            [
              'M',
              2093.5,
              560.5
            ],
            [
              'L',
              2082,
              572.5
            ],
            [
              'M',
              2093.5,
              560.5
            ],
            [
              'L',
              2099.5,
              560.5
            ],
            [
              'M',
              2082,
              572.5
            ],
            [
              'L',
              2087.5,
              572.5
            ],
            [
              'M',
              2099.5,
              560.5
            ],
            [
              'L',
              2087.5,
              572.5
            ],
            [
              'M',
              2099.5,
              560.5
            ],
            [
              'L',
              2104.5,
              560.5
            ],
            [
              'M',
              2087.5,
              572.5
            ],
            [
              'L',
              2093,
              572.5
            ],
            [
              'M',
              2104.5,
              560.5
            ],
            [
              'L',
              2093,
              572.5
            ],
            [
              'M',
              2104.5,
              560.5
            ],
            [
              'L',
              2110,
              560.5
            ],
            [
              'M',
              2093,
              572.5
            ],
            [
              'L',
              2098,
              572.5
            ],
            [
              'M',
              2110,
              560.5
            ],
            [
              'L',
              2111,
              560.5
            ],
            [
              'L',
              2118,
              557.9
            ],
            [
              'M',
              2110,
              560.5
            ],
            [
              'L',
              2098,
              572.5
            ],
            [
              'M',
              2098,
              572.5
            ],
            [
              'L',
              2103.5,
              572.5
            ],
            [
              'M',
              2118,
              557.9
            ],
            [
              'L',
              2103.5,
              572.5
            ],
            [
              'M',
              2118,
              557.9
            ],
            [
              'L',
              2126.5,
              554.743
            ],
            [
              'M',
              2103.5,
              572.5
            ],
            [
              'L',
              2109,
              572.5
            ],
            [
              'M',
              2126.5,
              554.743
            ],
            [
              'L',
              2109,
              572.5
            ],
            [
              'M',
              2126.5,
              554.743
            ],
            [
              'L',
              2135.23,
              551.5
            ],
            [
              'M',
              2109,
              572.5
            ],
            [
              'L',
              2111,
              572.5
            ],
            [
              'L',
              2117,
              570.257
            ],
            [
              'M',
              2135.23,
              551.5
            ],
            [
              'L',
              2117,
              570.257
            ],
            [
              'M',
              2135.23,
              551.5
            ],
            [
              'L',
              2143.72,
              548.347
            ],
            [
              'M',
              2117,
              570.257
            ],
            [
              'L',
              2125.5,
              567.078
            ],
            [
              'M',
              2143.72,
              548.347
            ],
            [
              'L',
              2125.5,
              567.078
            ],
            [
              'M',
              2143.72,
              548.347
            ],
            [
              'L',
              2152.73,
              545
            ],
            [
              'M',
              2125.5,
              567.078
            ],
            [
              'L',
              2133.73,
              564
            ],
            [
              'M',
              2152.73,
              545
            ],
            [
              'L',
              2133.73,
              564
            ],
            [
              'M',
              2152.73,
              545
            ],
            [
              'L',
              2160.81,
              542
            ],
            [
              'M',
              2133.73,
              564
            ],
            [
              'L',
              2142,
              560.909
            ],
            [
              'M',
              2160.81,
              542
            ],
            [
              'L',
              2163.5,
              541
            ],
            [
              'L',
              2167.5,
              541
            ],
            [
              'M',
              2160.81,
              542
            ],
            [
              'L',
              2142,
              560.909
            ],
            [
              'M',
              2142,
              560.909
            ],
            [
              'L',
              2150.79,
              557.621
            ],
            [
              'M',
              2167.5,
              541
            ],
            [
              'L',
              2168.5,
              541
            ],
            [
              'L',
              2168.5,
              545.5
            ],
            [
              'M',
              2167.5,
              541
            ],
            [
              'L',
              2150.79,
              557.621
            ],
            [
              'M',
              2150.79,
              557.621
            ],
            [
              'L',
              2160.5,
              553.991
            ],
            [
              'M',
              2168.5,
              545.5
            ],
            [
              'L',
              2168.5,
              551
            ],
            [
              'L',
              2160.5,
              553.991
            ],
            [
              'M',
              2168.5,
              545.5
            ],
            [
              'L',
              2160.5,
              553.991
            ]
          ],
          width: 164.5,
          height: 107,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 161.82751223300232,
          left: 857.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              2511,
              540.5
            ],
            [
              'L',
              2570.5,
              546.5
            ],
            [
              'L',
              2577,
              551
            ],
            [
              'L',
              2598.5,
              551
            ],
            [
              'L',
              2614.5,
              489
            ],
            [
              'L',
              2619.5,
              481
            ],
            [
              'M',
              2511,
              540.5
            ],
            [
              'L',
              2424.5,
              540.5
            ],
            [
              'M',
              2511,
              540.5
            ],
            [
              'L',
              2511,
              479.5
            ],
            [
              'M',
              2537.5,
              499.5
            ],
            [
              'L',
              2511,
              479.5
            ],
            [
              'M',
              2511,
              479.5
            ],
            [
              'L',
              2488.5,
              467.5
            ],
            [
              'L',
              2488.5,
              476.5
            ],
            [
              'L',
              2446.5,
              476.5
            ],
            [
              'L',
              2446.5,
              467.5
            ],
            [
              'L',
              2424.5,
              479.5
            ],
            [
              'M',
              2424.5,
              479.5
            ],
            [
              'L',
              2363.5,
              472
            ],
            [
              'L',
              2321,
              470.692
            ],
            [
              'M',
              2424.5,
              479.5
            ],
            [
              'L',
              2424.5,
              540.5
            ],
            [
              'M',
              2424.5,
              540.5
            ],
            [
              'L',
              2168.5,
              540.5
            ],
            [
              'L',
              2168.5,
              466
            ],
            [
              'L',
              2304.5,
              470.185
            ],
            [
              'M',
              2321,
              470.692
            ],
            [
              'L',
              2321,
              449
            ],
            [
              'M',
              2321,
              470.692
            ],
            [
              'L',
              2304.5,
              470.185
            ],
            [
              'M',
              2304.5,
              470.185
            ],
            [
              'L',
              2304.5,
              449
            ]
          ],
          width: 451,
          height: 102,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 180.32751223300232,
          left: 1136.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              2488,
              472
            ],
            [
              'L',
              2488,
              476.5
            ],
            [
              'L',
              2483.5,
              476.5
            ],
            [
              'M',
              2488,
              472
            ],
            [
              'L',
              2488,
              467.5
            ],
            [
              'L',
              2486.5,
              467.5
            ],
            [
              'M',
              2488,
              472
            ],
            [
              'L',
              2483.5,
              476.5
            ],
            [
              'M',
              2483.5,
              476.5
            ],
            [
              'L',
              2477.5,
              476.5
            ],
            [
              'M',
              2486.5,
              467.5
            ],
            [
              'L',
              2477.5,
              476.5
            ],
            [
              'M',
              2486.5,
              467.5
            ],
            [
              'L',
              2481.5,
              467.5
            ],
            [
              'M',
              2477.5,
              476.5
            ],
            [
              'L',
              2472,
              476.5
            ],
            [
              'M',
              2481.5,
              467.5
            ],
            [
              'L',
              2472,
              476.5
            ],
            [
              'M',
              2481.5,
              467.5
            ],
            [
              'L',
              2476,
              467.5
            ],
            [
              'M',
              2472,
              476.5
            ],
            [
              'L',
              2467,
              476.5
            ],
            [
              'M',
              2476,
              467.5
            ],
            [
              'L',
              2467,
              476.5
            ],
            [
              'M',
              2476,
              467.5
            ],
            [
              'L',
              2470,
              467.5
            ],
            [
              'M',
              2467,
              476.5
            ],
            [
              'L',
              2461,
              476.5
            ],
            [
              'M',
              2470,
              467.5
            ],
            [
              'L',
              2461,
              476.5
            ],
            [
              'M',
              2470,
              467.5
            ],
            [
              'L',
              2465,
              467.5
            ],
            [
              'M',
              2461,
              476.5
            ],
            [
              'L',
              2456,
              476.5
            ],
            [
              'M',
              2465,
              467.5
            ],
            [
              'L',
              2456,
              476.5
            ],
            [
              'M',
              2465,
              467.5
            ],
            [
              'L',
              2459.5,
              467.5
            ],
            [
              'M',
              2456,
              476.5
            ],
            [
              'L',
              2450.5,
              476.5
            ],
            [
              'M',
              2459.5,
              467.5
            ],
            [
              'L',
              2450.5,
              476.5
            ],
            [
              'M',
              2459.5,
              467.5
            ],
            [
              'L',
              2454,
              467.5
            ],
            [
              'M',
              2450.5,
              476.5
            ],
            [
              'L',
              2447,
              476.5
            ],
            [
              'L',
              2447,
              474.5
            ],
            [
              'M',
              2454,
              467.5
            ],
            [
              'L',
              2447,
              474.5
            ],
            [
              'M',
              2454,
              467.5
            ],
            [
              'L',
              2449,
              467.5
            ],
            [
              'M',
              2447,
              474.5
            ],
            [
              'L',
              2447,
              469.5
            ],
            [
              'M',
              2449,
              467.5
            ],
            [
              'L',
              2447,
              467.5
            ],
            [
              'L',
              2447,
              469.5
            ],
            [
              'M',
              2449,
              467.5
            ],
            [
              'L',
              2447,
              469.5
            ]
          ],
          width: 41,
          height: 9,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 183.32751223300232,
          left: 991.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              2323.5,
              473.5
            ],
            [
              'L',
              2323.5,
              471
            ],
            [
              'L',
              2320.5,
              470.93
            ],
            [
              'M',
              2323.5,
              473.5
            ],
            [
              'L',
              2323.5,
              476
            ],
            [
              'L',
              2321,
              476
            ],
            [
              'M',
              2323.5,
              473.5
            ],
            [
              'L',
              2321,
              476
            ],
            [
              'M',
              2321,
              476
            ],
            [
              'L',
              2315.5,
              476
            ],
            [
              'M',
              2320.5,
              470.93
            ],
            [
              'L',
              2315.5,
              476
            ],
            [
              'M',
              2320.5,
              470.93
            ],
            [
              'L',
              2315,
              470.802
            ],
            [
              'M',
              2315.5,
              476
            ],
            [
              'L',
              2310,
              476
            ],
            [
              'M',
              2315,
              470.802
            ],
            [
              'L',
              2310,
              476
            ],
            [
              'M',
              2315,
              470.802
            ],
            [
              'L',
              2310,
              470.686
            ],
            [
              'M',
              2310,
              476
            ],
            [
              'L',
              2305,
              476
            ],
            [
              'M',
              2310,
              470.686
            ],
            [
              'L',
              2305,
              476
            ],
            [
              'M',
              2310,
              470.686
            ],
            [
              'L',
              2304.5,
              470.558
            ],
            [
              'M',
              2305,
              476
            ],
            [
              'L',
              2302,
              476
            ],
            [
              'L',
              2302,
              473
            ],
            [
              'M',
              2304.5,
              470.558
            ],
            [
              'L',
              2302,
              470.5
            ],
            [
              'L',
              2302,
              473
            ],
            [
              'M',
              2304.5,
              470.558
            ],
            [
              'L',
              2302,
              473
            ]
          ],
          width: 21.5,
          height: 5.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -3.1724877669976763,
          left: 679.25,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              1990,
              318.5
            ],
            [
              'L',
              1990,
              316
            ],
            [
              'L',
              1994,
              314.674
            ],
            [
              'M',
              1990,
              318.5
            ],
            [
              'L',
              1990,
              322.5
            ],
            [
              'L',
              1992.5,
              321.667
            ],
            [
              'M',
              1990,
              318.5
            ],
            [
              'L',
              1994,
              314.674
            ],
            [
              'M',
              1994,
              314.674
            ],
            [
              'L',
              2002.5,
              311.855
            ],
            [
              'M',
              1992.5,
              321.667
            ],
            [
              'L',
              2002.5,
              311.855
            ],
            [
              'M',
              1992.5,
              321.667
            ],
            [
              'L',
              2001,
              318.833
            ],
            [
              'M',
              2002.5,
              311.855
            ],
            [
              'L',
              2010.5,
              309.202
            ],
            [
              'M',
              2001,
              318.833
            ],
            [
              'L',
              2010.5,
              309.202
            ],
            [
              'M',
              2001,
              318.833
            ],
            [
              'L',
              2009.5,
              316
            ],
            [
              'M',
              2010.5,
              309.202
            ],
            [
              'L',
              2018.65,
              306.5
            ],
            [
              'M',
              2009.5,
              316
            ],
            [
              'L',
              2018.65,
              306.5
            ],
            [
              'M',
              2009.5,
              316
            ],
            [
              'L',
              2017.5,
              313.333
            ],
            [
              'M',
              2018.65,
              306.5
            ],
            [
              'L',
              2027,
              303.731
            ],
            [
              'M',
              2017.5,
              313.333
            ],
            [
              'L',
              2027,
              303.731
            ],
            [
              'M',
              2017.5,
              313.333
            ],
            [
              'L',
              2025,
              310.833
            ],
            [
              'M',
              2027,
              303.731
            ],
            [
              'L',
              2034.5,
              301.244
            ],
            [
              'M',
              2025,
              310.833
            ],
            [
              'L',
              2034.5,
              301.244
            ],
            [
              'M',
              2025,
              310.833
            ],
            [
              'L',
              2033.5,
              308
            ],
            [
              'M',
              2034.5,
              301.244
            ],
            [
              'L',
              2043,
              298.425
            ],
            [
              'M',
              2043,
              298.425
            ],
            [
              'L',
              2033.5,
              308
            ],
            [
              'M',
              2043,
              298.425
            ],
            [
              'L',
              2050.31,
              296
            ],
            [
              'M',
              2033.5,
              308
            ],
            [
              'L',
              2041,
              305.5
            ],
            [
              'M',
              2050.31,
              296
            ],
            [
              'L',
              2041,
              305.5
            ],
            [
              'M',
              2050.31,
              296
            ],
            [
              'L',
              2058.5,
              293.285
            ],
            [
              'M',
              2041,
              305.5
            ],
            [
              'L',
              2049,
              302.833
            ],
            [
              'M',
              2058.5,
              293.285
            ],
            [
              'L',
              2049,
              302.833
            ],
            [
              'M',
              2058.5,
              293.285
            ],
            [
              'L',
              2066.5,
              290.632
            ],
            [
              'M',
              2049,
              302.833
            ],
            [
              'L',
              2057,
              300.167
            ],
            [
              'M',
              2066.5,
              290.632
            ],
            [
              'L',
              2057,
              300.167
            ],
            [
              'M',
              2066.5,
              290.632
            ],
            [
              'L',
              2075,
              287.813
            ],
            [
              'M',
              2057,
              300.167
            ],
            [
              'L',
              2066,
              297.167
            ],
            [
              'M',
              2075,
              287.813
            ],
            [
              'L',
              2066,
              297.167
            ],
            [
              'M',
              2075,
              287.813
            ],
            [
              'L',
              2083,
              285.161
            ],
            [
              'M',
              2066,
              297.167
            ],
            [
              'L',
              2073.75,
              294.582
            ],
            [
              'M',
              2083,
              285.161
            ],
            [
              'L',
              2086.5,
              284
            ],
            [
              'L',
              2088.71,
              284.737
            ],
            [
              'M',
              2083,
              285.161
            ],
            [
              'L',
              2073.75,
              294.582
            ],
            [
              'M',
              2073.75,
              294.582
            ],
            [
              'L',
              2082,
              291.833
            ],
            [
              'M',
              2088.71,
              284.737
            ],
            [
              'L',
              2082,
              291.833
            ],
            [
              'M',
              2088.71,
              284.737
            ],
            [
              'L',
              2092.5,
              286
            ],
            [
              'M',
              2082,
              291.833
            ],
            [
              'L',
              2086,
              290.5
            ],
            [
              'L',
              2088,
              291.163
            ],
            [
              'M',
              2092.5,
              286
            ],
            [
              'L',
              2088,
              291.163
            ],
            [
              'M',
              2092.5,
              286
            ],
            [
              'L',
              2097,
              287.5
            ],
            [
              'M',
              2088,
              291.163
            ],
            [
              'L',
              2092,
              292.49
            ],
            [
              'M',
              2097,
              287.5
            ],
            [
              'L',
              2092,
              292.49
            ],
            [
              'M',
              2097,
              287.5
            ],
            [
              'L',
              2100.74,
              288.745
            ],
            [
              'M',
              2092,
              292.49
            ],
            [
              'L',
              2096,
              293.816
            ],
            [
              'M',
              2100.74,
              288.745
            ],
            [
              'L',
              2096,
              293.816
            ],
            [
              'M',
              2100.74,
              288.745
            ],
            [
              'L',
              2105,
              290.167
            ],
            [
              'M',
              2096,
              293.816
            ],
            [
              'L',
              2100,
              295.142
            ],
            [
              'M',
              2105,
              290.167
            ],
            [
              'L',
              2100,
              295.142
            ],
            [
              'M',
              2105,
              290.167
            ],
            [
              'L',
              2109,
              291.5
            ],
            [
              'M',
              2100,
              295.142
            ],
            [
              'L',
              2104.09,
              296.5
            ],
            [
              'M',
              2109,
              291.5
            ],
            [
              'L',
              2104.09,
              296.5
            ],
            [
              'M',
              2109,
              291.5
            ],
            [
              'L',
              2113,
              292.833
            ],
            [
              'M',
              2104.09,
              296.5
            ],
            [
              'L',
              2108,
              297.795
            ],
            [
              'M',
              2113,
              292.833
            ],
            [
              'L',
              2108,
              297.795
            ],
            [
              'M',
              2113,
              292.833
            ],
            [
              'L',
              2117,
              294.167
            ],
            [
              'M',
              2108,
              297.795
            ],
            [
              'L',
              2112,
              299.122
            ],
            [
              'M',
              2117,
              294.167
            ],
            [
              'L',
              2112,
              299.122
            ],
            [
              'M',
              2117,
              294.167
            ],
            [
              'L',
              2121,
              295.5
            ],
            [
              'M',
              2112,
              299.122
            ],
            [
              'L',
              2116.16,
              300.5
            ],
            [
              'M',
              2121,
              295.5
            ],
            [
              'L',
              2116.16,
              300.5
            ],
            [
              'M',
              2121,
              295.5
            ],
            [
              'L',
              2125,
              296.833
            ],
            [
              'M',
              2116.16,
              300.5
            ],
            [
              'L',
              2120,
              301.775
            ],
            [
              'M',
              2125,
              296.833
            ],
            [
              'L',
              2120,
              301.775
            ],
            [
              'M',
              2125,
              296.833
            ],
            [
              'L',
              2129.31,
              298.27
            ],
            [
              'M',
              2120,
              301.775
            ],
            [
              'L',
              2124.5,
              303.267
            ],
            [
              'M',
              2129.31,
              298.27
            ],
            [
              'L',
              2124.5,
              303.267
            ],
            [
              'M',
              2129.31,
              298.27
            ],
            [
              'L',
              2133.18,
              299.56
            ],
            [
              'M',
              2124.5,
              303.267
            ],
            [
              'L',
              2128.5,
              304.593
            ],
            [
              'M',
              2133.18,
              299.56
            ],
            [
              'L',
              2128.5,
              304.593
            ],
            [
              'M',
              2133.18,
              299.56
            ],
            [
              'L',
              2137.27,
              300.924
            ],
            [
              'M',
              2128.5,
              304.593
            ],
            [
              'L',
              2132.5,
              305.92
            ],
            [
              'M',
              2137.27,
              300.924
            ],
            [
              'L',
              2132.5,
              305.92
            ],
            [
              'M',
              2137.27,
              300.924
            ],
            [
              'L',
              2141.5,
              302.333
            ],
            [
              'M',
              2132.5,
              305.92
            ],
            [
              'L',
              2136.5,
              307.246
            ],
            [
              'M',
              2141.5,
              302.333
            ],
            [
              'L',
              2136.5,
              307.246
            ],
            [
              'M',
              2141.5,
              302.333
            ],
            [
              'L',
              2145.5,
              303.667
            ],
            [
              'M',
              2136.5,
              307.246
            ],
            [
              'L',
              2140.5,
              308.573
            ],
            [
              'M',
              2145.5,
              303.667
            ],
            [
              'L',
              2140.5,
              308.573
            ],
            [
              'M',
              2145.5,
              303.667
            ],
            [
              'L',
              2149.5,
              305
            ],
            [
              'M',
              2140.5,
              308.573
            ],
            [
              'L',
              2144.8,
              310
            ],
            [
              'M',
              2149.5,
              305
            ],
            [
              'L',
              2144.8,
              310
            ],
            [
              'M',
              2149.5,
              305
            ],
            [
              'L',
              2153.5,
              306.333
            ],
            [
              'M',
              2144.8,
              310
            ],
            [
              'L',
              2148.5,
              311.225
            ],
            [
              'M',
              2153.5,
              306.333
            ],
            [
              'L',
              2148.5,
              311.225
            ],
            [
              'M',
              2153.5,
              306.333
            ],
            [
              'L',
              2157.72,
              307.741
            ],
            [
              'M',
              2148.5,
              311.225
            ],
            [
              'L',
              2153,
              312.718
            ],
            [
              'M',
              2157.72,
              307.741
            ],
            [
              'L',
              2153,
              312.718
            ],
            [
              'M',
              2157.72,
              307.741
            ],
            [
              'L',
              2161.73,
              309.075
            ],
            [
              'M',
              2153,
              312.718
            ],
            [
              'L',
              2157,
              314.044
            ],
            [
              'M',
              2161.73,
              309.075
            ],
            [
              'L',
              2157,
              314.044
            ],
            [
              'M',
              2161.73,
              309.075
            ],
            [
              'L',
              2166,
              310.5
            ],
            [
              'M',
              2157,
              314.044
            ],
            [
              'L',
              2161,
              315.37
            ],
            [
              'M',
              2166,
              310.5
            ],
            [
              'L',
              2161,
              315.37
            ],
            [
              'M',
              2166,
              310.5
            ],
            [
              'L',
              2169.5,
              311.667
            ],
            [
              'M',
              2161,
              315.37
            ],
            [
              'L',
              2165,
              316.697
            ],
            [
              'M',
              2169.5,
              311.667
            ],
            [
              'L',
              2165,
              316.697
            ],
            [
              'M',
              2169.5,
              311.667
            ],
            [
              'L',
              2173.5,
              313
            ],
            [
              'M',
              2165,
              316.697
            ],
            [
              'L',
              2168.5,
              317.858
            ],
            [
              'M',
              2173.5,
              313
            ],
            [
              'L',
              2168.5,
              317.858
            ],
            [
              'M',
              2173.5,
              313
            ],
            [
              'L',
              2178,
              314.5
            ],
            [
              'M',
              2168.5,
              317.858
            ],
            [
              'L',
              2173,
              319.35
            ],
            [
              'M',
              2178,
              314.5
            ],
            [
              'L',
              2173,
              319.35
            ],
            [
              'M',
              2178,
              314.5
            ],
            [
              'L',
              2181.5,
              315.667
            ],
            [
              'M',
              2173,
              319.35
            ],
            [
              'L',
              2177,
              320.676
            ],
            [
              'M',
              2181.5,
              315.667
            ],
            [
              'L',
              2182.5,
              316
            ],
            [
              'L',
              2182.5,
              320.5
            ],
            [
              'M',
              2181.5,
              315.667
            ],
            [
              'L',
              2177,
              320.676
            ],
            [
              'M',
              2177,
              320.676
            ],
            [
              'L',
              2180.99,
              322
            ],
            [
              'M',
              2182.5,
              320.5
            ],
            [
              'L',
              2182.5,
              322.5
            ],
            [
              'L',
              2180.99,
              322
            ],
            [
              'M',
              2182.5,
              320.5
            ],
            [
              'L',
              2180.99,
              322
            ]
          ],
          width: 192.5,
          height: 38.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 72.32751223300232,
          left: 732.9179487951071,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#ffffff',
          strokeWidth: 1,
          path: [
            [
              'M',
              2116.49,
              426
            ],
            [
              'C',
              2131.5,
              427,
              2140,
              449,
              2116.49,
              452.5
            ],
            [
              'L',
              2106.5,
              453.179
            ],
            [
              'L',
              2112,
              519
            ],
            [
              'L',
              2063.5,
              519
            ],
            [
              'L',
              2069,
              455.727
            ],
            [
              'L',
              2064.99,
              456
            ],
            [
              'C',
              2048,
              457.5,
              2027,
              430,
              2064.99,
              422.5
            ],
            [
              'L',
              2082,
              423.656
            ],
            [
              'L',
              2082,
              412
            ],
            [
              'L',
              2080,
              412
            ],
            [
              'L',
              2072,
              412
            ],
            [
              'L',
              2069,
              412
            ],
            [
              'L',
              2069,
              372
            ],
            [
              'L',
              2072,
              372
            ],
            [
              'L',
              2080,
              372
            ],
            [
              'L',
              2080,
              370
            ],
            [
              'L',
              2079,
              370
            ],
            [
              'L',
              2078,
              368.5
            ],
            [
              'L',
              2078,
              363.5
            ],
            [
              'L',
              2079,
              362.5
            ],
            [
              'L',
              2081,
              362.5
            ],
            [
              'L',
              2083,
              362.5
            ],
            [
              'L',
              2083,
              361
            ],
            [
              'L',
              2086,
              361
            ],
            [
              'L',
              2086,
              359.5
            ],
            [
              'L',
              2089.5,
              359.5
            ],
            [
              'L',
              2089.5,
              361
            ],
            [
              'L',
              2092.5,
              361
            ],
            [
              'L',
              2092.5,
              362.5
            ],
            [
              'L',
              2094.5,
              362.5
            ],
            [
              'L',
              2096,
              362.5
            ],
            [
              'L',
              2097.5,
              363.5
            ],
            [
              'L',
              2097.5,
              368.5
            ],
            [
              'L',
              2096,
              370
            ],
            [
              'L',
              2095,
              370
            ],
            [
              'L',
              2095,
              372
            ],
            [
              'L',
              2096.5,
              372
            ],
            [
              'L',
              2104,
              372
            ],
            [
              'L',
              2106.5,
              372
            ],
            [
              'L',
              2107,
              412
            ],
            [
              'L',
              2104,
              412
            ],
            [
              'L',
              2096.5,
              412
            ],
            [
              'L',
              2093,
              412
            ],
            [
              'L',
              2093,
              424.404
            ],
            [
              'L',
              2116.49,
              426
            ],
            [
              'Z'
            ]
          ],
          width: 87.43883089640326,
          height: 159.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 72.32751223300232,
          left: 732.9179487951071,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: 'transparent',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              2069,
              455.727
            ],
            [
              'L',
              2064.99,
              456
            ],
            [
              'C',
              2048,
              457.5,
              2027,
              430,
              2064.99,
              422.5
            ],
            [
              'L',
              2082,
              423.656
            ],
            [
              'M',
              2069,
              455.727
            ],
            [
              'L',
              2063.5,
              519
            ],
            [
              'L',
              2112,
              519
            ],
            [
              'L',
              2106.5,
              453.179
            ],
            [
              'M',
              2069,
              455.727
            ],
            [
              'L',
              2106.5,
              453.179
            ],
            [
              'M',
              2106.5,
              453.179
            ],
            [
              'L',
              2116.49,
              452.5
            ],
            [
              'C',
              2140,
              449,
              2131.5,
              427,
              2116.49,
              426
            ],
            [
              'L',
              2093,
              424.404
            ],
            [
              'M',
              2082,
              423.656
            ],
            [
              'L',
              2082,
              412
            ],
            [
              'M',
              2082,
              423.656
            ],
            [
              'L',
              2093,
              424.404
            ],
            [
              'M',
              2082,
              412
            ],
            [
              'L',
              2093,
              412
            ],
            [
              'M',
              2082,
              412
            ],
            [
              'L',
              2080,
              412
            ],
            [
              'M',
              2093,
              412
            ],
            [
              'L',
              2093,
              424.404
            ],
            [
              'M',
              2093,
              412
            ],
            [
              'L',
              2096.5,
              412
            ],
            [
              'M',
              2104,
              412
            ],
            [
              'L',
              2107,
              412
            ],
            [
              'L',
              2106.5,
              372
            ],
            [
              'L',
              2104,
              372
            ],
            [
              'M',
              2104,
              412
            ],
            [
              'L',
              2104,
              372
            ],
            [
              'M',
              2104,
              412
            ],
            [
              'L',
              2096.5,
              412
            ],
            [
              'M',
              2104,
              372
            ],
            [
              'L',
              2096.5,
              372
            ],
            [
              'M',
              2096.5,
              412
            ],
            [
              'L',
              2096.5,
              372
            ],
            [
              'M',
              2096.5,
              372
            ],
            [
              'L',
              2095,
              372
            ],
            [
              'M',
              2080,
              412
            ],
            [
              'L',
              2080,
              372
            ],
            [
              'M',
              2080,
              412
            ],
            [
              'L',
              2072,
              412
            ],
            [
              'M',
              2080,
              372
            ],
            [
              'L',
              2072,
              372
            ],
            [
              'M',
              2080,
              372
            ],
            [
              'L',
              2080,
              370
            ],
            [
              'M',
              2080,
              372
            ],
            [
              'L',
              2095,
              372
            ],
            [
              'M',
              2072,
              412
            ],
            [
              'L',
              2069,
              412
            ],
            [
              'L',
              2069,
              372
            ],
            [
              'L',
              2072,
              372
            ],
            [
              'M',
              2072,
              412
            ],
            [
              'L',
              2072,
              372
            ],
            [
              'M',
              2080,
              370
            ],
            [
              'L',
              2079,
              370
            ],
            [
              'L',
              2078,
              368.5
            ],
            [
              'L',
              2078,
              363.5
            ],
            [
              'L',
              2079,
              362.5
            ],
            [
              'L',
              2081,
              362.5
            ],
            [
              'M',
              2080,
              370
            ],
            [
              'L',
              2081,
              370
            ],
            [
              'M',
              2095,
              370
            ],
            [
              'L',
              2096,
              370
            ],
            [
              'L',
              2097.5,
              368.5
            ],
            [
              'L',
              2097.5,
              363.5
            ],
            [
              'L',
              2096,
              362.5
            ],
            [
              'L',
              2094.5,
              362.5
            ],
            [
              'M',
              2095,
              370
            ],
            [
              'L',
              2095,
              372
            ],
            [
              'M',
              2095,
              370
            ],
            [
              'L',
              2094.5,
              370
            ],
            [
              'M',
              2081,
              362.5
            ],
            [
              'L',
              2081,
              370
            ],
            [
              'M',
              2081,
              362.5
            ],
            [
              'L',
              2083,
              362.5
            ],
            [
              'M',
              2081,
              370
            ],
            [
              'L',
              2084,
              370
            ],
            [
              'M',
              2084,
              362.5
            ],
            [
              'L',
              2084,
              370
            ],
            [
              'M',
              2084,
              362.5
            ],
            [
              'L',
              2087.5,
              362.5
            ],
            [
              'M',
              2084,
              362.5
            ],
            [
              'L',
              2083,
              362.5
            ],
            [
              'M',
              2084,
              370
            ],
            [
              'L',
              2087.5,
              370
            ],
            [
              'M',
              2087.5,
              362.5
            ],
            [
              'L',
              2087.5,
              370
            ],
            [
              'M',
              2087.5,
              362.5
            ],
            [
              'L',
              2091,
              362.5
            ],
            [
              'M',
              2087.5,
              370
            ],
            [
              'L',
              2091,
              370
            ],
            [
              'M',
              2091,
              362.5
            ],
            [
              'L',
              2091,
              370
            ],
            [
              'M',
              2091,
              362.5
            ],
            [
              'L',
              2092.5,
              362.5
            ],
            [
              'M',
              2091,
              370
            ],
            [
              'L',
              2094.5,
              370
            ],
            [
              'M',
              2094.5,
              362.5
            ],
            [
              'L',
              2094.5,
              370
            ],
            [
              'M',
              2094.5,
              362.5
            ],
            [
              'L',
              2092.5,
              362.5
            ],
            [
              'M',
              2083,
              362.5
            ],
            [
              'L',
              2083,
              361
            ],
            [
              'L',
              2086,
              361
            ],
            [
              'M',
              2092.5,
              362.5
            ],
            [
              'L',
              2092.5,
              361
            ],
            [
              'L',
              2089.5,
              361
            ],
            [
              'M',
              2089.5,
              361
            ],
            [
              'L',
              2089.5,
              359.5
            ],
            [
              'L',
              2086,
              359.5
            ],
            [
              'L',
              2086,
              361
            ],
            [
              'M',
              2089.5,
              361
            ],
            [
              'L',
              2086,
              361
            ]
          ],
          width: 87.4388308964028,
          height: 159.5,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: 212.82751223300232,
          left: 705.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#00CEC9',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              2611,
              500
            ],
            [
              'L',
              2016.5,
              500
            ],
            [
              'L',
              2016.5,
              560
            ],
            [
              'L',
              2111.5,
              560
            ],
            [
              'L',
              2164,
              540
            ],
            [
              'L',
              2511.5,
              540
            ],
            [
              'L',
              2570.5,
              546
            ],
            [
              'L',
              2578,
              550.5
            ],
            [
              'L',
              2598.5,
              550.5
            ],
            [
              'L',
              2611,
              500
            ],
            [
              'Z'
            ]
          ],
          width: 594.5,
          height: 60,
          scaleX: 1,
          scaleY: 1
        },
        {
          type: 'path',
          angle: 0,
          top: -63.172487766997676,
          left: -971.75,
          groupId: '09296bcc-61e4-4689-aee1-f997e272cdb0',
          originX: 'left',
          originY: 'top',
          fill: '#D9D9D9',
          stroke: '#000000',
          strokeWidth: 1,
          path: [
            [
              'M',
              339,
              294
            ],
            [
              'L',
              339,
              238
            ],
            [
              'L',
              376.5,
              249.667
            ],
            [
              'L',
              506,
              240.333
            ],
            [
              'L',
              549.5,
              224
            ],
            [
              'L',
              549.5,
              252.583
            ],
            [
              'L',
              497,
              271.833
            ],
            [
              'L',
              378.5,
              291.667
            ],
            [
              'L',
              339,
              294
            ],
            [
              'Z'
            ]
          ],
          width: 210.5,
          height: 70,
          scaleX: 1,
          scaleY: 1
        }
      ]
    }
  ],
  config: [
    {
      id: '09296bcc-61e4-4689-aee1-f997e272cdb0',
      canvasLibraryType: 'mountain'
    }
  ]
}
