import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import { useSelector } from '@/store'
import { useDispatch } from 'react-redux'
import {
  editOrientation,
  pushOrientation,
  removeOrientation,
  savePlanimetryAndOrientations,
  updateMapConfig
} from '@slices/pv/plantCreation'
import Planimetry from '@components/Planimetry'
import clsx from 'clsx'
import { getAllDevices } from '@utils/plantOperations'
import GMap from '@components/Map/GMap'
import { useState } from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%'
  },
  stepDescription: {
    color: theme.palette.text.secondary
  },
  canvasContainer: {
    minHeight: '100% !important',
    height: '100%'
  },
  mapContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  }
}))

function PlantPlanimetry ({ className, ...rest }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { mapConfig, planimetry, addedDataloggers, orientations, distribution, plantType, address } = useSelector(state => state.pvPlantCreation)
  // Verifico l'oggetto address per accedere alle coordinate da passare alla mappa
  const coordinates = typeof address === 'object' ? [address.coordinates[0], address.coordinates[1]] : []
  const [lng, lat] = coordinates

  /* const [zoomDelta, setZoomDelta] = useState(1)
  const [panDeltaX, setPanDeltaX] = useState(0)
  const [panDeltaY, setPanDeltaY] = useState(0) */
  const [showPlanimetry, setShowPlanimetry] = useState(true)
  // Parametri da salvare per la configurazione della mappa
  const [centerDrag, setCenterDrag] = useState(mapConfig.center.lat > 0 && mapConfig.center.lng > 0 ? mapConfig.center : { lat, lng })
  const [currentZoom, setCurrentZoom] = useState(20)

  const devices = getAllDevices({ dataloggers: addedDataloggers })

  // const [orientationsCopy, setOrientationsCopy] = useState(JSON.parse(JSON.stringify(orientations)))

  const saveCanvas = (exportedCanvas) => {
    // Larghezza e alteza in mm
    // const moduleWidth = module.rows && module.rows.length > 0 ? module.rows[0].width : 0
    // const moduleHeight = module.rows && module.rows.length > 0 ? module.rows[0].height : 0
    // const moduleEfficency = module.rows && module.rows.length > 0 ? Number(module.rows[0].efficency) / 100 : 0
    // const moduleMaxPower = module.rows && module.rows.length > 0 ? Number(module.rows[0].maxPower) : 0
    // Superficie in metri quadri
    // const surface = (moduleWidth / 1000) * (moduleHeight / 1000) * (moduleEfficency / 100)
    // const newOrientations = calculateOrientationsSurfaces(moduleEfficency, moduleMaxPower, distribution, exportedCanvas.config, currentOrientations)
    // console.log('orientations - newOrientations: ', newOrientations)
    dispatch(savePlanimetryAndOrientations(exportedCanvas))
  }

  const saveOrientation = (newOrientation) => {
    // 2. dispatch del push dell'orientamento
    dispatch(pushOrientation(newOrientation))
  }

  const modifyOrientation = (editedOrientation) => {
    dispatch(editOrientation(editedOrientation))
  }

  const deleteOrientation = (orientationUuid) => {
    dispatch(removeOrientation(orientationUuid))
  }

  // Funzione che gestisce il pan della planietria e lo sincronizza alla mappa
  /* const handlePlanimetryPan = (planimetryPan) => {
    const [deltaX, deltaY] = planimetryPan
    // setPanDeltaX(deltaX)
    // setPanDeltaY(deltaY)
    setShowPlanimetry(false)
    console.log(`panning X: ${deltaX}`)
    console.log(`panning Y: ${deltaY}`)
  } */
  // Funzione che gestisce lo zoom della planimetria e lo sincronizza alla mappa
  /*   const handlePlanimetryZoom = (planimetryZoom) => {
    console.log(`setZoomDelta: ${planimetryZoom}`)
    setShowPlanimetry(false)
    // setZoomDelta(planimetryZoom)
  } */

  // Funzione che gestisce la modalità di modifica mappa e il salvataggio delle nuove lat e lng e del livello di zoom della mappa
  const handleMapModification = () => {
    if (showPlanimetry === false) {
      const mapConfigObj = {
        center: centerDrag,
        zoom: currentZoom
      }
      // salvo i nuovi lat e lng e il livello di zoom
      dispatch(updateMapConfig(mapConfigObj))
      // Esco dalla modalità di modifica mappa
      setShowPlanimetry(prevValue => !prevValue)
    } else {
      setShowPlanimetry(prevValue => !prevValue)
    }
  }

  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest} my={3}>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography component='div' className={classes.stepDescription} variant='body2'>
            Disegna la planimetria generale dell&apos; impianto
          </Typography>
          {/*  {lat && lng
            ? showPlanimetry
                ? <Button size='small' variant='outlined' onClick={() => handleMapModification()}>Modifica Mappa</Button>
                : (
                  <Box display='flex' alignItems='center'>
                    <Box mr={1}>
                      <Button onClick={() => setShowPlanimetry(true)} size='small' variant='outlined'>Annulla</Button>
                    </Box>
                    <Button size='small' variant='contained' color='primary' onClick={() => handleMapModification()}>Conferma</Button>
                  </Box>)
            : null} */}
        </Box>
      </Box>
      <Box height='60vh'>
        <Box position='relative' width='100%' height='100%'>
          <div className={classes.mapContainer}>
            {lat && lng && (
              <GMap
                // {...lat && lng ? ({ lat: Number(lat) + (Number(lat) * panDeltaX / 10), lng: Number(lng) + (Number(lng) * panDeltaY / 10) }) : null}
                {...mapConfig.center.lat && mapConfig.center.lng
                  ? { lat: Number(mapConfig.center.lat), lng: Number(mapConfig.center.lng) }
                  : lat && lng
                    ? ({ lat: Number(lat), lng: Number(lng) })
                    : null}
                showZoomControls={false}
                showMarker={false}
                zoom={mapConfig.zoom || 20}
                // zoom={20 * zoomDelta}
                handleDrag={(center) => {
                  setCenterDrag(center)
                  // setShowPlanimetry(true)
                  // handleMapModification()
                }}
                handleZoom={(zoom) => {
                  setCurrentZoom(zoom)
                  // setShowPlanimetry(true)
                  // handleMapModification()
                }}
              /* panDeltaX={panDeltaX}
              panDeltaY={panDeltaY}
              zoomDelta={zoomDelta} */
              />)}
          </div>
          <Planimetry
            zoom={currentZoom}
            pointerEvents={showPlanimetry}
            // onPan={handlePlanimetryPan}
            // onZoom={handlePlanimetryZoom}
            // isTransparent={lat && lng}
            plantType={plantType}
            className={classes.canvasContainer}
            distribution={distribution}
            saveOrientation={saveOrientation}
            deleteOrientation={deleteOrientation}
            modifyOrientation={modifyOrientation}
            devices={devices}
            orientations={orientations}
            importedCanvas={planimetry}
            saveCanvas={saveCanvas}
          />
        </Box>
      </Box>
    </>
  )
}
export default PlantPlanimetry
