import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import useCharts from '@/hooks/useCharts'
import { useEffect, useRef, useState } from 'react'
import LoadingCard from '../LoadingCard'
import { europeNum } from '@/utils/general'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  }
}))

function EnergyRadiationGraph ({ showAxis = true, showLegend = true, data = {}, height = null, width = null, className, ...rest }) {
  const classes = useStyles()
  // Prendo le istanze di core e am4Charts disponibili nel context
  const { chartsCore, amCharts } = useCharts()
  // Creo una ref del grafico
  const chartRef = useRef(null)
  // Variabile che determina la prima renderizzazione a schermo
  const [firstPaint, setFirstPaint] = useState(true)

  // Mapping dei testi per i nomi dei valori graficati
  const categoriesMap = {
    energy: { name: 'Energia Prodotta', color: chartsCore.color('#50af57') },
    radiation: { name: 'Irraggiamento', color: chartsCore.color('#ffffff') }
  }

  // Funzione per formattare i valori numerici dei tooltips
  const formatTooltipValues = (type, number) => {
    const typeMap = {
      energy: 'kWh',
      radiation: `Wh/m${String.fromCodePoint(0x00B2)}`
    }
    return europeNum(number, 2) ? `${europeNum(number, 2)} ${typeMap[type] || ''}` : 0
  }
  // Funzione per la formattazione dei dati nel formato richiesto dalla libreria grafica
  const formatData = (dataObj) => {
    const labels = dataObj.labels
    const energy = dataObj.energy || {}
    const radiation = dataObj.radiation || {}
    if (labels) {
      return labels.map((label, index) => ({
        category: label,
        energy: energy.series && energy.series.length > 0 ? energy.series[0].data[index] : 0,
        radiation: radiation.series && radiation.series.length > 0 ? radiation.series[0].data[index] : 0,
        realEnergy: formatTooltipValues('energy', energy.series && energy.series.length > 0 ? energy.series[0].data[index] : 0),
        realRadiation: formatTooltipValues('radiation', radiation.series && radiation.series.length > 0 ? radiation.series[0].data[index] : 0)
      }))
    }

    return []
  }

  // Funzione che dai dati del grafico restituisce il valore maggiore per la chiave passata come parametro
  const getMaxValue = (chartInstance, valueKey) => {
    const allValues = chartInstance.data.map(el => el[valueKey] || 0)

    return Math.max(...allValues)
  }

  // Funzione che prende in ingresso il valore massimo tra quelli graficati e restituisce il valore aumentato di una quantità proporzionale al numero ricevuto in ingresso
  const addPaddingToValue = (maxValue = 0) => {
    const firstDigit = Number(`${maxValue}`.charAt(0) || 1)
    return firstDigit === 0 ? (maxValue + 0.3) : maxValue + (firstDigit * 0.1)
  }

  // Funzione che crea un tooltip unificato per tutte le serie da graficare
  const createTooltip = (chartInstance, currentSerie) => {
    // Abilito i tooltip
    currentSerie.tooltipText = ''
    currentSerie.adapter.add('tooltipText', function (ev) {
      let text = '[bold]{category}[/]\n\n'

      chartInstance.series.each(function (item) {
        if (!item.isHidden) {
          text += '[' + item.stroke.hex + ']● [/]' + item.name + ': [bold]{' + item.dataFields.formattedValue + '}[/]\n'
        }
      })

      return text
    })
    // Definisco una grandezza per il tooltip e lo faccio andare a capo in caso di testi più lunghi
    currentSerie.tooltip.label.width = 220
    currentSerie.tooltip.label.wrap = true
    // Disabilito il colore del tooltip in base al colore della serie e lo definisco in maniera programmatica
    currentSerie.tooltip.getFillFromObject = false
    currentSerie.tooltip.background.fill = new chartsCore.InterfaceColorSet().getFor('alternativeBackground')
    currentSerie.tooltip.background.stroke = new chartsCore.InterfaceColorSet().getFor('alternativeBackground')
    currentSerie.tooltip.background.opacity = 0.8
    // Definisco gli stili per il testo all'interno del tooltip
    currentSerie.tooltip.label.fill = chartsCore.color('#fff')
    currentSerie.tooltip.label.fontFamily = 'Roboto, sans-serif'
    currentSerie.tooltip.label.fontSize = 12
  }

  // Funzione che crea gli assi del grafico
  const createAxis = (chartInstance) => {
    const dateAxis = chartInstance.xAxes.push(new amCharts.CategoryAxis())
    if (!showAxis) {
      dateAxis.tooltip.disabled = false
      dateAxis.renderer.grid.template.disabled = true
      dateAxis.renderer.labels.template.disabled = true
    } else {
      dateAxis.tooltip.disabled = true
    }
    dateAxis.tooltip.fontFamily = 'Roboto, sans-serif'
    dateAxis.tooltip.fontSize = 12
    dateAxis.tooltip.fill = '#ffffff'
    dateAxis.renderer.minGridDistance = 10
    dateAxis.dataFields.category = 'category'
    dateAxis.renderer.labels.template.fontWeight = 400
    dateAxis.renderer.labels.template.fontFamily = 'Roboto, sans-serif'
    dateAxis.renderer.labels.template.fontSize = 12
    dateAxis.renderer.labels.template.fill = '#ffffff'
    dateAxis.zoomable = false
    dateAxis.renderer.labels.template.maxWidth = 40
    dateAxis.events.on('sizechanged', function (ev) {
      const axis = ev.target
      const cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex)
      if (cellWidth < axis.renderer.labels.template.maxWidth) {
        axis.renderer.labels.template.rotation = -45
        axis.renderer.labels.template.horizontalCenter = 'right'
        axis.renderer.labels.template.verticalCenter = 'middle'
      } else {
        axis.renderer.labels.template.rotation = 0
        axis.renderer.labels.template.horizontalCenter = 'middle'
        axis.renderer.labels.template.verticalCenter = 'top'
      }
    })

    const energyMax = getMaxValue(chartInstance, 'energy')
    const radiationMax = getMaxValue(chartInstance, 'radiation')

    const energyAxis = chartInstance.yAxes.push(new amCharts.ValueAxis())
    energyAxis.min = 0
    energyAxis.max = addPaddingToValue(energyMax)
    energyAxis.renderer.minGridDistance = 20
    energyAxis.strictMinMax = true
    energyAxis.title.text = 'Energia Prodotta (kWh)'
    energyAxis.title.fontSize = 12
    energyAxis.title.fontWeight = 500
    energyAxis.title.fontFamily = 'Roboto, sans-serif'
    energyAxis.title.fill = '#ffffff'
    energyAxis.renderer.labels.template.fontWeight = 400
    energyAxis.renderer.labels.template.fontSize = 12
    energyAxis.renderer.labels.template.fontFamily = 'Roboto, sans-serif'
    energyAxis.renderer.labels.template.fill = '#ffffff'

    const radiationAxis = chartInstance.yAxes.push(new amCharts.ValueAxis())
    radiationAxis.min = 0
    radiationAxis.max = addPaddingToValue(radiationMax)
    radiationAxis.renderer.minGridDistance = 20
    radiationAxis.strictMinMax = true
    radiationAxis.renderer.grid.template.disabled = true
    radiationAxis.title.text = `Irraggiamento (Wh/m${String.fromCodePoint(0x00B2)})`
    radiationAxis.title.fontSize = 12
    radiationAxis.title.fontWeight = 500
    radiationAxis.title.fontFamily = 'Roboto, sans-serif'
    radiationAxis.title.fill = '#ffffff'
    radiationAxis.renderer.labels.template.fontWeight = 400
    radiationAxis.renderer.labels.template.fontFamily = 'Roboto, sans-serif'
    radiationAxis.renderer.labels.template.fontSize = 12
    radiationAxis.renderer.labels.template.fill = '#ffffff'
    // radiationAxis.syncWithAxis = chartInstance.yAxes.getIndex(0)
    radiationAxis.renderer.opposite = true
    energyAxis.tooltip.disabled = true
    radiationAxis.tooltip.disabled = true
    // Disattivo lo zoom
    energyAxis.zoomable = false
    radiationAxis.zoomable = false
    // Nascondo le labels, il titolo e le griglie degli assi se non voglio mostrare gli assi
    if (!showAxis) {
      energyAxis.renderer.grid.template.disabled = true
      energyAxis.renderer.labels.template.disabled = true
      radiationAxis.renderer.grid.template.disabled = true
      radiationAxis.renderer.labels.template.disabled = true
      energyAxis.title.disabled = true
      radiationAxis.title.disabled = true
    }

    return { energyAxis, radiationAxis }
  }

  // Funzione in cui definisco tutte le serie da graficare
  const createSeries = (chartInstance, energyAxis, radiationAxis) => {
    const energySeries = chartInstance.series.push(new amCharts.LineSeries())
    energySeries.dataFields.categoryX = 'category'
    energySeries.name = categoriesMap.energy.name
    energySeries.dataFields.valueY = 'energy'
    energySeries.dataFields.formattedValue = 'realEnergy'
    // Disabilito il colore del tooltip in base al colore della serie e lo definisco in maniera programmatica
    energySeries.tooltip.getFillFromObject = false
    energySeries.tooltip.getStrokeFromObject = false
    energySeries.yAxis = energyAxis
    energySeries.tooltipText = '[#000]{valueY.value}[/]'
    energySeries.tooltip.background.fill = chartsCore.color('#FFF')
    energySeries.tooltip.background.strokeWidth = 3
    energySeries.tooltip.getFillFromObject = false
    energySeries.fillOpacity = 0.5
    energySeries.strokeWidth = 2
    energySeries.tensionX = 0.77
    energySeries.fill = categoriesMap.energy?.color
    energySeries.stroke = categoriesMap.energy?.color

    // Creo il tooltip da mostrare
    createTooltip(chartInstance, energySeries)

    const radiationSeries = chartInstance.series.push(new amCharts.LineSeries())
    if (!firstPaint) {
      radiationSeries.showOnInit = false
      energySeries.showOnInit = false
    }
    radiationSeries.name = categoriesMap.radiation?.name
    radiationSeries.dataFields.categoryX = 'category'
    radiationSeries.dataFields.valueY = 'radiation'
    radiationSeries.dataFields.formattedValue = 'realRadiation'
    radiationSeries.yAxis = radiationAxis
    // radiationSeries.tooltipText = '[#000]{valueY.value}[/]'
    radiationSeries.tooltip.background.fill = chartsCore.color('#FFF')
    radiationSeries.tooltip.getStrokeFromObject = true
    radiationSeries.tooltip.background.strokeWidth = 3
    radiationSeries.fillOpacity = 0.5
    radiationSeries.strokeWidth = 2
    radiationSeries.tensionX = 0.77
    radiationSeries.fill = categoriesMap.radiation?.color
    radiationSeries.stroke = categoriesMap.radiation?.color
  }

  // Funzione che crea la legenda del grafico
  const createLegend = (chartInstance) => {
    // Istanzio un nuovo oggetto Legend
    chartInstance.legend = new amCharts.Legend()
    // Uso il marker di base
    chartInstance.legend.useDefaultMarker = true
    // Definisco larghezza massima e posizione della legenda
    chartInstance.legend.maxWidth = 100
    chartInstance.legend.position = 'bottom'
    chartInstance.legend.itemContainers.template.marginTop = -5
    // Definisco gli stili delle labels della legenda
    chartInstance.legend.labels.template.fill = chartsCore.color('#ffffff')
    chartInstance.legend.labels.template.fontFamily = 'Roboto, sans-serif'
    chartInstance.legend.labels.template.fontSize = 12
    // Modifico il marker di default con delle modifiche custom
    const markerTemplate = chartInstance.legend.markers.template
    const marker = markerTemplate.children.getIndex(0)
    markerTemplate.width = 12
    markerTemplate.height = 12
    marker.cornerRadius(12, 12, 12, 12)
  }

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      // Prendo il div con l'id indicato per creare il grafico Area
      const chart = chartsCore.create('energy-radiation-graph-chart', amCharts.XYChart)
      // Se non è la prima renderizzazione, disabilito le animazioni
      if (!firstPaint) {
        chart.showOnInit = false
      }
      // Assegno i dati al grafico dopo averli formattati
      chart.data = formatData(data)
      // Aggiungo il cursore
      chart.cursor = new amCharts.XYCursor()
      // Disabilito il bottone di zoom out
      chart.zoomOutButton.disabled = true
      if (showLegend) {
        // Aggiungo la legenda
        createLegend(chart)
      }
      // Creo gli assi del grafico
      const { energyAxis, radiationAxis } = createAxis(chart)
      // Creo la serie da graficare
      createSeries(chart, energyAxis, radiationAxis)
      // Assegno il grafico alla ref
      chartRef.current = chart
      // Dopo che è stato renderizzato la prima volta, setto la variabile a false
      if (firstPaint) {
        setFirstPaint(false)
      }
      return () => {
        chartRef.current.dispose()
      }
    }
  }, [data, showLegend])

  return Object.keys(data).length > 0
    ? (
      <div
        id='energy-radiation-graph-chart'
        style={{
          width: width || '100%',
          height: height || 300
        }}
        className={clsx(classes.root, className)}
        {...rest}
      />
      )
    : <LoadingCard glass={!showAxis} />
}
export default EnergyRadiationGraph
