import { useState, useEffect } from 'react'
import { assetsManagerPermissions } from '@/config'
// import { matchPath } from 'react-router-dom'
const accountPath = '/app/settings/account'
const reportsPath = '/app/overview/reports'
const welcomePath = '/app/welcome'

export const canIAccessThisUrl = (appPermissions, pathname, groups) => {
  let objToReturn = {
    answer: true,
    redirectTo: null,
    thisAcceptedUrls: [],
    thisDefaultsUrls: []
  }

  // mi prendo gli url accettati per tutti i suoi permessi
  if (!pathname.includes(accountPath) && !pathname.includes(welcomePath)) {
    appPermissions && appPermissions.forEach(userAppPerm => {
      const thisPermIndex = assetsManagerPermissions.findIndex(appPerm => appPerm.value === userAppPerm)
      if (thisPermIndex > -1) {
        for (const route of assetsManagerPermissions[thisPermIndex].acceptedRoutes) {
          if (!objToReturn.thisAcceptedUrls.includes(route)) {
            objToReturn.thisAcceptedUrls.push(route)
          }
        }
        objToReturn.thisDefaultsUrls.push(assetsManagerPermissions[thisPermIndex].defaultRoute)
      }
    })

    // controllo che l'url attuale sia incluso negli url accettati per l'utente
    if (!objToReturn.thisAcceptedUrls.find(goodUrl => pathname.includes(goodUrl))) {
      // l'url attuale non è incluso in quelli che può visualizzare, faccio redirect
      objToReturn = {
        ...objToReturn,
        answer: false,
        redirectTo: objToReturn.thisDefaultsUrls.length > 0 ? objToReturn.thisDefaultsUrls[0] : accountPath
      }
    } else if (pathname.includes(reportsPath)) {
      // sta accedendo ad i report di board ed ha il permesso, controllo i gruppi
      if (groups && groups.length > 0) {
        // ha gruppi non può accedere
        objToReturn = {
          ...objToReturn,
          answer: false,
          redirectTo: accountPath
        }
      }
    }
  }
  // console.log('objToReturn => ', objToReturn, 'usePermissions')
  return objToReturn
}

// Hook che prende in ingresso una queryString ?key=value&key2=value2... e restituisce un oggetto {key: value, key2: value2, ...}
export const useThisToAccess = (user, pathname) => {
  //  mi preparo la variabile da restituire
  const [canIAccessUrl, setCanIAccessUrl] = useState({
    answer: true,
    redirectTo: null,
    thisAcceptedUrls: [],
    thisDefaultsUrls: []
  })

  useEffect(() => {
    // controllo il path girato, se è quello di account lascio passare
    if (pathname && (!pathname.includes(accountPath) && !pathname.includes(welcomePath))) {
      if (!user?.projects || user?.projects?.length < 1) {
        setCanIAccessUrl(prev => {
          return {
            ...prev,
            answer: false,
            redirectTo: welcomePath
          }
        })
        // controllo se ci sono i permessi nel config
      } else if (assetsManagerPermissions && assetsManagerPermissions.length > 0) {
        // controllo se ha i permessi necessari per accedere all'url
        if (user && user.appPermissions) {
          // controllo che l'utente non abbia tutti i permessi, in quel caso lascio passare perché è super admin
          if (user.appPermissions.length !== assetsManagerPermissions.length) {
            setCanIAccessUrl(() => {
              const newObj = canIAccessThisUrl(user.appPermissions, pathname, user.groups)
              return newObj
            })
          }
        } else {
          // mancano delle info redirigo verso account
          setCanIAccessUrl(prev => {
            return {
              ...prev,
              answer: true,
              redirectTo: accountPath
            }
          })
        }
      }
    } else {
      const redirectPath = pathname.includes(accountPath) ? accountPath : welcomePath
      setCanIAccessUrl(prev => {
        return {
          ...prev,
          answer: true,
          redirectTo: redirectPath
        }
      })
    }
    // return () => {}
  }, [user, pathname])

  return canIAccessUrl
}
