import { useState, useEffect, memo } from 'react'
import {
  // Link as RouterLink,
  useLocation
  // useHistory
} from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  // AppBar,
  Box,
  // Hidden,
  // IconButton,
  Fab,
  // Toolbar,
  makeStyles,
  SvgIcon,
  // Breadcrumbs,
  // Typography,
  Tooltip
  // useTheme,
  // useMediaQuery
} from '@material-ui/core'
// import Account from './Account'
import { useSnackbar } from 'notistack'
import { routeSections } from '../NavBar'
// import { europeNum } from '@/utils/general'
import PlantSettings from './PlantSettings'
import { basicColors } from '@/theme'
import useTopBarInfo from '@/hooks/useTopBarInfo'
import useAuth from '@/hooks/useAuth'
// import log from '@pelv/frontlog'

import {
  // NavigateNextTwoTone as NavigateNextIcon,
  BusinessTwoTone as PlantIcon,
  ReportProblemTwoTone as MaintenanceIcon
  // ArrowForward as ForwardIcon,
  // ArrowBack as BackIcon
} from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  root: {
    // zIndex: theme.zIndex.drawer + 100,
    // ...(theme.name === THEMES.LIGHT
    //   ? {
    //       boxShadow: 'none',
    //       backgroundColor: theme.palette.primary.main
    //     }
    //   : {}),
    // ...(theme.name === THEMES.ONE_DARK
    //   ? {
    //       backgroundColor: theme.palette.background.default
    //     }
    //   : {})
    // backgroundColor: theme.palette.background.dark,
    // '@supports (backdrop-filter: none) or (-webkit-backdrop-filter: none)': {
    //   // backdropFilter: 'saturate(180%) blur(20px)', // dal sito Apple
    //   '-webkit-backdrop-filter': 'saturate(120%) blur(16px)',
    //   backdropFilter: 'saturate(120%) blur(16px)',
    //   backgroundColor: 'rgba(24, 38, 56, 0.3)'
    // },
    boxShadow: 'none !important',
    width: 'Calc(100% - 53px)',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    paddingTop: 8,
    paddingBottom: 6
  },
  toolbar: {
    minHeight: 24,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}))

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles()
  // const theme = useTheme()
  const location = useLocation()
  // const history = useHistory()
  // log({ text: 'history => ', variable: history, tag: 'topbar' })
  // let isASpacificView = 0
  const { user } = useAuth()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [key, setKey] = useState(null)
  const [filteredBreadcrumbs, setFilteredBreadcrumbs] = useState([])
  // log({ text: 'location.pathname', variable: location.pathname, tag: 'navbar' })
  const {
    name,
    // peakPower,
    // startDate,
    // plantType,
    // contractDuration,
    // endDate,
    uuid,
    maintenance,
    documents
  } = useTopBarInfo()

  // mi sistemo i dati
  useEffect(() => {
    // breadcrumbs
    const allRoute = []
    routeSections.forEach(section => {
      allRoute.push({
        title: section.title,
        UrlIcon: section.UrlIcon,
        href: section.href,
        showOnMenu: section.showOnMenu
      })
      allRoute.push(...section.items)
    })
    // log({ text: 'allRoute => ', variable: allRoute, tag: 'topbar' })
    // log({ text: 'location => ', variable: location, tag: 'topbar' })
    const breadTemp = allRoute.filter(route => location.pathname.includes(route.href))
    if (breadTemp.length > 0 && breadTemp[0].href === '/app/view') {
      breadTemp.push({
        title: name,
        href: '/app/view/plant',
        showOnMenu: false,
        // category: 'Panoramica',
        UrlIcon: PlantIcon
      })
    }
    setFilteredBreadcrumbs(breadTemp)
    // log({ text: 'filteredBreadcrumbs => ', variable: breadTemp, tag: 'topbar' })
  }, [location.pathname, name])

  // effect per controllare la conferma email
  useEffect(() => {
    if (user.mustConfirmEmailAddress === true) {
      if (!key) {
        const thisKey = enqueueSnackbar('Per favore, conferma la tua nuova email.', {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          preventDuplicate: true,
          persist: true
        })
        setKey(thisKey)
      }
    } else {
      if (key) {
        closeSnackbar(key)
        setKey(null)
      }
    }
    // chiudo la snackbar all'unmount
    return () => {
      if (key) {
        closeSnackbar(key)
        // setKey(null)
      }
    }
  }, [user.mustConfirmEmailAddress, key])

  // funzione che manda avanti o dietro l'history
  // const handleHistory = (goTo) => {
  //   if (goTo) {
  //     if (goTo === 'forward') {
  //       history.goForward()
  //     } else if (goTo === 'back') {
  //       history.goBack()
  //     }
  //   }
  // }

  // const isExtraSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  // const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  if (filteredBreadcrumbs.length > 0 && filteredBreadcrumbs[filteredBreadcrumbs.length - 1].href !== '/app/view/plant') {
    return <></>
  }

  return (
    <Box display='flex' flexDirection='row' alignItems='center' style={{ position: 'fixed', top: 8, right: 8 }}>
      {filteredBreadcrumbs.length > 0 && filteredBreadcrumbs[filteredBreadcrumbs.length - 1].href === '/app/view/plant' && name !== '-' && (
        <>
          {/* <Box ml={2} flexGrow={1} /> */}
          {maintenance && (
            <Tooltip title='Impianto in manutenzione'>
              <>
                <Fab
                  size='small'
                  disabled
                  aria-label='impostazioni impianto'
                  style={{ backgroundColor: basicColors.background, marginRight: 6 }}
                >
                  <SvgIcon size='small'>
                    <MaintenanceIcon style={{ color: basicColors.darkYellow }} />
                  </SvgIcon>
                </Fab>
              </>
            </Tooltip>
          )}
          <PlantSettings plantId={uuid} maintenance={maintenance} documents={documents} />
        </>
      )}
    </Box>
  )

  // return (
  //   <AppBar color='transparent' position='fixed' className={clsx(classes.root, className)} {...rest}>
  //     <Toolbar disableGutters variant='dense' className={classes.toolbar}>
  //       <Tooltip title='Indietro' enterDelay={500} disableTouchListener disableFocusListener>
  //         <IconButton
  //           onClick={() => handleHistory('back')}
  //           style={{ padding: 0, marginRight: 6 }}
  //         >
  //           <SvgIcon fontSize='small'>
  //             <BackIcon style={{ color: theme.palette.text.secondary }} />
  //           </SvgIcon>
  //         </IconButton>
  //       </Tooltip>
  //       <Tooltip title='Avanti' enterDelay={500} disableTouchListener disableFocusListener>
  //         <IconButton
  //           onClick={() => handleHistory('forward')}
  //           style={{ padding: 0, marginRight: theme.spacing(2) }}
  //         >
  //           <SvgIcon fontSize='small'>
  //             <ForwardIcon style={{ color: theme.palette.text.secondary }} />
  //           </SvgIcon>
  //         </IconButton>
  //       </Tooltip>
  //       {filteredBreadcrumbs.length > 0 && (
  //         <Box display='flex' flexDirection='row' alignItems='center' style={{ width: '100%' }}>
  //           <Breadcrumbs
  //             separator={<NavigateNextIcon style={{ color: theme.palette.text.secondary }} />}
  //             aria-label='breadcrumb'
  //           >
  //             {filteredBreadcrumbs.map((route, index) => {
  //               const last = index === filteredBreadcrumbs.length - 1
  //               return (
  //                 <Typography
  //                   key={index}
  //                   variant='body2'
  //                   style={{ color: last ? theme.palette.text.primary : theme.palette.text.secondary, textTransform: 'capitalize' }}
  //                 >
  //                   {route.title}
  //                 </Typography>
  //               )
  //             })}
  //           </Breadcrumbs>
  //           {filteredBreadcrumbs[filteredBreadcrumbs.length - 1].href === '/app/view/plant' && name !== '-' && (
  //             <>
  //               {!isSmall && (
  //                 <Box ml={4}>
  //                   <Typography component='span' variant='caption' style={{ color: 'white' }}>
  //                     <b>P. picco</b>: {`${europeNum(peakPower)} ${plantType && plantType === 'led' ? 'W' : 'kW'}`}
  //                   </Typography>
  //                   <Typography component='span' variant='caption' style={{ color: 'white', marginLeft: 16 }}>
  //                     <b>Contratto</b>: dal {startDate && startDate !== '-' ? moment(startDate).format('DD/MM/yy') : '-'} al{' '}
  //                     {endDate && endDate !== '-' ? moment(endDate).format('DD/MM/yy') : '-'} ({contractDuration} anni)
  //                   </Typography>
  //                 </Box>
  //               )}
  //               <Box ml={2} flexGrow={1} />
  //               {maintenance && (
  //                 <Tooltip title='Impianto in manutenzione'>
  //                   <SvgIcon style={{ marginRight: 8 }}>
  //                     <MaintenanceIcon style={{ color: basicColors.darkYellow }} />
  //                   </SvgIcon>
  //                 </Tooltip>
  //               )}
  //               <PlantSettings plantId={uuid} maintenance={maintenance} documents={documents} />
  //             </>
  //           )}
  //         </Box>
  //       )}
  //     </Toolbar>
  //   </AppBar>
  // )
}

TopBar.propTypes = {
  className: PropTypes.string
}

// TopBar.defaultProps = {
//   onMobileNavOpen: () => { }
// }

export default memo(TopBar)
