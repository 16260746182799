import { createSlice } from '@reduxjs/toolkit'
import { decodeFromApi, encodeForApi } from '@/utils/adapters/pv/plantAdapter'
import api from '@micmnt/apis'
import { calculateOrientationsSurfaces } from '@/views/systems/SystemsListView/AddPlantDialog/pv/utils'
import log from '@pelv/frontlog'

const initialState = {
  dataloggers: [],
  loadData: false,
  orientations: [],
  generators: [],
  addedDataloggers: [],
  maintenance: false,
  useInvertersForProducedEnergy: false,
  useMeterImpEnergyForExportedEnergy: false,
  useProductionMeterPower: false,
  activeStep: 0,
  hasAutosave: false,
  currentBaselinePage: 0,
  plantType: 'pv-pro-realtime',
  selectedDatalogger: '',
  selectedDataloggerDistribution: '',
  documents: [],
  anagraphic: {
    peakPower: '',
    plantCode: '',
    contractType: '',
    referentName: '',
    referentClient: '',
    referentRole: '',
    referentEmail: '',
    referentPhone: '',
    monitoringName: '',
    monitoringEmail: '',
    monitoringPhone: '',
    operationAndMaintenaceName: '',
    operationAndMaintenacePhone: '',
    operationAndMaintenaceEmail: ''
  },
  name: '',
  peakPower: '',
  module: {
    model: '',
    rows: [
      {
        efficency: 0,
        maxPower: 0,
        maxVoltage: 0,
        temp: 0,
        tresholdTemp: 0
      },
      {
        voc: 0,
        vmp: 0,
        isc: 0,
        imp: 0
      }
    ]
  },
  distribution: {},
  totalDistribution: 0,
  startDate: null,
  contractDuration: 1,
  endDate: '',
  years: [],
  manufacturability: [
    {
      label: 'Producibilità',
      value: 'productivity',
      numberValue: 0
    },
    {
      label: 'PR',
      value: 'performance',
      numberValue: 0
    }
  ],
  selectedManufacturability: '',
  year: '',
  address: '',
  mapConfig: { zoom: 20, center: { lat: 0, lng: 0 } },
  planimetry: { draw: [], config: [] },
  logoURL: null,
  baselineConfig: [
    { month: 'Gennaio', irradiation: '', producibility: '' },
    { month: 'Febbraio', irradiation: '', producibility: '' },
    { month: 'Marzo', irradiation: '', producibility: '' },
    { month: 'Aprile', irradiation: '', producibility: '' },
    { month: 'Maggio', irradiation: '', producibility: '' },
    { month: 'Giugno', irradiation: '', producibility: '' },
    { month: 'Luglio', irradiation: '', producibility: '' },
    { month: 'Agosto', irradiation: '', producibility: '' },
    { month: 'Settembre', irradiation: '', producibility: '' },
    { month: 'Ottobre', irradiation: '', producibility: '' },
    { month: 'Novembre', irradiation: '', producibility: '' },
    { month: 'Dicembre', irradiation: '', producibility: '' }
  ],
  baseline: {
    percentageLoss: [''],
    months: [
      { month: 'Gennaio', producibility: [''] },
      { month: 'Febbraio', producibility: [''] },
      { month: 'Marzo', producibility: [''] },
      { month: 'Aprile', producibility: [''] },
      { month: 'Maggio', producibility: [''] },
      { month: 'Giugno', producibility: [''] },
      { month: 'Luglio', producibility: [''] },
      { month: 'Agosto', producibility: [''] },
      { month: 'Settembre', producibility: [''] },
      { month: 'Ottobre', producibility: [''] },
      { month: 'Novembre', producibility: [''] },
      { month: 'Dicembre', producibility: [''] }
    ]
  }
}

const baseDistribution = {
  power: '',
  headerCols: 10,
  rows: [
    [...Array(10).fill(0)],
    [...Array(10).fill(0)]
  ]
}

const slice = createSlice({
  name: 'pvProRealtimePlantCreation',
  initialState,
  reducers: {
    setLoadData (state, actions) {
      const { loading } = actions.payload
      state.loadData = loading
    },
    setCurrentPlant (state, actions) {
      const { plant, orientations, generators } = actions.payload
      // console.log('decodeFromApi - values: ', plant, orientations, generators)
      const { mapConfig, hasAutosave, logoURL, name, uuid, anagraphic, baselineConfig, addedDataloggers, address, baseline, contractDuration, distribution, endDate, startDate, useInvertersForProducedEnergy, useMeterImpEnergyForExportedEnergy, useProductionMeterPower, maintenance, manufacturability, module, peakPower, planimetry, plantType, totalDistribution, years, documents } = plant
      const { mapConfig: initMapConfig, hasAutosave: initHasAutosave, documents: initDocuments, baseline: initBaseline, anagraphic: initAnagraphic, module: initModule, baselineConfig: initBaselineConfig } = initialState

      // L'impianto da modificare potrebbe non avere un oggetto mapConfig (è stato creato precedentemente all'inserimento di questa sezione)
      // se l'oggetto è presente lo setto nell'impianto corrente
      if (mapConfig) {
        state.mapConfig = mapConfig
      } else {
        state.mapConfig = initMapConfig
      }

      // l'impianto da modificare potrebbe non avere un oggetto anagrafica (è stato creato precedentemente all'inserimento di questa sezione)
      // se l'oggetto è presente lo setto nel'impianto corrente
      if (anagraphic) {
        state.anagraphic = anagraphic
        // altrimenti setto il valore di default dell'oggetto anagraphic che si utilizza per un nuovo impianto
      } else {
        state.anagraphic = initAnagraphic
      }

      // L'impianto da modificare potrebbe non avere un oggetto baselineConfig (è stato creato precedentemente rispetto al refactor di questa sezione)
      // se l'oggetto è presente lo setto nell'impianto corrente
      if (baselineConfig) {
        state.baselineConfig = baselineConfig
        // altrimenti setto il valore di default dell'oggetto baselineConfig che si utilizza per un nuovo impianto
      } else {
        state.baselineConfig = initBaselineConfig
      }
      // L'impianto da modificare potrebbe non avere un oggetto baseline (è stato creato precedentemente rispetto al refactor di questa sezione)
      // se l'oggetto è presente lo setto nell'impianto corrente
      if (baseline && !Array.isArray(baseline)) {
        state.baseline = baseline
        // altrimenti setto il valore di default dell'oggetto baseline che si utilizza per un nuovo impianto
      } else {
        state.baseline = initBaseline
      }
      // Se ci sono documenti salvati li setto altrimenti imposto l'array vuoto
      if (documents) {
        state.documents = documents
      } else {
        state.documents = initDocuments
      }
      // Se c'è il logo salvato lo setto altrimenti imposto l'oggetto a null
      if (logoURL) {
        state.logoURL = logoURL
      } else {
        state.logoURL = null
      }

      // Se c'è l'autosave attivato, lo setto
      if (hasAutosave) {
        state.hasAutosave = hasAutosave
      } else {
        state.hasAutosave = initHasAutosave
      }

      // All'oggetto rows di module è stato aggiunto un nuovo oggetto, quindi va aggiornato il precedente oggetto dell'impianto con la nuova struttura
      if (module) {
        // Oggetto che contiene tutte le possibili chiavi già presenti in un modulo
        const currentRowsObj = module.rows.reduce((acc, row) => {
          Object.keys(row).forEach(key => {
            acc[key] = row[key]
          })

          return acc
        }, {})

        const newRows = initModule.rows.map(row => {
          const newRow = Object.keys(row).reduce((acc, key) => {
            const currentValue = currentRowsObj[key]
            acc[key] = currentValue || 0

            return acc
          }, {})

          return newRow
        })

        const newModule = {
          ...module,
          rows: newRows
        }

        // console.log('newModule:', newModule)
        state.module = newModule
      } else {
        state.module = initModule
      }

      state.orientations = orientations
      state.generators = generators
      state.name = name
      state.uuid = uuid
      state.addedDataloggers = addedDataloggers
      state.useInvertersForProducedEnergy = useInvertersForProducedEnergy ?? false
      state.useMeterImpEnergyForExportedEnergy = useMeterImpEnergyForExportedEnergy ?? false
      state.useProductionMeterPower = useProductionMeterPower ?? false
      state.address = address
      state.contractDuration = contractDuration
      state.distribution = distribution
      state.endDate = endDate || ''
      state.startDate = startDate || null
      state.maintenance = maintenance
      state.manufacturability = manufacturability
      state.peakPower = peakPower
      state.planimetry = planimetry
      state.plantType = plantType
      state.totalDistribution = totalDistribution
      state.years = years
      state.loadData = false
    },
    setTotalDistribution (state, action) {
      const { total } = action.payload
      state.totalDistribution = total
    },
    setAddress (state, action) {
      const { newAddress } = action.payload
      state.address = newAddress
    },
    setPlantType (state, action) {
      const { plantType } = action.payload
      state.plantType = plantType
    },
    setModuleType (state, action) {
      const { value } = action.payload
      state.module.model = value
    },
    setAutosave (state, action) {
      const { hasAutosave } = action.payload
      state.hasAutosave = hasAutosave
    },
    setLogo (state, action) {
      const { url } = action.payload
      if (state.uuid) {
        api.post({ savedUrl: 'forceSummaryRefresh', path: `/${state.uuid}/summary` })
      }
      state.logoURL = url
    },
    resetPlant (state) {
      const { anagraphic, hasAutosave, dataloggers, currentBaselinePage, orientations, generators, addedDataloggers, maintenance, activeStep, plantType, selectedDatalogger, selectedDataloggerDistribution, name, peakPower, module, distribution, totalDistribution, startDate, contractDuration, endDate, years, manufacturability, selectedManufacturability, year, address, planimetry, baseline, baselineConfig, documents, ...rest } = initialState
      if (state.uuid) {
        delete state.uuid
      }
      if (state.devices) {
        delete state.devices
      }
      state.dataloggers = dataloggers
      state.anagraphic = anagraphic
      state.orientations = orientations
      state.addedDataloggers = addedDataloggers
      state.maintenance = maintenance
      state.activeStep = activeStep
      state.hasAutosave = hasAutosave
      state.currentBaselinePage = currentBaselinePage
      state.plantType = plantType
      state.contractDuration = contractDuration
      state.selectedDatalogger = selectedDatalogger
      state.selectedDataloggerDistribution = selectedDataloggerDistribution
      state.peakPower = peakPower
      state.generators = generators
      state.module = module
      state.distribution = distribution
      state.totalDistribution = totalDistribution
      state.useInvertersForProducedEnergy = false
      state.useMeterImpEnergyForExportedEnergy = false
      state.useProductionMeterPower = false
      state.startDate = startDate
      state.endDate = endDate
      state.years = years
      state.documents = documents
      state.manufacturability = manufacturability
      state.name = name
      state.logoURL = null
      state.selectedManufacturability = selectedManufacturability
      state.year = year
      state.address = address
      state.planimetry = planimetry
      state.baseline = baseline
      state.baselineConfig = baselineConfig
      state.rest = rest
    },
    setBaseline (state, action) {
      const { baseline } = action.payload
      state.baseline = baseline
    },
    updateBaseline (state, action) {
      const { monthIndex, producibilityIndex, name, value } = action.payload
      state.baseline.months[monthIndex][name][producibilityIndex] = value
    },
    updatePercentageLoss (state, action) {
      const { index, value } = action.payload
      const newPercentageLoss = state.baseline.percentageLoss.map((el, percentageIndex) => percentageIndex === index ? value : el)
      state.baseline.percentageLoss = newPercentageLoss
    },
    updateBaselineConfig (state, action) {
      const { index, name, value } = action.payload
      state.baselineConfig[index][name] = value
    },
    setManufacturabilityValue (state, action) {
      const { value } = action.payload
      const manufacturabilityIndex = state.manufacturability.findIndex(el => el.value === state.selectedManufacturability)
      if (manufacturabilityIndex > -1) {
        state.manufacturability[manufacturabilityIndex].numberValue = value
      }
    },
    setManufacturabilityValueAndOrientations (state, action) {
      const { value, orientations } = action.payload
      const manufacturabilityIndex = state.manufacturability.findIndex(el => el.value === state.selectedManufacturability)
      if (manufacturabilityIndex > -1) {
        state.manufacturability[manufacturabilityIndex].numberValue = value
      }
      state.orientations = orientations
    },
    setYearsValues (state, action) {
      const { value, name } = action.payload
      const currentYearIndex = state.years.findIndex(el => el.year === state.year)
      if (currentYearIndex > -1) {
        state.years[currentYearIndex][name] = value
      }
    },
    setYearsValuesAndOrientations (state, action) {
      const { value, name, orientations } = action.payload
      const currentYearIndex = state.years.findIndex(el => el.year === state.year)
      if (currentYearIndex > -1) {
        state.years[currentYearIndex][name] = value
      }
      state.orientations = orientations
    },
    changeAnagraphicValues (state, action) {
      const { value, name } = action.payload
      if (state.anagraphic !== undefined) {
        if (state.anagraphic[name] !== undefined) {
          state.anagraphic[name] = value
        }
      }
    },
    changeValues (state, action) {
      const { value, name } = action.payload
      if (state[name] !== undefined) {
        state[name] = value
      }
    },
    changeValuesAndOrientations (state, action) {
      const { value, name, orientations } = action.payload
      if (state[name] !== undefined) {
        state[name] = value
      }
      state.orientations = orientations
    },
    setDeviceTypeDocuments (state, action) {
      const { documents, deviceTypeId } = action.payload
      // Creo una copia dell'array dei datalogger aggiunti all'impianto
      const addedDataloggers = JSON.parse(JSON.stringify(state.addedDataloggers))
      const updatedDataloggers = addedDataloggers.map(datalogger => {
        if (datalogger.includes && datalogger.includes.devices) {
          const devices = [...datalogger.includes.devices]
          const newDevices = devices.map(device => {
            const deviceType = device.deviceType
            if (deviceType && deviceType.uuid === deviceTypeId) {
              const newDeviceType = {
                ...deviceType,
                metadata: {
                  ...deviceType.metadata,
                  documents
                }
              }

              return {
                ...device,
                deviceType: newDeviceType
              }
            }

            return device
          })

          return {
            ...datalogger,
            includes: {
              ...datalogger.includes,
              devices: newDevices
            }
          }
        }

        return datalogger
      })
      state.addedDataloggers = updatedDataloggers
    },
    setPlantDocuments (state, action) {
      const { documents } = action.payload
      state.documents = documents
    },
    setPlanimetry (state, action) {
      const { list } = action.payload
      state.planimetry = list
    },
    setPlanimetryAndOrientations (state, action) {
      const { list } = action.payload
      const moduleEfficency = state.module.rows && state.module.rows.length > 0 ? Number(state.module.rows[0].efficency) / 100 : 0
      const moduleMaxPower = state.module.rows && state.module.rows.length > 0 ? Number(state.module.rows[0].maxPower) : 0
      const newOrientations = Object.keys(state.distribution)?.length > 0
        ? calculateOrientationsSurfaces(moduleEfficency, moduleMaxPower, state.distribution, list.config, state.orientations)
        : state.orientations
      state.planimetry = list
      state.orientations = newOrientations
    },
    editOrientation (state, action) {
      const { orientation } = action.payload

      // se esiste l'orientamento con il suo id
      if (orientation && orientation.uuid && orientation.uuid !== undefined) {
        const currentOrientations = state.orientations.map(el => {
          if (el.uuid === orientation.uuid) {
            return orientation
          }

          return el
        })

        state.orientations = currentOrientations
      }
    },
    removeOrientation (state, action) {
      const { orientationId } = action.payload
      // se esiste l'id dell'orientamento
      if (orientationId && orientationId !== undefined) {
        // filtro gli orientamenti dell'impianto corrente rimuovendo l'orientamento con quell'id
        const currentOrientations = state.orientations.filter(el => el.uuid !== orientationId)
        // Devo cercare nel config, tutti gli elementi che hanno quell'orientamento e rimuoverlo
        const currentPlanimetryConfig = JSON.parse(JSON.stringify(state.planimetry.config))
        const newPlanimetryConfig = currentPlanimetryConfig.map(configEl => {
          // se l'elemento della configurazione ha un orientamento e il suo uuid è uguale a quello dell'orientamento da eliminare, lo svuoto
          if (configEl.data && configEl.data.orientation && configEl.data.orientation === orientationId) {
            // console.log('removeOrientation - removing: ', configEl.data.orientation)
            delete configEl.data.orientation
          }

          return configEl
        })

        // console.log('removeOrientation - newConfig: ', newPlanimetryConfig)
        // assegno il nuovo array di config della planimetry modificato
        state.planimetry.config = newPlanimetryConfig
        // assegno il nuovo array di orientamenti filtrato
        state.orientations = currentOrientations
      }
    },
    pushOrientation (state, action) {
      const { orientation } = action.payload
      const currentOrientations = JSON.parse(JSON.stringify(state.orientations))
      currentOrientations.push(orientation)
      state.orientations = currentOrientations
    },
    removeColumn (state) {
      const currentSelectedDataloggerDistribution = state.selectedDataloggerDistribution
      const currentHeaderCols = state.distribution[currentSelectedDataloggerDistribution].headerCols - 1
      const currentRows = state.distribution[currentSelectedDataloggerDistribution].rows
      // const powerDistribution = state.distribution[currentSelectedDataloggerDistribution].power
      const newRows = currentRows.map(row => {
        row.pop()
        return row
      })

      const modulesSum = newRows.reduce((acc, currRow, index) => {
        const rowModuleSum = currRow.reduce((tot, currValue, indexValue) => {
          // console.log('handleCellChange - index: ', index, 'cell Index: ', indexValue)
          tot += Number(currValue)
          return tot
        }, 0)
        acc += rowModuleSum
        return acc
      }, 0)
      // Potenza di picco espressa in kW
      const powerPeak = Number(state.module.rows[0].maxPower) * modulesSum / 1000

      const newDistribution = {
        power: powerPeak,
        headerCols: currentHeaderCols,
        rows: newRows
      }
      state.distribution[currentSelectedDataloggerDistribution] = newDistribution
    },
    addColumn (state) {
      const currentSelectedDataloggerDistribution = state.selectedDataloggerDistribution
      const currentHeaderCols = state.distribution[currentSelectedDataloggerDistribution].headerCols + 1
      const currentRows = state.distribution[currentSelectedDataloggerDistribution].rows
      const powerDistribution = state.distribution[currentSelectedDataloggerDistribution].power
      const newRows = currentRows.map(row => {
        row.push(0)
        return row
      })
      const newDistribution = {
        power: powerDistribution,
        headerCols: currentHeaderCols,
        rows: newRows
      }

      state.distribution[currentSelectedDataloggerDistribution] = newDistribution
    },
    setDistribution (state, action) {
      const { headerCols, rows, value, rowIndex, cellIndex, powerPeak } = action.payload
      const newDistribution = { ...state.distribution }
      if (value !== null && rowIndex !== null && cellIndex !== null) {
        newDistribution[state.selectedDataloggerDistribution].rows[rowIndex][cellIndex - 1] = value ? parseInt(value) : 0
      }
      if (powerPeak) {
        newDistribution[state.selectedDataloggerDistribution].power = powerPeak
      }

      if (headerCols) {
        newDistribution[state.selectedDataloggerDistribution].headerCols = headerCols
      }

      if (rows) {
        newDistribution[state.selectedDataloggerDistribution].rows = rows
      }

      state.distribution = newDistribution
    },
    setModuleValues (state, action) {
      const { moduleIndex, value, key } = action.payload
      const rows = state.module.rows
      rows[moduleIndex][key] = value ? parseFloat(value) : 0
      state.module.rows = rows
    },
    setModuleValuesAndOrientations (state, action) {
      const { moduleIndex, value, key, orientations } = action.payload
      const rows = state.module.rows
      rows[moduleIndex][key] = value ? parseFloat(value) : 0
      state.module.rows = rows
      state.orientations = orientations
    },
    setStep (state, action) {
      const { step } = action.payload
      state.activeStep = step
    },
    setSelectedDistributionPower (state, action) {
      const { power, selectedDatalogger } = action.payload
      const newDistribution = state.distribution
      if (newDistribution[selectedDatalogger]) {
        newDistribution[selectedDatalogger].power = power
      }

      state.distribution = newDistribution
    },
    setDistributionPower (state, action) {
      const { power } = action.payload
      const newDistribution = state.distribution
      const addedDataloggersCopy = state.addedDataloggers
      // Costruisco la lista dei devices con struttura della risorsa sul backend
      const dataloggersDevices = addedDataloggersCopy
        .map((datalogger) => (datalogger.includes && datalogger.includes.devices ? datalogger.includes.devices : []))
        .flat()
      // Array di tutti i devices di tutti i dataloggers associati all'impianto
      const devicesUuids = dataloggersDevices.map(el => el.uuid)
      // Ricreo un oggetto distribuzione con solo le chiavi presenti nei dataloggers associati all'impianto
      const filteredDistribution = Object.keys(newDistribution).reduce((acc, key) => {
        if (devicesUuids.includes(key)) {
          acc[key] = newDistribution[key]
        }

        return acc
      }, {})

      const currentDistributionDatalogger = state.selectedDataloggerDistribution
      if (filteredDistribution[currentDistributionDatalogger]) {
        filteredDistribution[currentDistributionDatalogger].power = power
      }

      state.distribution = filteredDistribution
    },
    setDataloggers (state, action) {
      const { dataloggers } = action.payload
      state.dataloggers = dataloggers
    },
    removeDatalogger (state, action) {
      const { dataloggerId } = action.payload
      // Datalogger disponibili
      const currentDataloggers = Object.assign([], state.dataloggers)
      // Datalogger assegnati all'impianto
      const addedDataloggers = Object.assign([], state.addedDataloggers)
      const currentDistribution = JSON.parse(JSON.stringify(state.distribution))
      const currentPlanimetryConfig = JSON.parse(JSON.stringify(state.planimetry.config))
      const selectedDataloggerIndex = addedDataloggers.findIndex(datalogger => datalogger.uuid === dataloggerId)
      const selectedDatalogger = addedDataloggers.splice(selectedDataloggerIndex, 1)
      if (selectedDatalogger) {
        // Rimetto l'oggetto datalogger solo se non è già presente nell'array dei datalogger disponibili
        const existingDatalogger = currentDataloggers.find(datalogger => datalogger.uuid === dataloggerId)
        if (!existingDatalogger) {
          currentDataloggers.push(selectedDatalogger[0])
        }

        // svuoto le distribuzioni create per i devices sottesi al datalogger eliminato
        const devices = selectedDatalogger[0].includes && selectedDatalogger[0].includes.devices

        devices.forEach(device => {
          if (currentDistribution[device.uuid] && currentDistribution[device.uuid] !== undefined) {
            delete currentDistribution[device.uuid]
          }

          currentPlanimetryConfig.forEach(configElement => {
            if (configElement.devices) {
              // rimuovo i devices dall'array devices di ogni elemento di configurazione
              const newDevices = configElement.devices.filter(el => el.deviceId !== device.uuid)
              // assegno il nuovo array di devices filtrato
              configElement.devices = newDevices
            }
          })
        })

        state.distribution = currentDistribution
        state.dataloggers = currentDataloggers
        state.addedDataloggers = addedDataloggers
        state.planimetry.config = currentPlanimetryConfig
      }
    },
    updateAddedDatalogger (state, action) {
      const { dataloggerId, newDevice } = action.payload
      // Creo una copia dell'array dei datalogger aggiunti all'impianto
      const addedDataloggers = JSON.parse(JSON.stringify(state.addedDataloggers))
      // Filtro tutti i datalogger che non sono quello selezionato
      const otherDataloggers = addedDataloggers.filter(el => el.uuid !== dataloggerId)
      // Prendo il datalogger selezionato
      const selectedDatalogger = addedDataloggers.find(el => el.uuid === dataloggerId)
      // console.log('datasheet - selectedDatalogger: ', selectedDatalogger)
      // Se il datalogger selezionato esiste
      if (selectedDatalogger && selectedDatalogger !== undefined) {
        // Se il datalogger ha l'array di device
        if (selectedDatalogger.includes && selectedDatalogger.includes.devices) {
          // Prendo tutti i devices di questo datalogger
          const dataloggerDevices = JSON.parse(JSON.stringify(selectedDatalogger.includes.devices))
          const newDataloggerDevices = dataloggerDevices.map(el => {
            if (el.uuid === newDevice.uuid) {
              el.metadata.documents = newDevice.metadata.documents
            }

            return el
          })
          selectedDatalogger.includes.devices = newDataloggerDevices
          otherDataloggers.push(selectedDatalogger)
          // console.log('datasheet - otherDataloggers: ', otherDataloggers)
          state.addedDataloggers = otherDataloggers
        }
      }
    },
    setDistributionDatalogger (state, action) {
      const { dataloggerId } = action.payload
      const newDistribution = state.distribution
      if (!Object.prototype.hasOwnProperty.call(newDistribution, dataloggerId)) {
        newDistribution[dataloggerId] = baseDistribution
        state.distribution = newDistribution
      }
      state.selectedDataloggerDistribution = dataloggerId
    },
    selectDatalogger (state, action) {
      const { dataloggerId } = action.payload
      const currentDataloggers = Object.assign([], state.dataloggers)
      const addedDataloggers = Object.assign([], state.addedDataloggers)
      const selectedDataloggerIndex = currentDataloggers.findIndex(datalogger => datalogger.uuid === dataloggerId)
      const selectedDatalogger = currentDataloggers.splice(selectedDataloggerIndex, 1)
      if (selectedDatalogger) {
        addedDataloggers.push(selectedDatalogger[0])
        state.dataloggers = currentDataloggers
        state.addedDataloggers = addedDataloggers
        // console.log('PlantComponent - devices on addedDataloggers: ', state.addedDataloggers)
      }
    },
    updateMapConfig (state, action) {
      const { center, zoom } = action.payload
      state.mapConfig = {
        zoom,
        center
      }
    }
  }
})

export const reducer = slice.reducer

export const setTotalDistribution = (total) => dispatch => {
  dispatch(slice.actions.setTotalDistribution({ total }))
}

export const pushOrientation = (orientation) => dispatch => {
  dispatch(slice.actions.pushOrientation({ orientation }))
}

export const editOrientation = (orientation) => dispatch => {
  dispatch(slice.actions.editOrientation({ orientation }))
}

export const removeOrientation = (orientationId) => dispatch => {
  dispatch(slice.actions.removeOrientation({ orientationId }))
}

export const updateAddedDatalogger = (dataloggerId, newDevice) => dispatch => {
  dispatch(slice.actions.updateAddedDatalogger({ dataloggerId, newDevice }))
}

export const setStep = (step) => dispatch => {
  dispatch(slice.actions.setStep({ step }))
}

export const savePlanimetry = (list) => dispatch => {
  dispatch(slice.actions.setPlanimetry({ list }))
}

export const updatePlantDocuments = (documents) => dispatch => {
  dispatch(slice.actions.setPlantDocuments({ documents }))
}

export const updateDeviceTypeDocuments = (documents, deviceTypeId) => dispatch => {
  dispatch(slice.actions.setDeviceTypeDocuments({ documents, deviceTypeId }))
}

export const savePlanimetryAndOrientations = (list) => dispatch => {
  dispatch(slice.actions.setPlanimetryAndOrientations({ list }))
}

export const changeModuleValues = (moduleIndex, value, key) => dispatch => {
  dispatch(slice.actions.setModuleValues({ moduleIndex, value, key }))
}
export const changeModuleValuesAndOrientations = (moduleIndex, value, key, orientations) => dispatch => {
  dispatch(slice.actions.setModuleValuesAndOrientations({ moduleIndex, value, key, orientations }))
}

export const addDatalogger = (dataloggerId) => dispatch => {
  dispatch(slice.actions.selectDatalogger({ dataloggerId }))
}

export const selectDistributionDatalogger = (dataloggerId) => dispatch => {
  dispatch(slice.actions.setDistributionDatalogger({ dataloggerId }))
}

export const deleteDatalogger = (dataloggerId) => dispatch => {
  dispatch(slice.actions.removeDatalogger({ dataloggerId }))
}

export const getDataloggers = ({ skip = 0, limit = 50, filter = null, hasNoPlant = null }) => async dispatch => {
  const params = {
    skip,
    limit,
    sortorder: 'DESC',
    include: 'devices'
  }

  if (filter) {
    params.filter = filter
  }

  if (hasNoPlant !== null) {
    params.hasNoPlant = hasNoPlant
  }

  const { data: dataloggers } = await api.get({ savedUrl: 'nodesList', params })
  if (dataloggers) {
    dispatch(slice.actions.setDataloggers({ dataloggers }))
  }
}

export const modifyDistribution = ({ powerPeak = null, value = null, rowIndex = null, cellIndex = null, headerCols = null, rows = null }) => dispatch => {
  dispatch(slice.actions.setDistribution({ powerPeak, value, rowIndex, cellIndex, headerCols, rows }))
}

export const handleAddColumn = () => dispatch => {
  dispatch(slice.actions.addColumn())
}

export const handleRemoveColumn = () => dispatch => {
  dispatch(slice.actions.removeColumn())
}

export const changeDistributionPower = (power) => dispatch => {
  dispatch(slice.actions.setDistributionPower({ power }))
}

export const changeSelectedDistributionPower = (power, selectedDatalogger) => dispatch => {
  dispatch(slice.actions.setSelectedDistributionPower({ power, selectedDatalogger }))
}

export const createPlant = (plant, reset = true, userIsSaving = false) => async dispatch => {
  const { uuid } = plant
  log({ text: 'createPlant - plant => ', variable: plant, tag: 'pvPlantSlice' })
  // const plantCopy = JSON.parse(JSON.stringify(plant))
  const body = encodeForApi(plant)
  log({ text: 'encodeForApi - body => ', variable: JSON.stringify(body), tag: 'pvPlantSlice' })

  const { data: response, error } = await api.post({ savedUrl: 'dashboard', path: '/plants', body })
  if (error) {
    console.error('creation plant error: ', error)
    return { createdPlant: null, error: true }
  }

  const createdPlant = { ...response }
  if (!uuid || userIsSaving) {
    const { data: summaryRefreshResponse } = await api.post({ savedUrl: 'forceSummaryRefresh', path: `/${createdPlant.uuid}/summary` })
    log({ text: 'summaryRefreshResponse => ', variable: summaryRefreshResponse, tag: 'pvPlantSlice' })
  }
  if (reset) {
    dispatch(slice.actions.resetPlant())
  }
  return { createdPlant, error: false }
}

export const resetInitState = () => dispatch => {
  dispatch(slice.actions.resetPlant())
}

export const getCurrentPlant = (plantId, firstLoad = false) => async dispatch => {
  if (plantId) {
    if (firstLoad) {
      dispatch(slice.actions.setLoadData({ loading: true }))
    }
    const options = {
      path: `/plants/${plantId}`
    }
    // if (fakeAuth) {
    //   // options.auth = 'Bearer 3adfc5f9-eac8-411a-b85a-c390709ce1f4'
    //   // options.auth = 'apikey RqNCrH.VPaHgRWv9pbrb58NPoA8EO8LbMd28l0t6XcNu7Kjh0M='
    // }
    const { data: response } = await api.get({ savedUrl: 'dashboard', ...options })

    const { orientations, plant, dataloggers, devices } = response || {}
    const adaptedPlant = decodeFromApi({ orientations, plant, dataloggers, devices })
    dispatch(slice.actions.setCurrentPlant(adaptedPlant))
  }
}

export const changeContractValue = (value, name) => dispatch => {
  if (name === 'percentageLoss' || name === 'percentageDeviation') {
    dispatch(slice.actions.setYearsValues({ value, name }))
  } else if (name === 'manufacturabilityValue') {
    dispatch(slice.actions.setManufacturabilityValue({ value, name }))
  }
  dispatch(slice.actions.changeValues({ value, name }))
}
export const changeContractValueAndOrientations = (value, name, orientations) => dispatch => {
  if (name === 'percentageLoss' || name === 'percentageDeviation') {
    dispatch(slice.actions.setYearsValuesAndOrientations({ value, name, orientations }))
  } else if (name === 'manufacturabilityValue') {
    dispatch(slice.actions.setManufacturabilityValueAndOrientations({ value, name, orientations }))
  }
  dispatch(slice.actions.changeValuesAndOrientations({ value, name, orientations }))
}

export const changeAnagraphicValue = (value, name) => dispatch => {
  dispatch(slice.actions.changeAnagraphicValues({ value, name }))
}

export const changePlantType = (plantType) => dispatch => {
  dispatch(slice.actions.setPlantType({ plantType }))
}

export const changeModuleValue = (value, name) => dispatch => {
  if (name === 'moduleType') {
    dispatch(slice.actions.setModuleType({ value }))
  }
}

export const setBaseline = (baseline) => dispatch => {
  dispatch(slice.actions.setBaseline({ baseline }))
}

export const modifyBaselineValue = (monthIndex, producibilityIndex, name, value) => dispatch => {
  dispatch(slice.actions.updateBaseline({ monthIndex, producibilityIndex, name, value }))
}

export const modifyPercentageLoss = (index, value) => dispatch => {
  dispatch(slice.actions.updatePercentageLoss({ index, value }))
}

export const modifyBaselineConfig = (index, name, value) => dispatch => {
  dispatch(slice.actions.updateBaselineConfig({ index, name, value }))
}

export const setAddress = (newAddress) => dispatch => {
  dispatch(slice.actions.setAddress({ newAddress }))
}

export const updateLogo = (url = null) => dispatch => {
  dispatch(slice.actions.setLogo({ url }))
}

export const togglePlantAutosave = (hasAutosave) => dispatch => {
  dispatch(slice.actions.setAutosave({ hasAutosave }))
}

// Funzione che setta la configurazione della mappa della planimetria
export const updateMapConfig = ({ center, zoom }) => dispatch => {
  dispatch(slice.actions.updateMapConfig({ center, zoom }))
}
