import clsx from 'clsx'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updatedPlantInfo } from '@/slices/pv/plantView'
import { europeNum, getDeviceLogs, getExcelLogs } from '@utils/general'
import moment from 'moment'
import fileDownload from 'js-file-download'
import LoadingCard from '@components/LoadingCard'
import { basicColors } from '@theme'
import DeviceChip from './DeviceChip'
import DownloadDatasheetsDialog from '../../shared/DownloadDatasheetsDialog'
import ModalExcelDeviceLogs from '@components/ModalExcelDeviceLogs'
import ModalDeviceLogs from '@components/ModalDeviceLogs'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  errorText: {
    color: 'white',
    textAlign: 'center'
  },
  cardLabel: {
    color: basicColors.blueGrayLight
  }
}))

// mi preparo i dati per i log
const logsParams = {
  deviceId: '',
  name: [],
  timeTo: null,
  timeFrom: null
}

function Components ({ className, ...rest }) {
  const classes = useStyles()

  const { uuid: plantId, hasError, inverters, sunMeters, energyMeters, dataloggers } = useSelector((state) => state.pvPlantView)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const now = moment()
    .set({ minute: 0, second: 0, millisecond: 0 })
    .toISOString()

  const [showDownloadDialog, setShowDownloadDialog] = useState(false)
  const [currentDocuments, setCurrentDocuments] = useState(null)

  // Variabili per le dialog dei logs
  const [openLogsModal, setOpenLogsModal] = useState(false)
  const [openExcelModal, setOpenExcelModal] = useState(false)
  const [logs, setLogs] = useState({
    uuid: '',
    name: '',
    data: {},
    columns: [],
    rows: []
  })
  const [isExcelTooBig, setIsExcelTooBig] = useState(false)
  const [from, setFrom] = useState(moment(now).toISOString())
  const [to, setTo] = useState(
    moment(now)
      .add({ hours: '1' })
      .toISOString()
  )

  useEffect(() => {
    if (plantId && plantId !== 'demo') {
      dispatch(updatedPlantInfo({ plantId }))
    }

    const overviewInterval = setInterval(() => {
      dispatch(updatedPlantInfo({ plantId }))
    }, 60000)
    return () => {
      clearInterval(overviewInterval)
    }
  }, [dispatch, plantId, hasError])

  const sortedInverters = Object.assign([], inverters).sort((a, b) => {
    if (!Object.prototype.hasOwnProperty.call(a.metadata, 'inverterNum')) {
      // viene prima b
      return 1
    }

    if (!Object.prototype.hasOwnProperty.call(b.metadata, 'inverterNum')) {
      // viene prima a
      return -1
    }

    return Number(a.metadata.inverterNum) - Number(b.metadata.inverterNum)
  })

  const inverterRows = sortedInverters.map((inverter) => {
    // console.log('rows: ', inverter)
    // const datasheetUrl = inverter.metadata && inverter.metadata.documents && inverter.metadata.documents.length > 0 ? inverter.metadata.documents[inverter.metadata.documents.length - 1].url : ''
    const inverterDocuments = inverter.metadata && inverter.metadata.documents ? inverter.metadata.documents : []
    const deviceTypeDocuments = inverter.deviceType && inverter.deviceType.metadata && inverter.deviceType.metadata.documents ? inverter.deviceType.metadata.documents : []
    let documents = deviceTypeDocuments.concat(inverterDocuments)

    if (documents.length === 0) {
      documents = null
    }

    const currentRow = {
      uuid: '',
      type: 'inverter',
      name: inverter.name,
      model: inverter?.deviceType?.model,
      vac: 'N.F.',
      iac: 'N.F.',
      power: 'N.F.',
      energy: 'N.F.',
      // pr: 'N.F.',
      icc1: 'N.F',
      vcc1: 'N.F.',
      status: {
        general: null,
        communication: null
      },
      datalogger: 'N.F.',
      actions: {
        documents
        // datasheet: datasheetUrl
      }
    }
    // mi salvo l'uuid
    currentRow.uuid = inverter.uuid
    // cerco il datalogger
    const dataloggerIndex = dataloggers.findIndex((datalogger) => datalogger.uuid === inverter.nodeId)
    if (dataloggerIndex > -1) {
      currentRow.datalogger = dataloggers[dataloggerIndex].name
    }
    // controllo se ho dati
    if (inverter.state && Object.keys(inverter.state).length > 0) {
      // controllo i dati che ho
      // comunicazione e stato
      if (Object.prototype.hasOwnProperty.call(inverter, 'lastActivityAt') && inverter.lastActivityAt !== 'never') {
        // controllo la differenza del periodo
        const a = moment()
        const b = moment(inverter.lastActivityAt)
        if (a.diff(b, 'minutes') >= 30) {
          currentRow.status.communication = 'offline'
        } else {
          currentRow.status.communication = 'online'
        }
      } else {
        currentRow.status.communication = 'offline'
      }
      if (Object.prototype.hasOwnProperty.call(inverter.metadata, 'status')) {
        currentRow.status.general = inverter.metadata.status
      } else {
        currentRow.status.general = 'ok'
      }
      // altri dati
      if (
        Object.prototype.hasOwnProperty.call(inverter.state, 'vac1') &&
        Object.prototype.hasOwnProperty.call(inverter.state, 'vac2') &&
        Object.prototype.hasOwnProperty.call(inverter.state, 'vac3')
      ) {
        currentRow.vac = (inverter.state.vac1 + inverter.state.vac2 + inverter.state.vac3) / 3
        currentRow.vac = europeNum(currentRow.vac, 2)
      } else if (Object.prototype.hasOwnProperty.call(inverter.state, 'vac1')) {
        currentRow.vac = europeNum(inverter.state.vac1, 2)
      }
      if (
        Object.prototype.hasOwnProperty.call(inverter.state, 'iac1') &&
        Object.prototype.hasOwnProperty.call(inverter.state, 'iac2') &&
        Object.prototype.hasOwnProperty.call(inverter.state, 'iac3')
      ) {
        currentRow.iac = (inverter.state.iac1 + inverter.state.iac2 + inverter.state.iac3) / 3
        currentRow.iac = europeNum(currentRow.iac, 2)
      } else if (Object.prototype.hasOwnProperty.call(inverter.state, 'iac1')) {
        currentRow.iac = europeNum(inverter.state.iac1, 2)
      }
      Object.keys(inverter.state).forEach((key) => {
        // skippo le variabili che ho già analizzato
        if (key === 'activepowertot' || key === 'totalenergy') {
          currentRow[key] = europeNum(inverter.state[key])
        }
      })
    }

    return currentRow
  })

  const sunMeterRows = sunMeters.map((sunMeter) => {
    // const datasheetUrl = sunMeter.metadata && sunMeter.metadata.documents && sunMeter.metadata.documents.length > 0 ? sunMeter.metadata.documents[sunMeter.metadata.documents.length - 1].url : ''
    const sunMeterDocuments = sunMeter.metadata && sunMeter.metadata.documents ? sunMeter.metadata.documents : []
    const deviceTypeDocuments = sunMeter.deviceType && sunMeter.deviceType.metadata && sunMeter.deviceType.metadata.documents ? sunMeter.deviceType.metadata.documents : []
    let documents = deviceTypeDocuments.concat(sunMeterDocuments)

    if (documents.length === 0) {
      documents = null
    }
    const currentRow = {
      uuid: '',
      type: 'sunMeter',
      name: sunMeter.name,
      model: sunMeter?.deviceType?.model,
      irradiation: 'N.F.',
      temperature: 'N.F.',
      datalogger: 'N.F.',
      status: {
        general: null,
        communication: null
      },
      actions: {
        documents
        // datasheet: datasheetUrl
      }
    }
    // mi salvo l'uuid
    currentRow.uuid = sunMeter.uuid
    // cerco il datalogger
    const dataloggerIndex = dataloggers.findIndex((datalogger) => datalogger.uuid === sunMeter.nodeId)
    if (dataloggerIndex > -1) {
      currentRow.datalogger = dataloggers[dataloggerIndex].name
    }
    // controllo se ho dati
    if (sunMeter.state && Object.keys(sunMeter.state).length > 0) {
      // controllo i dati che ho
      // comunicazione e stato
      if (Object.prototype.hasOwnProperty.call(sunMeter, 'lastActivityAt') && sunMeter.lastActivityAt !== 'never') {
        // controllo la differenza del periodo
        const a = moment()
        const b = moment(sunMeter.lastActivityAt)
        if (a.diff(b, 'minutes') >= 30) {
          currentRow.status.communication = 'offline'
        } else {
          currentRow.status.communication = 'online'
        }
      } else {
        currentRow.status.communication = 'offline'
      }
      if (Object.prototype.hasOwnProperty.call(sunMeter.metadata, 'status')) {
        currentRow.status.general = sunMeter.metadata.status
      } else {
        currentRow.status.general = 'ok'
      }
      // altri dati
      Object.keys(sunMeter.state).forEach((key) => {
        currentRow[key] = europeNum(sunMeter.state[key], 2)
      })
    }

    return currentRow
  })

  const energyMeterRows = energyMeters.map((energyMeter) => {
    // const datasheetUrl = energyMeter.metadata && energyMeter.metadata.documents && energyMeter.metadata.documents.length > 0 ? energyMeter.metadata.documents[energyMeter.metadata.documents.length - 1].url : ''
    const energyMeterDocuments = energyMeter.metadata && energyMeter.metadata.documents ? energyMeter.metadata.documents : []
    const deviceTypeDocuments = energyMeter.deviceType && energyMeter.deviceType.metadata && energyMeter.deviceType.metadata.documents ? energyMeter.deviceType.metadata.documents : []
    let documents = deviceTypeDocuments.concat(energyMeterDocuments)

    if (documents.length === 0) {
      documents = null
    }
    const currentRow = {
      uuid: '',
      type: 'energyMeter',
      name: energyMeter.name,
      model: energyMeter?.deviceType?.model,
      activepowerl1: 'N.F.',
      activepowerl2: 'N.F.',
      activepowerl3: 'N.F.',
      activepowertot: 'N.F.',
      expenergy: 'N.F.',
      impenergy: 'N.F.',
      currentl1: 'N.F.',
      currentl2: 'N.F.',
      currentl3: 'N.F.',
      voltagel1: 'N.F.',
      voltagel2: 'N.F.',
      voltagel3: 'N.F.',
      datalogger: 'N.F.',
      status: {
        general: null,
        communication: null
      },
      actions: {
        documents
        // datasheet: datasheetUrl
      }
    }
    // mi salvo l'uuid
    currentRow.uuid = energyMeter.uuid
    // cerco il datalogger
    const dataloggerIndex = dataloggers.findIndex((datalogger) => datalogger.uuid === energyMeter.nodeId)
    if (dataloggerIndex > -1) {
      currentRow.datalogger = dataloggers[dataloggerIndex].name
      // currentRow.status.communication =
      //   dataloggers[dataloggerIndex].connectivityStatus === 'connected' ? 'online' : 'offline'
    }
    // controllo se ho dati
    if (energyMeter.state && Object.keys(energyMeter.state).length > 0) {
      // controllo i dati che ho
      // comunicazione e stato
      if (Object.prototype.hasOwnProperty.call(energyMeter, 'lastActivityAt') && energyMeter.lastActivityAt !== 'never') {
        // controllo la differenza del periodo
        const a = moment()
        const b = moment(energyMeter.lastActivityAt)
        if (a.diff(b, 'minutes') >= 30) {
          currentRow.status.communication = 'offline'
        } else {
          currentRow.status.communication = 'online'
        }
      } else {
        currentRow.status.communication = 'offline'
      }
      if (Object.prototype.hasOwnProperty.call(energyMeter.metadata, 'status')) {
        currentRow.status.general = energyMeter.metadata.status
      } else {
        currentRow.status.general = 'ok'
      }
      // currentRow.status.general = 'ok'
      // altri dati
      Object.keys(energyMeter.state).forEach((key) => {
        if (
          key !== 'cosphil1' &&
          key !== 'cosphil2' &&
          key !== 'cosphil3' &&
          key !== 'status' &&
          key.indexOf('avg') === -1 &&
          key.indexOf('delta') === -1 &&
          key.indexOf('sum') === -1
        ) {
          if (key === 'expenergy' || key === 'impenergy') {
            const finalValue = Number(energyMeter.state[key]) / 1000
            currentRow[key] = europeNum(finalValue, 2)
          } else {
            currentRow[key] = europeNum(energyMeter.state[key], 2)
          }
        }
      })
    }

    return currentRow
  })

  // funzione che torna l'array con le colonne
  const generateLogColumns = (deviceProps, props) => {
    const arrayToSend = []
    if (deviceProps) {
      Object.keys(deviceProps).forEach(key => {
        const label = deviceProps[key]?.uom ? `${deviceProps[key]?.displayName || '-'} (${deviceProps[key].uom})` : `${deviceProps[key]?.displayName || '-'}`
        const propObject = {
          prop: key,
          label
        }
        arrayToSend.push(propObject)
      })
    }

    return arrayToSend
  }

  // Funzione che preso in ingresso l'oggetto dei logs di un singolo device restituisce lo stesso oggetto ma trascurando la diversità di secondi tra un messaggio e l'altro
  const approximateLogsToMinutesPrecision = (logsObj) => {
    if (logsObj) {
      return Object.keys(logsObj).reduce((acc, propKey) => {
        const newProp = logsObj[propKey]
          .map(dateValueEl => dateValueEl?.map((el, index) => index === 0 ? moment(el).format('DD/MM/YYYY HH:mm') : el))

        acc[propKey] = newProp

        return acc
      }, {})
    }

    return {}
  }

  // funzione che apre la modal dei log
  const openLogsDialog = async (row) => {
    // setto i valori nei parametri della query
    logsParams.deviceId = row.uuid
    // mi calcolo le giuste propsLabels
    const currentDevice = [...inverters, ...sunMeters, ...energyMeters].find(device => device.uuid === row.uuid)
    const newColumns = generateLogColumns(currentDevice.deviceType?.properties, currentDevice ? currentDevice.state : null)

    logsParams.name = newColumns.map((col) => col.prop)
    logsParams.timeFrom = from
    logsParams.timeTo = to
    const newData = await getDeviceLogs(logsParams)
    // mi preparo le righe
    const formattedData = approximateLogsToMinutesPrecision(newData[currentDevice?.uuid] || {})
    const newRows = generateLogRows(formattedData)

    setLogs({
      uuid: logsParams.deviceId,
      name: row.name,
      columns: newColumns,
      rows: newRows,
      data: formattedData
    })
    // console.log('logs => ', logs)

    setOpenLogsModal(true)
  }

  // funzione che viene invocata alla chiusura della modal dei log
  const handleCloseLogsModal = () => {
    setOpenLogsModal(false)
  }

  // funzione che ritorna l'array delle righe
  const generateLogRows = (data) => {
    const arrayToReturn = []
    // mi scorro i valori di tutte le prop e mi salvo i valori ISOString che non ho
    Object.keys(data).forEach((key) => {
      data[key].forEach((elem) => {
        if (arrayToReturn.indexOf(elem[0]) === -1) {
          arrayToReturn.push(elem[0])
        }
      })
    })
    // Ordino l'array in base al tempo (dal più vicino al from fino al to)
    arrayToReturn.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0))
    return arrayToReturn
  }

  // funzione che aggiorna i dati
  const onPeriodChange = async (newFrom, newTo) => {
    // console.log('onPeriodChange, newFrom => ', newFrom)
    // console.log('onPeriodChange, newTo => ', newTo)
    logsParams.timeFrom = moment(newFrom).toISOString()
    logsParams.timeTo = moment(newTo).toISOString()
    setFrom(logsParams.timeFrom)
    setTo(logsParams.timeTo)

    const newData = await getDeviceLogs(logsParams)

    const key = Object.keys(newData)
    const formattedData = approximateLogsToMinutesPrecision(newData[key])
    const newRows = generateLogRows(formattedData)

    setLogs({ ...logs, data: formattedData, rows: newRows })
    // console.log('logs => ', logs)
  }

  // funzione che apre la modal per scaricare Excel
  const toogleDownloadExcel = () => {
    setOpenExcelModal(true)
  }

  // funzione che viene invocata alla chiusura della modal dei log
  const handleCloseExcelModal = () => {
    setOpenExcelModal(false)
  }

  // funzione che scarica l'excel
  const downloadExcel = async (from, to, selectedAggregation) => {
    setIsExcelTooBig(false)
    // console.log('downloadExcel from => ', from)
    // console.log('downloadExcel to => ', to)
    const excelParams = JSON.parse(JSON.stringify(logsParams))
    excelParams.timeFrom = moment(from).toISOString()
    excelParams.timeTo = moment(to).toISOString()
    excelParams.aggregationType = selectedAggregation || 'quarter'
    // console.log('excelParams => ', excelParams)
    try {
      const excelResponse = await getExcelLogs(excelParams)
      // console.log('excelResponse => ', excelResponse)
      // controllo se è ritornato il file o se manda email
      if (excelResponse.status === 202) {
        // mando l'excel per email
        // console.log('MANDO LA MAILLLLL')
        setIsExcelTooBig(true)
      } else {
        // scarico il blob
        fileDownload(
          excelResponse.data,
          `LOGS ${logs.name} - dal ${moment(from).format('DD-MM-YY (HH)')} al ${moment(to).format(
            'DD-MM-YY (HH)'
          )} - dati tipo ${selectedAggregation || 'quarter'}.xlsx`
        )
      }
    } catch (err) {
      console.error('get excel err => ', err)
      if (String(err).indexOf('404') > -1) {
        enqueueSnackbar('Attenzione: non ci sono dati per il tipo di aggregazione richiesta.', {
          variant: 'warning'
        })
      } else {
        enqueueSnackbar('Errore nel richiedere i log in Excel, per favore aggiornare la pagina e riprovare.', {
          variant: 'error'
        })
      }
    }
  }

  console.log(logs)

  return (
    <>
      {showDownloadDialog
        ? <DownloadDatasheetsDialog list={currentDocuments} open={showDownloadDialog} onClose={() => setShowDownloadDialog(false)} />
        : null}
      <Box px={2} py={1} className={clsx(classes.root, className)}>
        {dataloggers[0] === '-'
          ? <LoadingCard glass />
          : dataloggers.length === 0
            ? <Typography variant='body1' className={classes.errorText}>Non ci sono dispositivi per questo impianto</Typography>
            : (
              <>
                <Typography className={classes.cardLabel} variant='caption' component='div'>Componenti</Typography>
                <Box flexWrap='wrap' display='flex' alignItems='center' mt={0.5} width='100%'>
                  {[...inverterRows, ...energyMeterRows, ...sunMeterRows].map(el => (
                    <DeviceChip
                      onClick={openLogsDialog}
                      key={el.uuid}
                      device={el}
                      downloadActions={(documents) => {
                        setShowDownloadDialog(true)
                        setCurrentDocuments(documents || [])
                      }}
                    />))}
                </Box>
                {openLogsModal
                  ? (
                    <ModalDeviceLogs
                      open={openLogsModal}
                      onClose={handleCloseLogsModal}
                      data={logs.data}
                      columnsLabels={logs.columns}
                      initialRows={logs.rows}
                      name={logs.name}
                      onPeriodChange={onPeriodChange}
                      initialFrom={from}
                      initialTo={to}
                      toogleDownloadExcel={toogleDownloadExcel}
                    />)
                  : null}
                {openExcelModal
                  ? (
                    <ModalExcelDeviceLogs
                      open={openExcelModal}
                      onClose={handleCloseExcelModal}
                      deviceId={logs.uuid}
                      name={logs.name}
                      initialFrom={from}
                      initialTo={to}
                      downloadExcel={downloadExcel}
                      isExcelTooBig={isExcelTooBig}
                    />)
                  : null}

              </>)}
      </Box>
    </>
  )
}
export default memo(Components)
