import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import useAuth from '@/hooks/useAuth'
import { configuration } from '@/config'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}))

function ShareLayout ({ children, className, ...rest }) {
  const classes = useStyles()
  const { currentProject } = useAuth()

  const isGeDashboard = configuration.geProject === currentProject?.uuid

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Helmet>
        {isGeDashboard
          ? (<link rel='icon' type='image/png' sizes='any' href='/static/ge.png' />)
          : (<link rel='icon' type='image/svg+xml' sizes='any' href='/favicon.svg' />)}
      </Helmet>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children || 'null'}
          </div>
        </div>
      </div>
    </div>
  )
}
export default ShareLayout
