import { useState } from 'react'
import clsx from 'clsx'
import { Box, Button, Checkbox, Divider, Grid, IconButton, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles, MenuItem, SvgIcon, TextField, Tooltip, Typography } from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid'
import {
  Edit as EditIcon,
  Trash2 as TrashIcon
} from 'react-feather'
import { HexColorPicker } from 'react-colorful'
import ConfirmationDialogRaw from '@/components/ConfirmationDialogRaw'
import OrientationDialog from '../../OrientationDialog'
import { libraryDevices } from '../../libraryDevices'

const useStyles = makeStyles(() => ({
  root: {}
}))

function SolarimeterContent ({ saveOrientation, modifyOrientation, deleteOrientation, className, ...rest }) {
  const classes = useStyles()
  const { selectedObject, orientations, devices, element, setElement/* , additionalData */ } = rest

  const configDevices = element && element !== undefined
    ? element.config.length > 0 ? element.config[0].devices : []
    : []

  const selected = configDevices && configDevices.length > 0 ? configDevices[0].deviceId : ''
  const number = element && element.config.length > 0
    ? element.config[0].data
        ? element.config[0].data.draw && element.config[0].data.draw.number
            ? element.config[0].data.draw.number
            : 0
        : 0
    : 0

  const color = element && element.config.length > 0
    ? element.config[0].data
        ? element.config[0].data.draw && element.config[0].data.draw.backgroundColor
            ? element.config[0].data.draw.backgroundColor
            : 'white'
        : 'white'
    : 'white'

  // const currentOrientation = (additionalData && additionalData !== undefined && additionalData.orientation) || ''
  const currentOrientation = element && element !== undefined
    ? element.config.length > 0
        ? element.config[0].data && element.config[0].data.orientation
            ? element.config[0].data.orientation
            : ''
        : ''
    : ''
  const [selectedDevice, setSelectedDevice] = useState(selected)
  const [selectedOrientation, setSelectedOrientation] = useState(currentOrientation)
  const [showOrientationNameInput, setShowOrientationNameInput] = useState(false)
  const [currentNumber, setCurrentNumber] = useState(number)
  const [currentColor, setCurrentColor] = useState(color)
  const sunmeters = devices ? devices.filter(device => device.deviceType.category === 'SunMeter') : []

  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [orientationToDelete, setOrientationToDelete] = useState(null)
  const [orientationToModify, setOrientationToModify] = useState(null)

  const handleDeleteOrientation = (confirmed, orientationUuid) => {
    if (orientationUuid && orientationUuid !== undefined) {
      if (confirmed) {
        deleteOrientation(orientationUuid)
      }
      setOrientationToDelete(null)
      setShowConfirmDialog(false)
    }
  }

  const handleModifyOrientation = () => {
    modifyOrientation(orientationToModify)
    setOrientationToModify(false)
    setShowOrientationNameInput(false)
  }

  const handleNewOrientation = (orientation) => {
    const newOrientationId = uuidv4()
    // 1. Creare il nuovo oggetto orientamento
    const newOrientation = {
      ...orientation,
      uuid: newOrientationId
    }
    saveOrientation(newOrientation)
    // 3. settare l'id come selected
    setSelectedOrientation(newOrientationId)
    setElement(prevElement => {
      if (prevElement.config.length > 0) {
        if (!prevElement.config[0].data) {
          prevElement.config[0].data = {}
        }
        prevElement.config[0].data =
        {
          ...prevElement.config[0].data,
          orientation: newOrientationId
        }
      } else {
        prevElement.config.push({
          devices: [],
          data: {
            orientation: newOrientationId,
            draw: {}
          }
        })
      }

      return { ...prevElement }
    })
    // 4. settare show
    setShowOrientationNameInput(false)
  }

  return (
    <div className={clsx(classes.root, className)}>
      {showOrientationNameInput
        ? (
          <OrientationDialog
            orientationToModify={orientationToModify}
            setOrientationToModify={setOrientationToModify}
            handleModifyOrientation={handleModifyOrientation}
            handleNewOrientation={handleNewOrientation}
            open={showOrientationNameInput}
            onClose={() => { setShowOrientationNameInput(false) }}
          />
          )
        : null}
      {showConfirmDialog && orientationToDelete
        ? (
          <ConfirmationDialogRaw
            open={showConfirmDialog}
            onClose={(confirmed) => handleDeleteOrientation(confirmed, orientationToDelete.uuid)}
            title='Elimina Orientamento'
            description={`Sei sicuro di voler eliminare l'orientamento "${orientationToDelete.name || ''}"?`}
          />
          )
        : null}
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Typography variant='h6'>Solarimetro</Typography>
          <Box my={2}>
            <TextField
              color='primary'
              variant='outlined'
              size='small'
              select
              fullWidth
              SelectProps={{
                renderValue: (selected) => <ListItemText style={{ marginTop: 0, marginBottom: 0 }}>{orientations.find(or => or.uuid === selected) ? orientations.find(or => or.uuid === selected).name : ''}</ListItemText>
              }}
              label='Seleziona Orientamento'
              value={selectedOrientation || ''}
              onChange={e => {
                setSelectedOrientation(e.target.value)
                setElement(prevElement => {
                  if (prevElement.config.length > 0) {
                    if (!prevElement.config[0].data) {
                      prevElement.config[0].data = {}
                    }
                    prevElement.config[0].data =
                    {
                      ...prevElement.config[0].data,
                      orientation: e.target.value
                    }
                  } else {
                    prevElement.config.push({
                      devices: [],
                      data: {
                        orientation: e.target.value,
                        draw: {}
                      }
                    })
                  }

                  return { ...prevElement }
                })
              }}
            >
              {orientations.map(el => (
                <MenuItem dense key={el.uuid || el.id} value={el.uuid || el.id}>
                  <ListItemText>{el.name}</ListItemText>
                  <ListItemSecondaryAction>
                    <Tooltip title='Modifica Orientamento'>
                      <IconButton
                        onClick={() => {
                          setShowOrientationNameInput(true)
                          setOrientationToModify(el)
                        }} edge='start'
                      >
                        <SvgIcon fontSize='small'>
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Elimina Orientamento'>
                      <IconButton
                        onClick={() => {
                          setShowConfirmDialog(true)
                          setOrientationToDelete(el)
                        }} edge='end'
                      >
                        <SvgIcon fontSize='small'>
                          <TrashIcon />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </MenuItem>
              ))}
              <Divider />
              <MenuItem
                dense
                className={classes.transparentMenuItem}
              >
                <Button onClick={() => setShowOrientationNameInput(true)} fullWidth>
                  Aggiungi orientamento
                </Button>
              </MenuItem>
            </TextField>
          </Box>
          <Box my={2}>
            <TextField
              color='primary'
              size='small'
              variant='outlined'
              select
              fullWidth
              SelectProps={{
                renderValue: (renderSelected) => <ListItemText style={{ marginTop: 0, marginBottom: 0 }}>{sunmeters.find(or => or.uuid === renderSelected) ? sunmeters.find(or => or.uuid === renderSelected).name : ''}</ListItemText>
              }}
              label='Seleziona Solarimetro'
              value={selectedDevice || ''}
            >
              {sunmeters.map(el => (
                <MenuItem
                  dense
                  onClick={() => {
                    let isDeselection = false
                    setElement(prevElement => {
                      // oggetto completo del device selezionato
                      const selectedDevice = devices.find(dev => dev.uuid === el.uuid)

                      // se l'oggetto esiste, devo aggiornare l'array dei devices nell'elemento
                      if (selectedDevice && selectedDevice !== undefined) {
                        // creo l'oggetto con le proprietà da mostrare nelle infobox
                        const properties = {}
                        const libraryDevice = libraryDevices.find(libEl => libEl.models.includes(selectedDevice.deviceType.model))
                        if (libraryDevice) {
                          const deviceKeys = Object.keys(libraryDevice)
                          deviceKeys.filter(key => key !== 'models').forEach(key => {
                            properties[key] = {
                              label: libraryDevice[key],
                              value: 0
                            }
                          })
                        }
                        // strutturo l'oggetto device
                        const elementDevice = {
                          type: selectedObject.type,
                          model: selectedDevice.deviceType.model,
                          deviceId: selectedDevice.uuid,
                          properties,
                          additionalData: {}
                        }

                        if (prevElement.config.length > 0 && prevElement.config[0].devices) {
                          const sameDevice = prevElement.config[0].devices.find(configEl => configEl.deviceId === el.uuid)
                          // se è presente lo rimuovo
                          if (sameDevice && sameDevice !== undefined) {
                            const newDevices = prevElement.config[0].devices.filter(dev => dev.deviceId !== el.uuid)
                            prevElement.config[0].devices = newDevices
                            isDeselection = true
                          } else {
                            // aggiorno l'array di devices di un singolo elemento
                            prevElement.config[0].devices = [{ ...elementDevice }]
                          }
                        } else {
                          prevElement.config.push({
                            devices: [{ ...elementDevice }],
                            data: {}
                          })
                        }
                      }

                      return ({
                        config: [{
                          ...prevElement.config[0]
                        }]
                      })
                    })
                    if (isDeselection) {
                      setSelectedDevice('')
                    } else {
                      setSelectedDevice(el.uuid)
                    }
                  }} key={el.uuid} value={el.uuid}
                >
                  <ListItemIcon>
                    <Checkbox
                      color='primary'
                      edge='start'
                      checked={selected === el.uuid}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText>{el.name}</ListItemText>
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box width='100%'>
            <TextField
              fullWidth
              size='small'
              variant='outlined'
              label='Numero'
              type='number'
              placeholder='0'
              value={currentNumber || ''}
              onChange={(e) => {
                e.persist()
                setElement(prevElement => {
                  if (prevElement.config.length > 0) {
                    // se l'elemento non ha l'oggetto data lo imposto a oggetto vuoto
                    if (!prevElement.config[0].data) {
                      prevElement.config[0].data = {}
                    }
                    // se l'elemento non ha l'oggetto draw lo imposto a oggetto vuoto
                    if (!prevElement.config[0].data.draw) {
                      prevElement.config[0].data.draw = {}
                    }
                    prevElement.config[0].data.draw = {
                      ...prevElement.config[0].data.draw,
                      number: e.target.value
                    }
                  } else {
                    prevElement.config.push({
                      devices: [],
                      data: {
                        draw: {
                          number: e.target.value
                        }
                      }
                    })
                  }
                  return { ...prevElement }
                })
                setCurrentNumber(e.target.value)
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant='h6'>Colore di sfondo</Typography>
          <Box my={2} width='100%'>
            <HexColorPicker
              style={{ height: '150px' }} color={currentColor} onChange={setCurrentColor} onMouseUp={(e) => {
                e.persist()
                setElement((prevElement) => {
                  // console.log(prevElement)
                  if (prevElement.config.length > 0) {
                    if (!prevElement.config[0].data.draw) {
                      prevElement.config[0].data.draw = {}
                    }
                    prevElement.config[0].data.draw = {
                      ...prevElement.config[0].data.draw,
                      backgroundColor: currentColor
                    }
                  } else {
                    prevElement.config.push({
                      devices: [],
                      data: {
                        draw: {
                          backgroundColor: currentColor
                        }
                      }
                    })
                  }
                  return { ...prevElement }
                })
              }}
            />
          </Box>
        </Grid>
      </Grid>

    </div>
  )
}
export default SolarimeterContent
