import { useEffect, useState, useCallback, memo } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import useAuth from '@/hooks/useAuth'
import api from '@micmnt/apis'
import { useSnackbar } from 'notistack'
// import useIsMountedRef from '@/hooks/useIsMountedRef'
// import PerfectScrollbar from 'react-perfect-scrollbar'
import ReportTable from '@/components/Report/ReportTable'
import ReportEmails from '@/components/Report/ReportEmails'
import SendEmails from '@/components/Report/SendEmails'
import PlantReportSidenav from '@/components/Report/PlantReportSidenav'
import log from '@pelv/frontlog'

// STILI DEL COMPONENTE
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  section: {
    padding: theme.spacing(1, 2),
    width: '100%',
    height: '100%'
  },
  sectionTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  },
  topMargin: {
    marginTop: (theme.spacing(2) - 4)
  },
  smallViewport: {
    overflowY: 'auto',
    height: '100%'
  },
  glassBackground: {
    '@supports (backdrop-filter: none) or (-webkit-backdrop-filter: none)': {
      '-webkit-backdrop-filter': 'saturate(120%) blur(16px)',
      backdropFilter: 'saturate(190%) blur(16px)',
      backgroundColor: 'rgba(16, 26, 38, 0.05)'
    }
  }
}))

// COMPONENTE PRINCIPALE
const Report = ({ hasGlass = false, className, ...rest }) => {
  const classes = useStyles()
  const { user, updateReportEmails } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const { uuid: plantId, name } = useSelector((state) => state.pvPlantView)
  // const dispatch = useDispatch()
  // variaibli principali
  const [reports, setReports] = useState([])
  const [refreshReports, setRefreshReports] = useState(true)
  // const [periods, setPeriods] = useState([])
  const [openSendReport, setOpenSendReport] = useState(false)
  const [openReportEmails, setOpenReportEmails] = useState(false)
  const [openReport, setOpenReport] = useState(false)
  const [reportToEdit, setReportToEdit] = useState(null)

  const getData = useCallback(async (plantId) => {
    const reportParams = {
      type: 'plant',
      plantId: plantId
    }
    const { data: reportResponse, error } = await api.get({ savedUrl: 'reports', params: reportParams })

    if (error) {
      console.error('Error getData reports =>', error)
      return null
    }

    const dataObj = reportResponse
    log({ text: 'report data => ', variable: dataObj, tag: 'report' })
    return dataObj
  }, [])

  useEffect(() => {
    // console.log('dentro useEffect')
    async function initialiseView () {
      if (plantId) {
        const data = await getData(plantId)
        if (data) {
          // const periods = data.map(report => `${report.month}${report.year}`)
          // setPeriods(periods)
          setReports(data)
          setRefreshReports(false)
        }
      }
    }
    // se devo refreshare la view lo faccio qui
    if (refreshReports) {
      initialiseView()
    }
    // setto interval
    const reportInterval = setInterval(() => {
      console.log('dentro interval')
      initialiseView()
    }, 60000)
    return () => {
      clearInterval(reportInterval)
    }
  }, [refreshReports, getData, plantId])

  // funzione che apre la sidenav per creare un nuovo report
  const openNewReport = (report) => {
    if (report) {
      setReportToEdit(report)
    } else {
      setReportToEdit(null)
    }
    setOpenReport(true)
  }

  // funzione lanciata al save del nuovo Report
  const saveNewReport = async (newReport) => {
    // controllo se è un report che ho già creato, lo edito
    const thisIndex = reports.findIndex(report => {
      // return periods.indexOf(`${newReport.month}${newReport.year}`) > -1
      return newReport.month === report.month && newReport.year === report.year
    })
    if (thisIndex > -1) {
      const toSend = {
        ...reports[thisIndex],
        notes: newReport.notes,
        plantStatus: newReport.plantStatus,
        plantStatusNotes: newReport.plantStatusNotes
      }
      editReport(toSend)
      return
    }
    const { error } = await api.post({ savedUrl: 'reports', body: newReport })
    if (error) {
      console.error('Error while sending new report, err => ', error)
      return enqueueSnackbar("Errore durante l'invio del nuovo report. Per favore riprovare", { variant: 'error' })
    }
    enqueueSnackbar('Richiesta inviata, il Report sarà pronto a breve!', { variant: 'success' })
    // chiudo la modal
    setTimeout(() => {
      handleCloseNewReport()
      setRefreshReports(true)
    }, 2000)
  }

  // funzione lanciata al save delle modifiche sul report
  const editReport = async (newReport) => {
    const { error } = await api.put({ savedUrl: 'reports', path: `/${newReport.uuid}`, body: newReport })
    if (error) {
      console.error('Error while sending new report, err => ', error)
      return enqueueSnackbar("Errore durante l'invio del report modificato. Per favore riprovare", { variant: 'error' })
    }
    enqueueSnackbar('Richiesta di modifica inviata, il Report sarà pronto a breve!', { variant: 'success' })
    // chiudo la modal
    setTimeout(() => {
      handleCloseNewReport()
      setRefreshReports(true)
    }, 2000)
  }

  // funzione lanciata al save delle modifiche sul report
  const deleteReport = async (report) => {
    const { error } = await api.delete({ savedUrl: 'reports', path: `/${report.uuid}` })

    if (error) {
      console.error('Error while sending new report, err => ', error)
      return enqueueSnackbar("Errore durante l'eliminazione del report. Per favore riprovare", { variant: 'error' })
    }

    enqueueSnackbar('Report eliminato correttamente!', { variant: 'success' })
    setRefreshReports(true)
  }

  // funzione lanciata al close della sidenav della creazione / modifica
  const handleCloseNewReport = () => {
    setOpenReport(false)
  }

  // funzione che apre la modal per salvare le mail a cui inviare i report
  const openEditReportsMail = () => {
    setOpenReportEmails(true)
  }

  // funzione che salva le email per il Report
  const saveNewEmails = async (newEmails) => {
    updateReportEmails(newEmails)
    const newMetadata = {
      ...user.metadata,
      reportEmails: newEmails
    }
    const newUser = {
      ...user,
      metadata: newMetadata
    }
    const { error } = await api.put({ savedUrl: 'accounts', path: '/self', body: newUser })

    if (error) {
      console.error('Error while updating self, err => ', error)
      return enqueueSnackbar("Errore durante l'aggiornamento delle email. Per favore riprovare", { variant: 'error' })
    }

    enqueueSnackbar('Email aggiornate con successo!', { variant: 'success' })
    // chiudo la modal
    setTimeout(() => {
      handleCloseReportEmails()
    }, 2000)
  }

  // funzione lanciata al close della modal delle email salvate
  const handleCloseReportEmails = () => {
    setOpenReportEmails(false)
  }

  // funzione che apre la modal per inviare il report
  const openSendPlantReport = (report) => {
    setReportToEdit(report)
    setOpenSendReport(true)
  }

  // funzione che invia i Report
  const sendEmails = async (emails) => {
    // mi preparo il body
    const body = {
      recipients: emails
    }

    const { error } = await api.post({ savedUrl: 'reports', path: `/${reportToEdit.uuid}/email/send`, body: body })

    if (error) {
      console.error('Error while updating self, err => ', error)
      return enqueueSnackbar('Errore durante la richiesta di invio. Per favore riprovare', { variant: 'error' })
    }

    enqueueSnackbar('Richiesta invio email ricevuta, saranno inviate a breve!', { variant: 'success' })
    // chiudo la modal
    setTimeout(() => {
      handleCloseSendEmails()
    }, 1600)
  }

  // funzione lanciata al close della modal dell'invio report
  const handleCloseSendEmails = () => {
    setOpenSendReport(false)
    setReportToEdit(null)
  }

  return (
    <div className={clsx(classes.root, className, classes.smallViewport)} {...rest}>
      <Box>
        <ReportTable
          hasGlass={hasGlass}
          reports={reports}
          // setRefreshReports={setRefreshReports}
          openEditReportsMail={openEditReportsMail}
          openNewReport={openNewReport}
          openEditReport={openNewReport}
          deleteReport={deleteReport}
          openSendReport={openSendPlantReport}
        />
      </Box>
      <ReportEmails
        open={openReportEmails}
        onClose={handleCloseReportEmails}
        emails={user.metadata.reportEmails}
        saveNewEmails={saveNewEmails}
      />
      <SendEmails
        open={openSendReport}
        onClose={handleCloseSendEmails}
        emails={user.metadata.reportEmails}
        sendEmails={sendEmails}
      />
      <PlantReportSidenav
        open={openReport}
        plantName={name}
        plantId={plantId}
        // periods={periods}
        reportToEdit={reportToEdit}
        onEdit={editReport}
        onSave={saveNewReport}
        onClose={handleCloseNewReport}
      />
    </div>
  )
}

export default memo(Report)
