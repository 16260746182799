import { useState, memo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Grid, Chip, makeStyles, CircularProgress, IconButton } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import format from 'date-fns/format'
import isSameDay from 'date-fns/isSameDay'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import isWithinInterval from 'date-fns/isWithinInterval'
import { parseISO } from 'date-fns'
import itLocale from 'date-fns/locale/it'
import { ChevronRight, ChevronLeft } from 'react-feather'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  chip: {
    minWidth: '100%',
    maxWidth: '100%'
  },
  dayWrapper: {
    position: 'relative'
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: 'inherit'
  },
  customDayHighlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '2px',
    right: '2px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%'
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled
  },
  highlightNonCurrentMonthDay: {
    color: '#676767'
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%'
  },
  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%'
  },
  label: {
    width: '100%',
    textAlign: 'center'
  }
}))

const allPeriods = [
  {
    label: 'Live',
    value: 'live'
  },
  {
    label: 'Settimana',
    value: 'week'
  },
  {
    label: 'Mese',
    value: 'month'
  },
  {
    label: 'Anno',
    value: 'year'
  }
]

function PeriodSelection ({ className, calendar = true, shortcuts = true, customPeriod, setCustomPeriod, selectedDate, setSelectedDate, isLoading, ...rest }) {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)

  // Funzione che fa il render della selezione settimanale sul calendario
  const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
    const dateClone = parseISO(JSON.parse(JSON.stringify(date)))
    const selectedDateClone = parseISO(JSON.parse(JSON.stringify(selectedDate)))

    const start = startOfWeek(selectedDateClone, { weekStartsOn: 1 })
    const end = endOfWeek(selectedDateClone, { weekStartsOn: 1 })
    const dayIsBetween = isWithinInterval(dateClone, { start, end })
    const isFirstDay = isSameDay(dateClone, start)
    const isLastDay = isSameDay(dateClone, end)

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay
    })

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween
    })

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, 'd')} </span>
        </IconButton>
      </div>
    )
  }

  // Opzioni del calendario in base al periodo
  const datepickerOptions = () => {
    if (customPeriod === 'year') {
      return ({ views: ['year'] })
    } else if (customPeriod === 'month') {
      return ({
        views: ['month']
      })
    } else if (customPeriod === 'week') {
      return ({
        renderDay: renderWrappedWeekDay
      })
    }
  }

  // Funzione che prende in ingresso una data in formato moment e ritorna una stringa contenente una data formattata a seconda del periodo da visualizzare
  const formatDateOptions = (date) => {
    const currentDate = parseISO(date.toISOString())
    if (customPeriod === 'live') {
      return format(currentDate, 'dd-MM-yyyy')
    } else if (customPeriod === 'week') {
      const start = startOfWeek(currentDate)
      const end = endOfWeek(currentDate)
      return `${format(start, 'd MMM', { locale: itLocale })} - ${format(end, 'd MMM', { locale: itLocale })}`
    } else if (customPeriod === 'month') {
      return format(currentDate, 'MMMM', { locale: itLocale })
    } else if (customPeriod === 'year') {
      return format(currentDate, 'yyyy', { locale: itLocale })
    }

    return format(currentDate, 'dd-MM-yyyy')
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {/* {isOpen && customPeriod !== 'year' && calendar */}
      {isOpen && calendar
        ? (
          <DatePicker
            open={isOpen}
            disableFuture
            {...datepickerOptions()}
            onClose={() => setIsOpen(false)}
            format='d MMM yyyy'
            value={selectedDate}
            TextFieldComponent={() => null}
            onChange={setSelectedDate}
          />
          )
        : null}
      <Grid container spacing={2}>
        {allPeriods.map((period, index) => {
          return (
            <Grid key={index} item xs={3}>
              <Chip
                classes={{ label: classes.label }}
                className={classes.chip}
                disabled={customPeriod === period.value && isLoading}
                label={customPeriod === period.value && isLoading
                  ? <CircularProgress size={15} color='secondary' />
                  : selectedDate && customPeriod === period.value
                    ? formatDateOptions(selectedDate)
                    : period.label}
                color={customPeriod === period.value ? 'primary' : 'default'}
                {...{
                  onDelete: shortcuts &&
                    customPeriod === period.value &&
                    !isLoading &&
                    // customPeriod !== 'year' &&
                    moment(selectedDate).isBefore(moment(), 'day') &&
                    ((customPeriod === 'week' && moment(selectedDate).startOf('week').isBefore(moment(), 'week')) ||
                    (customPeriod === 'month' && moment(selectedDate).startOf('month').isBefore(moment(), 'month')) ||
                    (customPeriod === 'year' && moment(selectedDate).startOf('year').isBefore(moment(), 'year')))
                    ? () => {
                      // console.log(customPeriod, moment(selectedDate).startOf('week'), moment(selectedDate).startOf('week').isBefore(moment(), 'week'))
                        if (selectedDate === null) {
                          if (customPeriod === 'live') {
                          // deve andare al precedente intervallo selezionato
                            setSelectedDate(moment().add(1, 'd'))
                          } else if (customPeriod === 'week') {
                          // aggiungo 7 giorni e prendo l'inizio della settimana
                            const newWeekStart = moment().add(7, 'd').startOf('week')
                            setSelectedDate(newWeekStart)
                          } else if (customPeriod === 'month') {
                          // prendo la fine del mese, ci aggiungo un giorno per entrare nel nuovo mese e prendo l'inizio del mese ottenuto
                            const newMonthStart = moment().endOf('month').add(1, 'd').startOf('month')
                            setSelectedDate(newMonthStart)
                          } else if (customPeriod === 'year') {
                            // prendo la fine dell'anno, ci aggiungo un giorno per entrare nel nuovo anno e prendo l'inizio dell'anno ottenuto
                            const newYearStart = moment().endOf('year').add(1, 'd').startOf('year')
                            setSelectedDate(newYearStart)
                          }
                        } else {
                          const prevDate = selectedDate
                          if (customPeriod === 'live') {
                          // deve andare al precedente intervallo selezionato
                            setSelectedDate(moment(prevDate).add(1, 'd'))
                          } else if (customPeriod === 'week') {
                          // aggiungo 7 giorni e prendo l'inizio della settimana
                            const newWeekStart = moment(prevDate).add(7, 'd').startOf('week')
                            setSelectedDate(newWeekStart)
                          } else if (customPeriod === 'month') {
                          // prendo la fine del mese della data selezionata, ci aggiungo un giorno per entrare nel nuovo mese e prendo l'inizio del mese ottenuto
                            const newMonthStart = moment(prevDate).endOf('month').add(1, 'd').startOf('month')
                            setSelectedDate(newMonthStart)
                          } else if (customPeriod === 'year') {
                            // prendo la fine dell'anno, ci aggiungo un giorno per entrare nel nuovo anno e prendo l'inizio dell'anno ottenuto
                            const newYearStart = moment(prevDate).endOf('year').add(1, 'd').startOf('year')
                            setSelectedDate(newYearStart)
                          }
                        }
                      }
                    : null,
                  deleteIcon: <ChevronRight />,
                  // icon: shortcuts && customPeriod === period.value && !isLoading && customPeriod !== 'year'
                  icon: shortcuts && customPeriod === period.value && !isLoading
                    ? (
                      <ChevronLeft
                        size={22}
                        onClick={(e) => {
                          // serve stoppare la propagazione dell'evento per impedire
                          // anche l'apertura del calendario invece che la chiamata alla funzione di cambio periodo
                          e.stopPropagation()
                          if (selectedDate === null) {
                            if (customPeriod === 'live') {
                              // deve andare al precedente intervallo selezionato
                              setSelectedDate(moment().subtract(1, 'd'))
                            } else if (customPeriod === 'week') {
                              // sottraggo 7 giorni e prendo l'inizio della settimana
                              const newWeekStart = moment().subtract(7, 'd').startOf('week')
                              setSelectedDate(newWeekStart)
                            } else if (customPeriod === 'month') {
                              // prendo la l'inizio del mese, ci sottraggo un giorno per entrare nel nuovo mese e prendo l'inizio del mese ottenuto
                              const newMonthStart = moment().startOf('month').subtract(1, 'd').startOf('month')
                              setSelectedDate(newMonthStart)
                            } else if (customPeriod === 'year') {
                              // prendo la l'inizio dell'anno, ci sottraggo un giorno per entrare nel nuovo anno e prendo l'inizio dell'anno ottenuto
                              const newYearStart = moment().startOf('year').subtract(1, 'd').startOf('year')
                              setSelectedDate(newYearStart)
                            }
                          } else {
                            const prevDate = selectedDate
                            if (customPeriod === 'live') {
                              // deve andare al precedente intervallo selezionato
                              setSelectedDate(moment(prevDate).subtract(1, 'd'))
                            } else if (customPeriod === 'week') {
                              // sottraggo 7 giorni e prendo l'inizio della settimana
                              const newWeekStart = moment(prevDate).subtract(7, 'd').startOf('week')
                              setSelectedDate(newWeekStart)
                            } else if (customPeriod === 'month') {
                              // prendo la fine del mese della data selezionata, ci sottraggo un giorno per entrare nel nuovo mese e prendo l'inizio del mese ottenuto
                              const newMonthStart = moment(prevDate).startOf('month').subtract(1, 'd').startOf('month')
                              setSelectedDate(newMonthStart)
                            } else if (customPeriod === 'year') {
                              // prendo la l'inizio dell'anno, ci sottraggo un giorno per entrare nel nuovo anno e prendo l'inizio dell'anno ottenuto
                              const newYearStart = moment(prevDate).startOf('year').subtract(1, 'd').startOf('year')
                              setSelectedDate(newYearStart)
                            }
                          }
                        }}
                      />)
                    : null
                }
                }
                onClick={() => {
                  if (calendar && customPeriod === period.value) {
                    setIsOpen(true)
                  } else {
                    setSelectedDate(null)
                    setCustomPeriod(period.value)
                  }
                }}
              />
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}
PeriodSelection.propTypes = {
  className: PropTypes.string,
  customPeriod: PropTypes.string,
  setCustomPeriod: PropTypes.func
}
PeriodSelection.defaultProps = {
  customPeriod: 'live'
}

export default memo(PeriodSelection)
