import { useCallback, useEffect, useState, memo } from 'react'
import clsx from 'clsx'
import { Box, Card, Grid, IconButton, LinearProgress, makeStyles, Typography, useMediaQuery, Tooltip } from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home'
import PeriodSelection from '@/components/PeriodSelection'
import InfoCard from '@/components/InfoCard'
import EnergyDeviation from '@/components/charts/EnergyDeviation'
import EnergyGraph from '@/components/charts/EnergyGraph'
// import EnergyRadiationGraph from '@/components/charts/EnergyRadiationGraph'
import { decodeCardsFromApi, decodePrDeviationGraphFromApi, decodeEnergyGraphFromApi /* decodeIrradiationGraphFromApi */, getDatesFromPeriod } from '../utils'
import api from '@micmnt/apis'
import { Skeleton } from '@material-ui/lab'
import moment from 'moment'
import ShareButton from '@/components/ShareButton'
import { createGuestHeaders } from '@/utils/createGuestHeaders'
import { useSelector } from '@/store'
import { useClientRect } from '@/hooks/useClientRect'
import DownloadExcelButton from '../../shared/DownloadExcelButton'
import useAuth from '@/hooks/useAuth'
import PrDeviation from '@/components/charts/PrDeviation'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  section: {
    padding: theme.spacing(2),
    width: '100%',
    height: '100%'
  },
  sectionTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  },
  topMargin: {
    marginTop: (theme.spacing(2) - 4)
  },
  smallViewport: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%'
  },
  glassBackground: {
    '@supports (backdrop-filter: none) or (-webkit-backdrop-filter: none)': {
      '-webkit-backdrop-filter': 'saturate(120%) blur(16px)',
      backdropFilter: 'saturate(190%) blur(16px)',
      backgroundColor: 'rgba(16, 26, 38, 0.05)'
    }
  }
}))

function Production ({ hasGlass = false, period = null, shared = false, guestToken = null, plantId: sharedPlantId = null, className, ...rest }) {
  const classes = useStyles()
  const { user } = useAuth()
  // Variabile che rappresenta il nome dell'impianto
  const { uuid: plantUuid, name: plantName, plantType } = useSelector(state => state.pvPlantView)
  // Variabile di stato che rappresenta lo stato di caricamento dei dati
  const [isLoading, setIsLoading] = useState(false)
  // periodo selezionato tramite le chips
  const [customPeriod, setCustomPeriod] = useState('live')
  // data/intervallo di tempo selezionato tramite il date picker
  // const [selectedDate, setSelectedDate] = useState(moment())
  const [selectedDate, setSelectedDate] = useState(null)
  // periodo impostato dallo zoom sul grafico
  const [zoomPeriod, setZoomPeriod] = useState('live')
  // data/data iniziale del periodo di selezione dello zoom
  const [zoomDate, setZoomDate] = useState(new Date().toISOString())
  // dati delle cards
  const [cardsSettings, setCardsSettings] = useState([])
  // dati del grafico di scostamento energia
  const [energyDeviationGraph, setEnergyDeviationGraph] = useState([])
  // dati del grafico di scostamento pr
  const [prDeviationGraph, setPrDeviationGraph] = useState([])
  // dati del grafico dell'energia
  const [energyGraph, setEnergyGraph] = useState({})
  // dati del grafico dell'irraggiamento
  // const [radiationGraph, setRadiationGraph] = useState({})

  const plantId = sharedPlantId || plantUuid

  // Ref per calcolare l'altezza dei grafici
  const [rect, ref] = useClientRect()

  useEffect(() => {
    if (period) {
      setCustomPeriod(period)
    }
  }, [period])

  // Funzione per fare la GET dei dati
  const getData = useCallback(async (timeFrom, timeTo) => {
    const customHeaders = createGuestHeaders(guestToken)
    if (plantId) {
      const { data: response, error } = await api.get({
        savedUrl: 'plantDetails',
        fullResponse: true,
        path: `/${plantId}/production?timeFrom=${timeFrom}&timeTo=${timeTo}`,
        customHeaders
      })

      if (error) {
        return null
      }
      const dataObj = response.data
      return dataObj
    }
  }, [plantId, guestToken])

  // Funzione che prepara i dati presi dalle API per essere passati alle rispettive funzioni di accoppiamento dei grafici
  const convertData = (dataObj) => {
    // Oggetto dei valori delle cards
    const cardsData = {
      totalExpectedEnergy: dataObj.totalExpectedEnergy || '',
      totalExportedEnergy: dataObj.totalExportedEnergy || '',
      totalProducedEnergy: dataObj.totalProducedEnergy || '',
      totalRadiation: dataObj.totalRadiation || ''
    }
    // Array di oggetti dei valori del grafico di scostamento dell'energia
    const energyDeviationData = [
      {
        label: 'E. Immessa',
        value: dataObj.totalExportedEnergy || ''
      },
      {
        label: 'E. Prodotta',
        value: dataObj.totalProducedEnergy || ''
      },
      {
        label: 'E. Attesa',
        value: dataObj.totalExpectedEnergy || ''
      }
    ]
    // Oggetto dei valori del grafico di scostamento della produzione/pr
    const prDeviationData = {
      realPr: dataObj.realPr,
      baselinePr: dataObj.baselinePr,
      realProduction: dataObj.realProduction,
      baselineProduction: dataObj.baselineProduction
    }
    // Oggetto dei valori del grafico dell'energia
    const energyGraphData = {
      expectedEnergy: JSON.parse(JSON.stringify(dataObj.expectedEnergy)),
      producedEnergy: JSON.parse(JSON.stringify(dataObj.producedEnergy)),
      exportedEnergy: JSON.parse(JSON.stringify(dataObj.exportedEnergy)),
      irradiation: dataObj.irradiation
    }
    // Oggetto dei valori del grafico dell'irraggiamento
    /* const radiationData = {
      expectedEnergy: JSON.parse(JSON.stringify(dataObj.expectedEnergy)),
      producedEnergy: JSON.parse(JSON.stringify(dataObj.producedEnergy)),
      irradiation: dataObj.irradiation
    } */

    return { cardsData, energyDeviationData, prDeviationData, energyGraphData /* radiationData */ }
  }

  // Funzione che aggiorna i dati del grafico di energia
  const updateEnergyGraph = async (timeFrom = new Date(), timeTo = new Date(), period) => {
    const periodOrder = {
      quarter: 0,
      live: 1,
      week: 2,
      month: 3,
      year: 4
    }
    if (periodOrder[period] > periodOrder[customPeriod]) {
      return
    }
    setIsLoading(true)
    const timeFromISOString = moment(timeFrom).toISOString()
    const timeToISOString = moment(timeTo).toISOString()
    const dataObj = await getData(timeFromISOString, timeToISOString)
    if (dataObj) {
      const { energyGraphData } = convertData(dataObj)
      // console.log('updateEnergyGraph: ', timeFrom, timeTo)
      setZoomDate(timeFromISOString)
      setZoomPeriod(period)
      setEnergyGraph(decodeEnergyGraphFromApi(energyGraphData, period))
    }
    setIsLoading(false)
  }

  // Funzione che resetta il periodo a quello della chip selezionata
  const resetPeriod = async (pagePeriod, chartPeriod) => {
    if (pagePeriod !== chartPeriod) {
      const periodDate = (selectedDate && selectedDate.toDate()) || new Date()
      const { minDate, maxDate } = getDatesFromPeriod(pagePeriod, periodDate)
      await updateEnergyGraph(minDate, maxDate, pagePeriod)
    }
  }

  // Funzione che calcola il periodo da richiedere alle api
  const buildPeriodToFetch = async (min, max, data, period) => {
    // console.log('zoomPeriod: min/max', min, max)
    // console.log('zoomPeriod: ', period)
    if (!min && !max) {
      return
    }
    // calcolo la soglia di rimodulazione dell'asse x
    const recalculateThreshold = max - min
    // controllo che gli estremi superino o meno la soglia per la quale si vuole ricalcolare la x
    if (period === 'live' && recalculateThreshold < 6) {
      const labels = data.labels || []
      if (labels.length > min) {
        // console.log('zoomPeriod - zoomDate: ', zoomDate)
        // Faccio questa conversione per le ore UTC
        const startTime = Number(labels[min].split(':')[0]) - 1
        const endTime = Number(labels[max].split(':')[0]) - 1
        // La data viene convertita in local time per avere il giorno corretto durante la richiesta dei valori per il grafico
        const localZoomDate = moment(zoomDate).toISOString(true)
        // console.log('zoomPeriod - localZoomDate: ', localZoomDate)

        const dateStart = `${localZoomDate.split('T')[0]}T${startTime < 10 ? `0${startTime}` : startTime}:00`
        const dateEnd = `${localZoomDate.split('T')[0]}T${endTime < 10 ? `0${endTime}` : endTime}:00`

        await updateEnergyGraph(dateStart, dateEnd, 'quarter')
      }
      // console.log('updateEnergyGraph - quarters: ', zoomDate)
      // TODO: fetch DATA
    } else if (period === 'week' && recalculateThreshold < 2) {
      const labels = data.labels || []
      if (labels.length > min) {
        // Prendo min perchè anche se il giorno da visualizzare sarebbe l'ultimo, le labels sono in base 0
        const startLabel = `20${labels[min].split('/').reverse().join('-')}`
        const dateStart = moment(startLabel).format('YYYY-MM-DD')
        const dateEnd = moment(dateStart).add(1, 'day').format('YYYY-MM-DD')
        // Ricalcolo i dati per il grafico
        await updateEnergyGraph(dateStart, dateEnd, 'live')
      }
    } else if (period === 'month' && recalculateThreshold < 2) {
      const labels = data.labels || []
      if (labels.length > min) {
        // Prendo min perchè anche se il giorno da visualizzare sarebbe l'ultimo, le labels sono in base 0
        const startLabel = `20${labels[min].split('/').reverse().join('-')}`
        const dateStart = moment(startLabel).format('YYYY-MM-DD')
        const dateEnd = moment(dateStart).add(1, 'day').format('YYYY-MM-DD')
        // Ricalcolo i dati per il grafico
        await updateEnergyGraph(dateStart, dateEnd, 'live')
      }
    } else if (period === 'year' && recalculateThreshold < 2) {
      // Prendo min perchè anche se il mese da visualizzare sarebbe l'ultimo, i mesi in moment sono in base 0
      const dateStart = moment().set('month', min).set('date', 1).format('YYYY-MM-DD')
      const dateEnd = moment(dateStart).add(1, 'month').format('YYYY-MM-DD')
      // Ricalcolo i dati per il grafico
      await updateEnergyGraph(dateStart, dateEnd, 'month')
    } else if (period === 'quarter') {
      const labels = data.labels || []
      // console.log('zoooom', labels.length, recalculateThreshold)
      if (labels.length === max) {
        const dateEnd = moment(zoomDate).add(1, 'day')
        await updateEnergyGraph(zoomDate, dateEnd, 'live')
      }
    } else if (period === 'live') {
      const labels = data.labels || []
      if (labels.length === max) {
        const { minDate, maxDate } = getDatesFromPeriod('week', zoomDate)
        await updateEnergyGraph(minDate, maxDate, 'week')
      }
    } else if (period === 'week') {
      const labels = data.labels || []
      // console.log('zoooom', labels.length, recalculateThreshold)
      if (labels.length === max) {
        const { minDate, maxDate } = getDatesFromPeriod('month', zoomDate)
        await updateEnergyGraph(minDate, maxDate, 'month')
      }
    } else if (period === 'month') {
      const labels = data.labels || []
      // console.log('zoooom', labels.length, recalculateThreshold)
      if (labels.length === max) {
        const { minDate, maxDate } = getDatesFromPeriod('year', zoomDate)
        await updateEnergyGraph(minDate, maxDate, 'year')
      }
    }

    // console.log('beforeZoom - threshold: ', recalculateThreshold)
    /* console.log('beforeZoom - chartContext: ', chartContext)
    console.log('beforeZoom - xaxis: ', min, max)
    console.log('beforeZoom - currentPeriod: ', customPeriod) */
  }

  // Parametri da passare al menu di condivisione
  const params = [{ name: 'plantId', value: plantId }, { name: 'plantName', value: plantName }, { name: 'plantType', value: plantType }]

  useEffect(() => {
    // Funzione che fa l'inizializzazione della view, settando i dati che i grafici consumeranno
    async function initialiseView (timeFrom, timeTo, customPeriod) {
      setIsLoading(true)
      const dataObj = await getData(timeFrom, timeTo)
      if (dataObj) {
        const { cardsData, energyGraphData, energyDeviationData, prDeviationData } = convertData(dataObj)
        // setRadiationGraph(decodeIrradiationGraphFromApi(radiationData, customPeriod))
        setCardsSettings(decodeCardsFromApi(cardsData))
        setEnergyGraph(decodeEnergyGraphFromApi(energyGraphData, customPeriod))
        setEnergyDeviationGraph(energyDeviationData)
        setPrDeviationGraph(decodePrDeviationGraphFromApi(prDeviationData))
      }
      setIsLoading(false)
    }
    const periodDate = (selectedDate && selectedDate.toDate()) || new Date()
    // Prendo le date minima e massima da passare alle api
    const { minDate, maxDate } = getDatesFromPeriod(customPeriod, periodDate)
    // console.log(customPeriod, zoomPeriod)
    if (!shared) {
      if (customPeriod === 'live') {
        if (zoomPeriod === 'live') {
          // Prima GET
          initialiseView(minDate, maxDate, customPeriod)
          // Attivazione del polling
          const pollingInterval = setInterval(() => {
            initialiseView(minDate, maxDate, customPeriod)
          }, 60000)
          return () => {
            clearInterval(pollingInterval)
          }
        }
      } else {
        if (customPeriod === zoomPeriod) {
          initialiseView(minDate, maxDate, customPeriod)
        }
      }
    } else {
      initialiseView(minDate, maxDate, customPeriod)
    }
  }, [customPeriod, plantId, getData, selectedDate, zoomPeriod, shared])

  const isExtraSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
  // const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const isLarge = useMediaQuery(theme => theme.breakpoints.down('lg'))

  // console.log(customPeriod)

  const graphHeight = isLarge ? rect.height - 270 : rect ? rect.height - 250 : '100%'

  return (
    <div
      className={
        isLarge
          ? clsx(classes.root, className, classes.smallViewport)
          : clsx(classes.root, className)
      }
      {...rest}
    >
      <Box mt={2}>
        <Grid width='100%' container spacing={1}>
          {shared
            ? null
            : (
              <Grid style={{ order: 1 }} item xs={12} sm={10} md={10} xl={3}>
                <PeriodSelection
                  isLoading={isLoading}
                  selectedDate={selectedDate}
                  setSelectedDate={(date) => {
                    setZoomPeriod(customPeriod)
                    setSelectedDate(date)
                  }}
                  customPeriod={customPeriod}
                  setCustomPeriod={(period) => {
                    setCustomPeriod(period)
                    setZoomPeriod(period)
                    setZoomDate(new Date().toISOString())
                  }}
                />
              </Grid>)}
          <Grid style={{ order: isLarge ? 3 : 2 }} item xs={12} md={12} xl={shared ? 12 : 8}>
            <Box width='100%'>
              <Grid container spacing={1}>
                {
                  cardsSettings.length > 0
                    ? cardsSettings.map((card, index) => (
                      <Grid key={`card-${index}`} item xs={12} md={6} lg={3}>
                        <InfoCard elevation={hasGlass ? 0 : 1} className={hasGlass ? classes.glassBackground : null} icon={card.icon} values={card.values} />
                      </Grid>))
                    : (
                      <>
                        <Grid item xs={12} md={6} lg={3}>
                          <Skeleton variant='rect' height={60} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Skeleton variant='rect' height={60} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Skeleton variant='rect' height={60} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Skeleton variant='rect' height={60} />
                        </Grid>
                      </>)
                }
              </Grid>
            </Box>
          </Grid>
          {shared
            ? null
            : !isExtraSmall && (
              <Grid style={{ order: isLarge ? 2 : 3 }} item xs={2} sm={2} md={2} xl={1}>
                <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='flex-end'>
                  <Box mr={1}>
                    <DownloadExcelButton
                      selectedDate={selectedDate}
                      period={customPeriod}
                      plantId={plantId}
                      plantName={plantName}
                      exportType='production'
                      description='Scarica i dati di produzione del periodo che stai visualizzando in un file Excel'
                    />
                  </Box>
                  <ShareButton userEmail={user ? user.email : null} userId={user ? user.uuid : null} path='production' params={params} period={customPeriod} />
                </Box>
              </Grid>)}
        </Grid>
      </Box>
      <Box ref={ref} width='100%' height='90%' mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={8} xl={9}>
            <Card elevation={hasGlass ? 0 : 1} className={hasGlass ? clsx(classes.section, classes.glassBackground) : classes.section}>
              {isLoading
                ? (
                  <Box mt={-2} ml={-2}>
                    <LinearProgress color='primary' />
                  </Box>)
                : null}
              {shared
                ? <Typography className={isLoading ? clsx(classes.topMargin, classes.sectionTitle) : classes.sectionTitle} variant='h6'>Energia</Typography>
                : (
                  <Box className={isLoading ? classes.topMargin : null} display='flex' justifyContent='space-between' alignItems='center'>
                    <Typography className={classes.sectionTitle} variant='h6'>Energia</Typography>
                    <Tooltip title='Resetta periodo grafico'>
                      <IconButton onClick={() => resetPeriod(customPeriod, zoomPeriod)} size='small'>
                        <HomeIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>)}
              <Box width='100%' display='flex' alignItems='center' justifyContent='center'>
                <EnergyGraph
                  hasGlass={hasGlass}
                  height={graphHeight < 300 ? 300 + 120 : graphHeight + 120}
                  canZoom={!shared}
                  showToolbar={!shared}
                  isLoading={isLoading}
                  // resetPeriod={() => resetPeriod(customPeriod, zoomPeriod)}
                  recalculatePeriod={(min, max, data) => buildPeriodToFetch(min, max, data, zoomPeriod)}
                  hasIrradiation
                  data={energyGraph}
                />
              </Box>
              {/* <Box width='100%' display='flex' alignItems='center' justifyContent='center'>
                <EnergyRadiationGraph
                  height={graphHeight * 0.5 < 300 ? 300 : graphHeight * 0.5}
                  data={radiationGraph}
                />
              </Box> */}
            </Card>
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <Card elevation={hasGlass ? 0 : 1} className={hasGlass ? clsx(classes.section, classes.glassBackground) : classes.section}>
              <Typography className={classes.sectionTitle} variant='h6'>Scostamento</Typography>
              <Box width='100%' display='flex' alignItems='center' justifyContent='center'>
                <EnergyDeviation hasGlass={hasGlass} width={graphHeight * 0.5 < 250 ? 250 : graphHeight * 0.5} height={graphHeight * 0.5 < 250 ? 250 : graphHeight * 0.5} data={energyDeviationGraph} />
                {/* <EnergyDeviation width={graphHeight * 0.5} height={graphHeight * 0.5} data={energyDeviationGraph} /> */}
              </Box>
              <Box width='100%' display='flex' alignItems='center' justifyContent='center'>
                <PrDeviation hasGlass={hasGlass} width={graphHeight * 0.5 < 250 ? 250 : graphHeight * 0.5} height={graphHeight * 0.5 < 250 ? 250 : graphHeight * 0.5} data={prDeviationGraph} />
                {/* <PrDeviation width={graphHeight * 0.5} height={graphHeight * 0.5} data={prDeviationGraph} /> */}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}
export default memo(Production)
