import { memo } from 'react'
import { Router } from 'react-router-dom'
import api from '@micmnt/apis'
import { createBrowserHistory } from 'history'
import { create } from 'jss'
import rtl from 'jss-rtl'
import MomentUtils from '@date-io/moment'
import { SnackbarProvider } from 'notistack'
import { jssPreset, StylesProvider, ThemeProvider, Slide, CssBaseline } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import GlobalStyles from '@components/GlobalStyles'
import ScrollReset from '@components/ScrollReset'
import ErrorBoundary from '@components/ErrorBoundary'
import { AuthProvider } from '@contexts/JWTAuthContext'
import useSettings from '@hooks/useSettings'
import { createDashboardTheme } from '@theme'
import routes, { renderRoutes } from '@/routes'
import 'moment/locale/it'
import { ChartsProvider } from '@contexts/ChartsContext'
import { configuration } from './config'
import apiConfig from './api/config'

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
const history = createBrowserHistory()

// Inizializzazione del servizio di chiamate API
api.init({
  baseUrl: configuration.apiUrl,
  savedUrls: apiConfig,
  jwtTokenName: 'accessToken',
  errorInterceptor: (error) => {
    const errorStatus = error?.response?.data?.error?.statusCode || null
    const errorMessage = error?.response?.data?.error?.message || null
    const isTokenError = errorMessage?.toLowerCase()?.includes('token')
    if (errorStatus === 401 && errorMessage && isTokenError) {
      window.localStorage.removeItem('accessToken')
      // window.location.replace(`${configuration.dashboardUrl}/login`)
      window.location.replace(`${window.location.origin}/login`)
    }
  }
})

const App = () => {
  const { settings } = useSettings()

  // creo il tema
  const theme = createDashboardTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  })

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={MomentUtils} locale='it'>
          <SnackbarProvider
            maxSnack={5}
            TransitionComponent={Slide}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            autoHideDuration={4000}
          // preventDuplicate
          >
            <ErrorBoundary>
              <Router history={history}>
                <ChartsProvider>
                  <AuthProvider>
                    <GlobalStyles />
                    <ScrollReset />
                    {renderRoutes(routes)}
                  </AuthProvider>
                </ChartsProvider>
              </Router>
            </ErrorBoundary>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  )
}

export default memo(App)
