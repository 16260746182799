export const libraryDevices = [
  {
    models: ['sun2000-36-ktl', 'sun2000-20-ktl', 'sun2000-60-ktl', 'trifase', 'sun2000-10-ktl', 'sun2000-12-ktl', 'sun2000-17-ktl', 'sun2000-70-ktl', 'sun2000-100-ktl', 'sun2000-50-ktl', 'sun2000-75-ktl', 'sun2000-63-ktl', 'sun2000-90-ktl', 'sun2000-15-ktl', 'sun2000-65-ktl', 'sun2000-95-ktl'],
    status: {},
    energy: 'Energia',
    voltage: 'Tensione',
    current: 'Corrente',
    power: 'Potenza'
    /* vac1: 'Tensione',
    iac1: 'Corrente',
    activepowertot: 'Potenza',
    totalenergy: 'Energia' */
  },
  {
    models: ['iskra-mt880', 'E650', 'LZQJ-XC'],
    status: {},
    impenergy: 'E. prelevata',
    expenergy: 'E. immessa'
    // rawimpenergy: 'rawimpenergy',
    // rawexpenergy: 'rawexpenergy'
  },
  {
    models: ['s502'],
    status: {},
    voltage: 'Tensione',
    current: 'Corrente',
    activepowertot: 'Potenza',
    impenergy: 'E. prelevata',
    expenergy: 'E. immessa'
  },
  {
    models: ['s504c', 's604e', 's534', 'dmg800', 'mid-xmc3f-80'],
    voltagel1: 'Tensione L1',
    voltagel2: 'Tensione L2',
    voltagel3: 'Tensione L3',
    currentl1: 'Corrente L1',
    currentl2: 'Corrente L2',
    currentl3: 'Corrente L3',
    activepowertot: 'Potenza',
    impenergy: 'E. prelevata',
    expenergy: 'E. immessa',
    status: {}
  },
  {
    models: ['sunmeter'],
    irradiation: 'irraggiamento',
    temperature: 'temperatura',
    status: {}
  },
  {
    models: ['virtual'],
    irradiation_avg_quarter_kwmq: 'Radiazione (kWh/mq)',
    status: {}
  },
  {
    models: ['nosim6'],
    adc1: 'ADC 1',
    adc2: 'ADC 2',
    sensor1: 'Sensore 1',
    sensor2: 'Sensore 2',
    quotaslm1: 'Quota slm 1',
    quotaslm2: 'Quota slm 2',
    fondoscala1: 'Fondoscala 1',
    fondoscala2: 'Fondoscala 2',
    distsicurezza1: 'Distanza di sicurezza 1',
    distsicurezza2: 'Distanza di sicurezza 2',
    distmisurata1: 'Distanza misurata 1',
    distmisurata2: 'Distanza misurata 2',
    quotalibera1: 'Quota libera 1',
    quotalibera2: 'Quota libera 2',
    status: {}
  },
  {
    models: ['RittmeyerFlow'],
    portata: 'Portata Q',
    vol_avanti: 'Volume in avanti',
    v_raw: 'Velocità',
    t_raw: 'Temperatura',
    status: {}
  },
  {
    models: ['NemoD4Le'],
    current: 'Corrente',
    current1: 'Corrente L1',
    current2: 'Corrente L2',
    current3: 'Corrente L3',
    reactivePower: 'Potenza Reattiva Totale',
    reactivePower1: 'Potenza Reattiva 1',
    reactivePower2: 'Potenza Reattiva 2',
    reactivePower3: 'Potenza Reattiva 3',
    voltage: 'Tensione',
    voltage1: 'Tensione L1-N',
    voltage2: 'Tensione L2-N',
    voltage3: 'Tensione L3-N',
    cosphi1: 'Fattore di potenza L1',
    cosphi2: 'Fattore di potenza L2',
    cosphi3: 'Fattore di potenza L3',
    expActiveEnergy: 'Exp Energy',
    activePower: 'Potenza Attiva Totale',
    activePower1: 'Potenza Attiva L1',
    activePower2: 'Potenza Attiva L2',
    activePower3: 'Potenza Attiva L3',
    voltage12: 'Tensione L1-L2',
    voltage23: 'Tensione L2-L3',
    voltage31: 'Tensione L3-L1',
    status: {}
  }
]
