import ContentTooltip from '../shared/ContentTooltip'
import InfoboxContent from '../shared/InfoboxContent'
import MeterContent from '../shared/MeterContent'
import TextContent from '../shared/TextContent'
import LightPointContent from './LightPointContent'
import ElectricLineContent from './ElectricLineContent'
import PlainContent from './PlainContent'

export const libraryObjects = {
  energymeter: {
    img: '/static/images/energy.svg',
    name: 'Meter',
    notes: "Meter dell'impianto",
    draw: {
      type: 'group',
      left: 0,
      top: 0,
      objects: [
        {
          type: 'circle',
          radius: 30,
          fill: '#fff',
          stroke: '#000',
          originX: 'center',
          originY: 'center'
        },
        {
          type: 'text',
          text: 'M',
          left: 0,
          top: 0,
          originX: 'center',
          originY: 'center'
        }
      ]
    },
    modal: (props) => <MeterContent {...props} />,
    tooltip: (props) => <ContentTooltip {...props} />,
    onReceive: (canvasObj, config) => {
      const newObj = canvasObj
      let generalStatus = ''

      for (let s = 0; s < config.devices.length; s++) {
        if (
          config.devices[s].properties &&
          config.devices[s].properties.status &&
          config.devices[s].properties.status.value &&
          config.devices[s].properties.status.value.code
        ) {
          generalStatus = config.devices[s].properties.status.value.code
        }
      }
      if (newObj.objects && newObj.objects.length > 0) {
        if (generalStatus === 'ok') {
          // newObj.objects[0].fill = 'white'
          newObj.objects[0].fill = (config.data && config.data.draw && config.data.draw.backgroundColor) || 'white'
        } else if (generalStatus === 'anomaly_comunication' || generalStatus === 'maintenance') {
          newObj.objects[0].fill = 'yellow'
        } else if (generalStatus === 'anomaly_energy') {
          newObj.objects[0].fill = 'orange'
        } else if (generalStatus === 'anomaly_component') {
          newObj.objects[0].fill = 'red'
        }
      }

      return newObj
    },
    event: {
      mouseDown: () => { },
      mouseOver: (config) => {
        const infos = []
        for (let s = 0; s < config.devices.length; s++) {
          for (const n in config.devices[s].properties) {
            if (n !== 'status') {
              const label = String(config.devices[s].properties[n].label)
              const value = String(config.devices[s].properties[n].value)
              infos.push({ label, value })
            }
          }
        }

        return infos
      }
    }

  },
  text: {
    img: '/static/images/text.svg',
    name: 'Testo',
    notes: 'Oggetto di testo per scrivere liberamente',
    draw: {
      type: 'text',
      text: 'Testo',
      backgroundColor: '#fff',
      left: 0,
      top: 0
    },
    modal: (props) => <TextContent {...props} />,
    // onReceive: (canvasObj, currentValues) => (objModified),
    onReceive: (thisDraw, config) => {
      // console.log(thisDraw, config)
      if (config.data && config.data.currentText) {
        thisDraw.text = config.data.currentText
      }
      return thisDraw
    },
    event: {
      onLoad: (thisDraw, config) => {
        // console.log(thisDraw, config)
        if (config.data && config.data.currentText) {
          thisDraw.text = config.data.currentText
        }
        return thisDraw
      },
      mouseDown: () => { },
      mouseOver: () => { }
    }
  },
  infobox: {
    img: '/static/images/text.svg',
    name: 'Infobox',
    notes: "Oggetto di testo per rappresentare valori sugli altri elementi dell'impianto",
    draw: {
      type: 'group',
      left: 0,
      top: 0,
      objects: []
    },
    modal: (props) => <InfoboxContent {...props} />,
    // onReceive: (canvasObj, currentValues) => (objModified),
    onReceive: (thisDraw, config) => {
      const backgroundColor = config.data && config.data.draw && config.data.draw.backgroundColor
      thisDraw.objects = []
      // console.log('infobox - onReceive: ', thisDraw)
      // console.log('infobox - onReceive config: ', config)
      for (let s = 0; s < config.devices.length; s++) {
        let index = 1
        const background = {
          type: 'rect',
          width: 100,
          height: Object.keys(config.devices[s].properties).filter(el => el !== 'status').length * 10,
          fill: backgroundColor || 'white',
          stroke: backgroundColor || 'white',
          top: -5,
          left: 10,
          originX: 'left',
          originY: 'top'
        }
        thisDraw.objects.push(background)
        for (const n in config.devices[s].properties) {
          if (n !== 'status') {
            const label = {
              type: 'text',
              text: String(config.devices[s].properties[n].label),
              left: 10,
              top: (index - 1) * 10,
              originX: 'left',
              originY: 'center',
              fontSize: 8,
              textAlign: 'left'
            }
            thisDraw.objects.push(label)
            const value = {
              type: 'text',
              text: String(config.devices[s].properties[n].value),
              left: 100,
              top: (index - 1) * 10,
              originX: 'right',
              originY: 'center',
              fontSize: 8,
              textAlign: 'right'
            }
            thisDraw.objects.push(value)
            index++
          }
        }
      }

      return thisDraw
    },
    event: {
      onLoad: (thisDraw, config) => {
        thisDraw.objects = []
        // console.log('onLoad: ', thisDraw)
        // console.log('config: ', config)
        for (let s = 0; s < config.devices.length; s++) {
          let index = 1
          const background = {
            type: 'rect',
            width: 100,
            height: Object.keys(config.devices[s].properties).filter(el => el !== 'status').length * 10,
            fill: 'white',
            stroke: 'white',
            top: -5,
            left: 10,
            originX: 'left',
            originY: 'top'
          }
          thisDraw.objects.push(background)
          for (const n in config.devices[s].properties) {
            if (n !== 'status') {
              const label = {
                type: 'text',
                text: String(config.devices[s].properties[n].label),
                left: 10,
                top: (index - 1) * 10,
                originX: 'left',
                originY: 'center',
                fontSize: 8,
                textAlign: 'left'
              }
              thisDraw.objects.push(label)
              const value = {
                type: 'text',
                text: String(config.devices[s].properties[n].value),
                left: 100,
                top: (index - 1) * 10,
                originX: 'right',
                originY: 'center',
                fontSize: 8,
                textAlign: 'right'
              }
              thisDraw.objects.push(value)
              index++
            }
          }
        }

        return thisDraw
      },
      mouseDown: () => {
        console.log('onMouseDown')
      },
      mouseOver: () => {
        console.log('onMouseOver')
      }
    }
  },
  plain: {
    img: '/static/images/floor.svg',
    name: 'Piano',
    notes: 'Rappresentazione del piano',
    draw: {
      type: 'group',
      left: 0,
      top: 0,
      objects: [
        {
          type: 'text',
          text: 'Piano 1',
          fontSize: 12
        },
        {
          type: 'rect',
          width: 500,
          height: 160,
          top: 14,
          fill: 'transparent',
          stroke: 'blue',
          originX: 'left',
          originY: 'top'
        }
      ]
    },
    modal: (props) => <PlainContent {...props} />,
    onReceive: () => { },
    event: {
      onLoad: (thisDraw, config) => {
        thisDraw._objects && thisDraw._objects.forEach(obj => {
          if (Object.prototype.hasOwnProperty.call(obj, 'stroke') && obj.type === 'rect') {
            if (config.data && config.data.draw && config.data.draw.borderColor) {
              obj.stroke = config.data.draw.borderColor
            }
          }
        })
        return thisDraw
      },
      mouseDown: () => { },
      mouseOver: () => { }
    }
  },
  lightPoint: {
    img: '/static/images/light.svg',
    name: 'Linea Luce',
    notes: 'Gruppo di LED',
    draw: {
      type: 'group',
      left: 0,
      top: 0,
      objects: [
        {
          type: 'text',
          text: 'Linea Luce',
          fontSize: 12
        },
        {
          type: 'rect',
          width: 100,
          height: 100,
          top: 14,
          fill: 'blue',
          originX: 'left',
          originY: 'top'
        },
        {
          type: 'text',
          text: '0 W',
          fontSize: 16,
          top: 16,
          left: 4
        },
        {
          type: 'text',
          text: 'P. Luce: 0',
          fontSize: 14,
          top: 32,
          left: 4
        }
      ]
    },
    modal: (props) => <LightPointContent {...props} />,
    onReceive: () => { },
    event: {
      onLoad: (thisDraw, config) => {
        thisDraw._objects && thisDraw._objects.forEach((obj, index) => {
          if (index === 0 && Object.prototype.hasOwnProperty.call(obj, 'text')) {
            if (config.data && config.data.line && config.data.line.name) {
              obj.set('text', config.data.line.name)
            }
          }
          if (index === 2 && Object.prototype.hasOwnProperty.call(obj, 'text')) {
            if (config.data && config.data.power) {
              obj.set('text', `${config.data.power} W`)
            }
          }
          if (index === 3 && Object.prototype.hasOwnProperty.call(obj, 'text')) {
            if (config.data && config.data.lightsNum) {
              obj.set('text', `P.Luce: ${config.data.lightsNum}`)
            }
          }
          if (Object.prototype.hasOwnProperty.call(obj, 'fill') && obj.type === 'rect') {
            if (config.data && config.data.draw && config.data.draw.backgroundColor) {
              obj.fill = config.data.draw.backgroundColor
            }
          }
        })
        return thisDraw
      },
      mouseDown: () => { },
      mouseOver: () => { }
    }
  },
  electricLine: {
    img: '/static/images/electric.svg',
    name: 'Linea Elettrica',
    notes: 'Linea Elettrica',
    draw: {
      type: 'group',
      left: 0,
      top: 0,
      objects: [
        {
          type: 'text',
          text: 'Linea Elettrica',
          fontSize: 12
        },
        {
          type: 'rect',
          width: 100,
          height: 100,
          top: 14,
          fill: 'blue',
          originX: 'left',
          originY: 'top'
        },
        {
          type: 'text',
          text: '0 W',
          fontSize: 16,
          top: 16,
          left: 4
        },
        {
          type: 'text',
          text: 'P. Luce: 0',
          fontSize: 14,
          top: 32,
          left: 4
        }
      ]
    },
    modal: (props) => <ElectricLineContent {...props} />,
    onReceive: () => { },
    event: {
      onLoad: (thisDraw, config) => {
        thisDraw._objects && thisDraw._objects.forEach((obj, index) => {
          if (index === 0 && Object.prototype.hasOwnProperty.call(obj, 'text')) {
            if (config.data && config.data.line && config.data.line.name) {
              obj.set('text', config.data.line.name)
            }
          }
          if (index === 2 && Object.prototype.hasOwnProperty.call(obj, 'text')) {
            if (config.data && config.data.power) {
              obj.set('text', `${config.data.power} W`)
            }
          }
          if (index === 3 && Object.prototype.hasOwnProperty.call(obj, 'text')) {
            if (config.data && config.data.lightsNum) {
              obj.set('text', `P.Luce: ${config.data.lightsNum}`)
            }
          }
          if (Object.prototype.hasOwnProperty.call(obj, 'fill') && obj.type === 'rect') {
            if (config.data && config.data.draw && config.data.draw.backgroundColor) {
              obj.fill = config.data.draw.backgroundColor
            }
          }
        })
        return thisDraw
      },
      mouseDown: () => { },
      mouseOver: () => { }
    }
  }
}
