import clsx from 'clsx'
import { Box, Drawer, IconButton, makeStyles, Typography } from '@material-ui/core'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { it } from './languages/it'

import {
  X as CloseIcon
} from 'react-feather'
import { basicColors } from '@/theme'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  drawer: {
    width: '80%',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  pdfViewer: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    fontFamily: 'Roboto',
    height: 'calc(100% - 64px)'
  },
  header: {
    height: 64,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.background.default,
    textTransform: 'uppercase'
  }
}))

function PDFViewerDrawer ({ file = null, title = 'Documento', open, onClose, className, ...rest }) {
  const classes = useStyles()

  // Toolbar custom per il viewer del pdf
  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {
        (slots) => {
          const {
            CurrentPageInput, Download, EnterFullScreen, GoToNextPage, GoToPreviousPage,
            NumberOfPages, Print, ShowSearchPopover, Zoom, ZoomIn,
            ZoomOut
          } = slots
          return (
            <div
              style={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <div style={{ padding: '0px 2px' }}>
                <ShowSearchPopover />
              </div>
              <div style={{ padding: '0px 2px' }}>
                <GoToPreviousPage />
              </div>
              <div style={{ padding: '0px 2px' }}>
                <CurrentPageInput /> / <NumberOfPages />
              </div>
              <div style={{ padding: '0px 2px' }}>
                <GoToNextPage />
              </div>
              <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                <ZoomOut />
              </div>
              <div style={{ padding: '0px 2px' }}>
                <Zoom />
              </div>
              <div style={{ padding: '0px 2px' }}>
                <ZoomIn />
              </div>

              <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                <EnterFullScreen />
              </div>
              <div style={{ padding: '0px 2px' }}>
                <Download />
              </div>
              <div style={{ padding: '0px 2px' }}>
                <Print />
              </div>
            </div>
          )
        }
      }
    </Toolbar>
  )

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: defaultTabs => [
      defaultTabs[0],
      defaultTabs[1]
    ],
    renderToolbar

  })

  return (
    <Drawer
      {...rest}
      className={clsx(classes.root, className)}
      anchor='right'
      classes={{ paper: classes.drawer }}
      variant='temporary'
      open={open}
      onClose={onClose}
    >
      {file && title
        ? (
          <>
            <Box px={2} className={classes.header} width='100%' display='flex' alignItems='center' justifyContent='space-between'>
              <Typography component='div' variant='h5'>{title}</Typography>
              <IconButton onClick={onClose} fontSize='small'>
                <CloseIcon color={basicColors.whiteText} />
              </IconButton>
            </Box>
            <Box width='100%' className={classes.pdfViewer}>
              <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.7.570/build/pdf.worker.min.js'>
                <Viewer
                  localization={it}
                  fileUrl={file}
                  plugins={[
                    defaultLayoutPluginInstance
                  ]}
                />
              </Worker>
            </Box>
          </>
          )
        : null}
    </Drawer>
  )
}
export default PDFViewerDrawer
