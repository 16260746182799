import mock from '@/utils/___mock'

// Dati Mock Analisi di Stringa
const stringAnalysisData = {
  stringAnalysis: [{
    cc: {
      data: [0, 7123.7, 6996.8, 6984.3, 6872.9, 6572.0],
      labels: ['', '1.2', '1.5', '1.3', '2.5', '1.1'],
      min: 5900,
      middle: 6910
    },
    energy: {
      data: [null, 35.3, 34.7, 34.3, 33.5, 33.2],
      middle: 34.2
    }
  },
  {
    cc: {
      data: [0, 8362.7, 3964.8, 5034.3, 7129.9, 6572.0],
      labels: ['', '1.5', '1.8', '2.4', '2.8', '3.1'],
      min: 6400,
      middle: 7130
    },
    energy: {
      data: [null, 45.3, 23.7, 56.3, 55.5, 42.2],
      middle: 41.5
    }
  },
  {
    cc: {
      data: [0, 8362.7, 3964.8, 5034.3, 7129.9, 6572.0],
      labels: ['', '1.5', '1.8', '2.4', '2.8', '3.1'],
      min: 6400,
      middle: 7130
    },
    energy: {
      data: [null, 45.3, 23.7, 56.3, 55.5, 42.2],
      middle: 41.5
    }
  }]
}
// Dati Mock Andamento
const trendData = {
  trendGraph: [{
    expectedEnergy: [
      {
        label: '2021-02-23T23:00:00.000Z',
        value: 0.03714681440443214
      },
      {
        label: '2021-02-24T00:00:00.000Z',
        value: 0.049529085872576185
      },
      {
        label: '2021-02-24T01:00:00.000Z',
        value: 0.049529085872576185
      },
      {
        label: '2021-02-24T02:00:00.000Z',
        value: 0.049529085872576185
      },
      {
        label: '2021-02-24T03:00:00.000Z',
        value: 0.049529085872576185
      },
      {
        label: '2021-02-24T04:00:00.000Z',
        value: 0.049529085872576185
      },
      {
        label: '2021-02-24T05:00:00.000Z',
        value: 0.11753331048632558
      },
      {
        label: '2021-02-24T06:00:00.000Z',
        value: 3.026277242910162
      },
      {
        label: '2021-02-24T07:00:00.000Z',
        value: 4.127939768409621
      },
      {
        label: '2021-02-24T08:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T09:00:00.000Z',
        value: 7.092977840907741
      },
      {
        label: '2021-02-24T10:00:00.000Z',
        value: 30.683647060007996
      },
      {
        label: '2021-02-24T11:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T12:00:00.000Z',
        value: 30.32568635291079
      },
      {
        label: '2021-02-24T13:00:00.000Z',
        value: 25.3386674522183
      },
      {
        label: '2021-02-24T14:00:00.000Z',
        value: 17.649276941196312
      },
      {
        label: '2021-02-24T15:00:00.000Z',
        value: 7.603333766651318
      },
      {
        label: '2021-02-24T16:00:00.000Z',
        value: 1.341928644578627
      },
      {
        label: '2021-02-24T17:00:00.000Z',
        value: 0.014858725467210043
      },
      {
        label: '2021-02-24T18:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T19:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T20:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T21:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T22:00:00.000Z',
        value: null
      }
    ],
    producedEnergy: [
      {
        label: '2021-02-23T23:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T00:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T01:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T02:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T03:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T04:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T05:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T06:00:00.000Z',
        value: 2.25
      },
      {
        label: '2021-02-24T07:00:00.000Z',
        value: 7.069999999992433
      },
      {
        label: '2021-02-24T08:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T09:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T10:00:00.000Z',
        value: 38.580000000001746
      },
      {
        label: '2021-02-24T11:00:00.000Z',
        value: 8.779999999998836
      },
      {
        label: '2021-02-24T12:00:00.000Z',
        value: 79.80999999999767
      },
      {
        label: '2021-02-24T13:00:00.000Z',
        value: 40.26000000000931
      },
      {
        label: '2021-02-24T14:00:00.000Z',
        value: 28.389999999992142
      },
      {
        label: '2021-02-24T15:00:00.000Z',
        value: 15.190000000002328
      },
      {
        label: '2021-02-24T16:00:00.000Z',
        value: 3.609999999993306
      },
      {
        label: '2021-02-24T17:00:00.000Z',
        value: 0.12000000000261934
      },
      {
        label: '2021-02-24T18:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T19:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T20:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T21:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T22:00:00.000Z',
        value: null
      }
    ],
    exportedEnergy: [
      {
        label: '2021-02-23T23:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T00:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T01:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T02:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T03:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T04:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T05:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T06:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T07:00:00.000Z',
        value: 0.1
      },
      {
        label: '2021-02-24T08:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T09:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T10:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T11:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T12:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T13:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T14:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T15:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T16:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T17:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T18:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T19:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T20:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T21:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T22:00:00.000Z',
        value: null
      }
    ]
  },
  {
    expectedEnergy: [
      {
        label: '2021-02-23T23:00:00.000Z',
        value: 0.03714681440443214
      },
      {
        label: '2021-02-24T00:00:00.000Z',
        value: 0.049529085872576185
      },
      {
        label: '2021-02-24T01:00:00.000Z',
        value: 0.049529085872576185
      },
      {
        label: '2021-02-24T02:00:00.000Z',
        value: 0.049529085872576185
      },
      {
        label: '2021-02-24T03:00:00.000Z',
        value: 0.049529085872576185
      },
      {
        label: '2021-02-24T04:00:00.000Z',
        value: 0.049529085872576185
      },
      {
        label: '2021-02-24T05:00:00.000Z',
        value: 0.11753331048632558
      },
      {
        label: '2021-02-24T06:00:00.000Z',
        value: 3.026277242910162
      },
      {
        label: '2021-02-24T07:00:00.000Z',
        value: 4.127939768409621
      },
      {
        label: '2021-02-24T08:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T09:00:00.000Z',
        value: 7.092977840907741
      },
      {
        label: '2021-02-24T10:00:00.000Z',
        value: 30.683647060007996
      },
      {
        label: '2021-02-24T11:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T12:00:00.000Z',
        value: 30.32568635291079
      },
      {
        label: '2021-02-24T13:00:00.000Z',
        value: 25.3386674522183
      },
      {
        label: '2021-02-24T14:00:00.000Z',
        value: 17.649276941196312
      },
      {
        label: '2021-02-24T15:00:00.000Z',
        value: 7.603333766651318
      },
      {
        label: '2021-02-24T16:00:00.000Z',
        value: 1.341928644578627
      },
      {
        label: '2021-02-24T17:00:00.000Z',
        value: 0.014858725467210043
      },
      {
        label: '2021-02-24T18:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T19:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T20:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T21:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T22:00:00.000Z',
        value: null
      }
    ],
    producedEnergy: [
      {
        label: '2021-02-23T23:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T00:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T01:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T02:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T03:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T04:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T05:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T06:00:00.000Z',
        value: 2.25
      },
      {
        label: '2021-02-24T07:00:00.000Z',
        value: 7.069999999992433
      },
      {
        label: '2021-02-24T08:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T09:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T10:00:00.000Z',
        value: 38.580000000001746
      },
      {
        label: '2021-02-24T11:00:00.000Z',
        value: 8.779999999998836
      },
      {
        label: '2021-02-24T12:00:00.000Z',
        value: 79.80999999999767
      },
      {
        label: '2021-02-24T13:00:00.000Z',
        value: 40.26000000000931
      },
      {
        label: '2021-02-24T14:00:00.000Z',
        value: 28.389999999992142
      },
      {
        label: '2021-02-24T15:00:00.000Z',
        value: 15.190000000002328
      },
      {
        label: '2021-02-24T16:00:00.000Z',
        value: 3.609999999993306
      },
      {
        label: '2021-02-24T17:00:00.000Z',
        value: 0.12000000000261934
      },
      {
        label: '2021-02-24T18:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T19:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T20:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T21:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T22:00:00.000Z',
        value: null
      }
    ],
    exportedEnergy: [
      {
        label: '2021-02-23T23:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T00:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T01:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T02:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T03:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T04:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T05:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T06:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T07:00:00.000Z',
        value: 0.1
      },
      {
        label: '2021-02-24T08:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T09:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T10:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T11:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T12:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T13:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T14:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T15:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T16:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T17:00:00.000Z',
        value: 0
      },
      {
        label: '2021-02-24T18:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T19:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T20:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T21:00:00.000Z',
        value: null
      },
      {
        label: '2021-02-24T22:00:00.000Z',
        value: null
      }
    ]
  }]
}
// Dati Mock Scostamento
const deviationData = {
  linearRegression: {
    line: [
      { x: 4.08, y: 223.586 },
      { x: 5.42, y: 289.514 },
      { x: 6.06, y: 321.02 },
      { x: 6.21, y: 328.382 },
      { x: 6.47, y: 341.174 },
      { x: 6.49, y: 342.158 },
      { x: 6.77, y: 355.934 }
    ],
    points: [
      { x: 6.77, y: 361.23 },
      { x: 4.08, y: 222.75 },
      { x: 6.47, y: 342.19 },
      { x: 6.49, y: 339.98 },
      { x: 6.21, y: 320.85 },
      { x: 6.21, y: 327.36 },
      { x: 6.06, y: 319.1 },
      { x: 5.42, y: 295.18 }
    ]
  },
  prDeviation: {
    baselinePr: 1.193026632415803,
    realPr: 0.9727761207753779,
    baselineProduction: 127.60691934951171,
    realProduction: 224.0599999999904
  },
  stringAnalysis: [{
    cc: {
      data: [0, 7123.7, 6996.8, 6984.3, 6872.9, 6572.0],
      labels: ['', '1.2', '1.5', '1.3', '2.5', '1.1'],
      min: 5900,
      middle: 6910
    },
    energy: {
      data: [null, 35.3, 34.7, 34.3, 33.5, 33.2],
      middle: 34.2
    }
  },
  {
    cc: {
      data: [0, 8362.7, 3964.8, 5034.3, 7129.9, 6572.0],
      labels: ['', '1.5', '1.8', '2.4', '2.8', '3.1'],
      min: 6400,
      middle: 7130
    },
    energy: {
      data: [null, 45.3, 23.7, 56.3, 55.5, 42.2],
      middle: 41.5
    }
  }]
}

// Per ora nelle chiamate mock mancano i parametri di data di inizio, data di fine e della configurazione dell'oggetto che serve al backend per prendere i dati corretti
// Chiamate Mock analisi di stringa
mock.onGet('/api/plant/plantId/analytics?type=stringAnalysis').reply(200, { data: stringAnalysisData })
// Chiamate Mock andamento
mock.onGet('/api/plant/plantId/analytics?type=trend').reply(200, { data: trendData })
// Chiamate scostamento
mock.onGet('/api/plant/plantId/analytics?type=deviation').reply(200, { data: deviationData })
