import { useState, useRef } from 'react'
import {
  Box,
  FormControlLabel,
  IconButton,
  Popover,
  SvgIcon,
  Switch,
  Tooltip,
  Typography,
  makeStyles,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  MenuItem
} from '@material-ui/core'
import { DownloadCloud as DownloadIcon, Eye as ViewIcon, Settings as SettingsIcon } from 'react-feather'
import ConfirmationDialogRaw from '@/components/ConfirmationDialogRaw'
import { getPlants } from '@slices/maps'
import { useDispatch } from '@/store'
import { useSnackbar } from 'notistack'
import { basicColors } from '@/theme'
import PDFViewerDrawer from '@/components/PDFViewerDrawer'
import useAuth from '@/hooks/useAuth'
import moment from 'moment'
import MetricsRecalculationModal from '@/components/MetricsRecalculationModal'

const useStyles = makeStyles((theme) => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  popover: {
    width: 370,
    padding: theme.spacing(2)
  },
  downloadButton: {
    color: basicColors.darkGreen,
    margin: theme.spacing(2)
  },
  viewFileButton: {
    color: theme.palette.primary.main
  },
  list: {
    maxHeight: 400,
    overflowY: 'auto'
  },
  divider: {
    margin: theme.spacing(2, -2)
  },
  settingsItem: {
    marginLeft: theme.spacing(1),
    width: '100%',
    justifyContent: 'space-between'
  }
}))

const italyBounds = [
  [36.491973470593685, 6.240234374999999],
  [36.491973470593685, 19.423828125],
  [47.30903424774781, 19.423828125],
  [47.30903424774781, 6.240234374999999]
]

const PlantSettings = ({ maintenance, recalculations, executeRecalculations, toggleMaintenance, plantId, documents }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useAuth()

  const { metadata } = user

  const { userFilters, zones } = metadata || {}

  // Map filter params
  const params = {
    coordinates: [...italyBounds].map(el => el.reverse()),
    zones,
    userFilters
  }

  const ref = useRef(null)

  const [isOpen, setOpen] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  // variabile che mostra o nasconde il drawer per la visualizzazione del PDF
  const [showPDFViewer, setShowPDFViewer] = useState(false)
  // variabile che contiene nome e url del file da visualizzare
  const [selectedFile, setSelectedFile] = useState({ name: null, url: null })
  // Variabile che mostra o nasconde la modal di ricalcolo delle metriche
  const [showRecalculationModal, setShowRecalculationModal] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = () => {
    setConfirmOpen(true)
  }

  const handleMaintenance = async (confirmed) => {
    if (confirmed) {
      const success = await dispatch(toggleMaintenance(!maintenance, plantId))
      // console.log('response: ', success)
      if (success === true) {
        dispatch(getPlants({ q: params }))
        enqueueSnackbar('Modalità manutenzione modificata correttamente', { variant: 'success' })
      } else {
        enqueueSnackbar('Errore durante la modifica della modalità manutenzione ', { variant: 'error' })
      }
    }
    setConfirmOpen(false)
  }

  // Valore che indica se ci sono ricalcoli in corso o meno
  const isRecalculating = Array.isArray(recalculations) && recalculations.some(el => el.status === 'pending' || el.status === 'in_progress')

  // Valore che indica l'ultima data di ricalcolo delle metriche
  const lastRecalculationElement = recalculations &&
    recalculations.length > 0 &&
    recalculations
      .filter(el => el.status === 'completed')
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))?.[0]

  const lastRecalculation = lastRecalculationElement && lastRecalculationElement.updatedAt ? moment(lastRecalculationElement.updatedAt).format('DD/MM/YYYY HH:mm') : '-'

  return (
    <>
      <PDFViewerDrawer
        file={selectedFile.url}
        title={selectedFile.name}
        open={showPDFViewer}
        onClose={() => {
          setShowPDFViewer(false)
          setSelectedFile({ name: null, url: null })
        }}
      />
      {confirmOpen
        ? (
          <ConfirmationDialogRaw
            open={confirmOpen}
            onClose={(confirmed) => handleMaintenance(confirmed)}
            title='Modalità manutenzione'
            description={maintenance === true ? 'Sei sicuro di voler riattivare l\'impianto?' : 'Sei sicuro di voler mettere l\'impianto in manutenzione?'}
          />)
        : null}
      {showRecalculationModal
        ? <MetricsRecalculationModal
            executeRecalculations={executeRecalculations}
            plantId={plantId}
            open={showRecalculationModal}
            onClose={() => setShowRecalculationModal(false)}
          />
        : null}
      <Tooltip title='Impostazioni Impianto'>
        <IconButton
          onClick={handleOpen}
          ref={ref}
          size='small'
          style={{ padding: 0, marginRight: 8 }}
        >
          <SvgIcon fontSize='small'>
            <SettingsIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography
          variant='h4'
          color='textPrimary'
        >
          Impostazioni Impianto
        </Typography>
        <Box
          mt={2}
          px={1}
        >
          <FormControlLabel
            className={classes.settingsItem}
            control={(
              <Switch
                checked={maintenance}
                color='primary'
                edge='start'
                name='maintenance'
                onChange={handleChange}
              />
            )}
            label={<Typography variant='body2'>Manutenzione</Typography>}
            labelPlacement='start'
          />
          <Tooltip title={
            isRecalculating
              ? "E' possibile eseguire un solo ricalcolo delle metriche alla volta, attendere il completamento di quello in corso per ripetere l'operazione"
              : "Esegui il ricalcolo delle metriche per l'impianto. Attenzione: l'operazione potrebbe richiedere alcuni minuti."
          }
          >
            <span>
              <MenuItem className={classes.recalculationsButton} onClick={() => setShowRecalculationModal(true)} button disabled={isRecalculating}>
                <Box>
                  <Typography variant='body1'>Ricalcola metriche</Typography>
                  {isRecalculating ? null : <Typography className={classes.lastMetrics} variant='caption'>Ultimo ricalcolo: {lastRecalculation}</Typography>}
                </Box>
                {isRecalculating
                  ? <CircularProgress size={20} thickness={6} style={{ marginLeft: 16 }} />
                  : null}
              </MenuItem>
            </span>
          </Tooltip>
        </Box>
        {documents && documents.length > 0
          ? (
            <>
              <Divider className={classes.divider} />
              <Typography variant='h4' color='textPrimary'>Documenti impianto</Typography>
              <List dense className={classes.list}>
                {documents.map((doc, docIndex) => (
                  <ListItem key={doc.url}>
                    <Tooltip title={doc.name || 'Documento'}>
                      <ListItemText
                        primaryTypographyProps={{ noWrap: true, style: { width: 220 } }}
                        primary={`${docIndex + 1}. ${doc.name || 'Documento'}`}
                      />
                    </Tooltip>
                    <ListItemSecondaryAction>
                      <Tooltip title='Visualizza Documento'>
                        <span>
                          <IconButton
                            size='small'
                            edge='end'
                            className={classes.viewFileButton}
                            disabled={!(doc.url && doc.url !== '')}
                            onClick={() => {
                              setSelectedFile({ name: doc.name, url: doc.url })
                              setShowPDFViewer(true)
                            }}
                          >
                            <ViewIcon fontSize={14} />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title='Scarica Documento'>
                        <span>
                          <IconButton
                            size='small'
                            edge='end'
                            className={classes.downloadButton}
                            disabled={!(doc.url && doc.url !== '')}
                            onClick={(e) => {
                              e.stopPropagation()
                              if (doc.url && doc.url !== '') {
                                const a = document.createElement('a')
                                document.body.appendChild(a)
                                a.style = 'display: none'
                                a.href = doc.url
                                a.click()
                                window.URL.revokeObjectURL(doc.url)
                              }
                            }}
                          >
                            <DownloadIcon fontSize={14} />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </>)
          : null}
      </Popover>
    </>
  )
}

export default PlantSettings
