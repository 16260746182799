import clsx from 'clsx'
import { Box, Checkbox, FormControlLabel, Grid, ListItemIcon, ListItemText, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core'
import { useState } from 'react'
import { HexColorPicker } from 'react-colorful'

const useStyles = makeStyles(() => ({
  root: {}
}))

const removeTrailingNumber = (propName) => {
  return propName.split(' ').filter(el => isNaN(Number(el))).join(' ')
}

function SensorContent ({ className, ...rest }) {
  const classes = useStyles()

  const { selectedObject, assets, devices, element, setElement } = rest

  const configAssets = element?.config?.[0]?.assets || []

  const selected = configAssets?.[0]?.assetId || ''

  const name = element?.config?.[0]?.data?.draw?.name || 0

  const color = element?.config?.[0]?.data?.draw?.backgroundColor || 'white'

  const properties = element?.config?.[0]?.additionalData?.properties || []

  const selectedProps = element?.config?.[0]?.additionalData?.selectedProperties || []

  const [selectedAsset, setSelectedAsset] = useState(selected)
  const [currentName, setCurrentName] = useState(name)
  const [currentColor, setCurrentColor] = useState(color)

  const [currentProperties, setCurrentProperties] = useState(properties)
  const [selectedProperties, setSelectedProperties] = useState(selectedProps)

  const handlePropertySelection = (e) => {
    e.persist()
    const value = e.target.name
    const checked = e.target.checked
    const newSelection = checked ? [...selectedProperties, value] : [...selectedProperties.filter(el => el !== value)]
    setSelectedProperties(newSelection)
    setElement(prevElement => {
      if (prevElement.config.length > 0) {
        prevElement.config[0].additionalData = {
          ...prevElement.config[0].additionalData,
          properties: currentProperties,
          selectedProperties: newSelection
        }
      } else {
        prevElement.config.push({
          ...prevElement.config?.[0],
          additionalData: {
            properties: currentProperties,
            selectedProperties: newSelection
          }
        })
      }

      return ({
        config: [{
          ...prevElement.config[0]
        }]
      })
    })
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <Box my={2} width='100%'>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  size='small'
                  color='primary'
                  variant='outlined'
                  select
                  fullWidth
                  SelectProps={{
                    renderValue: (renderSelected) => <ListItemText style={{ marginTop: 0, marginBottom: 0 }}>{assets?.find(asset => asset.uuid === renderSelected)?.name || ''}</ListItemText>
                  }}
                  label='Seleziona sensore di livello'
                  value={selectedAsset}
                >
                  {(assets || []).map(el => (
                    <MenuItem
                      dense
                      onClick={() => {
                        let properties = []
                        let isDeselection = false
                        setElement(prevElement => {
                          // oggetto completo del device selezionato
                          const selectedAsset = assets.find(asset => asset.uuid === el.uuid)
                          const selectedDeviceId = selectedAsset?.propertyMappings?.adc?.resourceId
                          // se l'oggetto esiste, devo aggiornare l'array dei devices nell'elemento
                          if (selectedAsset && selectedDeviceId) {
                            const selectedDevice = devices.find(device => device.uuid === selectedDeviceId)

                            // Costruisco l'array di properties
                            properties = Object.keys(selectedAsset?.propertyMappings).map(key => {
                              const devicePropName = selectedAsset?.propertyMappings[key]?.propertyName
                              if (devicePropName) {
                                const property = selectedDevice?.deviceType?.properties?.[devicePropName] ?? null
                                const normalizedProperty = { propKey: devicePropName, label: removeTrailingNumber(property.displayName), value: 0 }
                                return normalizedProperty
                              }

                              return null
                            }).filter(el => el !== null)

                            // strutturo l'oggetto device
                            const elementAsset = {
                              type: selectedObject.type,
                              assetId: selectedAsset.uuid,
                              // properties: selectedAsset.assetType?.properties,
                              properties,
                              additionalData: {}
                            }

                            if (prevElement.config.length > 0) {
                              const sameDevice = prevElement?.config?.[0]?.assets?.find(configEl => configEl.assetId === el.uuid)
                              // se è presente lo rimuovo
                              if (sameDevice && sameDevice !== undefined) {
                                const newAssets = prevElement?.config?.[0]?.assets?.filter(asset => asset.assetId !== el.uuid)
                                prevElement.config[0].assets = newAssets
                                isDeselection = true
                              } else {
                                // aggiorno l'array di devices di un singolo elemento
                                prevElement.config[0].assets = [{ ...elementAsset }]
                                if (!prevElement.config[0].data.draw) {
                                  prevElement.config[0].data.draw = {}
                                }
                                prevElement.config[0].data.draw = {
                                  ...prevElement.config[0].data.draw,
                                  name: el.name
                                }
                              }
                            } else {
                              prevElement.config.push({
                                assets: [{ ...elementAsset }],
                                data: {
                                  draw: {
                                    name: el.name
                                  }
                                }
                              })
                            }
                          }

                          return ({
                            config: [{
                              ...prevElement.config[0]
                            }]
                          })
                        })
                        setCurrentName(el.name ?? '')
                        setCurrentProperties(properties)
                        setSelectedProperties([])
                        if (isDeselection) {
                          setSelectedAsset('')
                        } else {
                          setSelectedAsset(el.uuid)
                        }
                      }} key={el.uuid} value={el.uuid}
                    >
                      <ListItemIcon>
                        <Checkbox
                          color='primary'
                          edge='start'
                          checked={selected === el.uuid}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText>{el.name}</ListItemText>
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  label='Nome'
                  placeholder='Nome'
                  value={currentName || ''}
                  onChange={(e) => {
                    e.persist()
                    setElement(prevElement => {
                      if (prevElement.config.length > 0) {
                        // se l'elemento non ha l'oggetto data lo imposto a oggetto vuoto
                        if (!prevElement.config[0].data) {
                          prevElement.config[0].data = {}
                        }
                        // se l'elemento non ha l'oggetto draw lo imposto a oggetto vuoto
                        if (!prevElement.config[0].data.draw) {
                          prevElement.config[0].data.draw = {}
                        }
                        prevElement.config[0].data.draw = {
                          ...prevElement.config[0].data.draw,
                          name: e.target.value
                        }
                      } else {
                        prevElement.config.push({
                          assets: [],
                          data: {
                            draw: {
                              name: e.target.value
                            }
                          }
                        })
                      }
                      return { ...prevElement }
                    })
                    setCurrentName(e.target.value)
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box>
            {selectedAsset
              ? (
                <>
                  <Typography variant='h6'>Seleziona fino a 2 proprietà da visualizzare</Typography>
                  <Box mt={2}>
                    <Grid container spacing={0}>
                      {currentProperties.filter(el => !el.propKey.includes('adc')).map(el => (
                        <Grid item xs={12} md={4} key={el.propKey}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={selectedProperties.length > 1 && !selectedProperties.includes(el.propKey)}
                                checked={selectedProperties.includes(el.propKey)}
                                onChange={handlePropertySelection}
                                name={el.propKey}
                                color='primary'
                              />
                            }
                            label={el.label}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </>
                )
              : null}
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant='h6'>Colore di sfondo</Typography>
          <Box my={2} width='100%'>
            <HexColorPicker
              style={{ height: '100px' }} color={currentColor} onChange={setCurrentColor} onMouseUp={(e) => {
                e.persist()
                setElement((prevElement) => {
                  if (prevElement.config.length > 0) {
                    if (!prevElement.config[0].data.draw) {
                      prevElement.config[0].data.draw = {}
                    }
                    prevElement.config[0].data.draw = {
                      ...prevElement.config[0].data.draw,
                      backgroundColor: currentColor
                    }
                  } else {
                    prevElement.config.push({
                      devices: [],
                      data: {
                        draw: {
                          backgroundColor: currentColor
                        }
                      }
                    })
                  }
                  return { ...prevElement }
                })
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}
export default SensorContent
