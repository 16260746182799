import { useRef, useState, memo, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import {
  Avatar,
  Paper,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  useMediaQuery,
  makeStyles,
  Divider,
  Box,
  TextField,
  ListItemText
} from '@material-ui/core'
import useAuth from '@/hooks/useAuth'

import {
  User as UserIcon,
  LogOut as OutIcon
} from 'react-feather'
const placeholder = '/static/images/avatars/user-placeholder2.svg'
const avatarWidth = 32

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    marginRight: 'auto',
    marginLeft: 'auto',
    borderRadius: 24,
    position: 'absolute',
    bottom: 16,
    left: 10
  },
  mobileAvatarContainer: {
    borderRadius: 24,
    position: 'absolute',
    bottom: 16,
    right: theme.spacing(3) + 2,
    [theme.breakpoints.down('xs')]: {
      bottom: 12
    }
  },
  menuItem: {
    marginBottom: 6
  },
  menuIcon: {
    marginRight: 6
  },
  avatar: {
    height: avatarWidth,
    width: avatarWidth
  },
  popover: {
    minWidth: 200,
    marginTop: -72
  }
}))

const Settings = () => {
  const classes = useStyles()
  const ref = useRef(null)
  const { user, logout, currentProject, updateCurrentProject } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const [isOpen, setOpen] = useState(false)

  const [selectedProject, setSelectedProject] = useState(currentProject?.uuid || '')

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = async () => {
    try {
      handleClose()
      await logout()
      console.clear()
    } catch (err) {
      console.error(err)
      enqueueSnackbar('Impossibile eseguire il logout. Per favore controlla la tua connessione.', {
        variant: 'error'
      })
    }
  }

  const handleProjectUpdate = (projectUuid) => {
    if (projectUuid !== selectedProject) {
      const changedProject = user.projects?.find(project => project.uuid === projectUuid)
      if (changedProject) {
        updateCurrentProject(changedProject)
      }
    }
  }

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  useEffect(() => {
    if (currentProject?.uuid) {
      setSelectedProject(currentProject.uuid)
    }
  }, [currentProject])

  return (
    <>
      <Paper
        display='flex'
        onClick={handleOpen}
        ref={ref}
        className={!isSmall ? classes.avatarContainer : classes.mobileAvatarContainer}
        elevation={2}
      >
        <Avatar
          alt='User'
          className={classes.avatar}
          src={user.metadata && user.metadata.imageURL ? user.metadata.imageURL : placeholder}
        />
      </Paper>
      {/* menu */}
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem disabled className={classes.menuItem}>
          Salve {user.fullName}
        </MenuItem>
        <Divider />
        {user.projects?.length > 0
          ? (
            <Box pl={2} pr={1} my={1}>
              <TextField
                size='small'
                color='primary'
                select
                fullWidth
                InputProps={{ disableUnderline: true }}
                SelectProps={{
                  renderValue: (renderSelected) => (
                    <ListItemText
                      primary={user.projects?.find(project => project.uuid === renderSelected)
                        ? user.projects?.find(project => project.uuid === renderSelected)?.name
                        : ''}
                      secondary={user.email}
                      primaryTypographyProps={{ variant: 'h6', style: { lineHeight: 1 } }}
                      secondaryTypographyProps={{ variant: 'caption', style: { lineHeight: 1 } }}
                      style={{ marginTop: 0, marginBottom: 0 }}
                    />)
                }}
                value={selectedProject}
                onChange={e => {
                  handleProjectUpdate(e.target.value)
                }}
              >
                {user.projects?.map(project => (
                  <MenuItem
                    dense
                    key={project.uuid}
                    value={project.uuid}
                  >
                    {project.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            )
          : null}
        <Divider />
        <MenuItem
          className={classes.menuItem}
          component={RouterLink}
          to='/app/settings/account'
        >
          <ListItemIcon className={classes.menuIcon}>
            <UserIcon fontSize='small' />
          </ListItemIcon>
          <Typography variant='inherit' noWrap>Account</Typography>
          {/* <ListItemText primary="Account" /> */}
        </MenuItem>
        <MenuItem onClick={handleLogout} className={classes.menuItem}>
          <ListItemIcon className={classes.menuIcon}>
            <OutIcon fontSize='small' />
          </ListItemIcon>
          <Typography variant='inherit' noWrap>Logout</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

export default memo(Settings)
