import clsx from 'clsx'
import { Avatar, Box, Collapse, Divider, Fade, IconButton, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  list: {
    maxHeight: '300px',
    backgroundColor: theme.palette.background.paper
  },
  openLibrary: {
    overflowY: 'scroll'
  },
  item: {
    cursor: 'pointer'
  },
  library: {
    position: 'absolute',
    bottom: '0px',
    left: '28px',
    width: '280px',
    zIndex: theme.zIndex.modal + 1
  },
  closeButton: {
    color: '#ffffff'
  },
  libraryTitle: {
    textAlign: 'left',
    width: '100%',
    cursor: 'pointer',
    color: '#ffffff'
  },
  openButton: {
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(1, 2)
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  }
}))

function LibraryMenu ({ libraryObjects = {}, className, showLibrary, setShowLibrary, setPosition, setShowPlaceholder, setSelectedObject, ...rest }) {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, className)}>
      <Collapse className={classes.library} in={showLibrary} collapsedSize={50}>
        <Box
          className={classes.openButton}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography
            className={classes.libraryTitle}
            onClick={() => setShowLibrary(true)}
            variant='h4'
          >
            Libreria
          </Typography>
          <Fade in={showLibrary}>
            <IconButton
              className={classes.closeButton}
              size='small'
              onClick={() => setShowLibrary(false)}
            >
              <CancelIcon />
            </IconButton>
          </Fade>
        </Box>
        <List className={showLibrary ? clsx(classes.openLibrary, classes.list) : classes.list}>
          {
            Object.keys(libraryObjects).map(s => {
              const obj = libraryObjects[s]
              obj.type = s
              if (obj.menuHide) {
                return null
              }
              return (
                <ListItem
                  className={classes.item} key={obj.name}
                  onMouseDown={(e) => {
                    setPosition({ x: (e.pageX - 40 / 2), y: (e.pageY - 40 / 2) })
                    setShowPlaceholder(true)
                    setShowLibrary(false)
                    setSelectedObject(obj)
                  }}
                >
                  <ListItemAvatar>
                    <Avatar imgProps={{ className: classes.avatar }} src={obj.img} />
                  </ListItemAvatar>
                  <ListItemText primary={obj.name} secondary={obj.notes} />
                  <Divider />
                </ListItem>
              )
            })
          }
        </List>
      </Collapse>
    </div>
  )
}
export default LibraryMenu
