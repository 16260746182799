import { memo } from 'react'
// import NProgress from 'nprogress'
import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3)
  },
  cardBackground: {
    backgroundColor: theme.palette.background.default
  },
  cicle: {
    color: theme.palette.primary.main
  },
  glassBackground: {
    '@supports (backdrop-filter: none) or (-webkit-backdrop-filter: none)': {
      '-webkit-backdrop-filter': 'saturate(120%) blur(10px)',
      backdropFilter: 'saturate(120%) blur(16px)',
      backgroundColor: 'rgba(16, 26, 38, 0.5)'
    }
  }
}))

const LoadingCard = ({ glass = false, className }) => {
  const classes = useStyles()

  // useEffect(() => {
  //   NProgress.start()

  //   return () => {
  //     NProgress.done()
  //   }
  // }, [])

  return (
    <div className={glass ? clsx(className, classes.root) : clsx(className, classes.root, classes.cardBackground)}>
      <Box width='100%' align='center'>
        <CircularProgress className={classes.cicle} variant='indeterminate' size={70} thickness={4} />
      </Box>
    </div>
  )
}

export default memo(LoadingCard)
