// Costanti che identificano le posizioni che possono avere i controlli della mappa
export const controlPositions = {
  TOP_LEFT: 1,
  TOP_CENTER: 2,
  TOP: 2,
  TOP_RIGHT: 3,
  LEFT_CENTER: 4,
  LEFT_TOP: 5,
  LEFT: 5,
  LEFT_BOTTOM: 6,
  RIGHT_TOP: 7,
  RIGHT: 7,
  RIGHT_CENTER: 8,
  RIGHT_BOTTOM: 9,
  BOTTOM_LEFT: 10,
  BOTTOM_CENTER: 11,
  BOTTOM: 11,
  BOTTOM_RIGHT: 12,
  CENTER: 13
}

// funzione che converte i bounds nel punto centrale della mappa e lo ritorna
export const convertBoundsToCenterPoint = (boundsArray = []) => {
  if (boundsArray.length > 0) {
    const startPoint = boundsArray[0]
    const endPoint = boundsArray[2]
    const [startLat, startLng] = startPoint
    const [endLat, endLng] = endPoint

    const italyPaddingLng = 3

    const middleLat = startLat + ((endLat - startLat) / 2)
    const middleLng = startLng + ((endLng - startLng) / 2) + italyPaddingLng

    return { lat: middleLat, lng: middleLng }
  }

  return { lat: 0, lng: 0 }
}

// Funzione che converte un array di marker all'interno di un cluster, in un array di oggetti utili per visualizzare il Popover per un cluster sulla mappa
export const convertMarkersToClusterTooltipData = (markersArray = []) => {
  return markersArray.map(el => {
    const status = el.status ? el.status : null
    const plantName = el.plantName ? el.plantName : null
    const position = el.position ? el.position : null
    if (status && plantName && position) {
      return {
        status,
        plantName,
        position,
        onClick: el.onClick || null
      }
    }

    return null
  })
    .filter(el => el)
}

// Array di oggetti contenente gli stili possibili per i marker sulla mappa
export const markerStyles = [
  {
    url: '/static/images/green_marker.png',
    width: 52,
    height: 53
  },
  {
    url: '/static/images/yellow_marker.png',
    width: 52,
    height: 53
  },
  {
    url: '/static/images/red_marker.png',
    width: 52,
    height: 53
  },
  {
    url: '/static/images/default_marker.png',
    width: 52,
    height: 53
  }
]
