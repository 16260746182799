import { Suspense, lazy } from 'react'
import moment from 'moment'
import { chunkArray, europeNum } from '@utils/general'
import { CornerUpRight as CornerUpRightIcon, TrendingUp as TrendingUpIcon, Crosshair as CrosshairIcon, Sun as SunIcon } from 'react-feather'
import SunCalc from 'suncalc'
import log from '@pelv/frontlog'
import LoadingScreen from '@components/LoadingScreen'
// import Overview from './Overview'
// import Components from './Components'
// import Analytics from './Analytics'
// import Consumption from './Consumption'
// import Anomalies from './Anomalies'
const Overview = lazy(() => import('./Overview'))
const Components = lazy(() => import('./Components'))
const Anomalies = lazy(() => import('./Anomalies'))
const Consumption = lazy(() => import('./Consumption'))
const Analytics = lazy(() => import('./Analytics'))

export const tabs = [
  {
    value: 'overview',
    label: 'Panoramica',
    isSmall: true
  },
  /* {
    value: 'components',
    label: 'Componenti',
    isSmall: true
  }, */
  {
    value: 'consumption',
    label: 'Consumi',
    isSmall: true
  },
  {
    value: 'anomalies',
    label: 'Anomalie',
    isSmall: true
  },
  {
    value: 'analytics',
    label: 'Analytics',
    isSmall: false
  }
]

export const selectActiveTab = (currentTab) => {
  switch (currentTab) {
    case 'overview': {
      return (
        <Suspense fallback={<LoadingScreen />}>
          <Overview />
        </Suspense>
      )
    }
    case 'components': {
      return (
        <Suspense fallback={<LoadingScreen />}>
          <Components />
        </Suspense>
      )
    }
    case 'consumption': {
      return (
        <Suspense fallback={<LoadingScreen />}>
          <Consumption />
        </Suspense>
      )
    }
    case 'anomalies': {
      return (
        <Suspense fallback={<LoadingScreen />}>
          <Anomalies />
        </Suspense>
      )
    }
    case 'analytics': {
      return (
        <Suspense fallback={<LoadingScreen />}>
          <Analytics />
        </Suspense>
      )
    }
    default:
      return null
  }
}

// Funzione che presi in ingresso il typo di periodo e la data di inizio ritornano data di inizio e data di fine
export const getDatesFromPeriod = (period, now, formatString = null) => {
  let maxDate, minDate
  if (period === 'live') {
    // min e max saranno la mezzanotte di oggi e le 23:59 di oggi
    minDate = moment(now).set({ hours: 0, minute: 0, second: 0, millisecond: 0 })
    maxDate = moment(now).set({ hours: 23, minute: 59, second: 59, millisecond: 900 })
  } else if (period === 'week') {
    // il min sarà il lunedì di questa settimana, il max la domenica
    //  su momento 0 è domenica, 6 è sabato, 7 è la prossima domenica
    minDate = moment(now).startOf('week')
    maxDate = moment(now).endOf('week')
  } else if (period === 'month') {
    // il min sarà l'1 del mese e il max sarà il 30/31 del mese
    // (secondo la doc moment gestisce da solo che se setti 31 ed ha 30 giorni imposta 30 e non 31)
    // https://momentjs.com/docs/#/get-set/date/
    // https://momentjs.com/docs/#/get-set/month/
    minDate = moment(now).startOf('month')
    maxDate = moment(now).endOf('month')
  } else if (period === 'year') {
    // sarà l'anno corrente
    minDate = moment(now).set({ hours: 0, minute: 0, second: 0, millisecond: 0 })
    minDate = moment(minDate)
      .month(0)
      .date(1)
    maxDate = moment(now).set({
      hours: 23,
      minute: 59,
      second: 59,
      millisecond: 900
    })
    maxDate = moment(maxDate)
      .month(11)
      .date(31)
  }

  return formatString
    ? { minDate: moment(minDate).format(formatString), maxDate: moment(maxDate).format(formatString) }
    : { minDate: moment(minDate).toISOString(), maxDate: moment(maxDate).toISOString() }
}

// Funzione che prende in ingresso i dati ricevuti dall'api e li formatta per le card
export const decodeCardsFromApi = (dataObj) => {
  // Oggetto che contiene la configurazione delle cards
  const labelIconConfig = {
    power: {
      icon: <CrosshairIcon />,
      label: 'Potenza Rilevata'
    },
    powerRating: {
      icon: <CornerUpRightIcon />,
      label: 'Potenza di Targa'
    },
    totalEnergy: {
      icon: <TrendingUpIcon />,
      label: 'E. Consumata'
    },
    lightHours: {
      icon: <SunIcon />,
      label: 'Ore di luce'
    }
  }

  return Object.keys(dataObj).map((key) => ({
    icon: (labelIconConfig[key] && labelIconConfig[key].icon) || null,
    values: [
      {
        label: (labelIconConfig[key] && labelIconConfig[key].label) || '',
        value:
          key === 'totalEnergy'
            ? dataObj[key] !== '-'
                ? dataObj[key] * 1000 >= 10000
                    ? `${europeNum(dataObj[key])} kWh`
                    : `${europeNum(dataObj[key] * 1000)} Wh`
                : '-'
            : key === 'lightHours'
              ? dataObj[key] !== '-'
                  ? `${europeNum(moment.duration(dataObj[key]).asHours(), 2)}`
                  : '-'
              : dataObj[key] !== '-'
                ? dataObj[key] >= 1000000
                    ? `${europeNum(dataObj[key] / 1000)} kW`
                    : `${europeNum(dataObj[key])} W`
                : '-'
      }
    ]
  }))
}

// Funzione che prende in ingresso i dati ricevuti dall'api e li formatta per il grafico di consumi
export const decodeLineConsumptionGraphFromApi = (linesConsumptionGraphData) => {
  // categorie che costituiscono le barre del grafico
  const categories = linesConsumptionGraphData.map(el => el.name)
  // valori di energia reale
  const realValues = linesConsumptionGraphData.map(el => el.real)
  // vaori di energia attesa
  const expectedValues = linesConsumptionGraphData.map(el => el.expected)
  // valori di delta
  const deltaValues = linesConsumptionGraphData.map(el => el.delta)

  const series = [{
    name: 'Consumo Atteso',
    color: '#8bb9dd',
    data: expectedValues
  },
  {
    name: 'Consumo Reale',
    color: '#63b521',
    data: realValues
  },
  {
    name: 'Differenza di Consumo',
    color: '#fb6342',
    data: deltaValues
  }]

  return { categories, series }
}

// funzione che torna la durata formattata
export const returnDuration = (duration) => {
  const toReturn = Number(duration)
  if (duration === '' || isNaN(toReturn)) {
    return duration
  } else {
    return moment.duration(toReturn).humanize()
  }
}

// funzione che ritorna i dati per la lista delle anomalie sistemati per la view della lista
export const normalizeAnomaly = (anomaly, categories) => {
  // mi preparo l'oggetto finale
  const finalObj = {
    ...anomaly,
    name: 'N.F.',
    description: 'N.F.',
    category: '',
    subCategory: {
      label: '',
      color: ''
    },
    selected: true
  }
  // cerco config e categorie
  const thisConfig = anomaly.configuration
  if (thisConfig) {
    finalObj.name = thisConfig.name
    finalObj.description = thisConfig.description
    // cerco la categoria principale
    const thisCategory = categories.find(
      (category) => category.name === thisConfig.category
    )
    if (thisCategory) {
      finalObj.category = thisCategory.label
      // cerco la subcategory
      const thisSubCategory = thisCategory.subCategory.find(
        (sub) => sub.name === thisConfig.subCategory
      )
      if (thisSubCategory) {
        finalObj.subCategory.label = thisSubCategory.label
        finalObj.subCategory.color = thisSubCategory.color
      }
    }
  }
  // mi sistemo la durata nel caso sia ancora aperta
  if (!finalObj.duration && (!finalObj.endedAt || finalObj.endedAt === '')) {
    // DA RIMUOVERE -> ref usato per i dati demo
    const dateRef = moment()
    finalObj.duration = moment
      .duration(moment(dateRef).diff(moment(finalObj.startedAt)))
      .asMilliseconds()
    // finalObj.duration = moment.duration(finalObj.duration).asHours()
  }

  return finalObj
}

// funzione che prende in ingresso i dati ricevuti dall'api e li formatta per il grafico dell'andamento
// L'oggetto dataObj avrà una chiave per ogni proprietà richiesta
export const decodeTrendGraph = (dataObj, currentFilter) => {
  const periodXFormat = {
    raw: 'HH:mm',
    quarter: 'HH:mm',
    hourly: 'HH:mm',
    daily: 'DD/MM/YY'
  }

  // prendo il primo dispositivo disponibile
  const firstDevice = Object.keys(dataObj).length > 0 ? Object.keys(dataObj)[0] : null
  // prendo la prima proprietà del dispositivo selezionato
  const firstKey = firstDevice && Object.keys(dataObj[firstDevice]).length > 0 ? Object.keys(dataObj[firstDevice])[0] : ''
  // Prendo il livello di aggregazione
  const { aggregationType } = currentFilter
  const { elements } = currentFilter
  log([
    { text: 'elements => ', variable: elements, tag: 'decodetrendgraph' },
    { text: 'firstDevice => ', variable: firstDevice, tag: 'decodetrendgraph' },
    { text: 'firstKey => ', variable: firstKey, tag: 'decodetrendgraph' },
    { text: 'dataObj => ', variable: dataObj, tag: 'decodetrendgraph' }
  ])
  // Prendo le labels (le date) dall'array del primo elemento e le formatta secondo l'oggetto periodXFormat
  const labels = dataObj[firstDevice][firstKey] ? dataObj[firstDevice][firstKey].map((el) => moment(el.label).format(periodXFormat[aggregationType])) : []
  const series = elements.map(property => {
    const data = (dataObj[property.deviceId] && dataObj[property.deviceId][property.name] && dataObj[property.deviceId][property.name].map(el => el.value)) || []

    // faccio questo per avere un numero di elementi sempre omogeneo per tutte le grandezze
    while (data.length < labels.length) {
      data.push(null)
    }

    return {
      data,
      type: property.graphType || 'column',
      uom: property.uom || 'kWh',
      name: property.displayName || 'Value'
    }
  })

  log({ text: 'series => ', variable: series, tag: 'decodetrendgraph' })
  // oggetto formattato da ritornare per visualizzare il grafico
  const trendGraph = {
    labels,
    axis: {
      color: '#fff'
      // yMax: 800
    },
    series
  }

  return trendGraph
}

// Funzione che prende in ingresso i dati ricevuti dalle API per i grafici di analisi di stringa e restituisce i dati nel formato giusto per essere graficati
export const decodeStringAnalysisFromApi = (dataObj) => {
  const stringAnalysisData = Object.keys(dataObj)
    .map(key => ({ ...dataObj[key] }))
    .map(element => Object.keys(element)
      .map(elKey => ({ ...element[elKey] }))).flat()

  const chunkStringAnalysis = chunkArray(stringAnalysisData, 2)

  log({ text: 'stringAnalysisData => ', variable: chunkStringAnalysis, tag: 'decodeStringAnalysisFromApi' })
  return chunkStringAnalysis
}

// Funzione che prende in ingresso i dati ricevuti dalle API per il grafico di scostamento e restituisce i dati nel formato giusto per essere graficati
export const decodeLinearRegressionGraphFromApi = (dataObj) => {
  // Array di oggetti presenti in un grafico
  const elements = Object.keys(dataObj).map(key => dataObj[key])

  let data = []
  if (elements.some(el => el.plant !== undefined)) {
    log({ text: 'elements => ', variable: elements, tag: 'decodeLinearRegressionGraphFromApi' })
    data = elements.map(element => ({ ...element.plant })).map(inv => ({
      max: inv.max,
      r2: inv.r2,
      line: inv.line.map(el => ({ x: el.irradiation, y: el.energy })),
      points: inv.scatter.map(el => ({ x: el.irradiation, y: el.energy, date: el.label }))
    }))
  } else {
    // Array di oggetti degli elementi da graficare
    data = elements
      .map(element => Object.keys(element)
        .map(elementKey => element[elementKey].inverters)).flat()
      .map(inverter => Object.keys(inverter)
        .map(inverterKey => inverter[inverterKey])).flat()
      .map(inv => ({
        max: inv.max,
        r2: inv.r2,
        line: inv.line.map(el => ({ x: el.irradiation, y: el.energy })),
        points: inv.scatter.map(el => ({ x: el.irradiation, y: el.energy, date: el.label }))
      }))
  }

  log({ text: 'dataa => ', variable: data, tag: 'decodeLinearRegressionGraphFromApi' })
  /* const data = elements
    .map(element => Object.keys(element)
      .map(key => (
        {
          line: element[key].line.map(el => ({ x: el.irradiation, y: el.energy })),
          points: element[key].scatter.map(el => ({ x: el.irradiation, y: el.energy, date: el.label }))
        }
      ))
    ).flat() */
  return data
}

// Funzione che prende in ingresso una data di inizio e una data di fine e ritorna il tipo di aggregazione da utilizzare
export const getAggregationTypeFromPeriod = (startDate, endDate) => {
  const daysDiff = moment(endDate).diff((moment(startDate)), 'days')
  const hoursDiff = moment(endDate).diff(moment(startDate), 'hours')

  // Se il periodo è maggiore di un giorno richiedo i dati in forma giornaliera
  if (daysDiff > 1) {
    return 'daily'
  }
  // Se il periodo è maggiore di 12 ore richiedo i dati in forma oraria
  if (hoursDiff > 12) {
    return 'hourly'
  }

  // Se il periodo è mggiore di 1 ora richiedo i dati in forma quartoraria
  if (hoursDiff > 1) {
    return 'quarter'
  }

  // Se sono sotto 1 ora richiedo i dati puntuali
  return 'raw'
}

// Funzione che prende in ingresso un set di filtri per il grafico di regressione e li restituisce arrichiti della chiamata all'analisi di stringa
export const addStringAnalysisToDeviation = (filter, type) => {
  let filterCopy = { ...filter }

  const currentElements = (filter[type] && filter[type].length > 0 && filter[type][0].elements) || []
  if (currentElements.length > 0) {
    const stringAnalysisFilter = currentElements.map(el => ({
      show: true,
      elements: [{ ...el }]
    }))
    filterCopy = {
      ...filter,
      stringAnalysis: stringAnalysisFilter
    }
  }

  return filterCopy
}

function getPlantSunTimes (plant, _now) {
  const now = new Date(_now)
  let sunrise = new Date(now)
  sunrise.setHours(5, 0, 0, 0)
  let sunset = new Date(now)
  sunset.setHours(22, 0, 0, 0)
  if (plant.location && plant.location.coordinates) {
    const suntimes = SunCalc.getTimes(new Date(now), plant.location.coordinates[1], plant.location.coordinates[0])
    sunrise = new Date(suntimes.sunrise)
    sunset = new Date(suntimes.sunset)
  }

  let sunriseMinutesOffset = 90
  let sunsetMinutesOffset = 90
  if (plant.metadata && plant.metadata.sunriseMinutesOffset && !isNaN(plant.metadata.sunriseMinutesOffset)) {
    sunriseMinutesOffset = Number(plant.metadata.sunriseMinutesOffset)
  }
  if (plant.metadata && plant.metadata.sunsetMinutesOffset && !isNaN(plant.metadata.sunsetMinutesOffset)) {
    sunsetMinutesOffset = Number(plant.metadata.sunsetMinutesOffset)
  }
  sunrise.setMinutes(sunrise.getMinutes() + sunriseMinutesOffset)
  sunset.setMinutes(sunset.getMinutes() - sunsetMinutesOffset)
  return { sunset, sunrise }
}

function isNightAtThePlant (plant, _now) {
  const now = new Date(_now)
  const { sunset, sunrise } = getPlantSunTimes(plant, now)
  // alog(`Dopo aver applicato gli offset, per l'impianto abbiamo sunrise=${sunrise.toISOString()} e sunset=${sunset.toISOString()}`, null, 'plant')
  return (now.getTime() < sunrise.getTime() || now.getTime() > sunset.getTime())
}
// funzione che ritorna la durata effettiva dell'anomalia (tiene conto solo delle ore di esercizio)
export const getAnomalyDuration = (_from, _to, plant) => {
  const from = new Date(_from)
  const to = new Date(_to)

  const cursor = new Date(from)
  let totalMinutes = 0
  while (cursor.getTime() < to.getTime()) {
    if (!isNightAtThePlant(plant, cursor)) {
      totalMinutes += 15
    }
    cursor.setMinutes(cursor.getMinutes() + 15)
  }

  // Diamo l'ouput in ms
  return totalMinutes * 60 * 1000
}
