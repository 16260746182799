import { useState } from 'react'
import clsx from 'clsx'
import { Box, Grid, ListItemText, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core'
import { HexColorPicker } from 'react-colorful'
import log from '@pelv/frontlog'

const useStyles = makeStyles(() => ({
  root: {}
}))

function ElectricLineContent ({ className, ...rest }) {
  const { selectedObject, element, setElement, powerLines } = rest
  log([
    { text: 'selectedObject => ', variable: selectedObject, tag: 'electricLineContent' },
    { text: 'element => ', variable: element, tag: 'electricLineContent' }
  ])

  const line = element && element.config.length > 0
    ? element.config[0].data
        ? element.config[0].data.line
            ? element.config[0].data.line.uuid
            : ''
        : ''
    : ''

  const color = element && element.config.length > 0
    ? element.config[0].data
        ? element.config[0].data.draw && element.config[0].data.draw.backgroundColor
            ? element.config[0].data.draw.backgroundColor
            : '#0000FF'
        : '#0000FF'
    : '#0000FF'

  const [selectedLine, setSelectedLine] = useState(line)
  const [currentColor, setCurrentColor] = useState(color)

  const classes = useStyles()
  return (
    <div className={clsx(classes.root, className)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6'>Linea</Typography>
              <Box my={2} width='100%'>
                <TextField
                  size='small'
                  color='primary'
                  variant='outlined'
                  select
                  fullWidth
                  label='Seleziona Linea'
                  value={selectedLine}
                  onChange={e => {
                    e.persist()
                    setElement(prevElement => {
                      const currentLine = powerLines.find(or => or.uuid === e.target.value)
                      if (prevElement.config.length > 0) {
                        // se l'elemento non ha l'oggetto data lo imposto a oggetto vuoto
                        if (!prevElement.config[0].data) {
                          prevElement.config[0].data = {}
                        }
                        prevElement.config[0].data = {
                          ...prevElement.config[0].data,
                          line: currentLine,
                          lightsNum: currentLine.num,
                          power: currentLine.power
                        }
                      } else {
                        prevElement.config.push({
                          devices: [],
                          data: {
                            line: currentLine,
                            lightsNum: currentLine.num,
                            power: currentLine.power,
                            draw: {}
                          }
                        })
                      }
                      return { ...prevElement }
                    })
                    setSelectedLine(e.target.value)
                  }}
                  // onChange={(e) => setSelectedLine(e.target.value)}
                  SelectProps={{
                    renderValue: (renderSelected) => <ListItemText primaryTypographyProps={{ style: { lineHeight: 1 } }} style={{ marginTop: 0, marginBottom: 0 }}>{powerLines.find(or => or.uuid === renderSelected) ? powerLines.find(or => or.uuid === renderSelected).name : ''}</ListItemText>
                  }}
                >
                  {
                    powerLines.filter(el => el.valid === true).map(el => (
                      <MenuItem
                        dense
                        key={el.uuid}
                        value={el.uuid}
                      >
                        {el.name}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant='h6'>Colore della linea</Typography>
          <Box my={2} width='100%'>
            <HexColorPicker
              style={{ height: '100px' }} color={currentColor} onChange={setCurrentColor} onMouseUp={(e) => {
                e.persist()
                setElement((prevElement) => {
                  // console.log(prevElement)
                  if (prevElement.config.length > 0) {
                    if (!prevElement.config[0].data.draw) {
                      prevElement.config[0].data.draw = {}
                    }
                    prevElement.config[0].data.draw = {
                      ...prevElement.config[0].data.draw,
                      backgroundColor: currentColor
                    }
                  } else {
                    prevElement.config.push({
                      devices: [],
                      data: {
                        draw: {
                          backgroundColor: currentColor
                        }
                      }
                    })
                  }
                  return { ...prevElement }
                })
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}
export default ElectricLineContent
