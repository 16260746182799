import { useCallback, useRef, useState } from 'react'
import Canvas from './Canvas'
import LibraryMenu from './LibraryMenu'
import LibraryPlaceholder from './LibraryPlaceholder'
import { fabric } from 'fabric'
import ObjectConfigDialog from './ObjectConfigDialog'
import PropTypes from 'prop-types'
import { makeStyles, Menu } from '@material-ui/core'
import libraries from './libraryObjects'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  canvasWrapper: {
    height: '100%'
  },
  paper: {
    backgroundColor: theme.palette.common.white
  },
  hidePointerEvents: {
    pointerEvents: 'none',
    opacity: 0.4
  }
}))
function Planimetry ({
  pointerEvents = true,
  zoom = null,
  onPan = null,
  onZoom = null,
  isTransparent = false,
  openDeviceDetail = null,
  getDeviceAnomalies = null,
  goToAnomalies = null,
  selectAnomaly = null,
  formatter = null,
  distribution = {},
  orientations = [],
  powerLines = [],
  assets = [],
  devices = [],
  fieldData = {},
  importedCanvas = {},
  saveCanvas,
  saveOrientation,
  deleteOrientation,
  modifyOrientation,
  unselectable = false,
  plantType = 'pv',
  className
}) {
  const parentCanvasRef = useRef()
  const classes = useStyles()
  const [showPlaceholder, setShowPlaceholder] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [selectedObject, setSelectedObject] = useState(null)
  const [canvasElements, setCanvasElements] = useState(null)
  const [showConfigDialog, setShowConfigDialog] = useState(false)
  const [editingObject, setEditingObject] = useState(null)
  const [config, setConfig] = useState([])
  const [showLibrary, setShowLibrary] = useState(false)

  // struttura che lega l'oggetto reale alla config dell'oggetto disegnato
  const [deviceIds, setDeviceIds] = useState([])
  // struttura che lega dati di configurazione specifica alla config dell'oggetto disegnato
  const [additionalData, setAdditionalData] = useState({})

  // elemento di config specifica dell'oggetto selezionato che si sta modificando
  const [element, setElement] = useState({ config: [] })

  // istanza del canvas
  const [canvas, setCanvas] = useState(null)

  // Stato locale delle informazioni da mostrare nel tooltip al passaggio del mouse sugli oggetti di libreria
  const [tooltipInfos, setTooltipInfos] = useState([])
  const [tooltipAnomalies, setTooltipAnomalies] = useState([])
  const [showTooltip, setShowTooltip] = useState(false)
  const [positions, setPositions] = useState({ x: null, y: null })
  const [currentObjectType, setCurrentObjectType] = useState(null)

  // Oggetti di libreria dinamici in base al tipo di impianto
  const libraryObjects = plantType ? libraries[plantType] || libraries.pv : libraries.pv

  const handleClose = () => {
    setPositions({ x: null, y: null })
    setTooltipInfos([])
    setShowTooltip(false)
    setCurrentObjectType(null)
  }

  const activateTooltip = useCallback(async (event) => {
    const configCopy = JSON.parse(JSON.stringify(config))

    for (const s in fieldData) {
      const uuid = s
      const thisDevice = fieldData[uuid]
      for (let c = 0; c < configCopy.length; c++) {
        if (configCopy?.[c]?.devices?.length > 0) {
          for (let n = 0; n < configCopy[c].devices.length; n++) {
            if (configCopy[c].devices[n].deviceId === uuid) {
              for (const m in thisDevice) {
                if (configCopy[c].devices[n].properties[m]) {
                  configCopy[c].devices[n].properties[m].value = thisDevice[m]
                }
              }
            }
          }
        }
      }
    }

    // console.log('mouseOver: ', configCopy)

    const currentElement = configCopy.find(el => el.id === event.target.id)
    if (currentElement) {
      const infos = libraryObjects[currentElement.canvasLibraryType] && libraryObjects[currentElement.canvasLibraryType].event && libraryObjects[currentElement.canvasLibraryType].event.mouseOver(currentElement)
      if (infos && getDeviceAnomalies) {
        const anomalies = await getDeviceAnomalies(currentElement.uuid)
        if (anomalies) {
          setTooltipAnomalies(anomalies)
          setCurrentObjectType(currentElement.canvasLibraryType)
          setTooltipInfos(infos)
          setPositions({ x: event.e.clientX - 2, y: event.e.clientY - 4 })
          setShowTooltip(true)
        }
      }
    }
    // eslint-disable-next-line
  }, [fieldData])

  // console.log('element: ', element)
  // Metodo che interpreta le strutture di libraryObjects ricavandone una struttura adatta al metodo 1
  // Prende in ingresso un elemento di libraryObjects e restituisce il corrispettivo elemento di libreria fabric
  /*
    libraryObject = {
      draw: {
        '<type>': 'rect',
        width: 20,
        height: 20,
        left: 0,
        top: 0,
        fill: '#000',
        stroke: '#fff'
      },
    }

    return = new fabric.<ElementType>()
  */
  const generateCanvasObjectFromLibrary = (libraryStructure, load = false) => {
    const objectConfig = load ? libraryStructure : libraryStructure.draw
    const { type, ...rest } = objectConfig
    if (type === 'rect') {
      const rect = new fabric.Rect({
        ...rest
      })
      return rect
    } else if (type === 'triangle') {
      const triangle = new fabric.Triangle({
        ...rest
      })
      return triangle
    } else if (type === 'text') {
      const text = new fabric.Text(rest.text, {
        ...rest
      })
      return text
    } else if (type === 'group') {
      const objects = rest.objects.map((obj) => {
        if (obj.type === 'circle') {
          return new fabric.Circle({
            ...obj
          })
        } else if (obj.type === 'text') {
          return new fabric.Text(obj.text, {
            ...obj
          })
        } else if (obj.type === 'rect') {
          return new fabric.Rect({
            ...obj
          })
        } else if (obj.type === 'path') {
          return new fabric.Path(obj.path, { ...obj })
        }
        return null
      })

      const group = new fabric.Group(
        objects.filter((el) => el),
        {
          ...rest
        }
      )

      return group
    }
  }

  return (
    <div className={pointerEvents ? className : clsx(className, classes.hidePointerEvents)}>
      {showTooltip
        ? (
          <Menu
            classes={{ paper: classes.paper }}
            MenuListProps={{ onMouseLeave: handleClose }}
            keepMounted
            open={positions.y !== null}
            onClose={handleClose}
            anchorReference='anchorPosition'
            anchorPosition={
              positions.y !== null && positions.x !== null
                ? { top: positions.y, left: positions.x }
                : undefined
            }
          >
            <div>
              {currentObjectType
                ? libraryObjects[currentObjectType].tooltip({ tooltipInfos, tooltipAnomalies, selectAnomaly, goToAnomalies })
                : null}
            </div>
          </Menu>
          )
        : null}
      {showConfigDialog
        ? (
          <ObjectConfigDialog
            formatter={formatter}
            config={config}
            setConfig={setConfig}
            element={element}
            setElement={setElement}
            generateCanvasObjectFromLibrary={generateCanvasObjectFromLibrary}
            selectedObject={selectedObject}
            editingObject={editingObject}
            setCanvasElements={setCanvasElements}
            onClose={() => {
              setShowConfigDialog(false)
              setShowLibrary(true)
              setEditingObject(null)
              setElement({ config: [] })
              setAdditionalData({})
            }}
            open={showConfigDialog}
            deviceIds={deviceIds}
            orientations={orientations}
            powerLines={powerLines}
            devices={devices}
            additionalData={additionalData}
          >
            {selectedObject &&
              selectedObject.modal({
                selectedObject,
                editingObject,
                element,
                setElement,
                config,
                distribution,
                orientations,
                powerLines,
                saveOrientation,
                deleteOrientation,
                modifyOrientation,
                devices,
                assets,
                deviceIds,
                setDeviceIds,
                additionalData,
                setAdditionalData
              })}
          </ObjectConfigDialog>
          )
        : null}
      <div
        className={classes.canvasWrapper}
        ref={parentCanvasRef}
        onDragStart={() => null}
        onMouseMove={
          showPlaceholder
            ? (e) => {
                setPosition({ x: e.pageX - 40 / 2, y: e.pageY - 40 / 2 })
              }
            : () => null
        }
      >
        <Canvas
          overrideZoom={zoom}
          onPan={onPan}
          onZoom={onZoom}
          isTransparent={isTransparent}
          plantType={plantType}
          libraryObjects={libraryObjects}
          openDeviceDetail={openDeviceDetail}
          activateTooltip={activateTooltip}
          setElement={setElement}
          canvas={canvas}
          setCanvas={setCanvas}
          formatter={formatter}
          unselectable={unselectable}
          setEditingObject={setEditingObject}
          setSelectedObject={setSelectedObject}
          setDeviceIds={setDeviceIds}
          setAdditionalData={setAdditionalData}
          importedCanvas={importedCanvas}
          config={config}
          assets={assets}
          devices={devices}
          saveCanvas={saveCanvas}
          fieldData={fieldData}
          setConfig={setConfig}
          canvasElements={canvasElements}
          setCanvasElements={setCanvasElements}
          setShowConfigDialog={setShowConfigDialog}
          generateCanvasObjectFromLibrary={generateCanvasObjectFromLibrary}
        />
        {showPlaceholder
          ? (
            <LibraryPlaceholder
              canvas={canvas}
              position={position}
              setShowConfigDialog={setShowConfigDialog}
              setPosition={setPosition}
              showPlaceholder={showPlaceholder}
              setShowPlaceholder={setShowPlaceholder}
              parentCanvasRef={parentCanvasRef}
              selectedObject={selectedObject}
              setShowLibrary={setShowLibrary}
            />
            )
          : null}
      </div>
      {unselectable
        ? null
        : (
          <LibraryMenu
            libraryObjects={libraryObjects}
            showLibrary={showLibrary}
            setShowLibrary={setShowLibrary}
            selectedObject={selectedObject}
            setSelectedObject={setSelectedObject}
            setPosition={setPosition}
            setShowPlaceholder={setShowPlaceholder}
          />
          )}
    </div>
  )
}

Planimetry.propTypes = {
  saveCanvas: PropTypes.func
}

export default Planimetry
