import { memo, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { makeStyles, Box, Typography } from '@material-ui/core'
import LoadingCard from '../LoadingCard'
import useCharts from '@/hooks/useCharts'
import { europeNum } from '@/utils/general'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}))

function EnergyDeviation ({
  hasGlass = false,
  data = [],
  width = 300,
  height = 300,
  className,
  hideGraph = false,
  hideMessage = 'Impossibile visualizzare il grafico.',
  ...rest
}) {
  const classes = useStyles()
  // Prendo le istanze di core e am4Charts disponibili nel context
  const { chartsCore, amCharts } = useCharts()
  // Creo una ref del grafico
  const chartRef = useRef(null)
  // Variabile che determina la prima renderizzazione a schermo
  const [firstPaint, setFirstPaint] = useState(true)

  const colorMapping = {
    immessa: chartsCore.color('#713e8d'),
    prodotta: chartsCore.color('#50af57'),
    attesa: chartsCore.color('#5f93d1')
  }

  const normalizeData = function (firstIndex, data) {
    const dataCopy = JSON.parse(JSON.stringify(data))
    const valuesArray = dataCopy.map(el => el.value !== '' ? el.value : 0)
    // Cerco l'indice del massimo valore nell'array
    const maxIndex = valuesArray.indexOf(Math.max(...valuesArray))
    // Indeice dell'array per il cui valore dobbiamo normalizzare la serie
    const normalizationIndex = maxIndex !== firstIndex ? maxIndex : firstIndex
    for (let s = 0; s < dataCopy.length; s++) {
      if (s !== normalizationIndex) {
        const expectedEnergyValue = isNaN(Number(dataCopy[dataCopy.length - 1].value)) ? 0 : Number(dataCopy[dataCopy.length - 1].value)
        dataCopy[s] = {
          ...dataCopy[s],
          percentValue: Number(((dataCopy[s].value / data[normalizationIndex].value) * 100).toFixed(2)),
          // realPercentValue: Number((dataCopy[s].value * 100 / (dataCopy[dataCopy.length - 1].value)).toFixed(2))
          realPercentValue: expectedEnergyValue !== 0 ? europeNum(dataCopy[s].value * 100 / expectedEnergyValue, 2) : null
        }
      }
    }
    if (dataCopy.length > 0) {
      const expectedEnergyValue = isNaN(Number(dataCopy[dataCopy.length - 1].value)) ? 0 : Number(dataCopy[dataCopy.length - 1].value)

      dataCopy[normalizationIndex] = {
        ...dataCopy[normalizationIndex],
        percentValue: 100,
        // realPercentValue: europeNum(dataCopy[normalizationIndex].value * 100 / (dataCopy[dataCopy.length - 1].value), 1)
        realPercentValue: expectedEnergyValue !== 0 ? europeNum(dataCopy[normalizationIndex].value * 100 / expectedEnergyValue, 2) : null
      }
    } else {
      dataCopy[normalizationIndex] = {
        ...dataCopy[normalizationIndex],
        percentValue: 0,
        realPercentValue: 0
      }
    }
    return dataCopy
  }

  // Funzione per la formattazione dei dati nel formato richiesto dalla libreria grafico
  const formatData = (dataArr) => {
    return normalizeData(0, dataArr).map((el, index) => ({
      category: dataArr.length - 1 === index ? el.label : `${el.label} ${el.realPercentValue === null ? '' : `(${el.realPercentValue}%)`}`,
      name: el.label,
      value: el.percentValue,
      tooltipValue: `${europeNum(el.value, 2)} kWh`,
      full: 100
    }))
  }
  // Funzione che crea gli assi del grafico
  const createAxis = (chartInstance) => {
    // Creo l'asse delle categorie (PR e Produzione)
    const categoryAxis = chartInstance.yAxes.push(new amCharts.CategoryAxis())
    // Faccio il bind dei dati da mostrare (quello che si trova nel campo 'category' dei dati passati al grafico)
    categoryAxis.dataFields.category = 'category'
    // Imposto gli stili per l'asse
    categoryAxis.renderer.grid.template.location = 0
    categoryAxis.renderer.grid.template.strokeOpacity = 0
    categoryAxis.renderer.labels.template.horizontalCenter = 'right'
    categoryAxis.renderer.labels.template.fontWeight = 500
    categoryAxis.renderer.labels.template.fontFamily = 'Roboto, sans-serif'
    categoryAxis.renderer.labels.template.fontSize = 12
    categoryAxis.renderer.labels.template.adapter.add('fill', function (fill, target) {
      if (target.dataItem.index < 0) return fill

      const colorKey = target.dataItem?.dataContext?.name?.split('.')?.[1]?.trim().toLowerCase() || ''
      const serieColor = colorMapping[colorKey] || chartInstance.colors.getIndex(target.dataItem.index)
      return serieColor
    })

    categoryAxis.renderer.minGridDistance = 10
    // Creo l'asse dei valori
    const valueAxis = chartInstance.xAxes.push(new amCharts.ValueAxis())
    // Imposto gli stili per l'asse
    valueAxis.renderer.labels.template.disabled = true
    valueAxis.renderer.grid.template.strokeOpacity = 0
    valueAxis.renderer.labels.template.fill = '#ffffff'
    valueAxis.renderer.labels.template.fontFamily = 'Roboto, sans-serif'
    valueAxis.renderer.labels.template.fontSize = 10
    valueAxis.min = 0
    valueAxis.max = 100
    valueAxis.strictMinMax = true
    // Disattivo i tooltip
    valueAxis.cursorTooltipEnabled = false
    // Disattivo lo zoom
    valueAxis.zoomable = false
  }

  // Funzione in cui definisco tutte le serie da graficare
  const createSeries = (chartInstance) => {
    // Creo la prima serie, che sarà lo sfondo delle barre con il valore reale
    const backgroundSeries = chartInstance.series.push(new amCharts.RadarColumnSeries())
    if (!firstPaint) {
      backgroundSeries.showOnInit = false
    }
    // Faccio il bind dei dati
    backgroundSeries.dataFields.valueX = 'full'
    backgroundSeries.dataFields.categoryY = 'category'
    // Permetto alle serie di essere una sotto l'altra
    backgroundSeries.clustered = false
    // Assegno un colore alternativo alle barre e setto gli stili della serie
    backgroundSeries.columns.template.fill = new chartsCore.InterfaceColorSet().getFor('alternativeBackground')
    backgroundSeries.columns.template.fillOpacity = 0.2
    backgroundSeries.columns.template.strokeWidth = 0
    backgroundSeries.tooltip.disabled = true
    backgroundSeries.columns.template.radarColumn.cornerRadius = 20

    // Creo la seconda serie che contiene i valori veri e propri da mostrare
    const valueSeries = chartInstance.series.push(new amCharts.RadarColumnSeries())
    if (!firstPaint) {
      valueSeries.showOnInit = false
    }

    // Faccio il bind dei dati
    valueSeries.dataFields.valueX = 'value'
    valueSeries.dataFields.categoryY = 'category'

    // Setto gli stili della serie
    valueSeries.clustered = false
    valueSeries.columns.template.strokeWidth = 0
    valueSeries.columns.template.tooltipText = '{name}: [bold]{tooltipValue}'
    valueSeries.tooltip.getStrokeFromObject = true
    valueSeries.tooltip.label.fontFamily = 'Roboto, sans-serif'
    valueSeries.tooltip.label.fontSize = 14
    valueSeries.columns.template.radarColumn.cornerRadius = 20
    valueSeries.columns.template.adapter.add('fill', function (fill, target) {
      const colorKey = target.dataItem?.dataContext?.name?.split('.')?.[1]?.trim().toLowerCase() || ''
      const serieColor = colorMapping[colorKey] || chartInstance.colors.getIndex(target.dataItem.index)
      return serieColor
    })
  }

  useEffect(() => {
    if (data.length > 0) {
      // Prendo il div con l'id indicato per creare il grafico Radar
      const chart = chartsCore.create('energy-deviation-chart', amCharts.RadarChart)
      // Dopo la prima animazione la disabilito per il polling
      if (!firstPaint) {
        chart.showOnInit = false
      }
      // Assegno i dati al grafico
      chart.data = formatData(data)
      // Setto il grafico per essere 3/4 di circonferenza
      chart.startAngle = -90
      chart.endAngle = 180
      // Setto lo spessore delle barre del grafico
      chart.innerRadius = chartsCore.percent(30)
      // Aggiungo il cursore
      chart.cursor = new amCharts.RadarCursor()
      // Creo gli assi del grafico
      createAxis(chart)
      // Creo le serie da graficare
      createSeries(chart)

      chartRef.current = chart
      if (firstPaint) {
        setFirstPaint(false)
      }

      return () => {
        chartRef.current.dispose()
      }
    }
  }, [data])

  console.log('hideGraph => ', hideGraph)
  console.log('hideMessage => ', hideMessage)

  if (hideGraph) {
    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        style={{
          width: width || 350,
          height: height || 350
        }}
      >
        <Typography component='span' variant='body1' align='center'>{hideMessage}</Typography>
      </Box>
    )
  }

  return data.length > 0
    ? (
      <div
        id='energy-deviation-chart'
        style={{
          width: width || 300,
          height: height || 300
        }}
        className={clsx(classes.root, className)}
        {...rest}
      />
      )
    : <LoadingCard glass={hasGlass} />
}
export default memo(EnergyDeviation)
