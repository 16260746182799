import { libraryObjects as pvLibraryObjects } from './pv'
import { libraryObjects as ledLibraryObjects } from './led'
import { libraryObjects as hydroLibraryObjects } from './hydro'

export default {
  pv: pvLibraryObjects,
  'pv-light': pvLibraryObjects,
  'pv-pro-light': pvLibraryObjects,
  'pv-pro-realtime': pvLibraryObjects,
  led: ledLibraryObjects,
  hydro: hydroLibraryObjects
}
