import useAuth from '@/hooks/useAuth'
import { basicColors } from '@/theme'
import { customFilterContractTypes } from '@/views/overview/MapsView/filtersUtils'
import { Box, Card, Collapse, Grid, IconButton, SvgIcon, Tooltip, Typography, makeStyles, useMediaQuery } from '@material-ui/core'
import { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import MaintenanceIcon from '@material-ui/icons/ReportProblemTwoTone'
import api from '@micmnt/apis'
import { toggleMaintenance, updatedPlantInfo } from '@/slices/publicEnergy/plantView'
import clsx from 'clsx'
import { europeNum } from '@/utils/general'
import PlantSettings from '@/layouts/SmartLayout/TopBar/PlantSettings'
import PlanimetryView from './PlanimetryView'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2)
  },
  cardRoot: {
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(1)
  },
  okStatusMini: {
    background: basicColors.darkGreen,
    boxShadow: 'inset 0px 0px 5px 2px rgba(21,144,91,0.5), 0px 0px 5px 2px rgba(21,144,51,0.6)'
  },
  warningStatusMini: {
    background: basicColors.darkYellow,
    boxShadow: 'inset 0px 0px 5px 2px rgba(255, 152, 50,0.5), 0px 0px 5px 2px rgba(255, 152, 0,0.6)'
  },
  errorStatusMini: {
    background: basicColors.darkRed,
    boxShadow: 'inset 0px 0px 5px 2px rgba(244, 67, 94,0.5), 0px 0px 5px 2px rgba(244, 67, 54,0.6)'
  },
  glassBackground: {
    '@supports (backdrop-filter: none) or (-webkit-backdrop-filter: none)': {
      '-webkit-backdrop-filter': 'saturate(120%) blur(10px)',
      backdropFilter: 'saturate(190%) blur(16px)',
      backgroundColor: 'rgba(16, 26, 38, 0.6)'
    }
  }
}))

const Overview = () => {
  const classes = useStyles()
  const { currentProject } = useAuth()
  const { uuid: plantId, maintenance, documents, name, anagraphic, hasError, anomaliesBar } = useSelector((state) => state.publicEnergyPlantView)
  const dispatch = useDispatch()

  // Variabile per sapere se i dettagli di anagrafica sono collasati o meno
  const [showAnagraphic, setShowAnagraphic] = useState(false)

  const [recalculations, setRecalculations] = useState([])

  // Ricavo il tipo di contratto
  const contractType = customFilterContractTypes.find(el => el.value === anagraphic.contractType)

  const getRecalculations = useCallback(async (plantId) => {
    if (plantId) {
      const { data: recalculationsList } = await api.get({ savedUrl: 'plantMetricsRecalculation', path: `?plantId=${plantId}` })
      setRecalculations(recalculationsList)
    }
  }, [])

  useEffect(() => {
    getRecalculations(plantId)
    const overviewInterval = setInterval(() => {
      if (plantId) {
        dispatch(updatedPlantInfo({ plantId, currentProject }))
      }
    }, 60000)
    return () => {
      clearInterval(overviewInterval)
    }
  }, [dispatch, plantId, hasError])

  const executeRecalculations = async (plantId, timeFrom, timeTo) => {
    if (plantId && timeFrom && timeTo) {
      const recalculationBody = {
        projectId: currentProject.uuid,
        plantId,
        from: timeFrom,
        to: timeTo
      }
      const { data: response, error } = await api.post({ savedUrl: 'plantMetricsRecalculation', body: recalculationBody, fullResponse: true })

      if (error) return false

      const status = response?.data?.status
      if (status || status === 200) {
        const currentRecalculationList = [response?.data?.data || {}]
        setRecalculations(currentRecalculationList)
        return true
      }
      return false
    }
  }

  const isExtraSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {!isExtraSmall && (
          <>
            <Grid item xs={12} sm={6} style={{ display: 'flex', flexDirection: 'column' }}>
              <Collapse in={showAnagraphic} collapsedSize={61} style={{ borderRadius: 8, display: 'block' }}>
                <Card className={clsx(classes.cardRoot, classes.glassBackground)}>
                  <Box mt={1} display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography variant='h4' component='div'>{`${name || ''} - ${europeNum(anagraphic.peakPower, 2) || '-'} kWp`}</Typography>
                    <Box display='flex' alignItems='center'>
                      {maintenance
                        ? (
                          <Tooltip title='Impianto in manutenzione'>
                            <Box display='flex' alignItems='center' mr={2}>
                              <SvgIcon size='small'>
                                <MaintenanceIcon style={{ color: basicColors.darkYellow }} />
                              </SvgIcon>
                            </Box>
                          </Tooltip>
                          )
                        : null}
                      <PlantSettings plantId={plantId} executeRecalculations={executeRecalculations} recalculations={recalculations} toggleMaintenance={toggleMaintenance} maintenance={maintenance} documents={documents} />
                      <IconButton onClick={() => setShowAnagraphic(prevValue => !prevValue)} size='small'>
                        {showAnagraphic ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </IconButton>
                    </Box>
                  </Box>
                  <Box mt={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Typography className={classes.cardLabel} component='div' variant='caption'>Nome Cliente</Typography>
                        <Tooltip title={anagraphic.referentClient || '-'}>
                          <Typography style={{ maxWidth: '100%' }} noWrap className={classes.cardValueSecondary} component='div'>{anagraphic.referentClient || '-'}</Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.cardLabel} component='div' variant='caption'>Tipo contratto</Typography>
                        <Tooltip title={contractType && contractType !== undefined ? contractType.label : '-'}>
                          <Typography style={{ maxWidth: '100%' }} noWrap className={classes.cardValueSecondary} component='div'>{contractType && contractType !== undefined ? contractType.label : '-'}</Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.cardLabel} component='div' variant='caption'>Referente Venera</Typography>
                        <Tooltip title={anagraphic.referentName || '-'}>
                          <Typography style={{ maxWidth: '100%' }} noWrap className={classes.cardValueSecondary} component='div'>{anagraphic.referentName || '-'}</Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.cardLabel} component='div' variant='caption'>Telefono</Typography>
                        <Tooltip title={anagraphic.referentPhone || '-'}>
                          <Typography style={{ maxWidth: '100%' }} noWrap className={classes.cardValueSecondary} component='div'>{anagraphic.referentPhone || '-'}</Typography>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Typography className={classes.cardLabel} component='div' variant='caption'>Referente O&M</Typography>
                        <Tooltip title={anagraphic.operationAndMaintenaceName || '-'}>
                          <Typography style={{ maxWidth: '100%' }} noWrap className={classes.cardValueSecondary} component='div'>{anagraphic.operationAndMaintenaceName || '-'}</Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.cardLabel} component='div' variant='caption'>Contatto O&M</Typography>
                        <Tooltip title={anagraphic.operationAndMaintenacePhone || '-'}>
                          <Typography style={{ maxWidth: '100%' }} noWrap className={classes.cardValueSecondary} component='div'>{anagraphic.operationAndMaintenacePhone || '-'}</Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.cardLabel} component='div' variant='caption'>Email</Typography>
                        <Tooltip title={anagraphic.operationAndMaintenaceEmail || '-'}>
                          <Typography style={{ maxWidth: '100%' }} noWrap className={classes.cardValueSecondary} component='div'>{anagraphic.operationAndMaintenaceEmail || '-'}</Typography>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Box>
                </Card>
              </Collapse>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card className={clsx(classes.cardRoot, classes.glassBackground)}>
                <Grid container spacing={2}>
                  {Object.keys(anomaliesBar).map((key, index) => {
                    return (
                      <Grid item key={index} xs={4}>
                        <Box width='100%' display='flex' alignItems='center'>
                          <Typography className={classes.cardLabel} component='div' variant='caption'>{anomaliesBar[key].label}</Typography>
                          <div
                            className={
                              anomaliesBar[key].status === 'anomaly_communication' || anomaliesBar[key].status === 'maintenance' || anomaliesBar[key].status === 'anomaly_energy'
                                ? classes.warningStatusMini
                                : anomaliesBar[key].status === 'anomaly_component'
                                  ? classes.errorStatusMini
                                  : classes.okStatusMini
                            }
                            style={{ marginBottom: '2px', marginLeft: '8px', width: 8, height: 8, borderRadius: '50%' }}
                          />
                        </Box>
                        <Typography className={classes.cardValueSecondary} component='div'>{anomaliesBar[key].total || 0}</Typography>
                      </Grid>
                    )
                  })}
                </Grid>
              </Card>
            </Grid>
          </>
        )}
      </Grid>
      <Grid item xs={12} style={{ height: 'calc(100% - 80px)' }}>
        <Box height='calc(100% - 130px)'>
          <PlanimetryView />
        </Box>
      </Grid>
    </div>
  )
}

export default memo(Overview)
