export const apiConfig = {
  accounts: '/accounts',
  aclRules: '/:projectId/aclrules',
  anomaliesList: '/:projectId/anomalies',
  anomaliesConfig: '/:projectId/anomalyConfigurations',
  apikeys: '/:projectId/apikeys',
  dashboard: '/:projectId/dashboard',
  devices: '/:projectId/dashboard/components/devices',
  deviceTypes: '/:projectId/devicetypes',
  fetchLinks: '/links',
  forceSummaryRefresh: '/:projectId/dashboard/plants',
  groupmemberships: '/:projectId/groupmemberships',
  groupslist: '/:projectId/groups',
  jobs: '/jobs',
  links: '/:projectId/links',
  logsInExcel: '/:projectId/dashboard/telemetry/export',
  logs: '/:projectId/telemetry',
  logout: '/accounts/logout',
  nodeConfig: '/:projectId',
  nodes: '/:projectId/nodes',
  nodesList: '/:projectId/dashboard/components/nodes',
  nodeTypes: '/:projectId/nodetypes',
  openstreetmap: 'https://nominatim.openstreetmap.org',
  orientations: '/:projectId/orientations',
  plantDetails: '/:projectId/dashboard/plants',
  plantMetricsRecalculation: '/:projectId/dashboard/recalculations',
  plants: '/:projectId/plants',
  plantsList: '/:projectId/dashboard/components/plants',
  plantsMap: '/:projectId/dashboard/plants/map',
  reports: '/:projectId/reports',
  roles: '/:projectId/roles',
  uploadDatasheet: '/:projectId/uploads/devices',
  uploadFiles: '/:projectId/imports/measures',
  uploads: '/:projectId/uploads',
  usersList: '/:projectId/dashboard/users'
}

export default apiConfig
