import moment from 'moment'
import mock from '@/utils/___mock'
import { getDatesFromPeriod } from '@/views/overview/AnomaliesView/pv/utils'

// Dati Mock Fotovoltaico
const pvLiveData = {
  totalAnomalies: 25,
  totalInactiveHours: 1678,
  openAnomalies: 8,
  closedAnomalies: 18,
  categoryStatus: {
    Comunicazione: {
      name: 'Comunicazione',
      count: 66,
      total: 2668032220,
      duration: 0,
      durations: [
        1340066517,
        91765712,
        33265675,
        33265042,
        32365768,
        32365392,
        31465868,
        31465554,
        30565786,
        30565799,
        29665898,
        29665471,
        28765589,
        28765517,
        27865550,
        27865532,
        26965631,
        26965513,
        26065766,
        26065609,
        25165819,
        25165692,
        24265710,
        24265675,
        23365605,
        23365553,
        22465739,
        22465622,
        21565718,
        21565686,
        20665596,
        20665427,
        19765789,
        19765657,
        18865722,
        18865487,
        17965650,
        17965530,
        17065759,
        17065444,
        16165733,
        16165614,
        15265472,
        15265320,
        14365748,
        14365535,
        13465735,
        13465529,
        12565819,
        12565473,
        11665629,
        11665499,
        10765733,
        10765666,
        9865740,
        9865543,
        8965788,
        8965471,
        8065670,
        8065494,
        7165742,
        7165564,
        6265822,
        6265519,
        5365753,
        5365540
      ],
      subcategories: {
        Datalogger: {
          name: 'Datalogger',
          count: 66,
          total: 2668032219,
          duration: 0,
          durations: [
            1340066517,
            91765712,
            33265675,
            33265042,
            32365768,
            32365392,
            31465868,
            31465554,
            30565786,
            30565799,
            29665898,
            29665471,
            28765589,
            28765517,
            27865550,
            27865532,
            26965631,
            26965513,
            26065766,
            26065609,
            25165819,
            25165692,
            24265710,
            24265675,
            23365605,
            23365553,
            22465739,
            22465622,
            21565718,
            21565686,
            20665596,
            20665427,
            19765789,
            19765657,
            18865722,
            18865487,
            17965650,
            17965530,
            17065759,
            17065444,
            16165733,
            16165614,
            15265472,
            15265320,
            14365748,
            14365535,
            13465735,
            13465529,
            12565819,
            12565473,
            11665629,
            11665499,
            10765733,
            10765666,
            9865740,
            9865543,
            8965788,
            8965471,
            8065670,
            8065493,
            7165742,
            7165564,
            6265822,
            6265519,
            5365753,
            5365540
          ],
          averageDuration: 40424730.59090909
        },
        Centralina: {
          name: 'Centralina',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Inverter: {
          name: 'Inverter',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Contatore: {
          name: 'Contatore',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 40424730.60606061
    },
    Energia: {
      name: 'Energia',
      count: 1,
      total: 969266517,
      duration: 0,
      durations: [
        969266517
      ],
      subcategories: {
        Potenza: {
          name: 'Potenza',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Energia: {
          name: 'Energia',
          count: 1,
          total: 969266517,
          duration: 0,
          durations: [
            969266517
          ],
          averageDuration: 969266517
        },
        'Condizioni Ambientali': {
          name: 'Condizioni Ambientali',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Performance: {
          name: 'Performance',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 969266517
    },
    Componenti: {
      name: 'Componenti',
      count: 3,
      total: 3274999551,
      duration: 0,
      durations: [
        1178066517,
        987266517,
        1109666517
      ],
      subcategories: {
        Inverter: {
          name: 'Inverter',
          count: 2,
          total: 2287733034,
          duration: 0,
          durations: [
            1178066517,
            1109666517
          ],
          averageDuration: 1143866517
        },
        Centralina: {
          name: 'Centralina',
          count: 1,
          total: 987266517,
          duration: 0,
          durations: [
            987266517
          ],
          averageDuration: 987266517
        },
        Protezioni: {
          name: 'Protezioni',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Moduli: {
          name: 'Moduli',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 1091666517
    }
  },
  disserviceHours: [
    {
      category: 'Comunicazione',
      subCategory: 'Datalogger',
      value: 2668032219
    },
    {
      category: 'Comunicazione',
      subCategory: 'Centralina',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Inverter',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Contatore',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Potenza',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Inverter',
      value: 2287733034
    },
    {
      category: 'Componenti',
      subCategory: 'Centralina',
      value: 987266517
    },
    {
      category: 'Componenti',
      subCategory: 'Protezioni',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Moduli',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Energia',
      value: 969266517
    },
    {
      category: 'Energia',
      subCategory: 'Condizioni Ambientali',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Performance',
      value: 0
    }
  ],
  avgHoursGraph: [
    {
      category: 'Comunicazione',
      subCategory: 'Datalogger',
      value: 40424730.59090909
    },
    {
      category: 'Comunicazione',
      subCategory: 'Centralina',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Inverter',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Contatore',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Potenza',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Inverter',
      value: 1143866517
    },
    {
      category: 'Componenti',
      subCategory: 'Centralina',
      value: 987266517
    },
    {
      category: 'Componenti',
      subCategory: 'Protezioni',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Moduli',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Energia',
      value: 969266517
    },
    {
      category: 'Energia',
      subCategory: 'Condizioni Ambientali',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Performance',
      value: 0
    }
  ],
  timeDistribution: [
    {
      category: 'Comunicazione',
      name: 'Datalogger',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 8,
          y: 5
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 16,
          y: 5
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 24,
          y: 5
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 32,
          y: 5
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 40,
          y: 5
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 48,
          y: 5
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 56,
          y: 5
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 64,
          y: 5
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 64,
          y: 5
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 64,
          y: 5
        }
      ]
    },
    {
      category: 'Comunicazione',
      name: 'Centralina',
      data: []
    },
    {
      category: 'Comunicazione',
      name: 'Inverter',
      data: []
    },
    {
      category: 'Comunicazione',
      name: 'Contatore',
      data: []
    },
    {
      category: 'Energia',
      name: 'Potenza',
      data: []
    },
    {
      category: 'Componenti',
      name: 'Inverter',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 2,
          y: 1
        }
      ]
    },
    {
      category: 'Componenti',
      name: 'Centralina',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 1,
          y: 1
        }
      ]
    },
    {
      category: 'Componenti',
      name: 'Protezioni',
      data: []
    },
    {
      category: 'Componenti',
      name: 'Moduli',
      data: []
    },
    {
      category: 'Energia',
      name: 'Energia',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 1,
          y: 1
        }
      ]
    },
    {
      category: 'Energia',
      name: 'Condizioni Ambientali',
      data: []
    },
    {
      category: 'Energia',
      name: 'Performance',
      data: []
    }
  ],
  sizeBalanceGraph: [
    {
      name: '100Kw',
      data: {
        alarms: 1,
        comunication: 4,
        environmentalConditions: 0,
        datalogger: 23,
        energy: 3,
        inverter: 12,
        modules: 7,
        strings: 0,
        performance: 8,
        platform: 32,
        activePower: 11,
        report: 10,
        sensors: 3
      }
    },
    {
      name: '250Kw',
      data: {
        alarms: 23,
        comunication: 0,
        environmentalConditions: 2,
        datalogger: 0,
        energy: 0,
        inverter: 4,
        modules: 36,
        strings: 1,
        performance: 1,
        platform: 1,
        activePower: 24,
        report: 8,
        sensors: 49
      }
    },
    {
      name: '500Kw',
      data: {
        alarms: 19,
        comunication: 0,
        environmentalConditions: 27,
        datalogger: 1,
        energy: 20,
        inverter: 43,
        modules: 0,
        strings: 0,
        performance: 0,
        platform: 0,
        activePower: 0,
        report: 0,
        sensors: 0
      }
    },
    {
      name: '1Mw',
      data: {
        alarms: 7,
        comunication: 45,
        environmentalConditions: 13,
        datalogger: 18,
        energy: 46,
        inverter: 0,
        modules: 4,
        strings: 3,
        performance: 2,
        platform: 11,
        activePower: 31,
        report: 21,
        sensors: 0
      }
    }
  ]
}
const pvWeekData = {
  totalAnomalies: 114,
  totalInactiveHours: 652,
  openAnomalies: 12,
  closedAnomalies: 23,
  categoryStatus: {
    Comunicazione: {
      name: 'Comunicazione',
      count: 66,
      total: 2668032220,
      duration: 0,
      durations: [
        1340066517,
        91765712,
        33265675,
        33265042,
        32365768,
        32365392,
        31465868,
        31465554,
        30565786,
        30565799,
        29665898,
        29665471,
        28765589,
        28765517,
        27865550,
        27865532,
        26965631,
        26965513,
        26065766,
        26065609,
        25165819,
        25165692,
        24265710,
        24265675,
        23365605,
        23365553,
        22465739,
        22465622,
        21565718,
        21565686,
        20665596,
        20665427,
        19765789,
        19765657,
        18865722,
        18865487,
        17965650,
        17965530,
        17065759,
        17065444,
        16165733,
        16165614,
        15265472,
        15265320,
        14365748,
        14365535,
        13465735,
        13465529,
        12565819,
        12565473,
        11665629,
        11665499,
        10765733,
        10765666,
        9865740,
        9865543,
        8965788,
        8965471,
        8065670,
        8065494,
        7165742,
        7165564,
        6265822,
        6265519,
        5365753,
        5365540
      ],
      subcategories: {
        Datalogger: {
          name: 'Datalogger',
          count: 66,
          total: 2668032219,
          duration: 0,
          durations: [
            1340066517,
            91765712,
            33265675,
            33265042,
            32365768,
            32365392,
            31465868,
            31465554,
            30565786,
            30565799,
            29665898,
            29665471,
            28765589,
            28765517,
            27865550,
            27865532,
            26965631,
            26965513,
            26065766,
            26065609,
            25165819,
            25165692,
            24265710,
            24265675,
            23365605,
            23365553,
            22465739,
            22465622,
            21565718,
            21565686,
            20665596,
            20665427,
            19765789,
            19765657,
            18865722,
            18865487,
            17965650,
            17965530,
            17065759,
            17065444,
            16165733,
            16165614,
            15265472,
            15265320,
            14365748,
            14365535,
            13465735,
            13465529,
            12565819,
            12565473,
            11665629,
            11665499,
            10765733,
            10765666,
            9865740,
            9865543,
            8965788,
            8965471,
            8065670,
            8065493,
            7165742,
            7165564,
            6265822,
            6265519,
            5365753,
            5365540
          ],
          averageDuration: 40424730.59090909
        },
        Centralina: {
          name: 'Centralina',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Inverter: {
          name: 'Inverter',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Contatore: {
          name: 'Contatore',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 40424730.60606061
    },
    Energia: {
      name: 'Energia',
      count: 1,
      total: 969266517,
      duration: 0,
      durations: [
        969266517
      ],
      subcategories: {
        Potenza: {
          name: 'Potenza',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Energia: {
          name: 'Energia',
          count: 1,
          total: 969266517,
          duration: 0,
          durations: [
            969266517
          ],
          averageDuration: 969266517
        },
        'Condizioni Ambientali': {
          name: 'Condizioni Ambientali',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Performance: {
          name: 'Performance',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 969266517
    },
    Componenti: {
      name: 'Componenti',
      count: 3,
      total: 3274999551,
      duration: 0,
      durations: [
        1178066517,
        987266517,
        1109666517
      ],
      subcategories: {
        Inverter: {
          name: 'Inverter',
          count: 2,
          total: 2287733034,
          duration: 0,
          durations: [
            1178066517,
            1109666517
          ],
          averageDuration: 1143866517
        },
        Centralina: {
          name: 'Centralina',
          count: 1,
          total: 987266517,
          duration: 0,
          durations: [
            987266517
          ],
          averageDuration: 987266517
        },
        Protezioni: {
          name: 'Protezioni',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Moduli: {
          name: 'Moduli',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 1091666517
    }
  },
  disserviceHours: [
    {
      category: 'Comunicazione',
      subCategory: 'Datalogger',
      value: 2668032219
    },
    {
      category: 'Comunicazione',
      subCategory: 'Centralina',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Inverter',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Contatore',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Potenza',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Inverter',
      value: 2287733034
    },
    {
      category: 'Componenti',
      subCategory: 'Centralina',
      value: 987266517
    },
    {
      category: 'Componenti',
      subCategory: 'Protezioni',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Moduli',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Energia',
      value: 969266517
    },
    {
      category: 'Energia',
      subCategory: 'Condizioni Ambientali',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Performance',
      value: 0
    }
  ],
  avgHoursGraph: [
    {
      category: 'Comunicazione',
      subCategory: 'Datalogger',
      value: 40424730.59090909
    },
    {
      category: 'Comunicazione',
      subCategory: 'Centralina',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Inverter',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Contatore',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Potenza',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Inverter',
      value: 1143866517
    },
    {
      category: 'Componenti',
      subCategory: 'Centralina',
      value: 987266517
    },
    {
      category: 'Componenti',
      subCategory: 'Protezioni',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Moduli',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Energia',
      value: 969266517
    },
    {
      category: 'Energia',
      subCategory: 'Condizioni Ambientali',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Performance',
      value: 0
    }
  ],
  timeDistribution: [
    {
      category: 'Comunicazione',
      name: 'Datalogger',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 8,
          y: 5
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 16,
          y: 5
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 24,
          y: 5
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 32,
          y: 5
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 40,
          y: 5
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 48,
          y: 5
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 56,
          y: 5
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 64,
          y: 5
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 64,
          y: 5
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 64,
          y: 5
        }
      ]
    },
    {
      category: 'Comunicazione',
      name: 'Centralina',
      data: []
    },
    {
      category: 'Comunicazione',
      name: 'Inverter',
      data: []
    },
    {
      category: 'Comunicazione',
      name: 'Contatore',
      data: []
    },
    {
      category: 'Energia',
      name: 'Potenza',
      data: []
    },
    {
      category: 'Componenti',
      name: 'Inverter',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 2,
          y: 1
        }
      ]
    },
    {
      category: 'Componenti',
      name: 'Centralina',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 1,
          y: 1
        }
      ]
    },
    {
      category: 'Componenti',
      name: 'Protezioni',
      data: []
    },
    {
      category: 'Componenti',
      name: 'Moduli',
      data: []
    },
    {
      category: 'Energia',
      name: 'Energia',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 1,
          y: 1
        }
      ]
    },
    {
      category: 'Energia',
      name: 'Condizioni Ambientali',
      data: []
    },
    {
      category: 'Energia',
      name: 'Performance',
      data: []
    }
  ],
  sizeBalanceGraph: [
    {
      name: '100Kw',
      data: {
        alarms: 1,
        comunication: 4,
        environmentalConditions: 0,
        datalogger: 23,
        energy: 3,
        inverter: 12,
        modules: 7,
        strings: 0,
        performance: 8,
        platform: 32,
        activePower: 11,
        report: 10,
        sensors: 3
      }
    },
    {
      name: '250Kw',
      data: {
        alarms: 23,
        comunication: 0,
        environmentalConditions: 2,
        datalogger: 0,
        energy: 0,
        inverter: 4,
        modules: 36,
        strings: 1,
        performance: 1,
        platform: 1,
        activePower: 24,
        report: 8,
        sensors: 49
      }
    },
    {
      name: '500Kw',
      data: {
        alarms: 19,
        comunication: 0,
        environmentalConditions: 27,
        datalogger: 1,
        energy: 20,
        inverter: 43,
        modules: 0,
        strings: 0,
        performance: 0,
        platform: 0,
        activePower: 0,
        report: 0,
        sensors: 0
      }
    },
    {
      name: '1Mw',
      data: {
        alarms: 7,
        comunication: 45,
        environmentalConditions: 13,
        datalogger: 18,
        energy: 46,
        inverter: 0,
        modules: 4,
        strings: 3,
        performance: 2,
        platform: 11,
        activePower: 31,
        report: 21,
        sensors: 0
      }
    }
  ]
}
const pvMonthData = {
  totalAnomalies: 245,
  totalInactiveHours: 1678,
  openAnomalies: 25,
  closedAnomalies: 220,
  categoryStatus: {
    Comunicazione: {
      name: 'Comunicazione',
      count: 66,
      total: 2668032220,
      duration: 0,
      durations: [
        1340066517,
        91765712,
        33265675,
        33265042,
        32365768,
        32365392,
        31465868,
        31465554,
        30565786,
        30565799,
        29665898,
        29665471,
        28765589,
        28765517,
        27865550,
        27865532,
        26965631,
        26965513,
        26065766,
        26065609,
        25165819,
        25165692,
        24265710,
        24265675,
        23365605,
        23365553,
        22465739,
        22465622,
        21565718,
        21565686,
        20665596,
        20665427,
        19765789,
        19765657,
        18865722,
        18865487,
        17965650,
        17965530,
        17065759,
        17065444,
        16165733,
        16165614,
        15265472,
        15265320,
        14365748,
        14365535,
        13465735,
        13465529,
        12565819,
        12565473,
        11665629,
        11665499,
        10765733,
        10765666,
        9865740,
        9865543,
        8965788,
        8965471,
        8065670,
        8065494,
        7165742,
        7165564,
        6265822,
        6265519,
        5365753,
        5365540
      ],
      subcategories: {
        Datalogger: {
          name: 'Datalogger',
          count: 66,
          total: 2668032219,
          duration: 0,
          durations: [
            1340066517,
            91765712,
            33265675,
            33265042,
            32365768,
            32365392,
            31465868,
            31465554,
            30565786,
            30565799,
            29665898,
            29665471,
            28765589,
            28765517,
            27865550,
            27865532,
            26965631,
            26965513,
            26065766,
            26065609,
            25165819,
            25165692,
            24265710,
            24265675,
            23365605,
            23365553,
            22465739,
            22465622,
            21565718,
            21565686,
            20665596,
            20665427,
            19765789,
            19765657,
            18865722,
            18865487,
            17965650,
            17965530,
            17065759,
            17065444,
            16165733,
            16165614,
            15265472,
            15265320,
            14365748,
            14365535,
            13465735,
            13465529,
            12565819,
            12565473,
            11665629,
            11665499,
            10765733,
            10765666,
            9865740,
            9865543,
            8965788,
            8965471,
            8065670,
            8065493,
            7165742,
            7165564,
            6265822,
            6265519,
            5365753,
            5365540
          ],
          averageDuration: 40424730.59090909
        },
        Centralina: {
          name: 'Centralina',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Inverter: {
          name: 'Inverter',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Contatore: {
          name: 'Contatore',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 40424730.60606061
    },
    Energia: {
      name: 'Energia',
      count: 1,
      total: 969266517,
      duration: 0,
      durations: [
        969266517
      ],
      subcategories: {
        Potenza: {
          name: 'Potenza',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Energia: {
          name: 'Energia',
          count: 1,
          total: 969266517,
          duration: 0,
          durations: [
            969266517
          ],
          averageDuration: 969266517
        },
        'Condizioni Ambientali': {
          name: 'Condizioni Ambientali',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Performance: {
          name: 'Performance',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 969266517
    },
    Componenti: {
      name: 'Componenti',
      count: 3,
      total: 3274999551,
      duration: 0,
      durations: [
        1178066517,
        987266517,
        1109666517
      ],
      subcategories: {
        Inverter: {
          name: 'Inverter',
          count: 2,
          total: 2287733034,
          duration: 0,
          durations: [
            1178066517,
            1109666517
          ],
          averageDuration: 1143866517
        },
        Centralina: {
          name: 'Centralina',
          count: 1,
          total: 987266517,
          duration: 0,
          durations: [
            987266517
          ],
          averageDuration: 987266517
        },
        Protezioni: {
          name: 'Protezioni',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Moduli: {
          name: 'Moduli',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 1091666517
    }
  },
  disserviceHours: [
    {
      category: 'Comunicazione',
      subCategory: 'Datalogger',
      value: 2668032219
    },
    {
      category: 'Comunicazione',
      subCategory: 'Centralina',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Inverter',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Contatore',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Potenza',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Inverter',
      value: 2287733034
    },
    {
      category: 'Componenti',
      subCategory: 'Centralina',
      value: 987266517
    },
    {
      category: 'Componenti',
      subCategory: 'Protezioni',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Moduli',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Energia',
      value: 969266517
    },
    {
      category: 'Energia',
      subCategory: 'Condizioni Ambientali',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Performance',
      value: 0
    }
  ],
  avgHoursGraph: [
    {
      category: 'Comunicazione',
      subCategory: 'Datalogger',
      value: 40424730.59090909
    },
    {
      category: 'Comunicazione',
      subCategory: 'Centralina',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Inverter',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Contatore',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Potenza',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Inverter',
      value: 1143866517
    },
    {
      category: 'Componenti',
      subCategory: 'Centralina',
      value: 987266517
    },
    {
      category: 'Componenti',
      subCategory: 'Protezioni',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Moduli',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Energia',
      value: 969266517
    },
    {
      category: 'Energia',
      subCategory: 'Condizioni Ambientali',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Performance',
      value: 0
    }
  ],
  timeDistribution: [
    {
      category: 'Comunicazione',
      name: 'Datalogger',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 8,
          y: 5
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 16,
          y: 5
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 24,
          y: 5
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 32,
          y: 5
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 40,
          y: 5
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 48,
          y: 5
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 56,
          y: 5
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 64,
          y: 5
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 64,
          y: 5
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 64,
          y: 5
        }
      ]
    },
    {
      category: 'Comunicazione',
      name: 'Centralina',
      data: []
    },
    {
      category: 'Comunicazione',
      name: 'Inverter',
      data: []
    },
    {
      category: 'Comunicazione',
      name: 'Contatore',
      data: []
    },
    {
      category: 'Energia',
      name: 'Potenza',
      data: []
    },
    {
      category: 'Componenti',
      name: 'Inverter',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 2,
          y: 1
        }
      ]
    },
    {
      category: 'Componenti',
      name: 'Centralina',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 1,
          y: 1
        }
      ]
    },
    {
      category: 'Componenti',
      name: 'Protezioni',
      data: []
    },
    {
      category: 'Componenti',
      name: 'Moduli',
      data: []
    },
    {
      category: 'Energia',
      name: 'Energia',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 1,
          y: 1
        }
      ]
    },
    {
      category: 'Energia',
      name: 'Condizioni Ambientali',
      data: []
    },
    {
      category: 'Energia',
      name: 'Performance',
      data: []
    }
  ],
  sizeBalanceGraph: [
    {
      name: '100Kw',
      data: {
        alarms: 1,
        comunication: 4,
        environmentalConditions: 0,
        datalogger: 23,
        energy: 3,
        inverter: 12,
        modules: 7,
        strings: 0,
        performance: 8,
        platform: 32,
        activePower: 11,
        report: 10,
        sensors: 3
      }
    },
    {
      name: '250Kw',
      data: {
        alarms: 23,
        comunication: 0,
        environmentalConditions: 2,
        datalogger: 0,
        energy: 0,
        inverter: 4,
        modules: 36,
        strings: 1,
        performance: 1,
        platform: 1,
        activePower: 24,
        report: 8,
        sensors: 49
      }
    },
    {
      name: '500Kw',
      data: {
        alarms: 19,
        comunication: 0,
        environmentalConditions: 27,
        datalogger: 1,
        energy: 20,
        inverter: 43,
        modules: 0,
        strings: 0,
        performance: 0,
        platform: 0,
        activePower: 0,
        report: 0,
        sensors: 0
      }
    },
    {
      name: '1Mw',
      data: {
        alarms: 7,
        comunication: 45,
        environmentalConditions: 13,
        datalogger: 18,
        energy: 46,
        inverter: 0,
        modules: 4,
        strings: 3,
        performance: 2,
        platform: 11,
        activePower: 31,
        report: 21,
        sensors: 0
      }
    }
  ]
}
const pvYearData = {
  totalAnomalies: 535,
  totalInactiveHours: 13678,
  openAnomalies: 1253,
  closedAnomalies: 1240,
  categoryStatus: {
    Comunicazione: {
      name: 'Comunicazione',
      count: 66,
      total: 2668032220,
      duration: 0,
      durations: [
        1340066517,
        91765712,
        33265675,
        33265042,
        32365768,
        32365392,
        31465868,
        31465554,
        30565786,
        30565799,
        29665898,
        29665471,
        28765589,
        28765517,
        27865550,
        27865532,
        26965631,
        26965513,
        26065766,
        26065609,
        25165819,
        25165692,
        24265710,
        24265675,
        23365605,
        23365553,
        22465739,
        22465622,
        21565718,
        21565686,
        20665596,
        20665427,
        19765789,
        19765657,
        18865722,
        18865487,
        17965650,
        17965530,
        17065759,
        17065444,
        16165733,
        16165614,
        15265472,
        15265320,
        14365748,
        14365535,
        13465735,
        13465529,
        12565819,
        12565473,
        11665629,
        11665499,
        10765733,
        10765666,
        9865740,
        9865543,
        8965788,
        8965471,
        8065670,
        8065494,
        7165742,
        7165564,
        6265822,
        6265519,
        5365753,
        5365540
      ],
      subcategories: {
        Datalogger: {
          name: 'Datalogger',
          count: 66,
          total: 2668032219,
          duration: 0,
          durations: [
            1340066517,
            91765712,
            33265675,
            33265042,
            32365768,
            32365392,
            31465868,
            31465554,
            30565786,
            30565799,
            29665898,
            29665471,
            28765589,
            28765517,
            27865550,
            27865532,
            26965631,
            26965513,
            26065766,
            26065609,
            25165819,
            25165692,
            24265710,
            24265675,
            23365605,
            23365553,
            22465739,
            22465622,
            21565718,
            21565686,
            20665596,
            20665427,
            19765789,
            19765657,
            18865722,
            18865487,
            17965650,
            17965530,
            17065759,
            17065444,
            16165733,
            16165614,
            15265472,
            15265320,
            14365748,
            14365535,
            13465735,
            13465529,
            12565819,
            12565473,
            11665629,
            11665499,
            10765733,
            10765666,
            9865740,
            9865543,
            8965788,
            8965471,
            8065670,
            8065493,
            7165742,
            7165564,
            6265822,
            6265519,
            5365753,
            5365540
          ],
          averageDuration: 40424730.59090909
        },
        Centralina: {
          name: 'Centralina',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Inverter: {
          name: 'Inverter',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Contatore: {
          name: 'Contatore',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 40424730.60606061
    },
    Energia: {
      name: 'Energia',
      count: 1,
      total: 969266517,
      duration: 0,
      durations: [
        969266517
      ],
      subcategories: {
        Potenza: {
          name: 'Potenza',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Energia: {
          name: 'Energia',
          count: 1,
          total: 969266517,
          duration: 0,
          durations: [
            969266517
          ],
          averageDuration: 969266517
        },
        'Condizioni Ambientali': {
          name: 'Condizioni Ambientali',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Performance: {
          name: 'Performance',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 969266517
    },
    Componenti: {
      name: 'Componenti',
      count: 3,
      total: 3274999551,
      duration: 0,
      durations: [
        1178066517,
        987266517,
        1109666517
      ],
      subcategories: {
        Inverter: {
          name: 'Inverter',
          count: 2,
          total: 2287733034,
          duration: 0,
          durations: [
            1178066517,
            1109666517
          ],
          averageDuration: 1143866517
        },
        Centralina: {
          name: 'Centralina',
          count: 1,
          total: 987266517,
          duration: 0,
          durations: [
            987266517
          ],
          averageDuration: 987266517
        },
        Protezioni: {
          name: 'Protezioni',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Moduli: {
          name: 'Moduli',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 1091666517
    }
  },
  disserviceHours: [
    {
      category: 'Comunicazione',
      subCategory: 'Datalogger',
      value: 2668032219
    },
    {
      category: 'Comunicazione',
      subCategory: 'Centralina',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Inverter',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Contatore',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Potenza',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Inverter',
      value: 2287733034
    },
    {
      category: 'Componenti',
      subCategory: 'Centralina',
      value: 987266517
    },
    {
      category: 'Componenti',
      subCategory: 'Protezioni',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Moduli',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Energia',
      value: 969266517
    },
    {
      category: 'Energia',
      subCategory: 'Condizioni Ambientali',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Performance',
      value: 0
    }
  ],
  avgHoursGraph: [
    {
      category: 'Comunicazione',
      subCategory: 'Datalogger',
      value: 40424730.59090909
    },
    {
      category: 'Comunicazione',
      subCategory: 'Centralina',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Inverter',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Contatore',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Potenza',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Inverter',
      value: 1143866517
    },
    {
      category: 'Componenti',
      subCategory: 'Centralina',
      value: 987266517
    },
    {
      category: 'Componenti',
      subCategory: 'Protezioni',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Moduli',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Energia',
      value: 969266517
    },
    {
      category: 'Energia',
      subCategory: 'Condizioni Ambientali',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Performance',
      value: 0
    }
  ],
  timeDistribution: [
    {
      category: 'Comunicazione',
      name: 'Datalogger',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 8,
          y: 5
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 16,
          y: 5
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 24,
          y: 5
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 32,
          y: 5
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 40,
          y: 5
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 48,
          y: 5
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 56,
          y: 5
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 64,
          y: 5
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 64,
          y: 5
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 64,
          y: 5
        }
      ]
    },
    {
      category: 'Comunicazione',
      name: 'Centralina',
      data: []
    },
    {
      category: 'Comunicazione',
      name: 'Inverter',
      data: []
    },
    {
      category: 'Comunicazione',
      name: 'Contatore',
      data: []
    },
    {
      category: 'Energia',
      name: 'Potenza',
      data: []
    },
    {
      category: 'Componenti',
      name: 'Inverter',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 2,
          y: 1
        }
      ]
    },
    {
      category: 'Componenti',
      name: 'Centralina',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 1,
          y: 1
        }
      ]
    },
    {
      category: 'Componenti',
      name: 'Protezioni',
      data: []
    },
    {
      category: 'Componenti',
      name: 'Moduli',
      data: []
    },
    {
      category: 'Energia',
      name: 'Energia',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 1,
          y: 1
        }
      ]
    },
    {
      category: 'Energia',
      name: 'Condizioni Ambientali',
      data: []
    },
    {
      category: 'Energia',
      name: 'Performance',
      data: []
    }
  ],
  sizeBalanceGraph: [
    {
      name: '100Kw',
      data: {
        alarms: 1,
        comunication: 4,
        environmentalConditions: 0,
        datalogger: 23,
        energy: 3,
        inverter: 12,
        modules: 7,
        strings: 0,
        performance: 8,
        platform: 32,
        activePower: 11,
        report: 10,
        sensors: 3
      }
    },
    {
      name: '250Kw',
      data: {
        alarms: 23,
        comunication: 0,
        environmentalConditions: 2,
        datalogger: 0,
        energy: 0,
        inverter: 4,
        modules: 36,
        strings: 1,
        performance: 1,
        platform: 1,
        activePower: 24,
        report: 8,
        sensors: 49
      }
    },
    {
      name: '500Kw',
      data: {
        alarms: 19,
        comunication: 0,
        environmentalConditions: 27,
        datalogger: 1,
        energy: 20,
        inverter: 43,
        modules: 0,
        strings: 0,
        performance: 0,
        platform: 0,
        activePower: 0,
        report: 0,
        sensors: 0
      }
    },
    {
      name: '1Mw',
      data: {
        alarms: 7,
        comunication: 45,
        environmentalConditions: 13,
        datalogger: 18,
        energy: 46,
        inverter: 0,
        modules: 4,
        strings: 3,
        performance: 2,
        platform: 11,
        activePower: 31,
        report: 21,
        sensors: 0
      }
    }
  ]
}

// Dati Mock Led
const ledLiveData = {
  totalAnomalies: 25,
  totalInactiveHours: 1678,
  openAnomalies: 8,
  closedAnomalies: 18,
  categoryStatus: {
    Comunicazione: {
      name: 'Comunicazione',
      count: 66,
      total: 2668032220,
      duration: 0,
      durations: [
        1340066517,
        91765712,
        33265675,
        33265042,
        32365768,
        32365392,
        31465868,
        31465554,
        30565786,
        30565799,
        29665898,
        29665471,
        28765589,
        28765517,
        27865550,
        27865532,
        26965631,
        26965513,
        26065766,
        26065609,
        25165819,
        25165692,
        24265710,
        24265675,
        23365605,
        23365553,
        22465739,
        22465622,
        21565718,
        21565686,
        20665596,
        20665427,
        19765789,
        19765657,
        18865722,
        18865487,
        17965650,
        17965530,
        17065759,
        17065444,
        16165733,
        16165614,
        15265472,
        15265320,
        14365748,
        14365535,
        13465735,
        13465529,
        12565819,
        12565473,
        11665629,
        11665499,
        10765733,
        10765666,
        9865740,
        9865543,
        8965788,
        8965471,
        8065670,
        8065494,
        7165742,
        7165564,
        6265822,
        6265519,
        5365753,
        5365540
      ],
      subcategories: {
        Datalogger: {
          name: 'Datalogger',
          count: 66,
          total: 2668032219,
          duration: 0,
          durations: [
            1340066517,
            91765712,
            33265675,
            33265042,
            32365768,
            32365392,
            31465868,
            31465554,
            30565786,
            30565799,
            29665898,
            29665471,
            28765589,
            28765517,
            27865550,
            27865532,
            26965631,
            26965513,
            26065766,
            26065609,
            25165819,
            25165692,
            24265710,
            24265675,
            23365605,
            23365553,
            22465739,
            22465622,
            21565718,
            21565686,
            20665596,
            20665427,
            19765789,
            19765657,
            18865722,
            18865487,
            17965650,
            17965530,
            17065759,
            17065444,
            16165733,
            16165614,
            15265472,
            15265320,
            14365748,
            14365535,
            13465735,
            13465529,
            12565819,
            12565473,
            11665629,
            11665499,
            10765733,
            10765666,
            9865740,
            9865543,
            8965788,
            8965471,
            8065670,
            8065493,
            7165742,
            7165564,
            6265822,
            6265519,
            5365753,
            5365540
          ],
          averageDuration: 40424730.59090909
        },
        Centralina: {
          name: 'Centralina',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Inverter: {
          name: 'Inverter',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Contatore: {
          name: 'Contatore',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 40424730.60606061
    },
    Energia: {
      name: 'Energia',
      count: 1,
      total: 969266517,
      duration: 0,
      durations: [
        969266517
      ],
      subcategories: {
        Potenza: {
          name: 'Potenza',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Energia: {
          name: 'Energia',
          count: 1,
          total: 969266517,
          duration: 0,
          durations: [
            969266517
          ],
          averageDuration: 969266517
        },
        'Condizioni Ambientali': {
          name: 'Condizioni Ambientali',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Performance: {
          name: 'Performance',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 969266517
    },
    Componenti: {
      name: 'Componenti',
      count: 3,
      total: 3274999551,
      duration: 0,
      durations: [
        1178066517,
        987266517,
        1109666517
      ],
      subcategories: {
        Inverter: {
          name: 'Inverter',
          count: 2,
          total: 2287733034,
          duration: 0,
          durations: [
            1178066517,
            1109666517
          ],
          averageDuration: 1143866517
        },
        Centralina: {
          name: 'Centralina',
          count: 1,
          total: 987266517,
          duration: 0,
          durations: [
            987266517
          ],
          averageDuration: 987266517
        },
        Protezioni: {
          name: 'Protezioni',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Moduli: {
          name: 'Moduli',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 1091666517
    }
  },
  disserviceHours: [
    {
      category: 'Comunicazione',
      subCategory: 'Datalogger',
      value: 2668032219
    },
    {
      category: 'Comunicazione',
      subCategory: 'Centralina',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Inverter',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Contatore',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Potenza',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Inverter',
      value: 2287733034
    },
    {
      category: 'Componenti',
      subCategory: 'Centralina',
      value: 987266517
    },
    {
      category: 'Componenti',
      subCategory: 'Protezioni',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Moduli',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Energia',
      value: 969266517
    },
    {
      category: 'Energia',
      subCategory: 'Condizioni Ambientali',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Performance',
      value: 0
    }
  ],
  avgHoursGraph: [
    {
      category: 'Comunicazione',
      subCategory: 'Datalogger',
      value: 40424730.59090909
    },
    {
      category: 'Comunicazione',
      subCategory: 'Centralina',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Inverter',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Contatore',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Potenza',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Inverter',
      value: 1143866517
    },
    {
      category: 'Componenti',
      subCategory: 'Centralina',
      value: 987266517
    },
    {
      category: 'Componenti',
      subCategory: 'Protezioni',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Moduli',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Energia',
      value: 969266517
    },
    {
      category: 'Energia',
      subCategory: 'Condizioni Ambientali',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Performance',
      value: 0
    }
  ],
  timeDistribution: [
    {
      category: 'Comunicazione',
      name: 'Datalogger',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 8,
          y: 5
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 16,
          y: 5
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 24,
          y: 5
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 32,
          y: 5
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 40,
          y: 5
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 48,
          y: 5
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 56,
          y: 5
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 64,
          y: 5
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 64,
          y: 5
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 64,
          y: 5
        }
      ]
    },
    {
      category: 'Comunicazione',
      name: 'Centralina',
      data: []
    },
    {
      category: 'Comunicazione',
      name: 'Inverter',
      data: []
    },
    {
      category: 'Comunicazione',
      name: 'Contatore',
      data: []
    },
    {
      category: 'Energia',
      name: 'Potenza',
      data: []
    },
    {
      category: 'Componenti',
      name: 'Inverter',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 2,
          y: 1
        }
      ]
    },
    {
      category: 'Componenti',
      name: 'Centralina',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 1,
          y: 1
        }
      ]
    },
    {
      category: 'Componenti',
      name: 'Protezioni',
      data: []
    },
    {
      category: 'Componenti',
      name: 'Moduli',
      data: []
    },
    {
      category: 'Energia',
      name: 'Energia',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 1,
          y: 1
        }
      ]
    },
    {
      category: 'Energia',
      name: 'Condizioni Ambientali',
      data: []
    },
    {
      category: 'Energia',
      name: 'Performance',
      data: []
    }
  ],
  sizeBalanceGraph: [
    {
      name: '100Kw',
      data: {
        alarms: 1,
        comunication: 4,
        environmentalConditions: 0,
        datalogger: 23,
        energy: 3,
        inverter: 12,
        modules: 7,
        strings: 0,
        performance: 8,
        platform: 32,
        activePower: 11,
        report: 10,
        sensors: 3
      }
    },
    {
      name: '250Kw',
      data: {
        alarms: 23,
        comunication: 0,
        environmentalConditions: 2,
        datalogger: 0,
        energy: 0,
        inverter: 4,
        modules: 36,
        strings: 1,
        performance: 1,
        platform: 1,
        activePower: 24,
        report: 8,
        sensors: 49
      }
    },
    {
      name: '500Kw',
      data: {
        alarms: 19,
        comunication: 0,
        environmentalConditions: 27,
        datalogger: 1,
        energy: 20,
        inverter: 43,
        modules: 0,
        strings: 0,
        performance: 0,
        platform: 0,
        activePower: 0,
        report: 0,
        sensors: 0
      }
    },
    {
      name: '1Mw',
      data: {
        alarms: 7,
        comunication: 45,
        environmentalConditions: 13,
        datalogger: 18,
        energy: 46,
        inverter: 0,
        modules: 4,
        strings: 3,
        performance: 2,
        platform: 11,
        activePower: 31,
        report: 21,
        sensors: 0
      }
    }
  ]
}
const ledWeekData = {
  totalAnomalies: 114,
  totalInactiveHours: 652,
  openAnomalies: 12,
  closedAnomalies: 23,
  categoryStatus: {
    Comunicazione: {
      name: 'Comunicazione',
      count: 66,
      total: 2668032220,
      duration: 0,
      durations: [
        1340066517,
        91765712,
        33265675,
        33265042,
        32365768,
        32365392,
        31465868,
        31465554,
        30565786,
        30565799,
        29665898,
        29665471,
        28765589,
        28765517,
        27865550,
        27865532,
        26965631,
        26965513,
        26065766,
        26065609,
        25165819,
        25165692,
        24265710,
        24265675,
        23365605,
        23365553,
        22465739,
        22465622,
        21565718,
        21565686,
        20665596,
        20665427,
        19765789,
        19765657,
        18865722,
        18865487,
        17965650,
        17965530,
        17065759,
        17065444,
        16165733,
        16165614,
        15265472,
        15265320,
        14365748,
        14365535,
        13465735,
        13465529,
        12565819,
        12565473,
        11665629,
        11665499,
        10765733,
        10765666,
        9865740,
        9865543,
        8965788,
        8965471,
        8065670,
        8065494,
        7165742,
        7165564,
        6265822,
        6265519,
        5365753,
        5365540
      ],
      subcategories: {
        Datalogger: {
          name: 'Datalogger',
          count: 66,
          total: 2668032219,
          duration: 0,
          durations: [
            1340066517,
            91765712,
            33265675,
            33265042,
            32365768,
            32365392,
            31465868,
            31465554,
            30565786,
            30565799,
            29665898,
            29665471,
            28765589,
            28765517,
            27865550,
            27865532,
            26965631,
            26965513,
            26065766,
            26065609,
            25165819,
            25165692,
            24265710,
            24265675,
            23365605,
            23365553,
            22465739,
            22465622,
            21565718,
            21565686,
            20665596,
            20665427,
            19765789,
            19765657,
            18865722,
            18865487,
            17965650,
            17965530,
            17065759,
            17065444,
            16165733,
            16165614,
            15265472,
            15265320,
            14365748,
            14365535,
            13465735,
            13465529,
            12565819,
            12565473,
            11665629,
            11665499,
            10765733,
            10765666,
            9865740,
            9865543,
            8965788,
            8965471,
            8065670,
            8065493,
            7165742,
            7165564,
            6265822,
            6265519,
            5365753,
            5365540
          ],
          averageDuration: 40424730.59090909
        },
        Centralina: {
          name: 'Centralina',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Inverter: {
          name: 'Inverter',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Contatore: {
          name: 'Contatore',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 40424730.60606061
    },
    Energia: {
      name: 'Energia',
      count: 1,
      total: 969266517,
      duration: 0,
      durations: [
        969266517
      ],
      subcategories: {
        Potenza: {
          name: 'Potenza',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Energia: {
          name: 'Energia',
          count: 1,
          total: 969266517,
          duration: 0,
          durations: [
            969266517
          ],
          averageDuration: 969266517
        },
        'Condizioni Ambientali': {
          name: 'Condizioni Ambientali',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Performance: {
          name: 'Performance',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 969266517
    },
    Componenti: {
      name: 'Componenti',
      count: 3,
      total: 3274999551,
      duration: 0,
      durations: [
        1178066517,
        987266517,
        1109666517
      ],
      subcategories: {
        Inverter: {
          name: 'Inverter',
          count: 2,
          total: 2287733034,
          duration: 0,
          durations: [
            1178066517,
            1109666517
          ],
          averageDuration: 1143866517
        },
        Centralina: {
          name: 'Centralina',
          count: 1,
          total: 987266517,
          duration: 0,
          durations: [
            987266517
          ],
          averageDuration: 987266517
        },
        Protezioni: {
          name: 'Protezioni',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Moduli: {
          name: 'Moduli',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 1091666517
    }
  },
  disserviceHours: [
    {
      category: 'Comunicazione',
      subCategory: 'Datalogger',
      value: 2668032219
    },
    {
      category: 'Comunicazione',
      subCategory: 'Centralina',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Inverter',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Contatore',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Potenza',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Inverter',
      value: 2287733034
    },
    {
      category: 'Componenti',
      subCategory: 'Centralina',
      value: 987266517
    },
    {
      category: 'Componenti',
      subCategory: 'Protezioni',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Moduli',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Energia',
      value: 969266517
    },
    {
      category: 'Energia',
      subCategory: 'Condizioni Ambientali',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Performance',
      value: 0
    }
  ],
  avgHoursGraph: [
    {
      category: 'Comunicazione',
      subCategory: 'Datalogger',
      value: 40424730.59090909
    },
    {
      category: 'Comunicazione',
      subCategory: 'Centralina',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Inverter',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Contatore',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Potenza',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Inverter',
      value: 1143866517
    },
    {
      category: 'Componenti',
      subCategory: 'Centralina',
      value: 987266517
    },
    {
      category: 'Componenti',
      subCategory: 'Protezioni',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Moduli',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Energia',
      value: 969266517
    },
    {
      category: 'Energia',
      subCategory: 'Condizioni Ambientali',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Performance',
      value: 0
    }
  ],
  timeDistribution: [
    {
      category: 'Comunicazione',
      name: 'Datalogger',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 8,
          y: 5
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 16,
          y: 5
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 24,
          y: 5
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 32,
          y: 5
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 40,
          y: 5
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 48,
          y: 5
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 56,
          y: 5
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 64,
          y: 5
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 64,
          y: 5
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 64,
          y: 5
        }
      ]
    },
    {
      category: 'Comunicazione',
      name: 'Centralina',
      data: []
    },
    {
      category: 'Comunicazione',
      name: 'Inverter',
      data: []
    },
    {
      category: 'Comunicazione',
      name: 'Contatore',
      data: []
    },
    {
      category: 'Energia',
      name: 'Potenza',
      data: []
    },
    {
      category: 'Componenti',
      name: 'Inverter',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 2,
          y: 1
        }
      ]
    },
    {
      category: 'Componenti',
      name: 'Centralina',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 1,
          y: 1
        }
      ]
    },
    {
      category: 'Componenti',
      name: 'Protezioni',
      data: []
    },
    {
      category: 'Componenti',
      name: 'Moduli',
      data: []
    },
    {
      category: 'Energia',
      name: 'Energia',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 1,
          y: 1
        }
      ]
    },
    {
      category: 'Energia',
      name: 'Condizioni Ambientali',
      data: []
    },
    {
      category: 'Energia',
      name: 'Performance',
      data: []
    }
  ],
  sizeBalanceGraph: [
    {
      name: '100Kw',
      data: {
        alarms: 1,
        comunication: 4,
        environmentalConditions: 0,
        datalogger: 23,
        energy: 3,
        inverter: 12,
        modules: 7,
        strings: 0,
        performance: 8,
        platform: 32,
        activePower: 11,
        report: 10,
        sensors: 3
      }
    },
    {
      name: '250Kw',
      data: {
        alarms: 23,
        comunication: 0,
        environmentalConditions: 2,
        datalogger: 0,
        energy: 0,
        inverter: 4,
        modules: 36,
        strings: 1,
        performance: 1,
        platform: 1,
        activePower: 24,
        report: 8,
        sensors: 49
      }
    },
    {
      name: '500Kw',
      data: {
        alarms: 19,
        comunication: 0,
        environmentalConditions: 27,
        datalogger: 1,
        energy: 20,
        inverter: 43,
        modules: 0,
        strings: 0,
        performance: 0,
        platform: 0,
        activePower: 0,
        report: 0,
        sensors: 0
      }
    },
    {
      name: '1Mw',
      data: {
        alarms: 7,
        comunication: 45,
        environmentalConditions: 13,
        datalogger: 18,
        energy: 46,
        inverter: 0,
        modules: 4,
        strings: 3,
        performance: 2,
        platform: 11,
        activePower: 31,
        report: 21,
        sensors: 0
      }
    }
  ]
}
const ledMonthData = {
  totalAnomalies: 245,
  totalInactiveHours: 1678,
  openAnomalies: 25,
  closedAnomalies: 220,
  categoryStatus: {
    Comunicazione: {
      name: 'Comunicazione',
      count: 66,
      total: 2668032220,
      duration: 0,
      durations: [
        1340066517,
        91765712,
        33265675,
        33265042,
        32365768,
        32365392,
        31465868,
        31465554,
        30565786,
        30565799,
        29665898,
        29665471,
        28765589,
        28765517,
        27865550,
        27865532,
        26965631,
        26965513,
        26065766,
        26065609,
        25165819,
        25165692,
        24265710,
        24265675,
        23365605,
        23365553,
        22465739,
        22465622,
        21565718,
        21565686,
        20665596,
        20665427,
        19765789,
        19765657,
        18865722,
        18865487,
        17965650,
        17965530,
        17065759,
        17065444,
        16165733,
        16165614,
        15265472,
        15265320,
        14365748,
        14365535,
        13465735,
        13465529,
        12565819,
        12565473,
        11665629,
        11665499,
        10765733,
        10765666,
        9865740,
        9865543,
        8965788,
        8965471,
        8065670,
        8065494,
        7165742,
        7165564,
        6265822,
        6265519,
        5365753,
        5365540
      ],
      subcategories: {
        Datalogger: {
          name: 'Datalogger',
          count: 66,
          total: 2668032219,
          duration: 0,
          durations: [
            1340066517,
            91765712,
            33265675,
            33265042,
            32365768,
            32365392,
            31465868,
            31465554,
            30565786,
            30565799,
            29665898,
            29665471,
            28765589,
            28765517,
            27865550,
            27865532,
            26965631,
            26965513,
            26065766,
            26065609,
            25165819,
            25165692,
            24265710,
            24265675,
            23365605,
            23365553,
            22465739,
            22465622,
            21565718,
            21565686,
            20665596,
            20665427,
            19765789,
            19765657,
            18865722,
            18865487,
            17965650,
            17965530,
            17065759,
            17065444,
            16165733,
            16165614,
            15265472,
            15265320,
            14365748,
            14365535,
            13465735,
            13465529,
            12565819,
            12565473,
            11665629,
            11665499,
            10765733,
            10765666,
            9865740,
            9865543,
            8965788,
            8965471,
            8065670,
            8065493,
            7165742,
            7165564,
            6265822,
            6265519,
            5365753,
            5365540
          ],
          averageDuration: 40424730.59090909
        },
        Centralina: {
          name: 'Centralina',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Inverter: {
          name: 'Inverter',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Contatore: {
          name: 'Contatore',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 40424730.60606061
    },
    Energia: {
      name: 'Energia',
      count: 1,
      total: 969266517,
      duration: 0,
      durations: [
        969266517
      ],
      subcategories: {
        Potenza: {
          name: 'Potenza',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Energia: {
          name: 'Energia',
          count: 1,
          total: 969266517,
          duration: 0,
          durations: [
            969266517
          ],
          averageDuration: 969266517
        },
        'Condizioni Ambientali': {
          name: 'Condizioni Ambientali',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Performance: {
          name: 'Performance',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 969266517
    },
    Componenti: {
      name: 'Componenti',
      count: 3,
      total: 3274999551,
      duration: 0,
      durations: [
        1178066517,
        987266517,
        1109666517
      ],
      subcategories: {
        Inverter: {
          name: 'Inverter',
          count: 2,
          total: 2287733034,
          duration: 0,
          durations: [
            1178066517,
            1109666517
          ],
          averageDuration: 1143866517
        },
        Centralina: {
          name: 'Centralina',
          count: 1,
          total: 987266517,
          duration: 0,
          durations: [
            987266517
          ],
          averageDuration: 987266517
        },
        Protezioni: {
          name: 'Protezioni',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Moduli: {
          name: 'Moduli',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 1091666517
    }
  },
  disserviceHours: [
    {
      category: 'Comunicazione',
      subCategory: 'Datalogger',
      value: 2668032219
    },
    {
      category: 'Comunicazione',
      subCategory: 'Centralina',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Inverter',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Contatore',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Potenza',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Inverter',
      value: 2287733034
    },
    {
      category: 'Componenti',
      subCategory: 'Centralina',
      value: 987266517
    },
    {
      category: 'Componenti',
      subCategory: 'Protezioni',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Moduli',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Energia',
      value: 969266517
    },
    {
      category: 'Energia',
      subCategory: 'Condizioni Ambientali',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Performance',
      value: 0
    }
  ],
  avgHoursGraph: [
    {
      category: 'Comunicazione',
      subCategory: 'Datalogger',
      value: 40424730.59090909
    },
    {
      category: 'Comunicazione',
      subCategory: 'Centralina',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Inverter',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Contatore',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Potenza',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Inverter',
      value: 1143866517
    },
    {
      category: 'Componenti',
      subCategory: 'Centralina',
      value: 987266517
    },
    {
      category: 'Componenti',
      subCategory: 'Protezioni',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Moduli',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Energia',
      value: 969266517
    },
    {
      category: 'Energia',
      subCategory: 'Condizioni Ambientali',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Performance',
      value: 0
    }
  ],
  timeDistribution: [
    {
      category: 'Comunicazione',
      name: 'Datalogger',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 8,
          y: 5
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 16,
          y: 5
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 24,
          y: 5
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 32,
          y: 5
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 40,
          y: 5
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 48,
          y: 5
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 56,
          y: 5
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 64,
          y: 5
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 64,
          y: 5
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 64,
          y: 5
        }
      ]
    },
    {
      category: 'Comunicazione',
      name: 'Centralina',
      data: []
    },
    {
      category: 'Comunicazione',
      name: 'Inverter',
      data: []
    },
    {
      category: 'Comunicazione',
      name: 'Contatore',
      data: []
    },
    {
      category: 'Energia',
      name: 'Potenza',
      data: []
    },
    {
      category: 'Componenti',
      name: 'Inverter',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 2,
          y: 1
        }
      ]
    },
    {
      category: 'Componenti',
      name: 'Centralina',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 1,
          y: 1
        }
      ]
    },
    {
      category: 'Componenti',
      name: 'Protezioni',
      data: []
    },
    {
      category: 'Componenti',
      name: 'Moduli',
      data: []
    },
    {
      category: 'Energia',
      name: 'Energia',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 1,
          y: 1
        }
      ]
    },
    {
      category: 'Energia',
      name: 'Condizioni Ambientali',
      data: []
    },
    {
      category: 'Energia',
      name: 'Performance',
      data: []
    }
  ],
  sizeBalanceGraph: [
    {
      name: '100Kw',
      data: {
        alarms: 1,
        comunication: 4,
        environmentalConditions: 0,
        datalogger: 23,
        energy: 3,
        inverter: 12,
        modules: 7,
        strings: 0,
        performance: 8,
        platform: 32,
        activePower: 11,
        report: 10,
        sensors: 3
      }
    },
    {
      name: '250Kw',
      data: {
        alarms: 23,
        comunication: 0,
        environmentalConditions: 2,
        datalogger: 0,
        energy: 0,
        inverter: 4,
        modules: 36,
        strings: 1,
        performance: 1,
        platform: 1,
        activePower: 24,
        report: 8,
        sensors: 49
      }
    },
    {
      name: '500Kw',
      data: {
        alarms: 19,
        comunication: 0,
        environmentalConditions: 27,
        datalogger: 1,
        energy: 20,
        inverter: 43,
        modules: 0,
        strings: 0,
        performance: 0,
        platform: 0,
        activePower: 0,
        report: 0,
        sensors: 0
      }
    },
    {
      name: '1Mw',
      data: {
        alarms: 7,
        comunication: 45,
        environmentalConditions: 13,
        datalogger: 18,
        energy: 46,
        inverter: 0,
        modules: 4,
        strings: 3,
        performance: 2,
        platform: 11,
        activePower: 31,
        report: 21,
        sensors: 0
      }
    }
  ]
}
const ledYearData = {
  totalAnomalies: 535,
  totalInactiveHours: 13678,
  openAnomalies: 1253,
  closedAnomalies: 1240,
  categoryStatus: {
    Comunicazione: {
      name: 'Comunicazione',
      count: 66,
      total: 2668032220,
      duration: 0,
      durations: [
        1340066517,
        91765712,
        33265675,
        33265042,
        32365768,
        32365392,
        31465868,
        31465554,
        30565786,
        30565799,
        29665898,
        29665471,
        28765589,
        28765517,
        27865550,
        27865532,
        26965631,
        26965513,
        26065766,
        26065609,
        25165819,
        25165692,
        24265710,
        24265675,
        23365605,
        23365553,
        22465739,
        22465622,
        21565718,
        21565686,
        20665596,
        20665427,
        19765789,
        19765657,
        18865722,
        18865487,
        17965650,
        17965530,
        17065759,
        17065444,
        16165733,
        16165614,
        15265472,
        15265320,
        14365748,
        14365535,
        13465735,
        13465529,
        12565819,
        12565473,
        11665629,
        11665499,
        10765733,
        10765666,
        9865740,
        9865543,
        8965788,
        8965471,
        8065670,
        8065494,
        7165742,
        7165564,
        6265822,
        6265519,
        5365753,
        5365540
      ],
      subcategories: {
        Datalogger: {
          name: 'Datalogger',
          count: 66,
          total: 2668032219,
          duration: 0,
          durations: [
            1340066517,
            91765712,
            33265675,
            33265042,
            32365768,
            32365392,
            31465868,
            31465554,
            30565786,
            30565799,
            29665898,
            29665471,
            28765589,
            28765517,
            27865550,
            27865532,
            26965631,
            26965513,
            26065766,
            26065609,
            25165819,
            25165692,
            24265710,
            24265675,
            23365605,
            23365553,
            22465739,
            22465622,
            21565718,
            21565686,
            20665596,
            20665427,
            19765789,
            19765657,
            18865722,
            18865487,
            17965650,
            17965530,
            17065759,
            17065444,
            16165733,
            16165614,
            15265472,
            15265320,
            14365748,
            14365535,
            13465735,
            13465529,
            12565819,
            12565473,
            11665629,
            11665499,
            10765733,
            10765666,
            9865740,
            9865543,
            8965788,
            8965471,
            8065670,
            8065493,
            7165742,
            7165564,
            6265822,
            6265519,
            5365753,
            5365540
          ],
          averageDuration: 40424730.59090909
        },
        Centralina: {
          name: 'Centralina',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Inverter: {
          name: 'Inverter',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Contatore: {
          name: 'Contatore',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 40424730.60606061
    },
    Energia: {
      name: 'Energia',
      count: 1,
      total: 969266517,
      duration: 0,
      durations: [
        969266517
      ],
      subcategories: {
        Potenza: {
          name: 'Potenza',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Energia: {
          name: 'Energia',
          count: 1,
          total: 969266517,
          duration: 0,
          durations: [
            969266517
          ],
          averageDuration: 969266517
        },
        'Condizioni Ambientali': {
          name: 'Condizioni Ambientali',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Performance: {
          name: 'Performance',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 969266517
    },
    Componenti: {
      name: 'Componenti',
      count: 3,
      total: 3274999551,
      duration: 0,
      durations: [
        1178066517,
        987266517,
        1109666517
      ],
      subcategories: {
        Inverter: {
          name: 'Inverter',
          count: 2,
          total: 2287733034,
          duration: 0,
          durations: [
            1178066517,
            1109666517
          ],
          averageDuration: 1143866517
        },
        Centralina: {
          name: 'Centralina',
          count: 1,
          total: 987266517,
          duration: 0,
          durations: [
            987266517
          ],
          averageDuration: 987266517
        },
        Protezioni: {
          name: 'Protezioni',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        },
        Moduli: {
          name: 'Moduli',
          count: 0,
          total: 0,
          duration: 0,
          durations: [],
          averageDuration: 0
        }
      },
      averageDuration: 1091666517
    }
  },
  disserviceHours: [
    {
      category: 'Comunicazione',
      subCategory: 'Datalogger',
      value: 2668032219
    },
    {
      category: 'Comunicazione',
      subCategory: 'Centralina',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Inverter',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Contatore',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Potenza',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Inverter',
      value: 2287733034
    },
    {
      category: 'Componenti',
      subCategory: 'Centralina',
      value: 987266517
    },
    {
      category: 'Componenti',
      subCategory: 'Protezioni',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Moduli',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Energia',
      value: 969266517
    },
    {
      category: 'Energia',
      subCategory: 'Condizioni Ambientali',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Performance',
      value: 0
    }
  ],
  avgHoursGraph: [
    {
      category: 'Comunicazione',
      subCategory: 'Datalogger',
      value: 40424730.59090909
    },
    {
      category: 'Comunicazione',
      subCategory: 'Centralina',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Inverter',
      value: 0
    },
    {
      category: 'Comunicazione',
      subCategory: 'Contatore',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Potenza',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Inverter',
      value: 1143866517
    },
    {
      category: 'Componenti',
      subCategory: 'Centralina',
      value: 987266517
    },
    {
      category: 'Componenti',
      subCategory: 'Protezioni',
      value: 0
    },
    {
      category: 'Componenti',
      subCategory: 'Moduli',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Energia',
      value: 969266517
    },
    {
      category: 'Energia',
      subCategory: 'Condizioni Ambientali',
      value: 0
    },
    {
      category: 'Energia',
      subCategory: 'Performance',
      value: 0
    }
  ],
  timeDistribution: [
    {
      category: 'Comunicazione',
      name: 'Datalogger',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 8,
          y: 5
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 16,
          y: 5
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 24,
          y: 5
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 32,
          y: 5
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 40,
          y: 5
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 48,
          y: 5
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 56,
          y: 5
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 64,
          y: 5
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 64,
          y: 5
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 2,
          y: 2
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 64,
          y: 5
        }
      ]
    },
    {
      category: 'Comunicazione',
      name: 'Centralina',
      data: []
    },
    {
      category: 'Comunicazione',
      name: 'Inverter',
      data: []
    },
    {
      category: 'Comunicazione',
      name: 'Contatore',
      data: []
    },
    {
      category: 'Energia',
      name: 'Potenza',
      data: []
    },
    {
      category: 'Componenti',
      name: 'Inverter',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 2,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 2,
          y: 1
        }
      ]
    },
    {
      category: 'Componenti',
      name: 'Centralina',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 1,
          y: 1
        }
      ]
    },
    {
      category: 'Componenti',
      name: 'Protezioni',
      data: []
    },
    {
      category: 'Componenti',
      name: 'Moduli',
      data: []
    },
    {
      category: 'Energia',
      name: 'Energia',
      data: [
        {
          x: '2021-02-25T23:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T00:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T01:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T02:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T03:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T04:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T05:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T06:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T07:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T08:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T09:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T10:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T11:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T12:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T13:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T14:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T15:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T16:00:00.001Z',
          z: 1,
          y: 1
        },
        {
          x: '2021-02-26T17:00:00.001Z',
          z: 1,
          y: 1
        }
      ]
    },
    {
      category: 'Energia',
      name: 'Condizioni Ambientali',
      data: []
    },
    {
      category: 'Energia',
      name: 'Performance',
      data: []
    }
  ],
  sizeBalanceGraph: [
    {
      name: '100Kw',
      data: {
        alarms: 1,
        comunication: 4,
        environmentalConditions: 0,
        datalogger: 23,
        energy: 3,
        inverter: 12,
        modules: 7,
        strings: 0,
        performance: 8,
        platform: 32,
        activePower: 11,
        report: 10,
        sensors: 3
      }
    },
    {
      name: '250Kw',
      data: {
        alarms: 23,
        comunication: 0,
        environmentalConditions: 2,
        datalogger: 0,
        energy: 0,
        inverter: 4,
        modules: 36,
        strings: 1,
        performance: 1,
        platform: 1,
        activePower: 24,
        report: 8,
        sensors: 49
      }
    },
    {
      name: '500Kw',
      data: {
        alarms: 19,
        comunication: 0,
        environmentalConditions: 27,
        datalogger: 1,
        energy: 20,
        inverter: 43,
        modules: 0,
        strings: 0,
        performance: 0,
        platform: 0,
        activePower: 0,
        report: 0,
        sensors: 0
      }
    },
    {
      name: '1Mw',
      data: {
        alarms: 7,
        comunication: 45,
        environmentalConditions: 13,
        datalogger: 18,
        energy: 46,
        inverter: 0,
        modules: 4,
        strings: 3,
        performance: 2,
        platform: 11,
        activePower: 31,
        report: 21,
        sensors: 0
      }
    }
  ]
}

// Date dei periodi calcolate in base al giorno corrente
const now = moment()
const { minDate: liveMinDate, maxDate: liveMaxDate } = getDatesFromPeriod('live', now)
const { minDate: weekMinDate, maxDate: weekMaxDate } = getDatesFromPeriod('week', now)
const { minDate: monthMinDate, maxDate: monthMaxDate } = getDatesFromPeriod('month', now)
const { minDate: yearMinDate, maxDate: yearMaxDate } = getDatesFromPeriod('year', now)

// Chiamate Mock per il fotovoltaico
// live
mock.onGet(`/api/anomalies?timeFrom=${liveMinDate}&timeTo=${liveMaxDate}&plantType=pv`).reply(200, { data: pvLiveData })
// settimana
mock.onGet(`/api/anomalies?timeFrom=${moment(weekMinDate).add(1, 'd').toISOString()}&timeTo=${moment(weekMaxDate).add(1, 'd').toISOString()}&plantType=pv`).reply(200, { data: pvWeekData })
// mese
mock.onGet(`/api/anomalies?timeFrom=${monthMinDate}&timeTo=${monthMaxDate}&plantType=pv`).reply(200, { data: pvMonthData })
// anno
mock.onGet(`/api/anomalies?timeFrom=${yearMinDate}&timeTo=${yearMaxDate}&plantType=pv`).reply(200, { data: pvYearData })

// Chiamate Mock per il led
// live
mock.onGet(`/api/anomalies?timeFrom=${liveMinDate}&timeTo=${liveMaxDate}&plantType=led`).reply(200, { data: ledLiveData })
// settimana
mock.onGet(`/api/anomalies?timeFrom=${weekMinDate}&timeTo=${moment(weekMaxDate).add(1, 'd').toISOString()}&plantType=led`).reply(200, { data: ledWeekData })
// mese
mock.onGet(`/api/anomalies?timeFrom=${monthMinDate}&timeTo=${monthMaxDate}&plantType=led`).reply(200, { data: ledMonthData })
// anno
mock.onGet(`/api/anomalies?timeFrom=${yearMinDate}&timeTo=${yearMaxDate}&plantType=led`).reply(200, { data: ledYearData })
