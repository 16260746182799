import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {},
  placeholder: {
    position: 'fixed',
    zIndex: theme.zIndex.modal + 3,
    opacity: 0.6,
    width: '40px',
    height: '40px'
  },
  placeholderImg: {
    userDrag: 'none',
    userSelect: 'none'
  }
}))

function LibraryPlaceholder ({
  canvas,
  position,
  setShowLibrary,
  setPosition,
  setShowConfigDialog,
  showPlaceholder,
  selectedObject,
  setShowPlaceholder,
  parentCanvasRef,
  className,
  ...rest
}) {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, className)}>
      {showPlaceholder
        ? (
          <div
            className={classes.placeholder}
            style={{ left: `${position.x}px`, top: `${position.y}px` }}
            onMouseUp={(e) => {
              if (showPlaceholder) {
                // const pageWidth = window.innerWidth
                // const pageHeight = window.innerHeight
                // const canvasWidth = parentCanvasRef.current.offsetWidth
                // const canvasHeight = parentCanvasRef.current.offsetHeight
                // const widthOffset = (pageWidth - canvasWidth) / 2
                // const heightOffset = (pageHeight - canvasHeight) - 100
                // const canvasX = e.pageX - widthOffset <= 0 ? 1 : e.pageX - widthOffset
                // const canvasY = e.pageY - heightOffset <= 0 ? 1 : e.pageY - heightOffset
                setShowPlaceholder(false)
                if (canvas) {
                  const absolutePointerPosition = canvas.getPointer(e)
                  // console.log('canvasPointer: ', absolutePointerPosition)
                  // setPosition({ x: canvasX, y: canvasY })
                  setPosition(absolutePointerPosition)
                  // console.log(position)
                  // selectedObject.draw.top = canvasY
                  // selectedObject.draw.left = canvasX
                  selectedObject.draw.top = absolutePointerPosition.y
                  selectedObject.draw.left = absolutePointerPosition.x
                }
                setShowLibrary(false)
                setShowConfigDialog(true)
              } else {
                setShowPlaceholder(false)
              }
            }}
          >
            <img
              alt='placeholder'
              className={classes.placeholderImg}
              style={{ left: `${position.x}px`, top: `${position.y}px` }}
              width={40}
              height={40}
              src={selectedObject.img}
            />
          </div>
          )
        : null}
    </div>
  )
}
export default LibraryPlaceholder
