import { createSlice } from '@reduxjs/toolkit'
import api from '@micmnt/apis'
import { v4 as uuidv4 } from 'uuid'
import { decodeFromApi, encodeForApi } from '@/utils/adapters/led/plantAdapter'
import log from '@pelv/frontlog'

// Oggetto base che rappresenta una riga della tabella di linee di corrente
const basePowerLineRow = {
  name: '',
  parent: '',
  power: '',
  num: '',
  model: '',
  valid: true
}

// Oggetto base che rappresenta una riga della tabella dei modelli di moduli dell'impianto
const baseModuleRow = {
  name: '',
  absorbedPower: '',
  saved: false,
  datasheetUrl: null
}

const initialState = {
  dataloggers: [],
  loadData: false,
  addedDataloggers: [],
  contractDuration: '',
  selectedDatalogger: '',
  maintenance: false,
  activeStep: 0,
  hasAutosave: false,
  plantType: 'led',
  documents: [],
  name: '',
  peakPower: '',
  logoURL: '',
  address: '',
  planimetry: { draw: [], config: [] },
  startDate: null,
  endDate: null,
  module: [],
  anagraphic: {
    peakPower: '',
    plantCode: '',
    contractType: '',
    referentName: '',
    referentClient: '',
    referentRole: '',
    referentEmail: '',
    referentPhone: '',
    monitoringName: '',
    monitoringEmail: '',
    monitoringPhone: '',
    operationAndMaintenaceName: '',
    operationAndMaintenacePhone: '',
    operationAndMaintenaceEmail: ''
  },
  baseline: [
    { month: 'Gennaio', consumption: '', lightHours: '' },
    { month: 'Febbraio', consumption: '', lightHours: '' },
    { month: 'Marzo', consumption: '', lightHours: '' },
    { month: 'Aprile', consumption: '', lightHours: '' },
    { month: 'Maggio', consumption: '', lightHours: '' },
    { month: 'Giugno', consumption: '', lightHours: '' },
    { month: 'Luglio', consumption: '', lightHours: '' },
    { month: 'Agosto', consumption: '', lightHours: '' },
    { month: 'Settembre', consumption: '', lightHours: '' },
    { month: 'Ottobre', consumption: '', lightHours: '' },
    { month: 'Novembre', consumption: '', lightHours: '' },
    { month: 'Dicembre', consumption: '', lightHours: '' }
  ],
  baselineConfig: [
    { month: 'Gennaio', startDate: null },
    { month: 'Febbraio', startDate: null },
    { month: 'Marzo', startDate: null },
    { month: 'Aprile', startDate: null },
    { month: 'Maggio', startDate: null },
    { month: 'Giugno', startDate: null },
    { month: 'Luglio', startDate: null },
    { month: 'Agosto', startDate: null },
    { month: 'Settembre', startDate: null },
    { month: 'Ottobre', startDate: null },
    { month: 'Novembre', startDate: null },
    { month: 'Dicembre', startDate: null }
  ],
  powerLines: []
}

const slice = createSlice({
  name: 'ledPlant',
  initialState,
  reducers: {
    setLoadData (state, actions) {
      const { loading } = actions.payload
      state.loadData = loading
    },
    setCurrentPlant (state, actions) {
      const { plant, powerLines } = actions.payload
      const { hasAutosave, logoURL, name, uuid, anagraphic, baselineConfig, addedDataloggers, address, baseline, contractDuration, endDate, startDate, maintenance, peakPower, planimetry, plantType, documents, module } = plant
      const { module: initModule, hasAutosave: initHasAutosave, documents: initDocuments, baseline: initBaseline, anagraphic: initAnagraphic, baselineConfig: initBaselineConfig } = initialState

      // l'impianto da modificare potrebbe non avere un oggetto anagrafica (è stato creato precedentemente all'inserimento di questa sezione)
      // se l'oggetto è presente lo setto nel'impianto corrente
      if (anagraphic) {
        state.anagraphic = anagraphic
        // altrimenti setto il valore di default dell'oggetto anagraphic che si utilizza per un nuovo impianto
      } else {
        state.anagraphic = initAnagraphic
      }

      // L'impianto da modificare potrebbe non avere un oggetto baselineConfig (è stato creato precedentemente rispetto al refactor di questa sezione)
      // se l'oggetto è presente lo setto nell'impianto corrente
      if (baselineConfig) {
        state.baselineConfig = baselineConfig
        // altrimenti setto il valore di default dell'oggetto baselineConfig che si utilizza per un nuovo impianto
      } else {
        state.baselineConfig = initBaselineConfig
      }
      // L'impianto da modificare potrebbe non avere un oggetto baseline (è stato creato precedentemente rispetto al refactor di questa sezione)
      // se l'oggetto è presente lo setto nell'impianto corrente
      if (baseline) {
        state.baseline = baseline
        // altrimenti setto il valore di default dell'oggetto baseline che si utilizza per un nuovo impianto
      } else {
        state.baseline = initBaseline
      }
      // Se ci sono documenti salvati li setto altrimenti imposto l'array vuoto
      if (documents) {
        state.documents = documents
      } else {
        state.documents = initDocuments
      }
      // Se c'è il logo salvato lo setto altrimenti imposto l'oggetto a null
      if (logoURL) {
        state.logoURL = logoURL
      } else {
        state.logoURL = null
      }

      // Se c'è l'autosave attivato, lo setto
      if (hasAutosave) {
        state.hasAutosave = hasAutosave
      } else {
        state.hasAutosave = initHasAutosave
      }

      // All'oggetto rows di module è stato aggiunto un nuovo oggetto, quindi va aggiornato il precedente oggetto dell'impianto con la nuova struttura
      if (module) {
        if (Array.isArray(module)) {
          state.module = module
        } else {
          state.module = initModule
        }
      } else {
        state.module = initModule
      }

      state.name = name
      state.uuid = uuid
      state.addedDataloggers = addedDataloggers
      state.address = address
      state.contractDuration = contractDuration
      state.endDate = endDate || ''
      state.startDate = startDate || null
      state.maintenance = maintenance
      state.peakPower = peakPower
      state.planimetry = planimetry
      state.plantType = plantType
      state.powerLines = powerLines
      state.loadData = false
    },
    resetPlant (state) {
      const { module, anagraphic, selectedDatalogger, hasAutosave, dataloggers, addedDataloggers, maintenance, activeStep, plantType, name, peakPower, startDate, contractDuration, endDate, address, planimetry, baseline, baselineConfig, documents, powerLines } = initialState
      if (state.uuid) {
        delete state.uuid
      }
      if (state.devices) {
        delete state.devices
      }
      state.dataloggers = dataloggers
      state.anagraphic = anagraphic
      state.addedDataloggers = addedDataloggers
      state.maintenance = maintenance
      state.activeStep = activeStep
      state.hasAutosave = hasAutosave
      state.plantType = plantType
      state.selectedDatalogger = selectedDatalogger
      state.contractDuration = contractDuration
      state.peakPower = peakPower
      state.startDate = startDate
      state.endDate = endDate
      state.module = module
      state.name = name
      state.logoURL = null
      state.address = address
      state.planimetry = planimetry
      state.powerLines = powerLines
      state.documents = documents
      state.baseline = baseline
      state.baselineConfig = baselineConfig
    },
    setStep (state, action) {
      const { step } = action.payload
      state.activeStep = step
    },
    setAutosave (state, action) {
      const { hasAutosave } = action.payload
      state.hasAutosave = hasAutosave
    },
    setAddress (state, action) {
      const { newAddress } = action.payload
      state.address = newAddress
    },
    changeAnagraphicValues (state, action) {
      const { value, name } = action.payload
      if (state.anagraphic !== undefined) {
        if (state.anagraphic[name] !== undefined) {
          state.anagraphic[name] = value
        }
      }
    },
    changeValues (state, action) {
      const { value, name } = action.payload
      if (state[name] !== undefined) {
        state[name] = value
      }
    },
    setLogo (state, action) {
      const { url } = action.payload
      if (state.uuid) {
        api.post({ savedUrl: 'forceSummaryRefresh', path: `/${state.uuid}/summary` })
      }
      state.logoURL = url
    },
    updateAddedDatalogger (state, action) {
      const { dataloggerId, newDevice } = action.payload
      // Creo una copia dell'array dei datalogger aggiunti all'impianto
      const addedDataloggers = JSON.parse(JSON.stringify(state.addedDataloggers))
      // Filtro tutti i datalogger che non sono quello selezionato
      const otherDataloggers = addedDataloggers.filter(el => el.uuid !== dataloggerId)
      // Prendo il datalogger selezionato
      const selectedDatalogger = addedDataloggers.find(el => el.uuid === dataloggerId)
      // Se il datalogger selezionato esiste
      if (selectedDatalogger && selectedDatalogger !== undefined) {
        // Se il datalogger ha l'array di device
        if (selectedDatalogger.includes && selectedDatalogger.includes.devices) {
          // Prendo tutti i devices di questo datalogger
          const dataloggerDevices = JSON.parse(JSON.stringify(selectedDatalogger.includes.devices))
          const newDataloggerDevices = dataloggerDevices.map(el => {
            if (el.uuid === newDevice.uuid) {
              el.metadata.documents = newDevice.metadata.documents
            }

            return el
          })
          selectedDatalogger.includes.devices = newDataloggerDevices
          otherDataloggers.push(selectedDatalogger)
          state.addedDataloggers = otherDataloggers
        }
      }
    },
    selectDatalogger (state, action) {
      const { dataloggerId } = action.payload
      const currentDataloggers = Object.assign([], state.dataloggers)
      const addedDataloggers = Object.assign([], state.addedDataloggers)
      const selectedDataloggerIndex = currentDataloggers.findIndex(datalogger => datalogger.uuid === dataloggerId)
      const selectedDatalogger = currentDataloggers.splice(selectedDataloggerIndex, 1)
      if (selectedDatalogger) {
        addedDataloggers.push(selectedDatalogger[0])
        state.dataloggers = currentDataloggers
        state.addedDataloggers = addedDataloggers
      }
    },
    removeDatalogger (state, action) {
      const { dataloggerId } = action.payload
      // Datalogger disponibili
      const currentDataloggers = Object.assign([], state.dataloggers)
      // Datalogger assegnati all'impianto
      const addedDataloggers = Object.assign([], state.addedDataloggers)
      const currentPlanimetryConfig = JSON.parse(JSON.stringify(state.planimetry.config))
      const selectedDataloggerIndex = addedDataloggers.findIndex(datalogger => datalogger.uuid === dataloggerId)
      const selectedDatalogger = addedDataloggers.splice(selectedDataloggerIndex, 1)
      if (selectedDatalogger) {
        // Rimetto l'oggetto datalogger solo se non è già presente nell'array dei datalogger disponibili
        const existingDatalogger = currentDataloggers.find(datalogger => datalogger.uuid === dataloggerId)
        if (!existingDatalogger) {
          currentDataloggers.push(selectedDatalogger[0])
        }

        const devices = selectedDatalogger[0].includes && selectedDatalogger[0].includes.devices
        devices.forEach(device => {
          currentPlanimetryConfig.forEach(configElement => {
            if (configElement.devices) {
              // rimuovo i devices dall'array devices di ogni elemento di configurazione
              const newDevices = configElement.devices.filter(el => el.deviceId !== device.uuid)
              // assegno il nuovo array di devices filtrato
              configElement.devices = newDevices
            }
          })
        })

        state.dataloggers = currentDataloggers
        state.addedDataloggers = addedDataloggers
        state.planimetry.config = currentPlanimetryConfig
      }
    },
    setDataloggers (state, action) {
      const { dataloggers } = action.payload
      state.dataloggers = dataloggers
    },
    setDeviceTypeDocuments (state, action) {
      const { documents, deviceTypeId } = action.payload
      // Creo una copia dell'array dei datalogger aggiunti all'impianto
      const addedDataloggers = JSON.parse(JSON.stringify(state.addedDataloggers))
      const updatedDataloggers = addedDataloggers.map(datalogger => {
        if (datalogger.includes && datalogger.includes.devices) {
          const devices = [...datalogger.includes.devices]
          const newDevices = devices.map(device => {
            const deviceType = device.deviceType
            if (deviceType && deviceType.uuid === deviceTypeId) {
              const newDeviceType = {
                ...deviceType,
                metadata: {
                  ...deviceType.metadata,
                  documents
                }
              }

              return {
                ...device,
                deviceType: newDeviceType
              }
            }

            return device
          })

          return {
            ...datalogger,
            includes: {
              ...datalogger.includes,
              devices: newDevices
            }
          }
        }

        return datalogger
      })
      log({ text: 'state.addedDataloggers => ', variable: updatedDataloggers, tag: 'ledplantslice' })
      state.addedDataloggers = updatedDataloggers
    },
    setPlantDocuments (state, action) {
      const { documents } = action.payload
      state.documents = documents
    },
    updateBaselineConfig (state, action) {
      const { index, name, value } = action.payload
      state.baselineConfig[index][name] = value
    },
    updateBaseline (state, action) {
      const { index, name, value } = action.payload
      state.baseline[index][name] = value
    },
    updatePowerLine (state, action) {
      const { index, name, value } = action.payload
      state.powerLines[index][name] = value
    },
    addPowerLine (state) {
      const powerLinesCopy = JSON.parse(JSON.stringify(state.powerLines))
      powerLinesCopy.push({ uuid: uuidv4(), ...basePowerLineRow })
      state.powerLines = powerLinesCopy
    },
    removePoweLine (state, action) {
      const { lineUuid } = action.payload
      const powerLinesCopy = state.powerLines.filter(el => el.uuid !== lineUuid)
      // state.powerLines = powerLinesCopy.length === 0 ? [basePowerLineRow] : powerLinesCopy
      state.powerLines = powerLinesCopy
    },
    updateModel (state, action) {
      const { index, name, value } = action.payload
      state.module[index][name] = value
    },
    addModel (state) {
      const moduleCopy = JSON.parse(JSON.stringify(state.module))
      moduleCopy.push({ uuid: uuidv4(), ...baseModuleRow })
      state.module = moduleCopy
    },
    removeModel (state, action) {
      const { modelUuid } = action.payload
      const moduleCopy = state.module.filter(el => el.uuid !== modelUuid)
      state.module = moduleCopy
    },
    setModule (state, action) {
      const { module } = action.payload
      state.module = module
    },
    setPlanimetry (state, action) {
      const { list } = action.payload
      state.planimetry = list
    }
  }
})

export const reducer = slice.reducer
// Action di creazione impianto
export const createPlant = (plant, userIsSaving = false) => async () => {
  const body = encodeForApi(plant)
  log({ text: 'plantEncoded => ', variable: JSON.stringify(body), tag: 'ledplantslice' })
  const { uuid } = plant

  const { data: response, error } = await api.post({ savedUrl: 'dashboard', path: '/plants', body })

  if (error) {
    return { createdPlant: null, error: true }
  }

  const createdPlant = { ...response }
  if (!uuid || userIsSaving) {
    const { data: summaryRefreshResponse } = await api.post({ savedUrl: 'forceSummaryRefresh', path: `/${createdPlant.uuid}/summary` })
    log({ text: 'summaryRefreshResponse => ', variable: summaryRefreshResponse, tag: 'ledplantslice' })
  }

  return { createdPlant, error: false }
}
// Action di fetch dell'impianto corrente
export const getCurrentPlant = (plantId, firstLoad = false) => async dispatch => {
  if (plantId) {
    if (firstLoad) {
      dispatch(slice.actions.setLoadData({ loading: true }))
    }
    const options = {
      path: `/plants/${plantId}`
    }
    // if (fakeAuth) {
    //   // options.auth = 'Bearer 3adfc5f9-eac8-411a-b85a-c390709ce1f4'
    //   // options.auth = 'apikey RqNCrH.VPaHgRWv9pbrb58NPoA8EO8LbMd28l0t6XcNu7Kjh0M='
    // }
    const { data: response } = await api.get({ savedUrl: 'dashboard', ...options })
    // alog(response)
    if (response) {
      const { powerLines, plant, dataloggers, devices } = response || {}
      const adaptedPlant = decodeFromApi({ powerLines, plant, dataloggers, devices })
      dispatch(slice.actions.setCurrentPlant(adaptedPlant))
    }
  }
}
// Action che resetta la modal di creazione impianto al suo stato iniziale
export const resetInitState = () => dispatch => {
  dispatch(slice.actions.resetPlant())
}
// Action che setta lo step attuale ,nel processo di creazione/modifica impianto
export const setStep = (step) => dispatch => {
  dispatch(slice.actions.setStep({ step }))
}
// Action che fa il toggle dell'attivazione/disattivazione della funzione di autosalvataggio
export const togglePlantAutosave = (hasAutosave) => dispatch => {
  dispatch(slice.actions.setAutosave({ hasAutosave }))
}
// Action che si occupa della modifica dei valori di anagrafica
export const changeAnagraphicValue = (value, name) => dispatch => {
  dispatch(slice.actions.changeAnagraphicValues({ value, name }))
}
// Action che modifica i valori contrattuali
export const changeContractValue = (value, name) => dispatch => {
  dispatch(slice.actions.changeValues({ value, name }))
}
// Action che setta l'indirizzo di un impianto
export const setAddress = (newAddress) => dispatch => {
  dispatch(slice.actions.setAddress({ newAddress }))
}
// Action che imposta un nuovo logo per l'impianto
export const updateLogo = (url = null) => async dispatch => {
  dispatch(slice.actions.setLogo({ url }))
}
// Action che aggiunge un datalogger ai datalogger associati all'impianto
export const addDatalogger = (dataloggerId) => dispatch => {
  dispatch(slice.actions.selectDatalogger({ dataloggerId }))
}
// Action che rimuove un datalogger dalla lista dei dataloggers associati ad un impianto
export const deleteDatalogger = (dataloggerId) => dispatch => {
  dispatch(slice.actions.removeDatalogger({ dataloggerId }))
}
// Action che fa il fetch dei dataloggers disponibili
export const getDataloggers = ({ skip = 0, limit = 50, filter = null, hasNoPlant = null }) => async dispatch => {
  const params = {
    skip,
    limit,
    sortorder: 'DESC',
    include: 'devices'
  }

  if (filter) {
    params.filter = filter
  }

  if (hasNoPlant !== null) {
    params.hasNoPlant = hasNoPlant
  }

  const { data: dataloggers } = await api.get({ savedUrl: 'nodesList', params })
  if (dataloggers) {
    dispatch(slice.actions.setDataloggers({ dataloggers }))
  }
}
// Action che aggiorna i documenti per un tipo di device
export const updateDeviceTypeDocuments = (documents, deviceTypeId) => dispatch => {
  dispatch(slice.actions.setDeviceTypeDocuments({ documents, deviceTypeId }))
}
// Action che aggiorna i documenti di un impianto
export const updatePlantDocuments = (documents) => dispatch => {
  dispatch(slice.actions.setPlantDocuments({ documents }))
}
// Action che modifica la baseline
export const modifyBaseline = (index, name, value) => dispatch => {
  dispatch(slice.actions.updateBaseline({ index, name, value }))
}
// Action che modifica la configurazione di baseline
export const modifyBaselineConfig = (index, name, value) => dispatch => {
  dispatch(slice.actions.updateBaselineConfig({ index, name, value }))
}
// Action che modifica i valori della tabella di linee di corrente
export const modifyPowerLine = (index, name, value) => dispatch => {
  dispatch(slice.actions.updatePowerLine({ index, name, value }))
}
// Action che aggiunge una riga alla tabella delle linee di corrente
export const addPowerLine = () => dispatch => {
  dispatch(slice.actions.addPowerLine())
}
// Action che rimuove una riga dalla tabella delle linee di corrente
export const removePoweLine = (lineUuid) => dispatch => {
  dispatch(slice.actions.removePoweLine({ lineUuid }))
}
// Action che modifica i valori della tabella dei moduli dell'impianto
export const modifyModel = (index, name, value) => dispatch => {
  dispatch(slice.actions.updateModel({ index, name, value }))
}
// Action che aggiunge una riga alla tabella dei moduli dell'impianto
export const addModel = () => dispatch => {
  dispatch(slice.actions.addModel())
}
// Action che rimuove una riga dalla tabella dei moduli dell'impianto
export const removeModel = (modelUuid) => dispatch => {
  dispatch(slice.actions.removeModel({ modelUuid }))
}
// Action che salva la planimetria allo stato corrente
export const savePlanimetry = (list) => dispatch => {
  dispatch(slice.actions.setPlanimetry({ list }))
}
// Action che aggiorna il datalogger di un impianto
export const updateAddedDatalogger = (dataloggerId, newDevice) => dispatch => {
  dispatch(slice.actions.updateAddedDatalogger({ dataloggerId, newDevice }))
}
// Action che modifica il valore della potenza di picco calcolata
export const updatePeakPower = (value) => dispatch => {
  dispatch(slice.actions.changeValues({ value, name: 'peakPower' }))
}
// Action che setta l'array module per i moduli di un impianto
export const setModule = (module) => dispatch => {
  dispatch(slice.actions.setModule({ module }))
}
