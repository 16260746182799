import { useState, useEffect, memo } from 'react'
import {
  // NavLink as RouterLink,
  useHistory,
  useLocation
} from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  // Box,
  Typography,
  IconButton,
  Popper,
  Fade,
  Paper,
  Grid,
  // List,
  // ListSubheader,
  // ListItem,
  // ListItemAvatar,
  // ListItemText,
  // Avatar,
  makeStyles,
  useTheme
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  listContainer: {
    minWidth: 20,
    minHeight: 20
  },
  paper: {
    padding: theme.spacing(2)
    // backgroundColor: theme.palette.background.paper,
    // '@supports (backdrop-filter: none)': {
    //   backdropFilter: 'blur(40px)',
    //   backgroundColor: 'rgba(38, 61, 84, 0.3)'
    // }
  }
}))

const NavItem = ({ section, icon: Icon, shortView: ShortView, ...rest }) => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const location = useLocation()
  const [anchorEl, setAnchorEl] = useState(null)
  const [openPopper, setOpenPopper] = useState(false)

  useEffect(() => {
    // resetto i menu
    setOpenPopper(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <>
      <IconButton
        style={{
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
          paddingLeft: theme.spacing(2) - 2,
          paddingRight: theme.spacing(2) - 2
        }}
        color='default'
        aria-label={section.title}
        onClick={(event) => {
          setOpenPopper(false)
          history.push(section.href)
        }}
        onMouseEnter={(event) => {
          setAnchorEl(event.currentTarget)
          setOpenPopper(true)
        }}
        onMouseLeave={(event) => {
          setOpenPopper(false)
        }}
        onTouchStart={(event) => {
          setAnchorEl(event.currentTarget)
          setOpenPopper(true)
        }}
      >
        <Icon style={{ color: location.pathname.includes(section.href) ? theme.palette.primary.main : null }} />
      </IconButton>
      <Popper
        open={openPopper}
        anchorEl={anchorEl}
        placement='right-start'
        transition
        style={{ zIndex: '10000', marginLeft: 8 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              elevation={4}
              onMouseEnter={() => setOpenPopper(true)}
              onMouseLeave={() => setOpenPopper(false)}
              // style={{ padding: theme.spacing(2) }}
              className={classes.paper}
            >
              <Grid container spacing={1} className={classes.listContainer}>
                <Grid item xs={12}>
                  <Typography variant='body1'>{section.title}</Typography>
                </Grid>
                {ShortView && (
                  <Grid item xs={12}>
                    <ShortView />
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  )
}

NavItem.propTypes = {
  section: PropTypes.object,
  icon: PropTypes.elementType,
  shortView: PropTypes.elementType
}

export default memo(NavItem)
