import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { basicColors } from '@/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    display: 'block',
    height: 1754,
    width: 1240,
    overflow: 'scroll',
    padding: 10
  },
  wrapper: {
    width: '100%',
    height: '100%',
    border: `2px solid ${basicColors.background}`
  }
}))

function VerticalReportLayout ({ children, className, ...rest }) {
  const classes = useStyles()
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div className={classes.wrapper}>
        {children || 'null'}
      </div>
    </div>
  )
}
export default VerticalReportLayout
