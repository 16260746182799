import { format } from 'date-fns'
import { it, enGB } from 'date-fns/locale'

/**
 * wrapper of the date-fns FORMAT func, with locale
 * @param {*} date // the date, it will be passed to a new Date() before format
 * @param {*} formatStr // string to specify how to format the date
 * @param {*} localeString // default it, accept en for english locale
 * @returns data-fns format with locale and formatString
 */
export const localFormat = (date, formatStr = 'dd/MM/yyyy', localeString = 'it') => {
  if (!date) return console.warn('Missing date param on localFormat')
  // if (formatStr === 'Pp') console.info('Missing format string, using dd/MM/yyyy.')

  return format(new Date(date), formatStr, {
    locale: localeString === 'it' ? it : localeString === 'en' ? enGB : it
  })
}
