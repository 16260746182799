import clsx from 'clsx'
import { Box, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core'
import { basicColors } from '@/theme'
import { getContrastColor } from '@/utils/general'
import {
  DownloadCloud as DownloadIcon
} from 'react-feather'

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 24,
    cursor: 'pointer'
  },
  ok: {
    backgroundColor: basicColors.lightGreen,
    color: getContrastColor(basicColors.lightGreen, true)
  },
  maintenance: {
    backgroundColor: basicColors.lightYellow,
    color: getContrastColor(basicColors.lightYellow, true)
  },
  anomaly_energy: {
    backgroundColor: basicColors.lightYellow,
    color: getContrastColor(basicColors.lightYellow, true)
  },
  anomaly_communication: {
    backgroundColor: basicColors.lightYellow,
    color: getContrastColor(basicColors.lightYellow, true)
  },
  anomaly_component: {
    backgroundColor: basicColors.lightRed,
    color: getContrastColor(basicColors.lightRed, true)
  },
  default: {
    backgroundColor: '#ffffff',
    color: getContrastColor('#ffffff', true)
  },
  chipText: {
    width: 80,
    // textOverflow: 'ellipsis',
    fontSize: '13px',
    fontWeight: 500
  }
}))

function DeviceChip ({ device, className, downloadActions, onClick }) {
  const classes = useStyles()

  const deviceStatus = device?.status || null

  // funzione che torna le icone di stato
  const returnStatus = (status) => {
    let general = 'N.F.'
    let communication = 'N.F.'
    if (status.general === 'ok') {
      general = 'OK'
    } else if (status.general === 'anomaly_component') {
      general = 'Errore'
    } else if (status.general === 'maintenance' || status.general === 'anomaly_energy' || status.general === 'anomaly_communication') {
      general = 'Attenzione'
    }

    if (status.communication === 'online') {
      communication = 'Connesso'
    } else {
      communication = 'Disconnesso'
    }

    return { general, communication }
  }

  // funzione che torna le icone delle azioni
  const returnActions = (actions) => {
    return (
      <Tooltip title={actions.documents ? 'Download Datasheet' : 'Nessun Datasheet caricato'} aria-label='Download Datasheet'>
        <span>
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              downloadActions(actions.documents)
            }}
            style={{ padding: 6 }}
            fontSize='small'
            disabled={!(actions.documents && actions.documents !== '')}
          >
            <DownloadIcon
              style={{ width: 14, height: 14 }}
              fontSize='small'
              color={actions.documents && actions.documents !== '' ? basicColors.darkText : basicColors.blueGrayLight}
            />
          </IconButton>
        </span>
      </Tooltip>
    )
  }

  // funzione che in base al tipo di device ritorna un tooltip con le info corrette
  const generateTooltip = (el, children) => {
    let tooltipMessage = ''
    const { general, communication } = returnStatus(el.status)
    if (el.type === 'inverter') {
      tooltipMessage = (
        <span>
          <p style={{ marginBottom: 4, fontWeight: 600 }}>{`${el.name}`}</p>
          <p>{el.activepowertot && el.activepowertot !== 'N.F.' ? `Potenza: ${el.activepowertot} kW` : 'Potenza: N.F.'}</p>
          <p>{el.totalenergy && el.totalenergy !== 'N.F.' ? `Energia: ${el.totalenergy} kWh` : 'Energia: N.F.'}</p>
          <p>{`Stato: ${general}`}</p>
          <p>{`Comunicazione: ${communication}`}</p>
        </span>
      )
    } else if (el.type === 'sunMeter') {
      tooltipMessage = (
        <span>
          <p style={{ marginBottom: 4, fontWeight: 600 }}>{`${el.name}`}</p>
          <p>{el.irradiation && el.irradiation !== 'N.F.' ? `Irraggiamento: ${el.irradiation} W/m${String.fromCodePoint(0x00B2)}` : 'Irraggiamento: N.F.'}</p>
          <p>{el.temperature && el.temperature !== 'N.F.' ? `Temperatura moduli: ${el.temperature} °C` : 'Temperatura moduli: N.F.'}</p>
          <p>{`Stato: ${general}`}</p>
          <p>{`Comunicazione: ${communication}`}</p>
        </span>
      )
    } else if (el.type === 'energyMeter') {
      tooltipMessage = (
        <span>
          <p style={{ marginBottom: 4, fontWeight: 600 }}>{`${el.name}`}</p>
          <p>{el.impenergy && el.impenergy !== 'N.F.' ? `E.Immessa: ${el.impenergy} kWh` : 'E.Immessa: N.F.'}</p>
          <p>{el.expenergy && el.expenergy !== 'N.F.' ? `E.Consumata: ${el.expenergy} kWh` : 'E.Consumata: N.F.'}</p>
          <p>{el.activepowertot && el.activepowertot !== 'N.F.' ? `Potenza totale: ${el.activepowertot} W` : 'Potenza totale: N.F.'}</p>
          <p>{`Stato: ${general}`}</p>
          <p>{`Comunicazione: ${communication}`}</p>
        </span>
      )
    }

    return (
      <Tooltip title={tooltipMessage} placement='top'>
        {children}
      </Tooltip>
    )
  }

  return (
    <Box onClick={() => onClick(device)} mr={1} my={1} pl={1.5} pr={1} className={clsx(classes.root, className, classes[deviceStatus?.general || 'default'])} display='flex' alignItems='center' justifyContent='space-between'>
      {generateTooltip(device, <Typography className={classes.chipText} noWrap component='div'>{device.name || ''}</Typography>)}
      <Box ml={1} display='flex' alignItems='center'>
        {returnActions(device.actions)}
      </Box>
    </Box>
  )
}
export default DeviceChip
