import { useState, useEffect, useMemo, useRef } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { configuration } from '@/config'
import log from '@pelv/frontlog'

// Hook che salva in un array gli ultimi 2 eventi ricevuti in websocket
const useSocket = (newUrl, userId) => {
  // const [latestMessage, setLatestEvent] = useState({})
  // const [previousEvent, setPreviousEvent] = useState({})
  const [socketUrl, setSocketUrl] = useState(newUrl || configuration.webSocket)
  const messageHistory = useRef([])

  // prefispongo la socket e mi prendo le variabili che mi servono
  const {
    sendMessage,
    lastMessage,
    readyState
  } = useWebSocket(socketUrl, {
    // onOpen: () => log({ text: 'socket opened', tag: 'socket' }),
    // onClose: () => log({ text: 'socket closed', tag: 'socket' }),
    queryParams: {
      userId: userId || ''
    },
    filter: (message) => {
      const thisEvent = JSON.parse(message.data)
      if (thisEvent && thisEvent.type) {
        return thisEvent.type === 'venera.anomaly.created' || thisEvent.type === 'venera.anomaly.closed'
      } else {
        return false
      }
    },
    // Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true
  })

  messageHistory.current = useMemo(() => {
    // return lastMessage ? messageHistory.current.concat(lastMessage) : messageHistory.current
    return lastMessage ? [lastMessage, ...messageHistory.current] : messageHistory.current
  }, [lastMessage])
  // variabile con lo stato della socket in modo leggibile
  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated'
  }[readyState]

  // faccio la connessione ed il ping al cambio dell'url
  useEffect(() => {
    if (newUrl !== socketUrl) {
      setSocketUrl(newUrl)
    }
    const pingInterval = setInterval(() => {
      log({ text: 'ping socket' })
      if (connectionStatus === 'Open') {
        sendMessage('HODORRRR')
      }
    }, 29000)
    return () => {
      clearInterval(pingInterval)
    }
  }, [newUrl, socketUrl, connectionStatus, sendMessage])

  // useEffect(() => {
  //   validateToken()
  // }, [validateToken])

  return { connectionStatus, lastMessage, messageHistory }
}

export default useSocket
