import moment from 'moment'
import mock from '@/utils/___mock'
import { getDatesFromPeriod } from '@/views/overview/AnalyticsView/pv/utils'

// Dati Mock Fotovoltaico
const pvLiveData = {
  peakPower: 89.4,
  totalPlants: 97,
  totalEnergy: 351.6669193495,
  totalProducedEnergy: 224.0599999999904,
  totalExportedEnergy: 0.1,
  totalExpectedEnergy: 127.60691934951171,
  expectedEnergy: [
    {
      label: '2021-02-23T23:00:00.000Z',
      value: 0.03714681440443214
    },
    {
      label: '2021-02-24T00:00:00.000Z',
      value: 0.049529085872576185
    },
    {
      label: '2021-02-24T01:00:00.000Z',
      value: 0.049529085872576185
    },
    {
      label: '2021-02-24T02:00:00.000Z',
      value: 0.049529085872576185
    },
    {
      label: '2021-02-24T03:00:00.000Z',
      value: 0.049529085872576185
    },
    {
      label: '2021-02-24T04:00:00.000Z',
      value: 0.049529085872576185
    },
    {
      label: '2021-02-24T05:00:00.000Z',
      value: 0.11753331048632558
    },
    {
      label: '2021-02-24T06:00:00.000Z',
      value: 3.026277242910162
    },
    {
      label: '2021-02-24T07:00:00.000Z',
      value: 4.127939768409621
    },
    {
      label: '2021-02-24T08:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T09:00:00.000Z',
      value: 7.092977840907741
    },
    {
      label: '2021-02-24T10:00:00.000Z',
      value: 30.683647060007996
    },
    {
      label: '2021-02-24T11:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T12:00:00.000Z',
      value: 30.32568635291079
    },
    {
      label: '2021-02-24T13:00:00.000Z',
      value: 25.3386674522183
    },
    {
      label: '2021-02-24T14:00:00.000Z',
      value: 17.649276941196312
    },
    {
      label: '2021-02-24T15:00:00.000Z',
      value: 7.603333766651318
    },
    {
      label: '2021-02-24T16:00:00.000Z',
      value: 1.341928644578627
    },
    {
      label: '2021-02-24T17:00:00.000Z',
      value: 0.014858725467210043
    },
    {
      label: '2021-02-24T18:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T19:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T20:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T21:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T22:00:00.000Z',
      value: null
    }
  ],
  producedEnergy: [
    {
      label: '2021-02-23T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T00:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T01:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T02:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T03:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T04:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T05:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T06:00:00.000Z',
      value: 2.25
    },
    {
      label: '2021-02-24T07:00:00.000Z',
      value: 7.069999999992433
    },
    {
      label: '2021-02-24T08:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T09:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T10:00:00.000Z',
      value: 38.580000000001746
    },
    {
      label: '2021-02-24T11:00:00.000Z',
      value: 8.779999999998836
    },
    {
      label: '2021-02-24T12:00:00.000Z',
      value: 79.80999999999767
    },
    {
      label: '2021-02-24T13:00:00.000Z',
      value: 40.26000000000931
    },
    {
      label: '2021-02-24T14:00:00.000Z',
      value: 28.389999999992142
    },
    {
      label: '2021-02-24T15:00:00.000Z',
      value: 15.190000000002328
    },
    {
      label: '2021-02-24T16:00:00.000Z',
      value: 3.609999999993306
    },
    {
      label: '2021-02-24T17:00:00.000Z',
      value: 0.12000000000261934
    },
    {
      label: '2021-02-24T18:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T19:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T20:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T21:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T22:00:00.000Z',
      value: null
    }
  ],
  exportedEnergy: [
    {
      label: '2021-02-23T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T00:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T01:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T02:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T03:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T04:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T05:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T06:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T07:00:00.000Z',
      value: 0.1
    },
    {
      label: '2021-02-24T08:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T09:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T10:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T11:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T12:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T13:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T14:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T15:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T16:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T17:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T18:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T19:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T20:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T21:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T22:00:00.000Z',
      value: null
    }
  ],
  baselinePr: 1.193026632415803,
  realPr: 0.9727761207753779,
  baselineProduction: 127.60691934951171,
  realProduction: 224.0599999999904,
  messages: [],
  performanceGraph: [
    {
      name: '100Kw',
      data: {
        pr: 0.87,
        expectedEnergy: 0.7,
        outOfService: 1,
        energyAnomalies: 2,
        monitoringAnomalies: 3,
        maintenances: 5
      }
    },
    {
      name: '250Kw',
      data: {
        pr: 0.7,
        expectedEnergy: 0.6,
        outOfService: 0,
        energyAnomalies: 4,
        monitoringAnomalies: 1,
        maintenances: 3
      }
    },
    {
      name: '500Kw',
      data: {
        pr: 0.8,
        expectedEnergy: 0.9,
        outOfService: 0,
        energyAnomalies: 0,
        monitoringAnomalies: 1,
        maintenances: 8
      }
    },
    {
      name: '1Mw',
      data: {
        pr: 0.8,
        expectedEnergy: 0.9,
        outOfService: 3,
        energyAnomalies: 0,
        monitoringAnomalies: 2,
        maintenances: 4
      }
    }
  ]
}

const pvWeekData =
{
  peakPower: 2359.4,
  totalPlants: 97,
  totalEnergy: 1362.6789453495,
  totalProducedEnergy: 706.6399999999994,
  totalExportedEnergy: 1.2,
  totalExpectedEnergy: 439.8060671956208,
  expectedEnergy: [
    {
      label: '2021-02-21T23:00:00.000Z',
      value: 100.85484570892297
    },
    {
      label: '2021-02-22T23:00:00.000Z',
      value: 171.53972138629186
    },
    {
      label: '2021-02-23T23:00:00.000Z',
      value: 127.90409386474698
    },
    {
      label: '2021-02-24T23:00:00.000Z',
      value: 39.50740623565899
    },
    {
      label: '2021-02-25T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-26T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-27T23:00:00.000Z',
      value: null
    }
  ],
  producedEnergy: [
    {
      label: '2021-02-21T23:00:00.000Z',
      value: 156.34999999999127
    },
    {
      label: '2021-02-22T23:00:00.000Z',
      value: 261.20000000001164
    },
    {
      label: '2021-02-23T23:00:00.000Z',
      value: 224.0599999999904
    },
    {
      label: '2021-02-24T23:00:00.000Z',
      value: 65.03000000000611
    },
    {
      label: '2021-02-25T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-26T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-27T23:00:00.000Z',
      value: null
    }
  ],
  exportedEnergy: [
    {
      label: '2021-02-21T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-22T23:00:00.000Z',
      value: 0.4
    },
    {
      label: '2021-02-23T23:00:00.000Z',
      value: 0.1
    },
    {
      label: '2021-02-24T23:00:00.000Z',
      value: 0.7
    },
    {
      label: '2021-02-25T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-26T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-27T23:00:00.000Z',
      value: null
    }
  ],
  baselinePr: 1.8857517738185265,
  realPr: 0.8901430287340952,
  baselineProduction: 439.8060671956208,
  realProduction: 706.6399999999994,
  messages: [],
  performanceGraph: [
    {
      name: '100Kw',
      data: {
        pr: 0.87,
        expectedEnergy: 0.7,
        outOfService: 1,
        energyAnomalies: 2,
        monitoringAnomalies: 3,
        maintenances: 5
      }
    },
    {
      name: '250Kw',
      data: {
        pr: 0.7,
        expectedEnergy: 0.6,
        outOfService: 0,
        energyAnomalies: 4,
        monitoringAnomalies: 1,
        maintenances: 3
      }
    },
    {
      name: '500Kw',
      data: {
        pr: 0.8,
        expectedEnergy: 0.9,
        outOfService: 0,
        energyAnomalies: 0,
        monitoringAnomalies: 1,
        maintenances: 1
      }
    },
    {
      name: '1Mw',
      data: {
        pr: 0.8,
        expectedEnergy: 0.9,
        outOfService: 3,
        energyAnomalies: 0,
        monitoringAnomalies: 2,
        maintenances: 4
      }
    }
  ]
}

const pvMonthData = {
  peakPower: 6459.4,
  totalPlants: 97,
  totalEnergy: 10534.235464578,
  totalProducedEnergy: 4038.9600000000064,
  totalExportedEnergy: 532.6000000000004,
  totalExpectedEnergy: 6367.7419354838685,
  expectedEnergy: [
    {
      label: '2021-01-31T23:00:00.000Z',
      value: 108.65901953243365
    },
    {
      label: '2021-02-01T23:00:00.000Z',
      value: 84.01904797138002
    },
    {
      label: '2021-02-02T23:00:00.000Z',
      value: 100.4510218442882
    },
    {
      label: '2021-02-03T23:00:00.000Z',
      value: 111.85348540421161
    },
    {
      label: '2021-02-04T23:00:00.000Z',
      value: 103.85611009780078
    },
    {
      label: '2021-02-05T23:00:00.000Z',
      value: 66.96664975172277
    },
    {
      label: '2021-02-06T23:00:00.000Z',
      value: 40.711080706578656
    },
    {
      label: '2021-02-07T23:00:00.000Z',
      value: 113.21515856460279
    },
    {
      label: '2021-02-08T23:00:00.000Z',
      value: 140.67622503290124
    },
    {
      label: '2021-02-09T23:00:00.000Z',
      value: 137.76930611647612
    },
    {
      label: '2021-02-10T23:00:00.000Z',
      value: 142.95970017494088
    },
    {
      label: '2021-02-11T23:00:00.000Z',
      value: 29.093829020144703
    },
    {
      label: '2021-02-12T23:00:00.000Z',
      value: 8.967978507504224
    },
    {
      label: '2021-02-13T23:00:00.000Z',
      value: 90.62400937285327
    },
    {
      label: '2021-02-14T23:00:00.000Z',
      value: 192.11640892765035
    },
    {
      label: '2021-02-15T23:00:00.000Z',
      value: 193.63311448051692
    },
    {
      label: '2021-02-16T23:00:00.000Z',
      value: 117.63903990940591
    },
    {
      label: '2021-02-17T23:00:00.000Z',
      value: 158.8114409985416
    },
    {
      label: '2021-02-18T23:00:00.000Z',
      value: 178.72450545623232
    },
    {
      label: '2021-02-19T23:00:00.000Z',
      value: 183.6663166084933
    },
    {
      label: '2021-02-20T23:00:00.000Z',
      value: 194.79510949252918
    },
    {
      label: '2021-02-21T23:00:00.000Z',
      value: 100.85484570892297
    },
    {
      label: '2021-02-22T23:00:00.000Z',
      value: 171.53972138629186
    },
    {
      label: '2021-02-23T23:00:00.000Z',
      value: 127.90409386474698
    },
    {
      label: '2021-02-24T23:00:00.000Z',
      value: 45.986429792411066
    },
    {
      label: '2021-02-25T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-26T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-27T23:00:00.000Z',
      value: null
    }
  ],
  producedEnergy: [
    {
      label: '2021-01-31T23:00:00.000Z',
      value: 170.12000000000262
    },
    {
      label: '2021-02-01T23:00:00.000Z',
      value: 129.9100000000035
    },
    {
      label: '2021-02-02T23:00:00.000Z',
      value: 158.09999999999854
    },
    {
      label: '2021-02-03T23:00:00.000Z',
      value: 155.1999999999898
    },
    {
      label: '2021-02-04T23:00:00.000Z',
      value: 159.74000000000524
    },
    {
      label: '2021-02-05T23:00:00.000Z',
      value: 110.3900000000067
    },
    {
      label: '2021-02-06T23:00:00.000Z',
      value: 61.20999999999913
    },
    {
      label: '2021-02-07T23:00:00.000Z',
      value: 171.54000000000087
    },
    {
      label: '2021-02-08T23:00:00.000Z',
      value: 207.36999999998807
    },
    {
      label: '2021-02-09T23:00:00.000Z',
      value: 210.17000000000553
    },
    {
      label: '2021-02-10T23:00:00.000Z',
      value: 215.88999999999214
    },
    {
      label: '2021-02-11T23:00:00.000Z',
      value: 43.80000000000291
    },
    {
      label: '2021-02-12T23:00:00.000Z',
      value: 2.3800000000119326
    },
    {
      label: '2021-02-13T23:00:00.000Z',
      value: 9.65999999998894
    },
    {
      label: '2021-02-14T23:00:00.000Z',
      value: 37.38000000000466
    },
    {
      label: '2021-02-15T23:00:00.000Z',
      value: 245.4800000000032
    },
    {
      label: '2021-02-16T23:00:00.000Z',
      value: 176.93999999999505
    },
    {
      label: '2021-02-17T23:00:00.000Z',
      value: 232.5500000000029
    },
    {
      label: '2021-02-18T23:00:00.000Z',
      value: 267.8899999999994
    },
    {
      label: '2021-02-19T23:00:00.000Z',
      value: 271.77999999999884
    },
    {
      label: '2021-02-20T23:00:00.000Z',
      value: 288.0200000000041
    },
    {
      label: '2021-02-21T23:00:00.000Z',
      value: 156.34999999999127
    },
    {
      label: '2021-02-22T23:00:00.000Z',
      value: 261.20000000001164
    },
    {
      label: '2021-02-23T23:00:00.000Z',
      value: 224.0599999999904
    },
    {
      label: '2021-02-24T23:00:00.000Z',
      value: 71.83000000000902
    },
    {
      label: '2021-02-25T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-26T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-27T23:00:00.000Z',
      value: null
    }
  ],
  exportedEnergy: [
    {
      label: '2021-01-31T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-01T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-02T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-03T23:00:00.000Z',
      value: 0.1
    },
    {
      label: '2021-02-04T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-05T23:00:00.000Z',
      value: 62.00000000000001
    },
    {
      label: '2021-02-06T23:00:00.000Z',
      value: 28.699999999999992
    },
    {
      label: '2021-02-07T23:00:00.000Z',
      value: 0.5
    },
    {
      label: '2021-02-08T23:00:00.000Z',
      value: 0.2
    },
    {
      label: '2021-02-09T23:00:00.000Z',
      value: 1.7
    },
    {
      label: '2021-02-10T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-11T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-12T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-13T23:00:00.000Z',
      value: 0.1
    },
    {
      label: '2021-02-14T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-15T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-16T23:00:00.000Z',
      value: 1
    },
    {
      label: '2021-02-17T23:00:00.000Z',
      value: 1.5
    },
    {
      label: '2021-02-18T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-19T23:00:00.000Z',
      value: 199.20000000000002
    },
    {
      label: '2021-02-20T23:00:00.000Z',
      value: 236.39999999999998
    },
    {
      label: '2021-02-21T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-22T23:00:00.000Z',
      value: 0.4
    },
    {
      label: '2021-02-23T23:00:00.000Z',
      value: 0.1
    },
    {
      label: '2021-02-24T23:00:00.000Z',
      value: 0.7
    },
    {
      label: '2021-02-25T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-26T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-27T23:00:00.000Z',
      value: null
    }
  ],
  baselinePr: 30.172028381096432,
  realPr: 0.7596862313720204,
  baselineProduction: 6367.7419354838685,
  realProduction: 4038.9600000000064,
  messages: [],
  performanceGraph: [
    {
      name: '100Kw',
      data: {
        pr: 0.87,
        expectedEnergy: 0.7,
        outOfService: 1,
        energyAnomalies: 2,
        monitoringAnomalies: 3,
        maintenances: 5
      }
    },
    {
      name: '250Kw',
      data: {
        pr: 0.7,
        expectedEnergy: 0.6,
        outOfService: 0,
        energyAnomalies: 4,
        monitoringAnomalies: 1,
        maintenances: 3
      }
    },
    {
      name: '500Kw',
      data: {
        pr: 0.8,
        expectedEnergy: 0.9,
        outOfService: 0,
        energyAnomalies: 0,
        monitoringAnomalies: 1,
        maintenances: 1
      }
    },
    {
      name: '1Mw',
      data: {
        pr: 0.8,
        expectedEnergy: 0.9,
        outOfService: 3,
        energyAnomalies: 0,
        monitoringAnomalies: 2,
        maintenances: 4
      }
    }
  ]
}

const pvYearData = {
  peakPower: 10345.456,
  totalPlants: 97,
  totalEnergy: 194565.1567834899,
  totalProducedEnergy: 7247.710000000014,
  totalExportedEnergy: 1284.1000000000001,
  totalExpectedEnergy: 109961.61290322579,
  expectedEnergy: [
    {
      label: '2020-12-31T23:00:00.001Z',
      value: 4220
    },
    {
      label: '2021-01-31T23:00:00.001Z',
      value: 7050
    },
    {
      label: '2021-02-28T23:00:00.001Z',
      value: 8220
    },
    {
      label: '2021-03-31T22:00:00.001Z',
      value: 12170
    },
    {
      label: '2021-04-30T22:00:00.001Z',
      value: 12710
    },
    {
      label: '2021-05-31T22:00:00.001Z',
      value: 14130
    },
    {
      label: '2021-06-30T22:00:00.001Z',
      value: 15880
    },
    {
      label: '2021-07-31T22:00:00.001Z',
      value: 13150
    },
    {
      label: '2021-08-31T22:00:00.001Z',
      value: 10460
    },
    {
      label: '2021-09-30T22:00:00.001Z',
      value: 7230
    },
    {
      label: '2021-10-31T23:00:00.001Z',
      value: 3420
    },
    {
      label: '2021-11-30T23:00:00.001Z',
      value: 3300
    }
  ],
  producedEnergy: [
    {
      label: '2020-12-31T23:00:00.000Z',
      value: 3208.7500000000073
    },
    {
      label: '2021-01-31T23:00:00.000Z',
      value: 4038.9600000000064
    },
    {
      label: '2021-02-28T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-03-31T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-04-30T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-05-31T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-06-30T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-07-31T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-08-31T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-09-30T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-10-31T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-11-30T23:00:00.000Z',
      value: null
    }
  ],
  exportedEnergy: [
    {
      label: '2020-12-31T23:00:00.000Z',
      value: 751.5
    },
    {
      label: '2021-01-31T23:00:00.000Z',
      value: 532.5999999999999
    },
    {
      label: '2021-02-28T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-03-31T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-04-30T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-05-31T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-06-30T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-07-31T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-08-31T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-09-30T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-10-31T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-11-30T23:00:00.000Z',
      value: null
    }
  ],
  baselinePr: 3554.1721481811815,
  realPr: 0.774446370493404,
  baselineProduction: 109961.61290322579,
  realProduction: 7247.710000000014,
  messages: [],
  performanceGraph: [
    {
      name: '100Kw',
      data: {
        pr: 0.87,
        expectedEnergy: 0.7,
        outOfService: 1,
        energyAnomalies: 2,
        monitoringAnomalies: 3,
        maintenances: 5
      }
    },
    {
      name: '250Kw',
      data: {
        pr: 0.7,
        expectedEnergy: 0.6,
        outOfService: 0,
        energyAnomalies: 4,
        monitoringAnomalies: 1,
        maintenances: 3
      }
    },
    {
      name: '500Kw',
      data: {
        pr: 0.8,
        expectedEnergy: 0.9,
        outOfService: 0,
        energyAnomalies: 0,
        monitoringAnomalies: 1,
        maintenances: 1
      }
    },
    {
      name: '1Mw',
      data: {
        pr: 0.8,
        expectedEnergy: 0.9,
        outOfService: 3,
        energyAnomalies: 0,
        monitoringAnomalies: 2,
        maintenances: 4
      }
    }
  ]
}

// Dati Mock Led
const ledLiveData = {
  peakPower: 29.4,
  totalPlants: 12,
  totalEnergy: 35.16669193495,
  totalProducedEnergy: 22.40599999999904,
  totalExportedEnergy: 0.1,
  totalExpectedEnergy: 12.760691934951171,
  expectedEnergy: [
    {
      label: '2021-02-23T23:00:00.000Z',
      value: 0.03714681440443214
    },
    {
      label: '2021-02-24T00:00:00.000Z',
      value: 0.049529085872576185
    },
    {
      label: '2021-02-24T01:00:00.000Z',
      value: 0.049529085872576185
    },
    {
      label: '2021-02-24T02:00:00.000Z',
      value: 0.049529085872576185
    },
    {
      label: '2021-02-24T03:00:00.000Z',
      value: 0.049529085872576185
    },
    {
      label: '2021-02-24T04:00:00.000Z',
      value: 0.049529085872576185
    },
    {
      label: '2021-02-24T05:00:00.000Z',
      value: 0.11753331048632558
    },
    {
      label: '2021-02-24T06:00:00.000Z',
      value: 3.026277242910162
    },
    {
      label: '2021-02-24T07:00:00.000Z',
      value: 4.127939768409621
    },
    {
      label: '2021-02-24T08:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T09:00:00.000Z',
      value: 7.092977840907741
    },
    {
      label: '2021-02-24T10:00:00.000Z',
      value: 30.683647060007996
    },
    {
      label: '2021-02-24T11:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T12:00:00.000Z',
      value: 30.32568635291079
    },
    {
      label: '2021-02-24T13:00:00.000Z',
      value: 25.3386674522183
    },
    {
      label: '2021-02-24T14:00:00.000Z',
      value: 17.649276941196312
    },
    {
      label: '2021-02-24T15:00:00.000Z',
      value: 7.603333766651318
    },
    {
      label: '2021-02-24T16:00:00.000Z',
      value: 1.341928644578627
    },
    {
      label: '2021-02-24T17:00:00.000Z',
      value: 0.014858725467210043
    },
    {
      label: '2021-02-24T18:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T19:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T20:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T21:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T22:00:00.000Z',
      value: null
    }
  ],
  producedEnergy: [
    {
      label: '2021-02-23T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T00:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T01:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T02:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T03:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T04:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T05:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T06:00:00.000Z',
      value: 2.25
    },
    {
      label: '2021-02-24T07:00:00.000Z',
      value: 7.069999999992433
    },
    {
      label: '2021-02-24T08:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T09:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T10:00:00.000Z',
      value: 38.580000000001746
    },
    {
      label: '2021-02-24T11:00:00.000Z',
      value: 8.779999999998836
    },
    {
      label: '2021-02-24T12:00:00.000Z',
      value: 79.80999999999767
    },
    {
      label: '2021-02-24T13:00:00.000Z',
      value: 40.26000000000931
    },
    {
      label: '2021-02-24T14:00:00.000Z',
      value: 28.389999999992142
    },
    {
      label: '2021-02-24T15:00:00.000Z',
      value: 15.190000000002328
    },
    {
      label: '2021-02-24T16:00:00.000Z',
      value: 3.609999999993306
    },
    {
      label: '2021-02-24T17:00:00.000Z',
      value: 0.12000000000261934
    },
    {
      label: '2021-02-24T18:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T19:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T20:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T21:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T22:00:00.000Z',
      value: null
    }
  ],
  exportedEnergy: [
    {
      label: '2021-02-23T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T00:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T01:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T02:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T03:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T04:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T05:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T06:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T07:00:00.000Z',
      value: 0.1
    },
    {
      label: '2021-02-24T08:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T09:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T10:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T11:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T12:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T13:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T14:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T15:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T16:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T17:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-24T18:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T19:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T20:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T21:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-24T22:00:00.000Z',
      value: null
    }
  ],
  baselinePr: 1.193026632415803,
  realPr: 0.9727761207753779,
  baselineProduction: 12.760691934951171,
  realProduction: 22.40599999999904,
  messages: [],
  performanceGraph: [
    {
      name: '100Kw',
      data: {
        pr: 0.87,
        expectedEnergy: 0.7,
        outOfService: 1,
        energyAnomalies: 2,
        monitoringAnomalies: 3,
        maintenances: 5
      }
    },
    {
      name: '250Kw',
      data: {
        pr: 0.7,
        expectedEnergy: 0.6,
        outOfService: 0,
        energyAnomalies: 4,
        monitoringAnomalies: 1,
        maintenances: 3
      }
    },
    {
      name: '500Kw',
      data: {
        pr: 0.8,
        expectedEnergy: 0.9,
        outOfService: 0,
        energyAnomalies: 0,
        monitoringAnomalies: 1,
        maintenances: 1
      }
    },
    {
      name: '1Mw',
      data: {
        pr: 0.8,
        expectedEnergy: 0.9,
        outOfService: 3,
        energyAnomalies: 0,
        monitoringAnomalies: 2,
        maintenances: 4
      }
    }
  ]
}

const ledWeekData =
{
  peakPower: 359.4,
  totalPlants: 12,
  totalEnergy: 136.26789453495,
  totalProducedEnergy: 70.66399999999994,
  totalExportedEnergy: 1.2,
  totalExpectedEnergy: 43.98060671956208,
  expectedEnergy: [
    {
      label: '2021-02-21T23:00:00.000Z',
      value: 100.85484570892297
    },
    {
      label: '2021-02-22T23:00:00.000Z',
      value: 171.53972138629186
    },
    {
      label: '2021-02-23T23:00:00.000Z',
      value: 127.90409386474698
    },
    {
      label: '2021-02-24T23:00:00.000Z',
      value: 39.50740623565899
    },
    {
      label: '2021-02-25T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-26T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-27T23:00:00.000Z',
      value: null
    }
  ],
  producedEnergy: [
    {
      label: '2021-02-21T23:00:00.000Z',
      value: 156.34999999999127
    },
    {
      label: '2021-02-22T23:00:00.000Z',
      value: 261.20000000001164
    },
    {
      label: '2021-02-23T23:00:00.000Z',
      value: 224.0599999999904
    },
    {
      label: '2021-02-24T23:00:00.000Z',
      value: 65.03000000000611
    },
    {
      label: '2021-02-25T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-26T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-27T23:00:00.000Z',
      value: null
    }
  ],
  exportedEnergy: [
    {
      label: '2021-02-21T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-22T23:00:00.000Z',
      value: 0.4
    },
    {
      label: '2021-02-23T23:00:00.000Z',
      value: 0.1
    },
    {
      label: '2021-02-24T23:00:00.000Z',
      value: 0.7
    },
    {
      label: '2021-02-25T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-26T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-27T23:00:00.000Z',
      value: null
    }
  ],
  baselinePr: 1.8857517738185265,
  realPr: 0.8901430287340952,
  baselineProduction: 43.98060671956208,
  realProduction: 70.66399999999994,
  messages: [],
  performanceGraph: [
    {
      name: '100Kw',
      data: {
        pr: 0.87,
        expectedEnergy: 0.7,
        outOfService: 1,
        energyAnomalies: 2,
        monitoringAnomalies: 3,
        maintenances: 5
      }
    },
    {
      name: '250Kw',
      data: {
        pr: 0.7,
        expectedEnergy: 0.6,
        outOfService: 0,
        energyAnomalies: 4,
        monitoringAnomalies: 1,
        maintenances: 3
      }
    },
    {
      name: '500Kw',
      data: {
        pr: 0.8,
        expectedEnergy: 0.9,
        outOfService: 0,
        energyAnomalies: 0,
        monitoringAnomalies: 1,
        maintenances: 1
      }
    },
    {
      name: '1Mw',
      data: {
        pr: 0.8,
        expectedEnergy: 0.9,
        outOfService: 3,
        energyAnomalies: 0,
        monitoringAnomalies: 2,
        maintenances: 4
      }
    }
  ]
}

const ledMonthData = {
  peakPower: 2459.4,
  totalPlants: 12,
  totalEnergy: 1053.4235464578,
  totalProducedEnergy: 403.89600000000064,
  totalExportedEnergy: 53.26000000000004,
  totalExpectedEnergy: 636.77419354838685,
  expectedEnergy: [
    {
      label: '2021-01-31T23:00:00.000Z',
      value: 108.65901953243365
    },
    {
      label: '2021-02-01T23:00:00.000Z',
      value: 84.01904797138002
    },
    {
      label: '2021-02-02T23:00:00.000Z',
      value: 100.4510218442882
    },
    {
      label: '2021-02-03T23:00:00.000Z',
      value: 111.85348540421161
    },
    {
      label: '2021-02-04T23:00:00.000Z',
      value: 103.85611009780078
    },
    {
      label: '2021-02-05T23:00:00.000Z',
      value: 66.96664975172277
    },
    {
      label: '2021-02-06T23:00:00.000Z',
      value: 40.711080706578656
    },
    {
      label: '2021-02-07T23:00:00.000Z',
      value: 113.21515856460279
    },
    {
      label: '2021-02-08T23:00:00.000Z',
      value: 140.67622503290124
    },
    {
      label: '2021-02-09T23:00:00.000Z',
      value: 137.76930611647612
    },
    {
      label: '2021-02-10T23:00:00.000Z',
      value: 142.95970017494088
    },
    {
      label: '2021-02-11T23:00:00.000Z',
      value: 29.093829020144703
    },
    {
      label: '2021-02-12T23:00:00.000Z',
      value: 8.967978507504224
    },
    {
      label: '2021-02-13T23:00:00.000Z',
      value: 90.62400937285327
    },
    {
      label: '2021-02-14T23:00:00.000Z',
      value: 192.11640892765035
    },
    {
      label: '2021-02-15T23:00:00.000Z',
      value: 193.63311448051692
    },
    {
      label: '2021-02-16T23:00:00.000Z',
      value: 117.63903990940591
    },
    {
      label: '2021-02-17T23:00:00.000Z',
      value: 158.8114409985416
    },
    {
      label: '2021-02-18T23:00:00.000Z',
      value: 178.72450545623232
    },
    {
      label: '2021-02-19T23:00:00.000Z',
      value: 183.6663166084933
    },
    {
      label: '2021-02-20T23:00:00.000Z',
      value: 194.79510949252918
    },
    {
      label: '2021-02-21T23:00:00.000Z',
      value: 100.85484570892297
    },
    {
      label: '2021-02-22T23:00:00.000Z',
      value: 171.53972138629186
    },
    {
      label: '2021-02-23T23:00:00.000Z',
      value: 127.90409386474698
    },
    {
      label: '2021-02-24T23:00:00.000Z',
      value: 45.986429792411066
    },
    {
      label: '2021-02-25T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-26T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-27T23:00:00.000Z',
      value: null
    }
  ],
  producedEnergy: [
    {
      label: '2021-01-31T23:00:00.000Z',
      value: 170.12000000000262
    },
    {
      label: '2021-02-01T23:00:00.000Z',
      value: 129.9100000000035
    },
    {
      label: '2021-02-02T23:00:00.000Z',
      value: 158.09999999999854
    },
    {
      label: '2021-02-03T23:00:00.000Z',
      value: 155.1999999999898
    },
    {
      label: '2021-02-04T23:00:00.000Z',
      value: 159.74000000000524
    },
    {
      label: '2021-02-05T23:00:00.000Z',
      value: 110.3900000000067
    },
    {
      label: '2021-02-06T23:00:00.000Z',
      value: 61.20999999999913
    },
    {
      label: '2021-02-07T23:00:00.000Z',
      value: 171.54000000000087
    },
    {
      label: '2021-02-08T23:00:00.000Z',
      value: 207.36999999998807
    },
    {
      label: '2021-02-09T23:00:00.000Z',
      value: 210.17000000000553
    },
    {
      label: '2021-02-10T23:00:00.000Z',
      value: 215.88999999999214
    },
    {
      label: '2021-02-11T23:00:00.000Z',
      value: 43.80000000000291
    },
    {
      label: '2021-02-12T23:00:00.000Z',
      value: 2.3800000000119326
    },
    {
      label: '2021-02-13T23:00:00.000Z',
      value: 9.65999999998894
    },
    {
      label: '2021-02-14T23:00:00.000Z',
      value: 37.38000000000466
    },
    {
      label: '2021-02-15T23:00:00.000Z',
      value: 245.4800000000032
    },
    {
      label: '2021-02-16T23:00:00.000Z',
      value: 176.93999999999505
    },
    {
      label: '2021-02-17T23:00:00.000Z',
      value: 232.5500000000029
    },
    {
      label: '2021-02-18T23:00:00.000Z',
      value: 267.8899999999994
    },
    {
      label: '2021-02-19T23:00:00.000Z',
      value: 271.77999999999884
    },
    {
      label: '2021-02-20T23:00:00.000Z',
      value: 288.0200000000041
    },
    {
      label: '2021-02-21T23:00:00.000Z',
      value: 156.34999999999127
    },
    {
      label: '2021-02-22T23:00:00.000Z',
      value: 261.20000000001164
    },
    {
      label: '2021-02-23T23:00:00.000Z',
      value: 224.0599999999904
    },
    {
      label: '2021-02-24T23:00:00.000Z',
      value: 71.83000000000902
    },
    {
      label: '2021-02-25T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-26T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-27T23:00:00.000Z',
      value: null
    }
  ],
  exportedEnergy: [
    {
      label: '2021-01-31T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-01T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-02T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-03T23:00:00.000Z',
      value: 0.1
    },
    {
      label: '2021-02-04T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-05T23:00:00.000Z',
      value: 62.00000000000001
    },
    {
      label: '2021-02-06T23:00:00.000Z',
      value: 28.699999999999992
    },
    {
      label: '2021-02-07T23:00:00.000Z',
      value: 0.5
    },
    {
      label: '2021-02-08T23:00:00.000Z',
      value: 0.2
    },
    {
      label: '2021-02-09T23:00:00.000Z',
      value: 1.7
    },
    {
      label: '2021-02-10T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-11T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-12T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-13T23:00:00.000Z',
      value: 0.1
    },
    {
      label: '2021-02-14T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-15T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-16T23:00:00.000Z',
      value: 1
    },
    {
      label: '2021-02-17T23:00:00.000Z',
      value: 1.5
    },
    {
      label: '2021-02-18T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-19T23:00:00.000Z',
      value: 199.20000000000002
    },
    {
      label: '2021-02-20T23:00:00.000Z',
      value: 236.39999999999998
    },
    {
      label: '2021-02-21T23:00:00.000Z',
      value: 0
    },
    {
      label: '2021-02-22T23:00:00.000Z',
      value: 0.4
    },
    {
      label: '2021-02-23T23:00:00.000Z',
      value: 0.1
    },
    {
      label: '2021-02-24T23:00:00.000Z',
      value: 0.7
    },
    {
      label: '2021-02-25T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-26T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-02-27T23:00:00.000Z',
      value: null
    }
  ],
  baselinePr: 30.172028381096432,
  realPr: 0.7596862313720204,
  baselineProduction: 636.77419354838685,
  realProduction: 403.89600000000064,
  messages: [],
  performanceGraph: [
    {
      name: '100Kw',
      data: {
        pr: 0.87,
        expectedEnergy: 0.7,
        outOfService: 1,
        energyAnomalies: 2,
        monitoringAnomalies: 3,
        maintenances: 5
      }
    },
    {
      name: '250Kw',
      data: {
        pr: 0.7,
        expectedEnergy: 0.6,
        outOfService: 0,
        energyAnomalies: 4,
        monitoringAnomalies: 1,
        maintenances: 3
      }
    },
    {
      name: '500Kw',
      data: {
        pr: 0.8,
        expectedEnergy: 0.9,
        outOfService: 0,
        energyAnomalies: 0,
        monitoringAnomalies: 1,
        maintenances: 1
      }
    },
    {
      name: '1Mw',
      data: {
        pr: 0.8,
        expectedEnergy: 0.9,
        outOfService: 3,
        energyAnomalies: 0,
        monitoringAnomalies: 2,
        maintenances: 4
      }
    }
  ]
}

const ledYearData = {
  peakPower: 8345.456,
  totalPlants: 12,
  totalEnergy: 19456.51567834899,
  totalProducedEnergy: 724.7710000000014,
  totalExportedEnergy: 128.410000000001,
  totalExpectedEnergy: 10996.1612903279,
  expectedEnergy: [
    {
      label: '2020-12-31T23:00:00.001Z',
      value: 4220
    },
    {
      label: '2021-01-31T23:00:00.001Z',
      value: 7050
    },
    {
      label: '2021-02-28T23:00:00.001Z',
      value: 8220
    },
    {
      label: '2021-03-31T22:00:00.001Z',
      value: 12170
    },
    {
      label: '2021-04-30T22:00:00.001Z',
      value: 12710
    },
    {
      label: '2021-05-31T22:00:00.001Z',
      value: 14130
    },
    {
      label: '2021-06-30T22:00:00.001Z',
      value: 15880
    },
    {
      label: '2021-07-31T22:00:00.001Z',
      value: 13150
    },
    {
      label: '2021-08-31T22:00:00.001Z',
      value: 10460
    },
    {
      label: '2021-09-30T22:00:00.001Z',
      value: 7230
    },
    {
      label: '2021-10-31T23:00:00.001Z',
      value: 3420
    },
    {
      label: '2021-11-30T23:00:00.001Z',
      value: 3300
    }
  ],
  producedEnergy: [
    {
      label: '2020-12-31T23:00:00.000Z',
      value: 3208.7500000000073
    },
    {
      label: '2021-01-31T23:00:00.000Z',
      value: 4038.9600000000064
    },
    {
      label: '2021-02-28T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-03-31T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-04-30T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-05-31T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-06-30T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-07-31T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-08-31T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-09-30T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-10-31T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-11-30T23:00:00.000Z',
      value: null
    }
  ],
  exportedEnergy: [
    {
      label: '2020-12-31T23:00:00.000Z',
      value: 751.5
    },
    {
      label: '2021-01-31T23:00:00.000Z',
      value: 532.5999999999999
    },
    {
      label: '2021-02-28T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-03-31T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-04-30T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-05-31T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-06-30T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-07-31T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-08-31T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-09-30T22:00:00.000Z',
      value: null
    },
    {
      label: '2021-10-31T23:00:00.000Z',
      value: null
    },
    {
      label: '2021-11-30T23:00:00.000Z',
      value: null
    }
  ],
  baselinePr: 3554.1721481811815,
  realPr: 0.774446370493404,
  baselineProduction: 10996.1612922579,
  realProduction: 724.7710000000014,
  messages: [],
  performanceGraph: [
    {
      name: '100Kw',
      data: {
        pr: 0.87,
        expectedEnergy: 0.7,
        outOfService: 1,
        energyAnomalies: 2,
        monitoringAnomalies: 3,
        maintenances: 5
      }
    },
    {
      name: '250Kw',
      data: {
        pr: 0.7,
        expectedEnergy: 0.6,
        outOfService: 0,
        energyAnomalies: 4,
        monitoringAnomalies: 1,
        maintenances: 3
      }
    },
    {
      name: '500Kw',
      data: {
        pr: 0.8,
        expectedEnergy: 0.9,
        outOfService: 0,
        energyAnomalies: 0,
        monitoringAnomalies: 1,
        maintenances: 1
      }
    },
    {
      name: '1Mw',
      data: {
        pr: 0.8,
        expectedEnergy: 0.9,
        outOfService: 3,
        energyAnomalies: 0,
        monitoringAnomalies: 2,
        maintenances: 4
      }
    }
  ]
}

// Date dei periodi calcolate in base al giorno corrente
const now = moment()
const { minDate: liveMinDate, maxDate: liveMaxDate } = getDatesFromPeriod('live', now)
const { minDate: weekMinDate, maxDate: weekMaxDate } = getDatesFromPeriod('week', now)
const { minDate: monthMinDate, maxDate: monthMaxDate } = getDatesFromPeriod('month', now)
const { minDate: yearMinDate, maxDate: yearMaxDate } = getDatesFromPeriod('year', now)

// console.log('performance - live: ', liveMinDate, liveMaxDate)
// console.log('performance - week: ', weekMinDate, weekMaxDate)
// console.log('performance - month: ', monthMinDate, monthMaxDate)
// console.log('performance - year: ', yearMinDate, yearMaxDate)

// Chiamate Mock per il fotovoltaico
// live
mock.onGet(`/api/analytics?timeFrom=${liveMinDate}&timeTo=${liveMaxDate}&plantType=pv`).reply(200, { data: pvLiveData })
// settimana
mock.onGet(`/api/analytics?timeFrom=${moment(weekMinDate).add(1, 'd').toISOString()}&timeTo=${moment(weekMaxDate).add(1, 'd').toISOString()}&plantType=pv`).reply(200, { data: pvWeekData })
// mese
mock.onGet(`/api/analytics?timeFrom=${monthMinDate}&timeTo=${monthMaxDate}&plantType=pv`).reply(200, { data: pvMonthData })
// anno
mock.onGet(`/api/analytics?timeFrom=${yearMinDate}&timeTo=${yearMaxDate}&plantType=pv`).reply(200, { data: pvYearData })

// Chiamate Mock per il led
// live
mock.onGet(`/api/analytics?timeFrom=${liveMinDate}&timeTo=${liveMaxDate}&plantType=led`).reply(200, { data: ledLiveData })
// settimana
mock.onGet(`/api/analytics?timeFrom=${weekMinDate}&timeTo=${weekMaxDate}&plantType=led`).reply(200, { data: ledWeekData })
// mese
mock.onGet(`/api/analytics?timeFrom=${monthMinDate}&timeTo=${monthMaxDate}&plantType=led`).reply(200, { data: ledMonthData })
// anno
mock.onGet(`/api/analytics?timeFrom=${yearMinDate}&timeTo=${yearMaxDate}&plantType=led`).reply(200, { data: ledYearData })
