// Tipi di impianto disponibili
export const plantTypes = [{
  value: 'pv',
  label: 'Fotovoltaico (PV)'
}]

export const getDevicesNumber = ({ devices = null, plant = null, datalogger = null, type }) => {
  if (plant) {
    const { addedDataloggers } = plant.metadata || {}
    if (addedDataloggers) {
      const devices = addedDataloggers.map(datalogger => {
        const inverters =
          datalogger.includes &&
          datalogger.includes.devices &&
          datalogger.includes.devices
            .filter(device => (device.deviceType && device.deviceType.category && device.deviceType.category === type) || (device.category === type))

        return inverters || []
      })

      return (devices && devices.length) || 0
    }
    return 0
  } else if (datalogger) {
    const inverters =
      datalogger.includes &&
      datalogger.includes.devices &&
      datalogger.includes.devices
        .filter(device => (device.deviceType && device.deviceType.category && device.deviceType.category === type) || (device.category === type))
    return (inverters && inverters.length) || 0
  } else if (devices) {
    const inverters = devices.filter(device => ((device.deviceType && device.deviceType.category && device.deviceType.category === type) || (device.category === type)))
    return (inverters && inverters.length)
  } else {
    return 0
  }
}

export const getAllDevices = ({ devices = null, dataloggers = null }) => {
  if (dataloggers && !devices) {
    const allDevices = dataloggers
      .filter(datalogger => (datalogger.name && datalogger.name !== undefined))
      .map(datalogger => {
        const devices = datalogger.includes &&
          datalogger.includes.devices &&
          datalogger.includes.devices
            .map(device => {
              const composedName = device.metadata && device.metadata.inverterNum !== null && device.metadata.inverterNum !== undefined
                ? `${datalogger.name} - ${device.name} (${device.metadata.inverterNum})`
                : `${datalogger.name} - ${device.name}`

              return ({
                ...device,
                name: composedName
              })
            })
        return devices || []
      })
    return allDevices.flat() || []
  } else if (devices && dataloggers) {
    const inverters = devices
      .map(device => {
        const datalogger = dataloggers.find(el => el.uuid === device.nodeId)
        if (datalogger && datalogger.name && datalogger.name !== undefined) {
          const composedName = device.metadata && device.metadata.inverterNum !== null && device.metadata.inverterNum !== undefined
            ? `${datalogger && datalogger.name} - ${device.name} (${device.metadata.inverterNum})`
            : `${datalogger && datalogger.name} - ${device.name}`

          return ({
            ...device,
            name: composedName
          })
        } else {
          return null
        }
      })

    return inverters.filter(el => el) || []
  }
}

// Funzione che prende in ingresso un array di dataloggers e restituisce tutti i tipi di device sotto i datalogger passati
export const getAllDeviceTypes = ({ dataloggers = null }) => {
  // Prendo tutti i devices per i datalogger passati in ingresso
  const allDevices = getAllDevices({ dataloggers })
  const allDeviceTypes = allDevices.map(device => {
    const deviceTypeId = device.deviceType && device.deviceType.uuid
    const deviceTypeName = device.deviceType && device.deviceType.name
    const deviceDocuments = (device.deviceType && device.deviceType.metadata && device.deviceType.metadata.documents) || []

    return {
      uuid: deviceTypeId,
      name: deviceTypeName,
      documents: deviceDocuments
    }
  })

  const uniqueDeviceTypes = [...new Set(allDeviceTypes.map(el => el.uuid))].map(uuid => allDeviceTypes.find(el => el.uuid === uuid))
  return uniqueDeviceTypes
}

// Funzione che prende in ingresso un array di dataloggers e restituisce tutti i documenti per tutti i deviceTypes
export const getAllDocuments = ({ dataloggers = null }) => {
  // prendo tutti i deviceTypes
  const allDeviceTypes = getAllDeviceTypes({ dataloggers })

  const allDocuments = allDeviceTypes
    .map(deviceType => deviceType.documents
      .map((doc, docIndex) => ({ ...doc, fileIndex: docIndex, type: 'devicetypes', resourceName: deviceType.name, resourceId: deviceType.uuid }))).flat()
  return allDocuments
}

export const calculateTotalDistribution = (distribution) => {
  const objectKeys = Object.keys(distribution)
  const distributionRows = objectKeys.map(key => distribution[key].rows)
  // creo un unico array per fare la somma delle distribuzioni,
  // usando l'array 'rows' che è formato a sua volta da array con il numero da sommare, per questo uso .flat(2)
  const flatDistributionArray = distributionRows.flat(2)

  // ritorno il valore della somma delle distribuzioni tramite il 'reduce' sull'array
  return flatDistributionArray.reduce((a, b) => a + b, 0)
}

export const getDevices = ({ dataloggers = null, type = null }) => {
  // if (dataloggers && !devices) {
  // console.log('PlantComponent - addedDataloggers', dataloggers)
  const devices = dataloggers
    .filter(datalogger => (datalogger.name && datalogger.name !== undefined))
    .map(datalogger => {
      const inverters =
        datalogger.includes &&
        datalogger.includes.devices &&
        datalogger.includes.devices
          .filter(device => !type ? device : (device.deviceType && device.deviceType.category && device.deviceType.category === type) || (device.category === type))
          .sort(function (a, b) {
            if (a.metadata && b.metadata && a.metadata.inverterNum && b.metadata.inverterNum) {
              return parseInt(a.metadata.inverterNum) - parseInt(b.metadata.inverterNum)
            } else {
              return 0
            }
          })
          .map(device => {
            const composedName = device.metadata && device.metadata.inverterNum !== null && device.metadata.inverterNum !== undefined
              ? `${datalogger.name} - ${device.name} (${device.metadata.inverterNum})`
              : `${datalogger.name} - ${device.name}`

            return ({
              ...device,
              name: composedName
            })
          })

      return inverters || []
    })

  return devices.flat() || []
  /* else if (devices && dataloggers) {
    const inverters = devices.filter(device => (device.deviceType && device.deviceType.category && device.deviceType.category === type) || (device.category === type))
      .sort(function (a, b) {
        if (a.metadata && b.metadata && a.metadata.inverterNum && b.metadata.inverterNum) {
          return parseInt(a.metadata.inverterNum) - parseInt(b.metadata.inverterNum)
        } else {
          return 0
        }
      })
      .map(device => {
        const datalogger = dataloggers.find(el => el.uuid === device.nodeId)
        if (datalogger && datalogger.name && datalogger.name !== undefined) {
          const composedName = device.metadata && device.metadata.inverterNum !== null && device.metadata.inverterNum !== undefined
            ? `${datalogger && datalogger.name} - ${device.name} (${device.metadata.inverterNum})`
            : `${datalogger && datalogger.name} - ${device.name}`

          return ({
            ...device,
            name: composedName
          })
        } else {
          return null
        }
      })

    return inverters.filter(el => el) || []
  } */
}
