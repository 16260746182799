import api from '@micmnt/apis'
import log from '@pelv/frontlog'

export const passwordValidation = {
  // regEx: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  regEx: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[.@$!%*#?&])[A-Za-z\d.@$!%*#?&]{8,}$/,
  errorText: 'Deve essere almeno 8 caratteri, contenere almeno una maiuscola, una minuscola, un numero ed un carattere speciale.'
}

// regex che fallisce il test se ci sono caratteri esterni da quelli specificati
export const inputTextRegex = /(?![a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ0-9.,"'+-\s€()!?]+)./gm
// funzione che ritorna la stringa corretta dalla regex
export const returnCleanInputString = (string) => {
  if (string && typeof string === 'string' && inputTextRegex.test(string)) {
    return string.replace(inputTextRegex, '')
  } else {
    return string
  }
}

export const fixedNum = (num, decimalsNum) => {
  const decimals = decimalsNum || 1
  let finalNum = null
  if (num !== null && num !== undefined && !isNaN(num)) {
    finalNum = Number(num).toFixed(decimals)
  }
  return finalNum ? Number(finalNum) : finalNum
}

export const europeNum = (num, decimalsNum = 1) => {
  const decimals = decimalsNum < 0 || decimalsNum > 100 ? 1 : decimalsNum
  let finalNum = '-'
  try {
    if (typeof num === 'string') {
      num = num.replace(',', '.')
    }
    // check if is a valid number
    finalNum = Number(num)
    if (isNaN(finalNum)) throw new Error('Invalid number')
    // fixed de decimals and locale
    finalNum = Math.round(finalNum * 10 ** decimals) / 10 ** decimals
    finalNum = finalNum.toLocaleString('it-IT', { minimumFractionDigits: 0 })
  } catch (e) {
    finalNum = '-'
  }
  // if (num !== null || num !== undefined) {
  //   if (!isNaN(num)) {
  //     finalNum = Number(num).toFixed(decimals)
  //     finalNum = Number(finalNum).toLocaleString('it-IT', { minimumFractionDigits: 0 })
  //   } else {
  //     finalNum = Number(num).toFixed(decimals)
  //     finalNum = Number(finalNum).toLocaleString('it-IT', { minimumFractionDigits: 0 })
  //   }
  // }
  return finalNum
}

export const openInNewTab = (url) => {
  if (url) {
    const win = window.open(url, '_blank')
    win.focus()
  }
}

// funzione che modifica l'url del windows senza fare refresh
export const updateUrl = (url) => {
  if (url) {
    window.history.replaceState(null, null, url)
  }
}

const fallbackCopyTextToClipboard = (text) => {
  const textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    const successful = document.execCommand('copy')
    const msg = successful ? 'successful' : 'unsuccessful'
    console.log('Fallback: Copying text command was ' + msg)
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea)
}
export const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(function () {
    // console.log('Async: Copying to clipboard was successful!')
  }, function (err) {
    console.error('Async: Could not copy text: ', err)
  })
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// funzione che torna i dati dei log dei device
export const getDeviceLogs = async (params) => {
  log({ text: 'log query, params => ', variable: params, tag: 'devicelogs' })
  const { data } = await api.get({ savedUrl: 'logs', params })
  return data
}

// funzione che torna i dati dei log dei device
export const getExcelLogs = async (params) => {
  log({ text: 'excel query, params => ', variable: params, tag: 'logexcel' })
  // const token = ls.get('accessToken')
  // return await api.getResource('logsInExcel', { params, fullResponse: true })

  const { data, error } = await api.get({ savedUrl: 'logsInExcel', params, fullResponse: true, responseType: 'blob' })

  if (error) {
    throw new Error('Error downloading file')
  }

  return data

  /* return axios.get(`${configuration.apiUrl}/projects/${configuration.projectId}/dashboard/telemetry/export`, {
    headers: {
      authorization: `Bearer ${window.localStorage.getItem('accessToken')}`
    },
    params,
    responseType: 'blob'
  }) */
}

// funzione che preso in ingresso un array ritorna un array di array in cui ogni sotto elemento è della lunghezza passata come parametro
export const chunkArray = (array, size) => {
  const result = []
  const arrayCopy = [...array]
  while (arrayCopy.length > 0) {
    result.push(arrayCopy.splice(0, size))
  }
  return result
}

// funzione che passato un colore in hex, decide se il colore del testo può essere bianco o nero
export const getContrastColor = (hex, bw) => {
  function padZero (str, len) {
    len = len || 2
    const zeros = new Array(len).join('0')
    return (zeros + str).slice(-len)
  }

  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1)
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  if (hex.length !== 6) {
    return '#000000'
  }
  let r = parseInt(hex.slice(0, 2), 16)
  let g = parseInt(hex.slice(2, 4), 16)
  let b = parseInt(hex.slice(4, 6), 16)
  if (bw) {
    // http://stackoverflow.com/a/3943023/112731
    return (r * 0.299 + g * 0.587 + b * 0.114) > 150
      ? '#000000'
      : '#FFFFFF'
  }
  // invert color components
  r = (255 - r).toString(16)
  g = (255 - g).toString(16)
  b = (255 - b).toString(16)
  // pad each with zeros and return
  return `#${padZero(r)}${padZero(g)}${padZero(b)}`
}
