import { useState, memo } from 'react'
// basic documentation
// https://www.notion.so/pelvspace/Energy-Device-Log-Modal-7d7a86548430499d836eed89bdcd64fe
// import clsx from 'clsx'
import PropTypes from 'prop-types'
// import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  useMediaQuery,
  useTheme,
  // Grid,
  Button,
  SvgIcon,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  // DialogContentText,
  DialogTitle as MuiDialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  Box,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { Trash as DeleteIcon, Edit as EditIcon, Plus as AddIcon, Save as SaveIcon } from 'react-feather'
import { basicColors } from '@/theme'
import log from '@pelv/frontlog'

// DialogTitle personalizzato
const titleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})
const DialogTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h4'>{children}</Typography>
      {onClose
        ? (
          <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          )
        : null}
    </MuiDialogTitle>
  )
})

// Stili del componente
const useStyles = makeStyles((theme) => ({
  root: {},
  fullWidth: {
    width: '100%'
  },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  selectLabel: {
    backgroundColor: theme.palette.background.paper,
    paddingRight: 8,
    paddingLeft: 8
  }
}))

function ReportEmails ({
  open,
  onClose,
  emails,
  saveNewEmails
}) {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const maxWidth = 'sm'

  const [currentEmails, setCurrentEmails] = useState(JSON.parse(JSON.stringify(emails)))
  const [emailsHasBeenEdited, setEmailsHasBeenEdited] = useState(false)
  const [emailsToEdits, setEmailsToEdits] = useState([])
  const [newEmail, setNewEmail] = useState('')

  // funzione lanciata al close della modal
  const handleClose = () => {
    // setSelectedColumns(['all'])
    onClose()
  }

  // funzione che aggiunge una mail
  const handleAddNewEmail = () => {
    const newEmails = currentEmails
    newEmails.push(newEmail)
    setCurrentEmails(newEmails)
    setNewEmail('')
    setEmailsHasBeenEdited(true)
  }

  // funzione che abilita edit di una o più email
  const toogleEditEmail = (email, action) => {
    log([
      { text: 'editing email => ', variable: email, tag: 'reportemail' },
      { text: 'editing action => ', variable: action, tag: 'reportemail' }
    ])
    if (email && action) {
      const newEmailsToEdits = JSON.parse(JSON.stringify(emailsToEdits))
      if (action === 'edit') {
        const thisIndex = newEmailsToEdits.findIndex(e => e === email)
        if (thisIndex === -1) {
          newEmailsToEdits.push(email)
        }
      } else if (action === 'save') {
        const thisIndex = newEmailsToEdits.findIndex(e => e === email)
        if (thisIndex > -1) {
          newEmailsToEdits.splice(thisIndex, 1)
        }
      }
      setEmailsToEdits(newEmailsToEdits)
      setEmailsHasBeenEdited(true)
    }
  }

  // funzione che elimina una mail salvata
  const deleteSavedEmail = (email) => {
    log({ text: 'deleting email => ', variable: email, tag: 'reportemail' })
    if (email) {
      const emailIndex = currentEmails.findIndex(e => e === email)
      if (emailIndex > -1) {
        const newEmails = JSON.parse(JSON.stringify(currentEmails))
        newEmails.splice(emailIndex, 1)
        setCurrentEmails(newEmails)
      }
      const editIndex = emailsToEdits.findIndex(e => e === email)
      if (editIndex > -1) {
        const newEmailsToEdit = JSON.parse(JSON.stringify(emailsToEdits))
        newEmailsToEdit.splice(editIndex, 1)
        setEmailsToEdits(newEmailsToEdit)
      }
      setEmailsHasBeenEdited(true)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='saved-email-dialog'
      aria-describedby='saved-email-dialog'
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      fullWidth
      // {...other}
    >
      <DialogTitle disableTypography id='salved-email-dialog-title' onClose={handleClose}>
        Email salvate per il Report
      </DialogTitle>
      <DialogContent>
        <Typography variant='body2'>Inserisci, modifica o elimina le mail di destinazione per i report.</Typography>
        <List className={classes.root}>
          {currentEmails.map((email, index) => {
            return (
              <ListItem key={index} divider disableGutters>
                {emailsToEdits.indexOf(email) === -1 && (
                  <ListItemText
                    id={`email-label-${index}`}
                    primary={email}
                    // secondary={index}
                  />
                )}
                {emailsToEdits.length > 0 && emailsToEdits.indexOf(email) > -1 && (
                  <TextField
                    id={`edit-email-${index}`}
                    // label='Modifica Email'
                    name='email'
                    type='email'
                    value={email}
                    fullWidth
                    size='small'
                    onChange={(event) => {
                      const newEmails = JSON.parse(JSON.stringify(currentEmails))
                      const thisEditIndex = emailsToEdits.findIndex(e => e === newEmails[index])
                      newEmails[index] = event.target.value
                      if (thisEditIndex > -1) {
                        const newEmailtoEdits = emailsToEdits
                        newEmailtoEdits[thisEditIndex] = event.target.value
                        setEmailsToEdits(newEmailtoEdits)
                      }
                      setCurrentEmails(newEmails)
                    }}
                  />
                )}
                <ListItemSecondaryAction>
                  {emailsToEdits.indexOf(email) === -1 && (
                    <IconButton aria-label='modifica' onClick={() => toogleEditEmail(email, 'edit')}>
                      <SvgIcon fontSize='small'>
                        <EditIcon color={basicColors.darkYellow} />
                      </SvgIcon>
                    </IconButton>
                  )}
                  {emailsToEdits.length > 0 && emailsToEdits.indexOf(email) > -1 && (
                    <IconButton aria-label='salva modifiche' onClick={() => toogleEditEmail(email, 'save')}>
                      <SvgIcon fontSize='small'>
                        <SaveIcon color={basicColors.darkGreen} />
                      </SvgIcon>
                    </IconButton>
                  )}
                  <IconButton edge='end' aria-label='elimina' onClick={() => deleteSavedEmail(email)}>
                    <SvgIcon fontSize='small'>
                      <DeleteIcon color={basicColors.darkRed} />
                    </SvgIcon>
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
          {currentEmails.length === 0 && (
            <ListItem divider disableGutters>
              <ListItemText
                id='email-label-no-email'
                primary='Non ci sono email salvate.'
              />
            </ListItem>
          )}
          <ListItem disableGutters>
            <TextField
              id='new-email'
              label='Nuova Email'
              name='newEmail'
              type='email'
              value={newEmail}
              placeholder='Aggiungi una nuova email'
              fullWidth
              size='small'
              onChange={(event) => {
                setNewEmail(event.target.value)
              }}
            />
            <ListItemSecondaryAction>
              <IconButton edge='end' aria-label='elimina' disabled={newEmail === '' || !newEmail} onClick={handleAddNewEmail}>
                <SvgIcon fontSize='small'>
                  <AddIcon color={basicColors.primary} />
                </SvgIcon>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Box width='100%' display='flex' alignItems='center' justifyContent='space-between'>
          <Button onClick={handleClose}>
            Chiudi
          </Button>
          <Button onClick={() => saveNewEmails(currentEmails)} disabled={!emailsHasBeenEdited} variant='contained' color='primary'>
            Salva
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

ReportEmails.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  saveNewEmails: PropTypes.func,
  emails: PropTypes.array
}

ReportEmails.defaultProps = {
  emails: []
}

export default memo(ReportEmails)
