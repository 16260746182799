import { useState } from 'react'
// import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx'
import moment from 'moment'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Box,
  // Button,
  Tooltip,
  Card,
  // Checkbox,
  CircularProgress,
  IconButton,
  InputAdornment,
  // Link,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  // TableHead,
  TablePagination,
  TableRow,
  TextField,
  useMediaQuery,
  makeStyles,
  Typography
} from '@material-ui/core'
// import { useSnackbar } from 'notistack'
import {
  // ExternalLink as OpenPdfIcon,
  // Download as DownloadIcon,
  Edit as EditIcon,
  Send as SendIcon,
  Settings as SettingsIcon,
  Plus as AddIcon,
  Search as SearchIcon,
  Trash as DeleteIcon
} from 'react-feather'
import ConfirmationDialogRaw from '@/components/ConfirmationDialogRaw'
import { basicColors } from '@/theme'
import PDFViewerDrawer from '../PDFViewerDrawer'
import log from '@pelv/frontlog'

/* const months = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre'
] */

const sortOptions = [
  {
    value: 'createdAt|desc',
    label: 'Creato il (recenti prima)'
  },
  {
    value: 'createdAt|asc',
    label: 'Creato il (vecchi prima)'
  }
  // {
  //   value: 'role',
  //   label: 'Role'
  // },
  // {
  //   value: 'orders|asc',
  //   label: 'Total orders (low to high)'
  // }
]

const applyFilters = (customers, query, filters) => {
  return customers.filter((customer) => {
    let matches = true

    if (query) {
      const properties = ['name']
      let containsQuery = false

      properties.forEach((property) => {
        if (customer[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true
        }
      })

      if (!containsQuery) {
        matches = false
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key]

      if (value && customer[key] !== value) {
        matches = false
      }
    })

    return matches
  })
}

const applyPagination = (customers, page, limit) => {
  return customers.slice(page * limit, page * limit + limit)
}

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }

  if (b[orderBy] > a[orderBy]) {
    return 1
  }

  return 0
}

const getComparator = (order, orderBy) => {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

const applySort = (customers, sort) => {
  const [orderBy, order] = sort.split('|')
  const comparator = getComparator(order, orderBy)
  const stabilizedThis = customers.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])

    if (order !== 0) return order

    return a[1] - b[1]
  })

  return stabilizedThis.map((el) => el[0])
}

const useStyles = makeStyles((theme) => ({
  root: {},
  queryField: {
    width: 500
  },
  glassBackground: {
    '@supports (backdrop-filter: none) or (-webkit-backdrop-filter: none)': {
      '-webkit-backdrop-filter': 'saturate(120%) blur(16px)',
      backdropFilter: 'saturate(190%) blur(16px)',
      backgroundColor: 'rgba(16, 26, 38, 0.05)'
    }
  },
  cardLabel: {
    color: basicColors.blueGrayLight
  }
}))

// componente principale
const ReportTable = ({ hasGlass = false, showTitle = true, className, reports, deleteReport, openSendReport, openEditReport, openEditReportsMail, openNewReport, ...rest }) => {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [query, setQuery] = useState('')
  const [sort, setSort] = useState(sortOptions[0].value)
  // variabile che gestisce apertura alert conferma eliminazione utente
  const [reportToDelete, setReportToDelete] = useState({})
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false)

  // variabile che mostra o nasconde il drawer per la visualizzazione del PDF
  const [showPDFViewer, setShowPDFViewer] = useState(false)
  // variabile che contiene nome e url del file da visualizzare
  const [selectedFile, setSelectedFile] = useState({ name: null, url: null })

  const filters = {
    status: null
  }

  const handleQueryChange = (event) => {
    event.persist()
    setQuery(event.target.value)
  }

  const handleSortChange = (event) => {
    event.persist()
    setSort(event.target.value)
  }

  const handlePageChange = (event, newPage) => {
    setPage(newPage)
  }

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value))
  }

  const filteredReports = applyFilters(reports, query, filters)
  const sortedReports = applySort(filteredReports, sort)
  const paginatedReports = applyPagination(sortedReports, page, limit)

  // funzione che scarica il pdf
  const openReport = (report) => {
    log({ text: 'openReport, report => ', variable: report, tag: 'report' })
    if (report.fileUrl) {
      setSelectedFile({ name: report.name, url: report.fileUrl })
      setShowPDFViewer(true)
    }
  }

  // funzione che gestisce l'apertura conferma delete datalogger
  const handleOpenConfirmDelete = (report) => {
    log({ text: 'apro modal conferma eliminazione, report => ', variable: report, tag: 'report' })
    setReportToDelete(report)
    setOpenConfirmDelete(true)
  }

  // funzione che gestisce chiusura conferma eliminazione utente
  const handleCloseConfirmDelete = async (confirmDelete) => {
    log({ text: 'close confirm delete, confirmDelete => ', variable: confirmDelete, tag: 'report' })
    if (confirmDelete) {
      try {
        await deleteReport(reportToDelete)
        setReportToDelete({})
      } catch (err) {
        console.error('delete report err => ', err)
      }
    } else {
      setReportToDelete({})
    }
    setOpenConfirmDelete(false)
  }

  const isExtraSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  return (
    <Card elevation={hasGlass ? 0 : 1} className={hasGlass ? clsx(classes.root, classes.glassBackground, className) : clsx(classes.root, className)} {...rest}>
      <PDFViewerDrawer
        file={selectedFile.url}
        title={selectedFile.name}
        open={showPDFViewer}
        onClose={() => {
          setShowPDFViewer(false)
          setSelectedFile({ name: null, url: null })
        }}
      />
      {/* <Divider /> */}
      {showTitle
        ? (
          <Box px={2} pt={1}>
            <Typography className={classes.cardLabel} variant='caption' component='div'>Report</Typography>
          </Box>
        )
        : null}
      <Box p={2} minHeight={56} display='flex' alignItems='center'>
        <TextField
          size='small'
          className={classes.queryField}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SvgIcon fontSize='small' color='action'>
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            )
          }}
          onChange={handleQueryChange}
          placeholder='Cerca per nome...'
          value={query}
          variant='outlined'
          disabled={paginatedReports[0] === '-' || paginatedReports.length === 0}
        />
        <Box flexGrow={1} />
        {!isExtraSmall && (
          <>
            <Tooltip title='Genera Report' aria-label='Genera Report'>
              <IconButton
                onClick={() => {
                  openNewReport()
                }}
              >
                <SvgIcon fontSize='small'>
                  <AddIcon color={basicColors.whiteText} />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title='Email salvate' aria-label='Email salvate'>
              <IconButton
                onClick={() => {
                  openEditReportsMail()
                }}
              >
                <SvgIcon fontSize='small'>
                  <SettingsIcon color={basicColors.whiteText} />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <TextField
              label='Ordina per'
              name='sort'
              size='small'
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              value={sort}
              variant='outlined'
              style={{ marginLeft: 12 }}
              disabled={paginatedReports[0] === '-' || paginatedReports.length === 0}
            >
              {sortOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </>
        )}
      </Box>
      <PerfectScrollbar
        containerRef={ref => {
          if (ref) {
            // https://github.com/mdbootstrap/perfect-scrollbar/pull/934/files
            // injecting a fix for this issue
            ref._getBoundingClientRect = ref.getBoundingClientRect

            ref.getBoundingClientRect = () => {
              const original = ref._getBoundingClientRect()

              return {
                bottom: original.bottom,
                left: original.left,
                right: original.right,
                top: original.top,
                width: Math.round(original.width),
                _width: original.width,
                height: Math.round(original.height),
                _height: original.height,
                x: original.x,
                y: original.y
              }
            }
          }
        }}
      >
        <Box width='100%'>
          <Table size='small'>
            <TableBody>
              {paginatedReports.length === 0
                ? (
                  <TableRow>
                    <TableCell colSpan={4}>
                      Non ci sono report.
                    </TableCell>
                  </TableRow>
                )
                : paginatedReports.map((report, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell
                        style={{
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          openReport(report)
                        }}
                      >
                        <Typography>{report.name}</Typography>
                        <Typography className={classes.cardLabel} variant='caption'>{`Creato il: ${moment(report.createdAt).format('DD/MM/YY')}`}</Typography>
                      </TableCell>
                      {report.fileGenerationStatus !== 'completed'
                        ? (
                          <TableCell align='right'>
                            <Tooltip title='Report in preparazione...' aria-label='Report in preparazione'>
                              <CircularProgress size={30} thickness={6} />
                            </Tooltip>
                          </TableCell>
                        )
                        : (
                          <TableCell align='right'>
                            {!isExtraSmall && (
                              <>
                                <Tooltip title='Modifica Report' aria-label='Modifica Report'>
                                  <IconButton
                                    onClick={() => {
                                      openEditReport(report)
                                    }}
                                  >
                                    <SvgIcon fontSize='small'>
                                      <EditIcon color={basicColors.darkYellow} />
                                    </SvgIcon>
                                  </IconButton>
                                </Tooltip>
                              </>
                            )}
                            <Tooltip title='Invia Report' aria-label='Invia Report'>
                              <IconButton
                                onClick={() => {
                                  openSendReport(report)
                                }}
                              >
                                <SvgIcon fontSize='small'>
                                  <SendIcon color={basicColors.primary} />
                                </SvgIcon>
                              </IconButton>
                            </Tooltip>
                            <Tooltip title='Elimina Report' aria-label='Elimina Report'>
                              <IconButton
                                onClick={() => {
                                  handleOpenConfirmDelete(report)
                                }}
                              >
                                <SvgIcon fontSize='small'>
                                  <DeleteIcon color={basicColors.darkRed} />
                                </SvgIcon>
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        )}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </Box>
        <ConfirmationDialogRaw
          open={openConfirmDelete}
          onClose={handleCloseConfirmDelete}
          title='Conferma eliminazione Datalogger'
          description={`Sei sicuro di voler eliminare il Datalogger ${reportToDelete.name}?`}
        />
      </PerfectScrollbar>
      <TablePagination
        component='div'
        count={filteredReports.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  )
}

ReportTable.propTypes = {
  className: PropTypes.string,
  reports: PropTypes.array.isRequired,
  openEditReport: PropTypes.func,
  openSendReport: PropTypes.func,
  deleteReport: PropTypes.func,
  openEditReportsMail: PropTypes.func,
  openNewReport: PropTypes.func
}

ReportTable.defaultProps = {
  reports: []
}

export default ReportTable
