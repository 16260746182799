import { forwardRef, useEffect, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import track from '@/utils/analytics'
import { configuration } from '@/config'
import useAuth from '@/hooks/useAuth'

const Page = forwardRef(({
  children,
  title = '',
  ...rest
}, ref) => {
  const location = useLocation()
  const { currentProject } = useAuth()

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: location.pathname
    })
  }, [])

  useEffect(() => {
    sendPageViewEvent()
  }, [sendPageViewEvent])

  const isGeDashboard = configuration.geProject === currentProject?.uuid || configuration.geHostname === window.location.hostname

  return (
    <div
      ref={ref}
      {...rest}
    >
      <Helmet>
        {isGeDashboard
          ? (<link rel='icon' type='image/png' sizes='any' href='/static/ge.png' />)
          : (<link rel='icon' type='image/svg+xml' sizes='any' href='/favicon.svg' />)}
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  )
})

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
}

export default Page
