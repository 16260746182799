import { useState, memo } from 'react'
// basic documentation
// https://www.notion.so/pelvspace/Energy-Device-Log-Modal-7d7a86548430499d836eed89bdcd64fe
// import clsx from 'clsx'
import PropTypes from 'prop-types'
// import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  useMediaQuery,
  useTheme,
  // Grid,
  Button,
  // SvgIcon,
  // TextField,
  Dialog,
  DialogActions,
  DialogContent,
  // DialogContentText,
  DialogTitle as MuiDialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
  Checkbox,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
// import { Trash as DeleteIcon, Edit as EditIcon, Plus as AddIcon, Save as SaveIcon } from 'react-feather'
// import { basicColors } from '@/theme'

// DialogTitle personalizzato
const titleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})
const DialogTitle = withStyles(titleStyles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h4'>{children}</Typography>
      {onClose
        ? (
          <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          )
        : null}
    </MuiDialogTitle>
  )
})

// Stili del componente
const useStyles = makeStyles((theme) => ({
  root: {},
  fullWidth: {
    width: '100%'
  },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  selectLabel: {
    backgroundColor: theme.palette.background.paper,
    paddingRight: 8,
    paddingLeft: 8
  }
}))

function SendEmails ({
  open,
  onClose,
  emails,
  sendEmails
}) {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const maxWidth = 'sm'

  const [currentEmails] = useState(JSON.parse(JSON.stringify(emails)))
  const [checked, setChecked] = useState(emails.map((email, index) => index))

  const handleToggleChecked = (value) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  // funzione lanciata al close della modal
  const handleClose = () => {
    // setSelectedColumns(['all'])
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='saved-email-dialog'
      aria-describedby='saved-email-dialog'
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      fullWidth
      // {...other}
    >
      <DialogTitle disableTypography id='salved-email-dialog-title' onClose={handleClose}>
        Invia Report
      </DialogTitle>
      <DialogContent>
        <Typography variant='body2'>Seleziona i destinatari.</Typography>
        <List className={classes.root}>
          {currentEmails.map((email, index) => {
            return (
              <ListItem
                key={index}
                divider
                disableGutters
                role={undefined}
                // button
                onClick={handleToggleChecked(index)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge='start'
                    color='primary'
                    checked={checked.indexOf(index) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': 'email' + index }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={`email-label-${index}`}
                  primary={email}
                // secondary={index}
                />
              </ListItem>
            )
          })}
          {currentEmails.length === 0 && (
            <ListItem divider disableGutters>
              <ListItemText
                id='email-label-no-email'
                primary='Non ci sono email salvate.'
              />
            </ListItem>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Box width='100%' display='flex' alignItems='center' justifyContent='space-between'>
          <Button onClick={handleClose}>
            Chiudi
          </Button>
          <Button
            onClick={() => {
              const emailsToSend = currentEmails.filter((email, index) => checked.indexOf(index) > -1)
              sendEmails(emailsToSend)
            }}
            disabled={checked.length === 0}
            variant='contained'
            color='primary'
          >
            Invia Report
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

SendEmails.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sendEmails: PropTypes.func,
  emails: PropTypes.array
}

SendEmails.defaultProps = {
  emails: []
}

export default memo(SendEmails)
