import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useState } from 'react'

const useStyles = makeStyles(() => ({
  root: {},
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))

function MetricsRecalculationModal ({ open, onClose, plantId, executeRecalculations }) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [startDate, setStartDate] = useState(moment().subtract('1', 'hour').set({ minute: 0, second: 0 }))
  const [endDate, setEndDate] = useState(moment().set({ minute: 0, second: 0 }))

  const [isError, setIsError] = useState(false)

  const handleCancel = (e, reason) => {
    if (reason !== 'backdropClick') {
      onClose()
    }
  }

  const handleConfirm = async () => {
    const isoStartDate = startDate.toISOString()
    const isoEndDate = endDate.toISOString()
    if (executeRecalculations) {
      const executionStatus = await executeRecalculations(plantId, isoStartDate, isoEndDate)
      if (executionStatus) {
        enqueueSnackbar('Il ricalcolo delle metriche è stato avviato correttamente!', { variant: 'success' })
      } else {
        enqueueSnackbar('Errore durante il ricalcolo delle metriche', { variant: 'error' })
      }
    }
    onClose()
  }

  const handleDateChange = (date, type) => {
    const isoStringDate = moment(date).set({ minute: 0, second: 0 }).toISOString()
    if (type === 'start') {
      const isBefore = moment(isoStringDate).isBefore(moment(endDate))
      setStartDate(moment(isoStringDate).set({ minute: 0, second: 0 }))
      if (isBefore) {
        setIsError(false)
      } else {
        setIsError(true)
      }
    } else if (type === 'end') {
      const isAfter = moment(isoStringDate).isAfter(moment(startDate))
      setEndDate(moment(isoStringDate).set({ minute: 0, second: 0 }))
      if (isAfter) {
        setIsError(false)
      } else {
        setIsError(true)
      }
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant='h4'>Ricalcola metriche</Typography>
        <IconButton size='small' aria-label='close' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Seleziona il periodo per il quale vuoi ricalcolare le metriche dell'impianto
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <KeyboardDateTimePicker
              label='Inizio periodo'
              size='small'
              fullWidth
              format='DD/MM/YYYY HH:mm'
              name='startDate'
              disableFuture
              disableToolbar
              inputVariant='outlined'
              views={['date', 'hours']}
              ampm={false}
              maxDate={endDate}
              maxDateMessage='La data di fine deve essere successiva alla data di inizio'
              value={startDate}
              strictCompareDates
              onChange={(date) => handleDateChange(date, 'start')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <KeyboardDateTimePicker
              label='Fine periodo'
              size='small'
              fullWidth
              inputVariant='outlined'
              format='DD/MM/YYYY HH:mm'
              views={['date', 'hours']}
              ampm={false}
              name='endDate'
              disableFuture
              disableToolbar
              minDate={startDate}
              minDateMessage='La data di inizio deve essere precedente alla data di fine'
              strictCompareDates
              value={endDate}
              onChange={(date) => handleDateChange(date, 'end')}
            />
          </Grid>
        </Grid>
        <Box my={2}>
          <Alert severity='warning'>
            <b>Attenzione</b>: l'operazione di ricalcolo delle metriche potrebbe richiedere alcuni minuti.
          </Alert>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant='outlined' onClick={handleCancel}>
          Annulla
        </Button>
        <Button fullWidth onClick={handleConfirm} disabled={isError} variant='contained' color='primary'>
          Ricalcola
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default MetricsRecalculationModal
