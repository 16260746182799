import clsx from 'clsx'
import { Box, Card, Grid, makeStyles, Typography } from '@material-ui/core'
import PrOverview from '@/components/charts/PrOverview'
import { basicColors } from '@/theme'
import { europeNum } from '@/utils/general'
import EnergyRadiationGraph from '@/components/charts/EnergyRadiationGraph'
import Components from '../Components'
import Report from '../Report'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  boxContainer: {
    width: '100%',
    height: graphSize.height - 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cardRoot: {
    padding: theme.spacing(1, 2)
  },
  cardLabel: {
    color: basicColors.blueGrayLight
  },
  cardValuePrimary: {
    fontSize: '24px',
    fontWeight: 600
  },
  cardValueSecondary: {
    fontSize: '16px',
    fontWeight: 500
  },
  glassBackground: {
    '@supports (backdrop-filter: none) or (-webkit-backdrop-filter: none)': {
      '-webkit-backdrop-filter': 'saturate(120%) blur(10px)',
      backdropFilter: 'saturate(190%) blur(16px)',
      backgroundColor: 'rgba(16, 26, 38, 0.6)'
    }
  }
}))

// variaible con le dimenzioni del grafico
const graphSize = {
  height: 270,
  width: 380
}

function OverviewCardGrid ({ cardsData = null, productionData = null, radiationData = null, anagraphicData = null, className }) {
  const classes = useStyles()
  return (
    <div className={clsx(classes.root, className)}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Card className={clsx(classes.cardRoot, classes.glassBackground)}>
            <Grid container spacing={3}>
              <Grid item>
                <Typography className={classes.cardLabel} component='div' variant='caption'>Produzione</Typography>
                <Typography className={classes.cardValueSecondary} component='div'>{cardsData.production !== '-' ? cardsData.production >= 1000 ? `${europeNum(cardsData.production / 1000, 1)} MWh` : `${europeNum(cardsData.production, 1)} kWh` : cardsData.production}</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.cardLabel} component='div' variant='caption'>PR</Typography>
                <Typography className={classes.cardValueSecondary} component='div'>{cardsData.pr !== '-' ? europeNum(cardsData.pr, 2) : cardsData.pr}</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item style={{ width: '100%' }}>
                <Box className={classes.boxContainer}>
                  <PrOverview
                    data={productionData}
                    height={graphSize.height - 50}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography className={classes.cardLabel} component='div' variant='caption'>P. Attiva</Typography>
                <Typography className={classes.cardValueSecondary} component='div'>{europeNum(cardsData.activePower)} kW</Typography>
              </Grid>
              {/* <Grid item xs={6}>
                <Typography className={classes.cardLabel} component='div' variant='caption'>P. Picco</Typography>
                <Typography className={classes.cardValueSecondary} component='div'>{europeNum(cardsData.peakPowerValue)} kW</Typography>
              </Grid> */}
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={clsx(classes.cardRoot, classes.glassBackground)}>
            <Grid container spacing={3}>
              <Grid item>
                <Typography className={classes.cardLabel} component='div' variant='caption'>Irr. Medio</Typography>
                <Typography className={classes.cardValueSecondary} component='div'>{cardsData.averageRadiation >= 1000 ? `${europeNum(cardsData.averageRadiation / 1000, 1)} kWh/m${String.fromCodePoint(0x00B2)}` : `${europeNum(cardsData.averageRadiation)} Wh/m${String.fromCodePoint(0x00B2)}`}</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.cardLabel} component='div' variant='caption'>Irr. Picco</Typography>
                <Typography className={classes.cardValueSecondary} component='div'>{cardsData.peakIrradiationValue >= 1000 ? `${europeNum(cardsData.peakIrradiationValue / 1000, 1)} kWh/m${String.fromCodePoint(0x00B2)}` : `${europeNum(cardsData.peakIrradiationValue)} W/m${String.fromCodePoint(0x00B2)}`}</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Box className={classes.boxContainer}>
                  <EnergyRadiationGraph
                    showAxis={false}
                    showLegend={false}
                    height={(graphSize.height - 50) || 0}
                    data={radiationData}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography className={classes.cardLabel} component='div' variant='caption'>Temp. Moduli</Typography>
                <Typography className={classes.cardValueSecondary} component='div'>{europeNum(cardsData.modulesTemperature)} °C</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.cardLabel} component='div' variant='caption'>Temp. Ambiente</Typography>
                <Typography className={classes.cardValueSecondary} component='div'>{europeNum(cardsData.environmentTemperature)} °C</Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.glassBackground}>
            <Components />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.glassBackground}>
            <Report hasGlass />
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}
export default OverviewCardGrid
