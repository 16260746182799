import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import useCharts from '@/hooks/useCharts'
import { memo, useEffect, useRef, useState } from 'react'
import LoadingCard from '../LoadingCard'
import { europeNum } from '@/utils/general'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%'
  }
}))

// mapping delle categorie del grafico
const categoryMap = {
  kwh: 'Reale',
  pr: 'Baseline'
}

// mapping delle categories dei tooltips
const tooltipsMap = {
  kwh: 'Prod',
  pr: 'PR'
}

function PrOverview ({ data = {}, height = null, width = null, className, ...rest }) {
  const classes = useStyles()
  // Prendo le istanze di core e am4Charts disponibili nel context
  const { chartsCore, amCharts } = useCharts()
  // Creo una ref del grafico
  const chartRef = useRef(null)
  // Variabile che determina la prima renderizzazione a schermo
  const [firstPaint, setFirstPaint] = useState(true)

  // mapping dei colori delle barre
  const colorMapping = {
    reale: chartsCore.color('#50af57'),
    baseline: chartsCore.color('#5f93d1')
  }

  // Funzione che formatta i valori da mostrare a schermo
  const formatValues = (type, number = 0) => {
    if (type === 'kwh') {
      return number >= 1000 ? `${europeNum(number / 1000, 2) || 0} MWh` : `${europeNum(number, 2) || 0} kWh`
    }

    return europeNum(number, 2) || 0
  }

  // Funzione per la formattazione dei dati nel formato richiesto dalla libreria grafica
  const formatData = (dataObj) => {
    return Object.keys(categoryMap).map((key, index) => ({
      category: categoryMap[key],
      value: dataObj.series[index],
      realValue: dataObj[key][index],
      name: categoryMap[key],
      full: 100,
      pr: dataObj.pr[0],
      deviation: dataObj.productability,
      tooltipValues: `${tooltipsMap.kwh}: [bold]${formatValues('kwh', dataObj.kwh[index])}[/]\n${tooltipsMap.pr}: [bold]${formatValues('pr', dataObj.pr[index])}[/]`
    })).reverse()
  }

  // Funzione per creare una label dinamicamente
  const createLabel = ({ chart = null, text = '', opts = {} }) => {
    if (chart) {
      // Creo la label
      const label = chart.radarContainer.createChild(chartsCore.Label)
      // imposto i valori di default
      label.text = text
      label.fontFamily = 'Roboto, sans-serif'
      label.fill = '#ffffff'
      // Setto le opzioni restanti se ci sono
      if (Object.keys(opts).length > 0) {
        Object.keys(opts).forEach(key => {
          label[key] = opts[key]
        })
      }

      return label
    }

    return null
  }

  // Funzione per settare la label centrale del grafico con i valori iniziali
  const setCenterLabel = (chartInstance) => {
    const nameLabel = createLabel({
      chart: chartInstance,
      // text: 'Produz. | PR',
      text: 'Scostam.',
      opts: {
        fontSize: 14,
        horizontalCenter: 'middle',
        verticalCenter: 'bottom'
      }
    })

    const valueLabel = createLabel({
      chart: chartInstance,
      text: data.productability ? `${formatValues('percentage', data.productability * 100)}% ` : '-',
      // text: data.kwh.length > 0 && data.kwh[0] && data.pr.length > 0 && data.pr[0] ? `${formatValues('kwh', data.kwh[0])} | ${formatValues('pr', data.pr[0])}` : '- | -',
      opts: {
        fontSize: 18,
        fontWeight: 800,
        horizontalCenter: 'middle',
        verticalCenter: 'top'
      }
    })

    return { nameLabel, valueLabel }
  }

  // Funzione che crea gli assi del grafico
  const createAxis = (chartInstance) => {
    // Creo l'asse delle categorie (Reale e Baseline)
    const categoryAxis = chartInstance.yAxes.push(new amCharts.CategoryAxis())
    // Faccio il bind dei dati da mostrare
    categoryAxis.dataFields.category = 'category'
    // Imposto gli stili per l'asse
    categoryAxis.renderer.grid.template.location = 0
    categoryAxis.renderer.grid.template.strokeOpacity = 0
    categoryAxis.renderer.labels.template.horizontalCenter = 'right'
    categoryAxis.renderer.labels.template.fontWeight = 500
    categoryAxis.renderer.labels.template.fontFamily = 'Roboto, sans-serif'
    categoryAxis.renderer.labels.template.fontSize = 12
    categoryAxis.renderer.labels.template.adapter.add('fill', function (fill, target) {
      if (target.dataItem.index < 0) return fill

      const colorKey = target.dataItem?.dataContext?.name?.trim().toLowerCase() || ''
      const serieColor = colorMapping[colorKey] || chartInstance.colors.getIndex(target.dataItem.index)
      return serieColor
    })
    categoryAxis.renderer.minGridDistance = 10
    // Creo l'asse dei valori
    const valueAxis = chartInstance.xAxes.push(new amCharts.ValueAxis())
    // Imposto gli stili per l'asse
    valueAxis.renderer.labels.template.disabled = true
    valueAxis.renderer.grid.template.strokeOpacity = 0
    valueAxis.renderer.labels.template.fill = '#ffffff'
    valueAxis.renderer.labels.template.fontFamily = 'Roboto, sans-serif'
    valueAxis.renderer.labels.template.fontSize = 10
    valueAxis.min = 0
    valueAxis.max = 100
    valueAxis.strictMinMax = true
    // Disattivo i tooltip
    valueAxis.cursorTooltipEnabled = false
    // Disattivo lo zoom
    valueAxis.zoomable = false
  }

  // Funzione che crea un tooltip unificato per tutte le serie da graficare
  const createTooltip = (currentSerie) => {
    // Abilito i tooltip
    currentSerie.columns.template.tooltipText = ''
    currentSerie.columns.template.adapter.add('tooltipText', function (ev) {
      const text = '[bold]{category}[/]\n\n{tooltipValues}'
      return text
    })
    // Definisco una grandezza per il tooltip e lo faccio andare a capo in caso di testi più lunghi
    currentSerie.tooltip.label.width = 160
    currentSerie.tooltip.label.wrap = true
    // Disabilito il colore del tooltip in base al colore della serie e lo definisco in maniera programmatica
    currentSerie.tooltip.getFillFromObject = false
    currentSerie.tooltip.background.fill = new chartsCore.InterfaceColorSet().getFor('alternativeBackground')
    currentSerie.tooltip.background.stroke = new chartsCore.InterfaceColorSet().getFor('alternativeBackground')
    currentSerie.tooltip.background.opacity = 0.8
    // Definisco gli stili per il testo all'interno del tooltip
    currentSerie.tooltip.label.fill = chartsCore.color('#fff')
    currentSerie.tooltip.label.fontFamily = 'Roboto, sans-serif'
    currentSerie.tooltip.label.fontSize = 12
  }

  // Funzione in cui definisco tutte le serie da graficare
  const createSeries = (chartInstance, nameLabel, valueLabel) => {
    // Creo la prima serie, di sfondo
    const backgroundSerie = chartInstance.series.push(new amCharts.RadarColumnSeries())
    if (!firstPaint) {
      backgroundSerie.showOnInit = false
    }
    // Faccio il bind dei dati
    backgroundSerie.dataFields.valueX = 'full'
    backgroundSerie.dataFields.categoryY = 'category'
    // Permetto alle serie di essere una sotto l'altra
    backgroundSerie.clustered = false
    // Assegno un colore alternativo alle barre e setto gli stili della serie
    backgroundSerie.columns.template.fill = new chartsCore.InterfaceColorSet().getFor('alternativeBackground')
    backgroundSerie.columns.template.fillOpacity = 0.2
    backgroundSerie.columns.template.strokeWidth = 0
    backgroundSerie.columns.template.radarColumn.cornerRadius = 20
    // Creo la serie che contiene i valori veri e propri da mostrare
    const valueSerie = chartInstance.series.push(new amCharts.RadarColumnSeries())
    if (!firstPaint) {
      valueSerie.showOnInit = false
    }
    // Faccio il bind dei dati
    valueSerie.dataFields.valueX = 'value'
    valueSerie.dataFields.categoryY = 'category'
    // Assegno un ulteriore proprietà da mostrare all'over nella label centrale
    valueSerie.propertyFields.name = 'name'
    valueSerie.propertyFields.pr = 'pr'
    valueSerie.propertyFields.deviation = 'deviation'
    valueSerie.propertyFields.tooltipText = 'tooltipValues'
    // Setto gli stili della serie
    valueSerie.clustered = false
    valueSerie.columns.template.strokeWidth = 0
    valueSerie.columns.template.radarColumn.cornerRadius = 20
    valueSerie.columns.template.adapter.add('fill', function (fill, target) {
      const colorKey = target.dataItem?.dataContext?.name?.trim().toLowerCase() || ''
      const serieColor = colorMapping[colorKey] || chartInstance.colors.getIndex(target.dataItem.index)
      return serieColor
    })
    createTooltip(valueSerie)
    // Definisco la funzione da richiamare all'over del mouse sulla barra del grafico
    /* const overDisposer = valueSerie.columns.template.events.on('over', (e) => {
      nameLabel.text = e.target?.column?.dataItem?.properties?.name || ''
      nameLabel.text = 'PR | Scostam.'
      valueLabel.text = e.target?.column?.dataItem?.properties?.pr && e.target?.column?.dataItem?.properties?.deviation ? `${formatValues('pr', e.target.column.dataItem.properties.pr)} | ${formatValues('percentage', e.target.column.dataItem.properties.deviation)}% ` : '- | -'
    }, this) */
    // Definisco la funzione da richiamare all'out del mouse sulla barra del grafico
    /*  const outDisposer = valueSerie.columns.template.events.on('out', (e) => {
      nameLabel.text = 'Produz. | PR'
      valueLabel.text = data.kwh.length > 0 && data.kwh[0] && data.pr.length > 0 && data.pr[0] ? `${formatValues('kwh', data.kwh[0])} | ${formatValues('pr', data.pr[0])} ` : '- | -'
    }, this) */

    // return { overDisposer, outDisposer }
  }

  useEffect(() => {
    // Prendo il div con l'id indicato per creare il grafico Radar
    const chart = chartsCore.create('pr-overview-chart', amCharts.RadarChart)
    if (!firstPaint) {
      chart.showOnInit = false
    }
    // Assegno i dati al grafico dopo averli formattati
    chart.data = formatData(data)
    // Setto il grafico per essere 3/4 di circonferenza
    chart.startAngle = -90
    chart.endAngle = 180
    // Setto lo spessore delle barre del grafico
    chart.innerRadius = chartsCore.percent(70)

    // Creo le labels centrali con i valori di default
    const { nameLabel, valueLabel } = setCenterLabel(chart)
    // Aggiungo il cursore
    chart.cursor = new amCharts.RadarCursor()
    // Creo gli assi del grafico
    createAxis(chart)
    // Creo la serie da graficare
    // const { overDisposer, outDisposer } = createSeries(chart, nameLabel, valueLabel)
    createSeries(chart, nameLabel, valueLabel)

    chartRef.current = chart

    if (firstPaint) {
      setFirstPaint(false)
    }
    return () => {
      /* overDisposer.dispose()
      outDisposer.dispose() */
      chartRef.current.dispose()
    }
  }, [data])

  return Object.keys(data).length > 0
    ? (
      <div
        id='pr-overview-chart'
        style={{
          width: width || 300,
          height: height || 300
        }}
        className={clsx(classes.root, className)}
        {...rest}
      />)
    : <LoadingCard />
}
export default memo(PrOverview)
