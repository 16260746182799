import clsx from 'clsx'
import { Box, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {}
}))

function MountainContent ({ className, ...rest }) {
  const classes = useStyles()
  return (
    <div className={clsx(classes.root, className)}>
      <Typography variant='h6'>Montagna</Typography>
      <Box my={2}>
        <Typography variant='body1'>
          Vuoi inserire la montagna?
        </Typography>
      </Box>
    </div>
  )
}
export default MountainContent
