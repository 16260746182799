import { useEffect } from 'react'
import clsx from 'clsx'
import {
  Box,
  Divider,
  Grid,
  makeStyles,
  // TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Paper
} from '@material-ui/core'
import { useSelector } from '@/store'
import BaselineTable from './BaselineTable'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { calculateTotalDistribution, getDevicesNumber } from '@utils/plantOperations'
import { confirmationDistributionsTable } from './utils'
import { useDispatch } from 'react-redux'
import { setTotalDistribution } from '@slices/pv/plantCreation'
import moment from 'moment'
import Planimetry from '@components/Planimetry'
import { europeNum } from '@utils/general'
import { basicColors } from '@theme'
import { withStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  root: {},
  stepDescription: {
    color: theme.palette.text.secondary
  },
  title: {
    fontWeight: 'bold'
  },
  canvaContainer: {
    minHeight: '100% !important',
    height: '100%'
  },
  divider: {
    marginTop: 16,
    marginBottom: 16
  },
  logo: {
    maxWidth: 'calc(100% - 8px)',
    width: 'auto',
    height: 'auto'
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: basicColors.lightBlue2,
    alignItems: 'center',
    overflowX: 'auto',
    height: '100%'
  }
}))

const contractTypeMap = (type) => {
  const map = {
    operatingRental: 'Noleggio Operativo',
    energyPerformanceContract: 'Energy Performance Contract',
    engineeringProcurementConstruction: 'Engineering Procurement Construction',
    dmFer: 'DM FER'
  }

  return map[type] || type
}

const typeMap = (type) => {
  const map = {
    pv: 'Fotovoltaico',
    led: 'Led',
    chp: 'Cogenerazione',
    cchp: 'Trigenerazione',
    ca: 'Aria Compressa',
    trs: 'Recupero Termico'
  }

  return map[type] || type
}

const LightTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#BBE4F1',
    color: basicColors.blueGrayDark,
    fontWeight: '600'
  },
  body: {
    backgroundColor: basicColors.lightBlue2,
    color: basicColors.blueGrayDark
  }
}))(TableCell)

const DarkTableCell = withStyles(() => ({
  head: {
    backgroundColor: basicColors.blueGrayDark,
    color: 'white',
    fontWeight: '600'
  },
  body: {
    backgroundColor: '#107FA9',
    color: 'white',
    fontWeight: '600'
  }
}))(TableCell)

function PlantConfirmation ({ className, ...rest }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    anagraphic,
    devices,
    distribution,
    planimetry,
    totalDistribution,
    plantType,
    addedDataloggers,
    name,
    peakPower,
    logoURL,
    module,
    startDate,
    endDate,
    contractDuration,
    manufacturability,
    address,
    baseline,
    baselineConfig,
    orientations
  } = useSelector((state) => state.pvPlantCreation)

  const distributionTables = confirmationDistributionsTable(devices || null, distribution, addedDataloggers)
  // console.log('PlantConfirmation distributionTables => ', distributionTables)

  useEffect(() => {
    const distributionNumber = calculateTotalDistribution(distribution)
    dispatch(setTotalDistribution(distributionNumber))
  }, [distribution, dispatch])

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div id='pdfPage1'>
        <Box my={3}>
          <Typography className={classes.stepDescription} variant='body2'>
            Di seguito le informazioni inserite.
          </Typography>
        </Box>
        <Box mt={3} mb={1}>
          <Typography variant='body2' color='primary'>
            Contratto
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper variant='outlined' style={{ overflowX: 'auto' }} elevation={2}>
              <Table size='small'>
                <TableBody>
                  <TableRow>
                    <DarkTableCell variant='head'>Tipo di impianto</DarkTableCell>
                    <LightTableCell>{typeMap(plantType)}</LightTableCell>
                  </TableRow>
                  <TableRow>
                    <DarkTableCell variant='head'>Tipo contratto</DarkTableCell>
                    <LightTableCell>{contractTypeMap(anagraphic.contractType || '')}</LightTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
        <Box mt={3} mb={1}>
          <Typography variant='body2' color='primary'>
            Anagrafica Impianto
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {logoURL
            ? (
              <Grid item xs={12} md={2}>
                <Paper variant='outlined' className={classes.logoWrapper} elevation={2}>
                  <img alt='Logo' className={classes.logo} src={logoURL} />
                </Paper>
              </Grid>)
            : null}
          <Grid item xs={12} md={logoURL ? 5 : 6}>
            <Paper variant='outlined' style={{ overflowX: 'auto' }} elevation={2}>
              <Table size='small'>
                <TableBody>
                  <TableRow>
                    <DarkTableCell variant='head'>Nominativo</DarkTableCell>
                    <LightTableCell>{anagraphic.referentClient || ''}</LightTableCell>
                  </TableRow>
                  <TableRow>
                    <DarkTableCell variant='head'>Ruolo</DarkTableCell>
                    <LightTableCell>{anagraphic.referentRole || ''}</LightTableCell>
                  </TableRow>
                  <TableRow>
                    <DarkTableCell variant='head'>Telefono</DarkTableCell>
                    <LightTableCell>{anagraphic.referentPhone || ''}</LightTableCell>
                  </TableRow>
                  <TableRow>
                    <DarkTableCell variant='head'>Referente Venera</DarkTableCell>
                    <LightTableCell>{anagraphic.referentName || ''}</LightTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={12} md={logoURL ? 5 : 6}>
            <Paper variant='outlined' style={{ overflowX: 'auto' }} elevation={2}>
              <Table size='small'>
                <TableBody>
                  <TableRow>
                    <DarkTableCell variant='head'>Contatto O&M</DarkTableCell>
                    <LightTableCell>{anagraphic.operationAndMaintenaceName || ''}</LightTableCell>
                  </TableRow>
                  <TableRow>
                    <DarkTableCell variant='head'>Telefono O&M</DarkTableCell>
                    <LightTableCell>{anagraphic.operationAndMaintenacePhone || ''}</LightTableCell>
                  </TableRow>
                  <TableRow>
                    <DarkTableCell variant='head'>Email O&M</DarkTableCell>
                    <LightTableCell>{anagraphic.operationAndMaintenaceEmail || ''}</LightTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Box mt={3} mb={1}>
          <Typography variant='body2' color='primary'>
            Componenti
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper variant='outlined' style={{ overflowX: 'auto' }} elevation={2}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <DarkTableCell>Datalogger</DarkTableCell>
                    <DarkTableCell>N. inverter</DarkTableCell>
                    <DarkTableCell>N. centraline</DarkTableCell>
                    <DarkTableCell>N. contatori</DarkTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {addedDataloggers.map((datalogger) => (
                    <TableRow key={datalogger.uuid}>
                      <LightTableCell>{datalogger.name}</LightTableCell>
                      <LightTableCell>{`${getDevicesNumber({ datalogger, type: 'Inverter' })} inverter`}</LightTableCell>
                      <LightTableCell>{`${getDevicesNumber({ datalogger, type: 'SunMeter' })} centralina`}</LightTableCell>
                      <LightTableCell>{`${getDevicesNumber({ datalogger, type: 'EnergyMeter' })} contatore`}</LightTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Box mt={3} mb={1}>
          <Typography variant='body2' color='primary'>
            Caratteristiche
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper variant='outlined' style={{ overflowX: 'auto' }} elevation={2}>
              <Table size='small'>
                <TableBody>
                  <TableRow>
                    <DarkTableCell variant='head'>Nome impianto</DarkTableCell>
                    <LightTableCell>{name}</LightTableCell>
                  </TableRow>
                  <TableRow>
                    <DarkTableCell variant='head'>Potenza di picco</DarkTableCell>
                    <LightTableCell>{`${europeNum(peakPower, 2)} kWp`}</LightTableCell>
                  </TableRow>
                  <TableRow>
                    <DarkTableCell variant='head'>Moduli</DarkTableCell>
                    <LightTableCell>
                      {module.model} ({`n. totale: ${totalDistribution}`})
                    </LightTableCell>
                  </TableRow>
                  {
                    orientations.reduce((acc, el) => {
                      if (el.surface && el.surface !== undefined) {
                        acc += el.surface
                      }
                      return acc
                    }, 0) > 0
                      ? (
                        <TableRow>
                          <DarkTableCell variant='head'>Superficie Totale</DarkTableCell>
                          <LightTableCell>
                            {`${europeNum(orientations.reduce((acc, el) => {
                              if (el.surface && el.surface !== undefined) {
                                acc += el.surface
                              }
                              return acc
                            }, 0), 2)} m${String.fromCodePoint(0x00B2)}`}
                          </LightTableCell>
                        </TableRow>)
                      : null
                  }
                  {
                    orientations.map(el => el.surface && el.surface !== undefined && el.surface > 0
                      ? (
                        <TableRow key={el.uuid}>
                          <DarkTableCell variant='head'>{`Superficie ${el.name}`}</DarkTableCell>
                          <LightTableCell>
                            {`${europeNum(el.surface, 2)} m${String.fromCodePoint(0x00B2)}`}
                          </LightTableCell>
                        </TableRow>)
                      : null)
                  }
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Typography variant='body2' color='primary'>
            Distribuzione dei moduli
          </Typography>
        </Box>
        {distributionTables && distributionTables.length > 0
          ? (
            <Box py={1}>
              {distributionTables &&
                distributionTables.map((el, index) => el.inverter
                  ? (
                    <Paper variant='outlined' elevation={2} key={`table-dist-${index}`} style={{ overflowX: 'auto', marginBottom: 10 }}>
                      <PerfectScrollbar>
                        <Table size='small'>
                          <TableHead>
                            <TableRow>
                              <DarkTableCell colSpan={el.tableHeader.length}>{el.inverter}</DarkTableCell>
                            </TableRow>
                            <TableRow>{el.tableHeader}</TableRow>
                          </TableHead>
                          <TableBody>{el.tableRows}</TableBody>
                        </Table>
                      </PerfectScrollbar>
                    </Paper>)
                  : null)}
            </Box>)
          : null}
        <Divider className={classes.divider} />
        <Box mt={3} mb={1}>
          <Typography variant='body2' color='primary'>
            Parametri contrattuali
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper variant='outlined' style={{ overflowX: 'auto' }} elevation={2}>
              <Table size='small'>
                <TableBody>
                  <TableRow>
                    <DarkTableCell variant='head'>Installato il</DarkTableCell>
                    <LightTableCell>
                      {startDate && startDate.includes('/')
                        ? startDate
                        : (startDate && moment(startDate.split('/').join('-')).format('DD/MM/YYYY')) || ''}
                    </LightTableCell>
                  </TableRow>
                  <TableRow>
                    <DarkTableCell variant='head'>Durata contratto</DarkTableCell>
                    <LightTableCell>{`${contractDuration} anni`}</LightTableCell>
                  </TableRow>
                  <TableRow>
                    <DarkTableCell variant='head'>Data fine servizio</DarkTableCell>
                    <LightTableCell>
                      {endDate && endDate.includes('/')
                        ? endDate
                        : (endDate && moment(endDate.split('/').join('-')).format('DD/MM/YYYY')) || ''}
                    </LightTableCell>
                  </TableRow>
                  <TableRow>
                    <DarkTableCell variant='head'>Producibilità (MWh)</DarkTableCell>
                    <LightTableCell>{manufacturability[0].numberValue}</LightTableCell>
                  </TableRow>
                  <TableRow>
                    <DarkTableCell variant='head'>Indirizzo</DarkTableCell>
                    <LightTableCell>{address?.inputValue || ''}</LightTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
      </div>
      <div id='pdfPage2'>
        <Box mt={3}>
          <Typography variant='body2' color='primary'>
            Planimetria
          </Typography>
        </Box>
        {planimetry.draw.length > 0
          ? (
            <Box width='100%' height='650px' my={2}>
              <Planimetry
                className={classes.canvaContainer}
                formatter={europeNum}
                distribution={distribution}
                unselectable
                devices={devices}
                importedCanvas={planimetry}
              />
            </Box>)
          : null}
        <Divider />
      </div>
      <div id='pdfPage3'>
        <Box mt={3} mb={1}>
          <Typography variant='body2' color='primary'>
            Baseline
          </Typography>
        </Box>
        <BaselineTable disabled baselineConfig={baselineConfig} baseline={baseline} />
      </div>
    </div>
  )
}
export default PlantConfirmation
