import { memo } from 'react'
import clsx from 'clsx'
import { Avatar, Box, Card, makeStyles, Typography } from '@material-ui/core'
import { basicColors } from '@/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2)
    // width: '100%'
  },
  label: {
    textTransform: 'uppercase',
    color: basicColors.blueGrayLight
  },
  value: {
    fontSize: '24px'
  },
  avatar: {
    backgroundColor: basicColors.background,
    color: basicColors.whiteText
  }
}))

function InfoCard ({ className, values = [], icon = null, ...rest }) {
  const classes = useStyles()

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          {values.map((el, index) => el.label && el.value
            ? (
              <Box mr={3} key={`${el.label || 'element'}-${index}`}>
                <Typography className={classes.label} component='div' variant='caption'>{el.label}</Typography>
                <Typography className={classes.value} component='div' variant='h3'>{el.value}</Typography>
              </Box>)
            : null)}
        </Box>
        <Box>
          <Avatar className={classes.avatar}>
            {icon}
          </Avatar>
        </Box>
      </Box>
    </Card>
  )
}
export default memo(InfoCard)
