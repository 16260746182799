import api from '@micmnt/apis'

// Funzione che fa l'upload del logo di un impianto
export const loadLogo = async (logo, plantId) => {
  if (plantId && plantId !== undefined) {
    const body = new FormData()
    body.append('file', logo)
    return api.put({ savedUrl: 'plantDetails', body, path: `/${plantId}/logo` })
  }
}

// funzione che fa l'upload un datasheet di un device
// riceve in ingresso un array di files da caricare e l'id del device selezionato
export const loadDatasheet = async (files, deviceId) => {
  if (deviceId && deviceId !== undefined) {
    return Promise.all(
      files.map(async (file) => {
        const body = new FormData()
        body.append('file', file)
        const { data, error } = await api.post({ savedUrl: 'uploadDatasheet', body, path: `/${deviceId}/documents` })
        if (error) { return null }
        return data
      }).filter(el => el !== null)
    )
  } else {
    return false
  }
}

// funzione che fa l'upload dei documenti associati ad un tipo di device o all'impianto
export const loadDocuments = async (files, deviceType, deviceId) => {
  if (deviceType && deviceType !== undefined && deviceId && deviceId !== undefined) {
    return Promise.all(
      files.map(async file => {
        const body = new FormData()
        body.append('file', file)

        const { data, error } = await api.post({ savedUrl: 'uploads', body, path: `/${deviceType}/${deviceId}/documents` })
        if (error) { return null }
        return data
      }).filter(el => el !== null)
    )
  } else {
    return false
  }
}

// Funzione che permette l'upload di un datasheet per un determinato modulo (per ora solo in impianti LED)
export const loadModuleDatasheet = async (files, plantId, moduleId) => {
  if (plantId && plantId !== undefined && moduleId && moduleId !== undefined) {
    return Promise.all(
      files.map(async file => {
        const body = new FormData()
        body.append('file', file)
        const { data, error } = await api.post({ savedUrl: 'uploads', body, path: `/plants/${plantId}/modules/${moduleId}/datasheet` })
        if (error) { return null }
        return data
      }).filter(el => el !== null)
    )
  } else {
    return false
  }
}

// funzione che elimina un logo
export const deleteLogo = async (plantId) => {
  return api.delete({ savedUrl: 'plantDetails', path: `/${plantId}/logo` })
}

// funzione che fa la delete dei documenti associati ad un tipo di device o all'impianto
export const deleteDocuments = async (fileIndex, deviceType, deviceId) => {
  if (fileIndex !== null && fileIndex !== undefined && deviceType && deviceType !== undefined && deviceId && deviceId !== undefined) {
    return api.delete({ savedUrl: 'uploads', path: `/${deviceType}/${deviceId}/documents/${fileIndex}` })
  } else {
    return false
  }
}

export const deleteDatasheet = async (fileIndex, deviceId) => {
  if (fileIndex !== null && fileIndex !== undefined && deviceId && deviceId !== undefined) {
    return api.delete({ savedUrl: 'uploadDatasheet', path: `/${deviceId}/documents/${fileIndex}` })
  } else {
    return false
  }
}

// Funzione che fa la delete del datasheet associato ad un modulo
export const removeModuleDatasheet = async (plantId, moduleId) => {
  return api.delete({ savedUrl: 'uploads', path: `/plants/${plantId}/modules/${moduleId}/datasheet` })
}

// Funzione che prende in ingresso un array di stringhe e ritorna un array di oggetti nella forma [{value: 'string' ,label: 'string'}]
export const formatPlantTypes = (types) => {
  const plantMaps = {
    pv: 'Fotovoltaico (PV)',
    led: 'LED',
    hydro: 'Idroelettrico',
    publicEnergy: 'Pubblica Illuminazione',
    'pv-light': 'Fotovoltaico (Light)',
    'pv-pro-light': 'Fotovoltaico (Pro Light)',
    'pv-pro-realtime': 'Fotovoltaico (Real Time)'
  }

  const formattedTypes = types.map(type => plantMaps[type] ? ({ value: type, label: plantMaps[type] }) : null).filter(el => el !== null)

  // return formattedTypes.length > 0 ? formattedTypes : [{ value: 'pv', label: plantMaps.pv }]
  return formattedTypes
}
