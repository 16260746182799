import { useState } from 'react'
import clsx from 'clsx'
import { Box, Checkbox, Grid, ListItemIcon, ListItemText, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core'
import { libraryDevices } from '../../libraryDevices'
import { HexColorPicker } from 'react-colorful'
import log from '@pelv/frontlog'

const useStyles = makeStyles(() => ({
  root: {}
}))

function LightPointContent ({ className, ...rest }) {
  const { selectedObject, devices, element, setElement, powerLines } = rest
  log([
    { text: 'selectedObject => ', variable: selectedObject, tag: 'lightPointContent' },
    { text: 'element => ', variable: element, tag: 'lightPointContent' }
  ])
  const configDevices = element && element !== undefined ? (element.config.length > 0 ? element.config[0].devices : []) : []
  const selected = configDevices && configDevices.length > 0 ? configDevices[0].deviceId : ''

  const line = element && element.config.length > 0
    ? element.config[0].data
        ? element.config[0].data.line
            ? element.config[0].data.line.uuid
            : ''
        : ''
    : ''

  const power =
    element && element.config.length > 0
      ? element.config[0].data
          ? element.config[0].data.power
              ? element.config[0].data.power
              : 0
          : 0
      : 0

  const lightsNum =
    element && element.config.length > 0
      ? element.config[0].data
          ? element.config[0].data.lightsNum
              ? element.config[0].data.lightsNum
              : 0
          : 0
      : 0

  const color = element && element.config.length > 0
    ? element.config[0].data
        ? element.config[0].data.draw && element.config[0].data.draw.backgroundColor
            ? element.config[0].data.draw.backgroundColor
            : '#0000FF'
        : '#0000FF'
    : '#0000FF'

  const [selectedDevice, setSelectedDevice] = useState(selected)
  const [selectedLine, setSelectedLine] = useState(line)
  // const [currentPower] = useState(power)
  const [currentColor, setCurrentColor] = useState(color)
  // const [lightPoints] = useState(lightsNum)

  const meters = devices ? devices.filter(device => device.deviceType.category === 'EnergyMeter') : []

  const classes = useStyles()
  return (
    <div className={clsx(classes.root, className)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant='h6'>Linea</Typography>
              <Box my={2} width='100%'>
                <TextField
                  size='small'
                  color='primary'
                  variant='outlined'
                  select
                  fullWidth
                  label='Seleziona Linea'
                  value={selectedLine}
                  onChange={e => {
                    e.persist()
                    setElement(prevElement => {
                      const currentLine = powerLines.find(or => or.uuid === e.target.value)
                      if (prevElement.config.length > 0) {
                        // se l'elemento non ha l'oggetto data lo imposto a oggetto vuoto
                        if (!prevElement.config[0].data) {
                          prevElement.config[0].data = {}
                        }
                        prevElement.config[0].data = {
                          ...prevElement.config[0].data,
                          line: currentLine,
                          lightsNum: currentLine.num,
                          power: currentLine.power
                        }
                      } else {
                        prevElement.config.push({
                          devices: [],
                          data: {
                            line: currentLine,
                            lightsNum: currentLine.num,
                            power: currentLine.power,
                            draw: {}
                          }
                        })
                      }
                      return { ...prevElement }
                    })
                    setSelectedLine(e.target.value)
                  }}
                  // onChange={(e) => setSelectedLine(e.target.value)}
                  SelectProps={{
                    renderValue: (renderSelected) => <ListItemText primaryTypographyProps={{ style: { lineHeight: 1 } }} style={{ marginTop: 0, marginBottom: 0 }}>{powerLines.find(or => or.uuid === renderSelected) ? powerLines.find(or => or.uuid === renderSelected).name : ''}</ListItemText>
                  }}
                >
                  {
                    powerLines.filter(el => el.valid === true).map(el => (
                      <MenuItem
                        dense
                        key={el.uuid}
                        value={el.uuid}
                      >
                        {el.name}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant='h6'>Meter</Typography>
              <Box my={2} width='100%'>
                <TextField
                  size='small'
                  color='primary'
                  variant='outlined'
                  select
                  fullWidth
                  label='Seleziona Meter'
                  value={selectedDevice}
                  SelectProps={{
                    renderValue: (renderSelected) => <ListItemText primaryTypographyProps={{ style: { lineHeight: 1 } }} style={{ marginTop: 0, marginBottom: 0 }}>{meters.find(or => or.uuid === renderSelected) ? meters.find(or => or.uuid === renderSelected).name : ''}</ListItemText>
                  }}
                >
                  {meters.map(el => (
                    <MenuItem
                      dense
                      onClick={() => {
                        let isDeselection = false
                        setElement(prevElement => {
                          // oggetto completo del device selezionato
                          const selectedDevice = devices.find(dev => dev.uuid === el.uuid)

                          // se l'oggetto esiste, devo aggiornare l'array dei devices nell'elemento
                          if (selectedDevice && selectedDevice !== undefined) {
                            // creo l'oggetto con le proprietà da mostrare nelle infobox
                            const properties = {}
                            const libraryDevice = libraryDevices.find(libEl => libEl.models.includes(selectedDevice.deviceType.model))
                            if (libraryDevice) {
                              const deviceKeys = Object.keys(libraryDevice)
                              deviceKeys.filter(key => key !== 'models').forEach(key => {
                                properties[key] = {
                                  label: libraryDevice[key],
                                  value: 0
                                }
                              })
                            }
                            // strutturo l'oggetto device
                            const elementDevice = {
                              type: selectedObject.type,
                              model: selectedDevice.deviceType.model,
                              deviceId: selectedDevice.uuid,
                              properties,
                              additionalData: {}
                            }

                            if (prevElement.config.length > 0) {
                              const sameDevice = prevElement.config[0].devices.find(configEl => configEl.deviceId === el.uuid)
                              // se è presente lo rimuovo
                              if (sameDevice && sameDevice !== undefined) {
                                const newDevices = prevElement.config[0].devices.filter(dev => dev.deviceId !== el.uuid)
                                prevElement.config[0].devices = newDevices
                                isDeselection = true
                              } else {
                                // aggiorno l'array di devices di un singolo elemento
                                prevElement.config[0].devices = [{ ...elementDevice }]
                              }
                            } else {
                              prevElement.config.push({
                                devices: [{ ...elementDevice }],
                                data: {}
                              })
                            }
                          }

                          return ({
                            config: [{
                              ...prevElement.config[0]
                            }]
                          })
                        })
                        if (isDeselection) {
                          setSelectedDevice('')
                        } else {
                          setSelectedDevice(el.uuid)
                        }
                      }} key={el.uuid} value={el.uuid}
                    >
                      <ListItemIcon>
                        <Checkbox
                          color='primary'
                          edge='start'
                          checked={selected === el.uuid}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText>{el.name}</ListItemText>
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>
          </Grid>
          <Box width='100%'>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  disabled
                  label='Numero punti luce'
                  type='number'
                  placeholder='0'
                  value={lightsNum || ''}
                  /* onChange={e => {
                    e.persist()
                    setElement(prevElement => {
                      if (prevElement.config.length > 0) {
                        // se l'elemento non ha l'oggetto data lo imposto a oggetto vuoto
                        if (!prevElement.config[0].data) {
                          prevElement.config[0].data = {}
                        }
                        prevElement.config[0].data = {
                          ...prevElement.config[0].data,
                          lightsNum: e.target.value
                        }
                      } else {
                        prevElement.config.push({
                          devices: [],
                          data: {
                            lightsNum: e.target.value,
                            draw: {}
                          }
                        })
                      }
                      return { ...prevElement }
                    })
                    setLightPoints(e.target.value)
                  }} */
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size='small'
                  variant='outlined'
                  label='Potenza'
                  type='number'
                  disabled
                  placeholder='0'
                  value={power || ''}
                  /* onChange={e => {
                    e.persist()
                    setElement(prevElement => {
                      if (prevElement.config.length > 0) {
                        // se l'elemento non ha l'oggetto data lo imposto a oggetto vuoto
                        if (!prevElement.config[0].data) {
                          prevElement.config[0].data = {}
                        }
                        prevElement.config[0].data = {
                          ...prevElement.config[0].data,
                          power: e.target.value
                        }
                      } else {
                        prevElement.config.push({
                          devices: [],
                          data: {
                            power: e.target.value,
                            draw: {}
                          }
                        })
                      }
                      return { ...prevElement }
                    })
                    setCurrentPower(e.target.value)
                  }} */
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant='h6'>Colore della linea</Typography>
          <Box my={2} width='100%'>
            <HexColorPicker
              style={{ height: '100px' }} color={currentColor} onChange={setCurrentColor} onMouseUp={(e) => {
                e.persist()
                setElement((prevElement) => {
                  // console.log(prevElement)
                  if (prevElement.config.length > 0) {
                    if (!prevElement.config[0].data.draw) {
                      prevElement.config[0].data.draw = {}
                    }
                    prevElement.config[0].data.draw = {
                      ...prevElement.config[0].data.draw,
                      backgroundColor: currentColor
                    }
                  } else {
                    prevElement.config.push({
                      devices: [],
                      data: {
                        draw: {
                          backgroundColor: currentColor
                        }
                      }
                    })
                  }
                  return { ...prevElement }
                })
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}
export default LightPointContent
