import { memo, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import useCharts from '@/hooks/useCharts'
import clsx from 'clsx'
import moment from 'moment'
// import Chart from 'react-apexcharts'
// import { fixedNum, europeNum } from '@/utils/general'
import { getContrastColor } from '@/utils/general'
// import { basicColors } from '@/theme'
import LoadingCard from '@/components/LoadingCard'
// import log from '@pelv/frontlog'
import {
  Box,
  // Button,
  // Tooltip,
  Card,
  CardHeader,
  // CardContent,
  // Typography,
  // withStyles,
  makeStyles
} from '@material-ui/core'
// import { useSnackbar } from 'notistack'

// import * as chartsCore from '@amcharts/amcharts4/core'
// import * as amCharts from '@amcharts/amcharts4/charts'

// chartsCore.useTheme(am4themes_frozen)
// chartsCore.useTheme(am4themes_animated)

// stile CSS generale
const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    maxHeight: '100%',
    width: '100%'
  },
  disserviceGraph: {
    width: '100%',
    height: '100%',
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 16,
    zIndex: 1000
  },
  glassBackground: {
    '@supports (backdrop-filter: none) or (-webkit-backdrop-filter: none)': {
      '-webkit-backdrop-filter': 'saturate(120%) blur(16px)',
      backdropFilter: 'saturate(190%) blur(16px)',
      backgroundColor: 'rgba(16, 26, 38, 0.05)'
    }
  }
}))

// variabile con il format per il periodo
const periodsUnit = {
  live: 'hour',
  week: 'day',
  month: 'day',
  year: 'month'
}
const periodsFormat = {
  live: 'HH:mm',
  week: 'dd/MM',
  month: 'dd/MM',
  year: 'MM/yy'
}

const chartId = 'distribution-disservice-hours-chart'

// componente principale
const TimeDistributionGraphView = ({
  hasGlass = false,
  noCard = false,
  width = null,
  height = null,
  className,
  timeDistribution: distributionData,
  disserviceHours: disserviceData,
  avgHoursGraph: avgData,
  anomaliesCategoryStatus,
  anomaliesPeriod,
  selectedDate,
  ...rest
}) => {
  // const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const refDate = selectedDate ? moment(selectedDate) : moment()
  // variabile che specifica il livello di severity nella Y
  // Prendo le istanze di core e am4Charts disponibili nel context
  const { chartsCore, amCharts } = useCharts()
  // Creo una ref del grafico
  const chartRef = useRef(null)
  // Variabile che determina la prima renderizzazione a schermo
  const [firstPaint, setFirstPaint] = useState(true)
  // log({ text: 'distributionData => ', variable: distributionData, tag: 'TimeDistributionGraphView' })
  // log({ text: 'disserviceHours => ', variable: disserviceData, tag: 'TimeDistributionGraphView' })
  // log({ text: 'avgHoursGraph => ', variable: avgData, tag: 'TimeDistributionGraphView' })
  // log({ text: 'anomaliesCategoryStatus =>', variable: anomaliesCategoryStatus, tag: 'TimeDistributionGraphView' })

  // variabili d'appoggio per i dati
  let barData = []
  let bubbleData = []

  // PREPARE DATA
  const handleData = (distributionData, disserviceData, avgData, categoryStatus, refDate, anomaliesPeriod) => {
    // pulisco le variabili
    barData = []
    bubbleData = []
    // mi sistemo i dati
    categoryStatus.forEach((cat, catIndex) => {
      cat.subCategory.forEach((subCat, subCatIndex) => {
        // prendo i dati delle severity, 5 severity per ogni categoria
        let thisHours = 0
        let thisSubHours = 0
        let thisAvgHours = 0
        const thisHoursData = disserviceData.find(data => data.category === cat.name && data.subCategory === subCat.name)
        if (thisHoursData?.value) {
          thisHours = moment.duration(Number(thisHoursData.value)).asHours().toFixed(0)
        }
        const thisAvgHoursData = avgData.find(data => data.category === cat.name && data.subCategory === subCat.name)
        if (thisAvgHoursData?.value) thisAvgHours = moment.duration(Number(thisAvgHoursData.value)).asHours().toFixed(0)
        if (thisAvgHoursData?.value && thisHoursData?.value) thisSubHours = moment.duration(Number(thisHoursData.value) - Number(thisAvgHoursData.value)).asHours().toFixed(0)

        // mi preparo gli array
        const thisCatArray = []
        for (let i = 1; i < 6; i++) {
          thisCatArray.push(
            {
              axis: `${i}-${cat.name}-${subCat.name}`,
              severity: i,
              category: cat.name,
              subCategory: subCat.name,
              color: subCat.color,
              catLabel: cat.label,
              label: subCat.label,
              catTotalAnomalies: subCat?.value || '-',
              hours: Number(thisHours),
              subHours: Number(thisSubHours),
              avgHours: Number(thisAvgHours)
            }
          )
        }
        barData.push(...thisCatArray)

        // mi preparo i dati per il bubble
        const thisBubble = distributionData.find(data => data.category === cat.name && data.name === subCat.name)
        if (thisBubble) {
          thisBubble.data.forEach(bubble => {
            bubbleData.push({
              day: new Date(bubble.x),
              axis: `${bubble.y}-${cat.name}-${subCat.name}`,
              count: bubble.z || 0,
              readableDay: moment(bubble.x).format('DD/MM/YY HH:mm'),
              severity: bubble.y,
              category: cat.name,
              subCategory: subCat.name,
              color: subCat.color
            })
          })
        }
      })
    })
  }

  // funzione che crea l'asse delle severity
  const createSeverityAxes = (chartInstance, categoryStatus) => {
    // const dateAxis = chart.xAxes.push(new amCharts.DateAxis()) // crea un asse temporale
    const severityAxis = chartInstance.xAxes.push(new amCharts.CategoryAxis()) // crea un asse temporale
    // dateAxis.baseInterval = { timeUnit: 'week', count: 1 } // specifica che i dati nella serie sono settimanali a distanza di una settimana
    severityAxis.dataFields.category = 'axis'
    severityAxis.renderer.innerRadius = chartsCore.percent(40)
    severityAxis.renderer.minGridDistance = 5
    severityAxis.renderer.grid.template.strokeOpacity = 0.1
    severityAxis.renderer.grid.template.stroke = chartsCore.color('#efefef')
    severityAxis.renderer.labels.template.relativeRotation = 0
    severityAxis.renderer.labels.template.location = 0.5
    severityAxis.renderer.labels.template.radius = chartsCore.percent(-57)
    severityAxis.renderer.labels.template.fontSize = '8px'
    severityAxis.renderer.labels.template.fill = chartsCore.color('#ffffff') // setto le label bianche
    severityAxis.renderer.labels.template.adapter.add('textOutput', (text) => {
      return text && Number(text.charAt(0)) > 0 && Number(text.charAt(0)) < 6 ? text.charAt(0) : text
    })
    // severityAxis.dateFormats.setKey('week', 'w') // dateFormats.setKey(period, format) -> specifica come formattare la label
    // severityAxis.periodChangeDateFormats.setKey('week', 'w') // stessa cosa di sopra ma vale quando cambia il periodo (passa al mese dopo ecc)
    severityAxis.cursorTooltipEnabled = false

    // add categories ranges
    categoryStatus.forEach((cat, catIndex) => {
      cat.subCategory.forEach((subCat, subCatIndex) => {
        const range = severityAxis.axisRanges.create()
        range.category = `1-${cat.name}-${subCat.name}`
        range.endCategory = `5-${cat.name}-${subCat.name}`
        range.axisFill.fillOpacity = 0.9
        range.axisFill.radius = -28
        range.axisFill.adapter.add('innerRadius', function (innerRadius, target) {
          return severityAxis.renderer.pixelRadius + 7
        })
        range.axisFill.fill = chartsCore.color(subCat.color)
        range.axisFill.stroke = chartsCore.color(subCat.color)
        range.grid.disabled = true
        range.label.text = `${subCat.label} - ${subCat?.value || '-'}`
        range.label.fill = getContrastColor(subCat.color, true)
        range.label.bent = true
        range.label.radius = 10
        range.label.fontSize = 11
        range.label.fontWeight = 600
        range.label.paddingBottom = 4
        range.label.interactionsEnabled = false
        range.axisFill.interactionsEnabled = true
        range.axisFill.cursorOverStyle = chartsCore.MouseCursorStyle.pointer
        range.axisFill.events.on('hit', function (event) {
          if (severityAxis.start === 0 && severityAxis.end === 1) {
            // console.log('event.target.dataItem.category =>', event.target.dataItem.category)
            // console.log('event.target.dataItem.endCategory =>', event.target.dataItem.endCategory)
            severityAxis.zoomToCategories(event.target.dataItem.category, event.target.dataItem.endCategory)
            // dateAxis.zoomToDates(event.target.dataItem.category, event.target.dataItem.endCategory)
          } else {
            severityAxis.zoom({ start: 0, end: 1 })
          }
        })
      })
    })
  }

  // funzione che crea l'asse circolare del bar chart
  const createCircularAxes = (chartInstance) => {
    // crea asse circolare sul grafico a barre
    const circularAxis = chartInstance.yAxes.push(new amCharts.ValueAxis())
    circularAxis.min = 0
    // valueAxis.max = true
    circularAxis.strictMinMax = true
    circularAxis.renderer.grid.template.strokeOpacity = 0.1
    circularAxis.renderer.grid.template.stroke = chartsCore.color('#efefef')
    circularAxis.renderer.inversed = true
    circularAxis.renderer.radius = chartsCore.percent(40)
    circularAxis.renderer.minGridDistance = 15
    circularAxis.renderer.minLabelPosition = 0.05
    circularAxis.renderer.axisAngle = 90
    circularAxis.cursorTooltipEnabled = false
    circularAxis.renderer.labels.template.fill = chartsCore.color('#ffffff')
  }

  // funzione che specifica la serie a barre
  const createBarSeries = (chartInstance) => {
    // stacked avg
    // const barStackedSeries = chartInstance.series.push(new amCharts.RadarColumnSeries())
    // barStackedSeries.dataFields.categoryX = 'axis' // specifica asse X nella serie
    // barStackedSeries.dataFields.valueY = 'avgHours' // specifica asse Y nella serie
    // barStackedSeries.stacked = false
    // barStackedSeries.columns.template.strokeOpacity = 0
    // barStackedSeries.columns.template.width = chartsCore.percent(100)
    // barStackedSeries.fill = chartsCore.color('#b735e2')
    // barStackedSeries.fillOpacity = 0.8
    // barStackedSeries.tooltip.fontSize = 10
    // barStackedSeries.tooltip.pointerOrientation = 'down'
    // barStackedSeries.tooltip.background.fillOpacity = 0.8
    // barStackedSeries.columns.template.tooltipText = '[bold]{catLabel} - {label}\n[font-size:13px]Media ore nel periodo: {avgHours}'
    // barStackedSeries.cursorTooltipEnabled = false
    // barStackedSeries.columns.template.adapter.add('fill', function (fill, target) {
    //   // console.log('target.dataItem.dataContext => ', target.dataItem.dataContext)
    //   return target.dataItem?.dataContext?.color ? chartsCore.color(target.dataItem.dataContext.color) : fill
    // })

    // specify BAR series
    const barSeries = chartInstance.series.push(new amCharts.RadarColumnSeries())
    barSeries.dataFields.categoryX = 'axis' // specifica asse X nella serie
    barSeries.dataFields.valueY = 'hours' // specifica asse Y nella serie
    // barSeries.stacked = true
    barSeries.columns.template.strokeOpacity = 0
    barSeries.columns.template.width = chartsCore.percent(100)
    barSeries.fill = chartsCore.color('#f6ffff')
    barSeries.fillOpacity = 0.8
    barSeries.tooltip.fontSize = 10
    barSeries.tooltip.pointerOrientation = 'down'
    barSeries.tooltip.background.fillOpacity = 0.8
    barSeries.columns.template.tooltipText = '[bold]{catLabel} - {label}\n[font-size:13px]Totale ore nel periodo: {hours}\nMedia ore nel periodo: {avgHours}'
    barSeries.cursorTooltipEnabled = false
    barSeries.columns.template.adapter.add('fill', function (fill, target) {
      // console.log('target.dataItem.dataContext => ', target.dataItem.dataContext)
      return target.dataItem?.dataContext?.color ? chartsCore.color(target.dataItem.dataContext.color) : fill
    })
  }

  // funzione che crea asse per il grafico a bubble
  const createBubbleAxisAndSeries = (chartInstance) => {
    // asse giorni settimanali + dati grafico a bolla
    const bubbleAxis = chartInstance.yAxes.push(new amCharts.DateAxis())
    bubbleAxis.baseInterval = { timeUnit: periodsUnit[anomaliesPeriod], count: 1 }
    bubbleAxis.data = bubbleData
    bubbleAxis.renderer.grid.template.strokeOpacity = 0.1
    bubbleAxis.renderer.grid.template.stroke = chartsCore.color('#efefef')
    bubbleAxis.renderer.innerRadius = chartsCore.percent(50)
    bubbleAxis.renderer.minGridDistance = 10
    bubbleAxis.renderer.grid.template.location = 0
    bubbleAxis.renderer.line.disabled = true
    bubbleAxis.renderer.axisAngle = 90
    bubbleAxis.cursorTooltipEnabled = false
    bubbleAxis.renderer.labels.template.fill = chartsCore.color('#ffffff')
    bubbleAxis.dateFormats.setKey(periodsUnit[anomaliesPeriod], periodsFormat[anomaliesPeriod]) // dateFormats.setKey(period, format) -> specifica come formattare
    bubbleAxis.periodChangeDateFormats.setKey(periodsUnit[anomaliesPeriod], periodsFormat[anomaliesPeriod]) // stessa cosa di sopra ma vale quando cambia il periodo (passa al mese dopo ecc)

    // bubble series
    const bubbleSeries = chartInstance.series.push(new amCharts.RadarSeries())
    bubbleSeries.dataFields.categoryX = 'axis' // questa è la X
    bubbleSeries.dataFields.dateY = 'day' // questa è la Y
    bubbleSeries.dataFields.value = 'count' // questa è la Z del cerchio
    bubbleSeries.yAxis = bubbleAxis // mette l'asso Y
    bubbleSeries.data = bubbleData // specifica (di nuovo?) i dati a bolla
    bubbleSeries.strokeOpacity = 0 // linee nascoste sotto ad i pallini
    bubbleSeries.maskBullets = false
    bubbleSeries.cursorTooltipEnabled = false
    bubbleSeries.tooltip.fontSize = 10
    bubbleSeries.tooltip.pointerOrientation = 'down'
    bubbleSeries.tooltip.background.fillOpacity = 0.9

    // // grafico a bolle
    const bubbleBullet = bubbleSeries.bullets.push(new amCharts.CircleBullet())
    bubbleBullet.locationX = 0.5
    bubbleBullet.locationY = 0.5
    // bubbleBullet.stroke = chartsCore.color('#b9ce37')
    // bubbleBullet.fill = chartsCore.color('#b9ce37')
    bubbleBullet.tooltipText = '[font-size:11px bold]{readableDay}[/]\n[font-size:11px]{category} - {subCategory}[/]\n[font-size:13px]Anomalie severity {severity}: [font-size:13px bold]{count}'
    bubbleBullet.adapter.add('tooltipY', function (tooltipY, target) { return -target.circle.radius })
    bubbleBullet.adapter.add('fill', function (fill, target) {
      // console.log('target.dataItem.dataContext => ', target.dataItem.dataContext)
      return target.dataItem?.dataContext?.color ? chartsCore.color(target.dataItem.dataContext.color) : fill
    })
    bubbleBullet.adapter.add('stroke', function (fill, target) {
      // console.log('target.dataItem.dataContext => ', target.dataItem.dataContext)
      return target.dataItem?.dataContext?.color ? chartsCore.color(target.dataItem.dataContext.color) : fill
    })

    bubbleSeries.heatRules.push({ target: bubbleBullet.circle, min: 4, max: 12, dataField: 'value', property: 'radius' })
    bubbleSeries.dataItems.template.locations.dateY = 0.5
    bubbleSeries.dataItems.template.locations.categoryX = 0.5
  }

  // setto il grafico al cambio dei dati
  useEffect(() => {
    if (distributionData.length > 0 && distributionData[0] !== '-') {
      // Preparo dati e creo grafico
      const chart = chartsCore.create(chartId, amCharts.RadarChart)
      if (!firstPaint) {
        chart.showOnInit = false
      }
      handleData(distributionData, disserviceData, avgData, anomaliesCategoryStatus, refDate, anomaliesPeriod)
      // log({ text: 'barData =>', variable: barData, tag: 'TimeDistributionGraphView' })
      // log({ text: 'bubbleData =>', variable: bubbleData, tag: 'TimeDistributionGraphView' })
      // base del chart
      chart.innerRadius = chartsCore.percent(15)
      chart.radius = chartsCore.percent(90)
      chart.data = barData // dati per il grafico a base (quello a barre)
      chart.fontSize = '11px'
      chart.startAngle = 102
      chart.endAngle = chart.startAngle + 335 // la fine dell'angolo indica dove finisce tutto il cerchio
      // creo l'asse per le severity
      createSeverityAxes(chart, anomaliesCategoryStatus)
      // creo l'asse dei dati a barre
      createCircularAxes(chart)
      // creo la serie per i dati a barre
      createBarSeries(chart)
      // creo l'asse per i dati bubble
      createBubbleAxisAndSeries(chart)

      // setto il cursor
      chart.cursor = new amCharts.RadarCursor()
      chart.cursor.innerRadius = chartsCore.percent(40)
      chart.cursor.lineY.disabled = true
      // setto la label
      const label = chart.radarContainer.createChild(chartsCore.Label)
      label.horizontalCenter = 'middle'
      label.verticalCenter = 'middle'
      label.fill = chartsCore.color('#ffffff')
      label.fontSize = 10
      label.fontWeight = 'bold'
      label.text = 'TOTALE\nORE NEL\nPERIODO'

      // mi setto il ref per distruggerlo quando si smonta il componente
      chartRef.current = chart
      if (firstPaint) {
        setFirstPaint(false)
      }
      return () => {
        chartRef.current.dispose()
      }
    }
  }, [distributionData, disserviceData, anomaliesCategoryStatus])

  // ritorno il grafico
  return distributionData[0] !== '-'
    ? noCard
        ? (
          <div
            id={chartId}
            className={clsx(classes.root, className)}
            {...rest}
          />
          )
        : (
          <Card elevation={hasGlass ? 0 : 1} className={hasGlass ? clsx(classes.root, classes.glassBackground, className) : clsx(classes.root, className)} {...rest}>
            <CardHeader
              title='Distribuzione per categoria nel periodo'
              style={{ paddingBottom: 6 }}
            />
            <Box display='flex' alignItems='center' justifyContent='center' height='Calc(100% - 43px)'>
              <div
                id={chartId}
                className={classes.disserviceGraph}
              />
            </Box>
          </Card>)
    : <LoadingCard glass={hasGlass} />
}

TimeDistributionGraphView.propTypes = {
  className: PropTypes.string,
  anomaliesPeriod: PropTypes.string,
  selectedDate: PropTypes.object,
  timeDistribution: PropTypes.array,
  disserviceHours: PropTypes.array,
  avgHoursGraph: PropTypes.array,
  anomaliesCategoryStatus: PropTypes.array
  // setRefreshUsers: PropTypes.func
}

TimeDistributionGraphView.defaultProps = {
  anomaliesPeriod: 'live',
  selectedDate: null,
  timeDistribution: [],
  disserviceHours: [],
  avgHoursGraph: [],
  anomaliesCategoryStatus: []
}

export default memo(TimeDistributionGraphView)
