import log from '@pelv/frontlog'
import { createContext, useEffect, useReducer } from 'react'
import SplashScreen from '@components/SplashScreen'
import { assetsManagerPermissions } from '@/config'
import api from '@micmnt/apis'

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  currentProject: null
}

const newBasicRole = [
  'assetsmanager.plants.access',
  'assetsmanager.plants.manage',
  'assetsmanager.nodes.manage',
  'assetsmanager.reports.board',
  'assetsmanager.users.manage',
  'assetsmanager.roles.manage',
  'assetsmanager.alarms.components',
  'assetsmanager.alarms.performances',
  'assetsmanager.alarms.communication'
]

const setSession = (accessToken) => {
  if (accessToken) {
    window.localStorage.setItem('accessToken', accessToken)
  } else {
    window.localStorage.removeItem('accessToken')
    window.localStorage.removeItem('venera-lastProject')
  }
}

// funzione che prende in ingresso la lista di progetti e verifica se esiste un progetto già visitato, altrimenti lo setta e ritorna il progetto in ingress
const setProject = (projectsList) => {
  let currentProject = null
  if (projectsList) {
    // Verifico se nel local storage è presente un progetto
    const lastProject = window.localStorage.getItem('venera-lastProject')
    // Se è presente, lo carico come progetto attuale nel Context
    if (lastProject) {
      const parsedLastProject = JSON.parse(lastProject)
      currentProject = projectsList.find(project => project.uuid === parsedLastProject.uuid) || projectsList[0] || null
      // Altrimenti prendo il primo progetto dalla lista e lo setto sia nel Context che nel local storage
    } else {
      const firstProject = projectsList[0] || null
      if (firstProject) {
        window.localStorage.setItem('venera-lastProject', JSON.stringify(firstProject))
        currentProject = firstProject
      }
    }
  }

  return currentProject
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user, currentProject } = action.payload

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        currentProject
      }
    }
    case 'LOGIN': {
      const { user, currentProject } = action.payload

      return {
        ...state,
        isAuthenticated: true,
        user,
        currentProject
      }
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        currentProject: null
      }
    }
    case 'REGISTER': {
      const { user } = action.payload

      return {
        ...state,
        isAuthenticated: true,
        user
      }
    }
    case 'UPDATE_USER': {
      const { newEdits } = action.payload

      return {
        ...state,
        user: {
          ...state.user,
          ...newEdits
        }
      }
    }
    case 'UPDATE_USER_IMAGE': {
      const { newUrl } = action.payload

      return {
        ...state,
        user: {
          ...state.user,
          metadata: {
            ...state.user.metadata,
            imageURL: newUrl
          }
        }
      }
    }
    case 'UPDATE_USER_ZONES': {
      const { zones } = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          metadata: {
            ...state.user.metadata,
            zones
          }
        }
      }
    }
    case 'UPDATE_USER_ANALYTICS_FILTERS': {
      const { filters } = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          metadata: {
            ...state.user.metadata,
            analyticsFilters: filters
          }
        }
      }
    }
    case 'UPDATE_USER_FILTERS': {
      const { filters } = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          metadata: {
            ...state.user.metadata,
            userFilters: filters
          }
        }
      }
    }
    case 'UPDATE_REPORT_EMAIL': {
      const { emails } = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          metadata: {
            ...state.user.metadata,
            reportEmails: emails
          }
        }
      }
    }
    case 'UPDATE_CURRENT_PROJECT': {
      const { project } = action.payload
      return {
        ...state,
        currentProject: project
      }
    }
    default: {
      return { ...state }
    }
  }
}

const AuthContext = createContext({
  ...initialAuthState,
  allUserPermissions: assetsManagerPermissions,
  newBasicRole: newBasicRole,
  method: 'JWT',
  login: () => Promise.resolve(),
  partnerLogin: () => Promise.resolve(),
  logout: () => Promise.resolve()
})

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState)

  const login = async (email, password) => {
    const { data: response } = await api.post({ savedUrl: 'accounts', path: '/authenticate', body: { email, password } })
    const { token, account, roles, groups, projects } = response

    const currentProject = setProject(projects)

    account.groups = groups || []
    if (!account.metadata.reportEmails) {
      account.metadata.reportEmails = [account.email]
    }
    if (!account.mustConfirmEmailAddress) {
      account.mustConfirmEmailAddress = false
    }

    if (currentProject) {
      api.updatePlaceholders({ projectId: `/projects/${currentProject.uuid}` })

      account.role = roles.find(role => role.projectId === currentProject.uuid) || {}
      account.appPermissions = account?.role?.metadata?.applicationPermissions ? account.role.metadata.applicationPermissions : []
      // sistemo il project
      account.projects = projects
      const thisProject = projects.find(project => project.uuid === currentProject.uuid)
      account.features = thisProject?.features || []
      account.projectPlantTypes = account.features.find(feature => feature.name === 'availablePlantTypes')?.configuration?.plantTypes || []
    }

    setSession(token)

    dispatch({
      type: 'LOGIN',
      payload: {
        user: account,
        currentProject
      }
    })
  }

  const partnerLogin = async (response) => {
    const { token, account, roles, groups, projects } = response

    const currentProject = setProject(projects)

    api.updatePlaceholders({ projectId: `/projects/${currentProject.uuid}` })

    account.role = roles.find(role => role.projectId === currentProject.uuid) || {}
    account.appPermissions = account?.role?.metadata?.applicationPermissions ? account.role.metadata.applicationPermissions : []
    account.groups = groups || []
    if (!account.metadata.reportEmails) {
      account.metadata.reportEmails = [account.email]
    }
    if (!account.mustConfirmEmailAddress) {
      account.mustConfirmEmailAddress = false
    }

    // sistemo il project
    account.projects = projects
    const thisProject = projects.find(project => project.uuid === currentProject.uuid)
    account.features = thisProject?.features || []
    account.projectPlantTypes = account.features.find(feature => feature.name === 'availablePlantTypes')?.configuration?.plantTypes || []

    setSession(token)

    dispatch({
      type: 'LOGIN',
      payload: {
        user: account,
        currentProject
      }
    })
  }

  const updateZones = (zones) => {
    dispatch({
      type: 'UPDATE_USER_ZONES',
      payload: {
        zones
      }
    })
  }

  const updateFilters = (customFilters) => {
    dispatch({
      type: 'UPDATE_USER_FILTERS',
      payload: {
        filters: customFilters
      }
    })
  }

  const updateAnalyticsFilters = (customFilters) => {
    dispatch({
      type: 'UPDATE_USER_ANALYTICS_FILTERS',
      payload: {
        filters: customFilters
      }
    })
  }

  const updateReportEmails = (emails) => {
    dispatch({
      type: 'UPDATE_REPORT_EMAIL',
      payload: {
        emails: emails
      }
    })
  }

  const logout = async () => {
    const { error } = await api.post({ savedUrl: 'logout' })
    if (error) {
      console.error('Error while loggin out, err => ', error)
      return
    }

    setSession(null)
    dispatch({ type: 'LOGOUT' })
  }

  // funzione che aggiorna l'immagine dello user salvato
  const updateLocalUserImage = (newUrl) => {
    log({ text: 'update image, newUrl => ', variable: newUrl, tag: 'authcontext' })
    dispatch({
      type: 'UPDATE_USER_IMAGE',
      payload: {
        newUrl: newUrl || null
      }
    })
  }

  // funzione che in caso di progetto modificato, aggiorna i valori del context e del local storage
  const updateCurrentProject = (project = null) => {
    if (project?.uuid !== state.currentProject?.uuid) {
      window.localStorage.setItem('venera-lastProject', JSON.stringify(project))
      window.location.assign(window.location.origin)
    }
  }

  // funzione che aggiorna il nome dello user salvato
  const updateLocalUser = (newEdits) => {
    log({ text: 'update nome, newName => ', variable: newEdits, tag: 'authcontext' })
    dispatch({
      type: 'UPDATE_USER',
      payload: {
        newEdits: newEdits || null
      }
    })
  }

  useEffect(() => {
    const initialise = async () => {
      const accessToken = window.localStorage.getItem('accessToken')

      if (accessToken) {
        setSession(accessToken)

        const userParams = {
          include: 'roles,groups,aclrules,groupmemberships,projects'
        }
        const { data: response, error } = await api.get({ savedUrl: 'accounts', path: '/self', params: userParams, fullResponse: true })

        if (error) {
          console.error(error)
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          })
          return
        }

        const account = response.data.data
        // Progetto attualmente selezionato da mostrare all'utente
        const currentProject = setProject(account?.includes?.projects)

        account.acl = account.includes.aclrules || []
        account.groups = account.includes.groups || []
        account.groupmemberships = account.includes.groupmemberships || []
        if (!account.metadata.reportEmails) {
          account.metadata.reportEmails = [account.email]
        }
        if (!account.mustConfirmEmailAddress) {
          account.mustConfirmEmailAddress = false
        }
        log({ text: 'account self => ', variable: account, tag: 'authcontext' })

        if (currentProject) {
          account.role = account.includes.roles.find(role => role.projectId === currentProject?.uuid) || {}
          account.appPermissions = account?.role?.metadata?.applicationPermissions ? account.role.metadata.applicationPermissions : []
          // Inizializzo il servizio delle api con il projectID corretto
          api.updatePlaceholders({ projectId: `/projects/${currentProject.uuid}` })

          // sistemo il project
          account.projects = account.includes.projects
          const thisProject = account.includes.projects.find(project => project.uuid === currentProject?.uuid)
          account.features = thisProject?.features || []
          account.projectPlantTypes = account.features.find(feature => feature.name === 'availablePlantTypes')?.configuration?.plantTypes || []
        }

        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: true,
            user: account,
            currentProject
          }
        })
      } else {
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
            currentProject: null
          }
        })
      }
    }

    initialise()
  }, [])

  if (!state.isInitialised) {
    return <SplashScreen />
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        allUserPermissions: assetsManagerPermissions,
        newBasicRole: newBasicRole,
        method: 'JWT',
        login,
        partnerLogin,
        logout,
        updateFilters,
        updateAnalyticsFilters,
        updateReportEmails,
        updateZones,
        updateLocalUserImage,
        updateCurrentProject,
        updateLocalUser
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
