const track = (...args) => {
  if (import.meta.env.MODE !== 'production') {
    return
  }

  if (!window.gtag) {
    return
  }

  window.gtag(...args)
}

const pageview = (props) => {
  track('config', import.meta.env.VITE_GA_MEASUREMENT_ID, props);
}

const event = (type, props) => {
  track('event', type, props)
}

export default {
  pageview,
  event
}
