import clsx from 'clsx'
import { Box, Grid, makeStyles, SvgIcon, TextField, Tooltip, Typography } from '@material-ui/core'
import { Formik } from 'formik'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'
import { useSelector } from '@/store'
import { useDispatch } from 'react-redux'
import { changeContractValue, setBaseline } from '@slices/pv/plantCreation'
import BaselineTable from './BaselineTable'
import { Info as InfoIcon } from 'react-feather'

const useStyles = makeStyles(theme => ({
  root: {},
  stepDescription: {
    color: theme.palette.text.secondary
  }
}))

function PlantContract ({ className, ...rest }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { baseline, baselineConfig, startDate, contractDuration, endDate } = useSelector(state => state.pvPlantCreation)

  // console.log('store - startDate: ', startDate)

  const handleChange = (dateOrEvent, name) => {
    const value =
      name === 'startDate' ||
        name === 'endDate' ||
        name === 'years' ||
        name === 'percentageLoss' ||
        name === 'percentageDeviation'
        ? dateOrEvent
        : dateOrEvent.target.value

    // console.log('handleChange: ', value)
    dispatch(changeContractValue(value, name))
  }

  // Funzione che imposta la data di inizio in caso fosse vuota
  const handleEmptyStartDate = () => {
    const newStartDate = moment().format('DD/MM/YYYY')
    handleChange(newStartDate, 'startDate')
  }

  return (
    <>
      <Formik>
        {({
          errors,
          touched
        }) => (
          <form
            noValidate
            {...rest}
            className={clsx(classes.root, className)}
          >
            <Box my={3}>
              <Box mb={3}>
                <Typography className={classes.stepDescription} variant='body2'>
                  Riempi le seguenti informazioni per continuare
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Box display='flex' alignItems='center'>
                    <Tooltip placement='top' title='La data di attivazione e la durata del contratto sono informazioni necessarie per il calcolo della baseline'>
                      <SvgIcon style={{ marginRight: 16 }} fontSize='small'>
                        <InfoIcon />
                      </SvgIcon>
                    </Tooltip>
                    <DatePicker
                      format='DD/MM/YYYY'
                      label='Data di Attivazione'
                      value={startDate ? moment(startDate.split('/').reverse().join('-')) : handleEmptyStartDate()}
                      onChange={date => {
                        // Quando mi trovo a dover cambiare la data iniziale, devo esclusivamente aggiornare la data finale in base al numero di anni di contratto
                        handleChange(date.format('DD/MM/YYYY'), 'startDate')
                        if (startDate && contractDuration !== '') {
                          // Setto la nuova data di fine contratto
                          const formattedStartDate = date.format('DD/MM/YYYY').split('/').reverse().join('-')
                          const endDate = moment(formattedStartDate).add(parseInt(contractDuration), 'y')
                          const formattedEndDate = endDate.format('DD/MM/YYYY')
                          handleChange(formattedEndDate, 'endDate')
                        }
                      }}
                      error={Boolean(touched.startDate && errors.startDate)}
                      fullWidth
                      size='small'
                      name='startDate'
                      inputVariant='outlined'
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    error={Boolean(touched.contractDuration && errors.contractDuration)}
                    fullWidth
                    size='small'
                    type='number'
                    inputProps={{
                      min: '1',
                      max: '50'
                    }}
                    name='contractDuration'
                    helperText={touched.contractDuration && errors.contractDuration}
                    label='Durata Contratto'
                    value={contractDuration}
                    onChange={event => {
                      if (event.target.value > 0 && event.target.value < 51) {
                        handleChange(event, 'contractDuration')
                        if (event.target.value !== '') {
                          // Setto la nuova data di fine contratto
                          const formattedStartDate = startDate ? startDate.split('/').reverse().join('-') : moment()
                          const endDate = moment(formattedStartDate).add(parseInt(event.target.value), 'y').subtract(1, 'd')
                          const formattedEndDate = endDate.format('DD/MM/YYYY')
                          handleChange(formattedEndDate, 'endDate')
                          // Ricalcolo il numero di anni della baseline
                          // Durata contratto in anni
                          const currentYears = parseInt(event.target.value)
                          let newPercentageLoss = []
                          let newMonths = []
                          // Se gli anni di contratto sono minori o uguali di quelli precedentemente impostati, rimuovo quelli successivi alla fine del contratto
                          if (currentYears <= baseline.percentageLoss.length) {
                            newPercentageLoss = baseline.percentageLoss.filter((el, index) => index < currentYears)
                            newMonths = baseline.months.map(singleMonth => {
                              const newProducibility = singleMonth.producibility.filter((el, index) => index < currentYears)
                              return ({
                                ...singleMonth,
                                producibility: newProducibility
                              })
                            })
                            // Altrimenti, se gli anni di contratto sono di più di quelli precendetemente impostati, li aggiungo
                          } else {
                            newPercentageLoss = [...baseline.percentageLoss]
                            for (let i = 0; i < (currentYears - baseline.percentageLoss.length); i++) {
                              newPercentageLoss.push(0)
                            }
                            newMonths = baseline.months.map((singleMonth, monthIndex) => {
                              const newProducibility = [...singleMonth.producibility]
                              for (let i = 0; i < (currentYears - singleMonth.producibility.length); i++) {
                                const producibilityConfigValue = monthIndex < baselineConfig.length ? baselineConfig[monthIndex].producibility : 0
                                newProducibility.push(Number(producibilityConfigValue))
                              }
                              return ({
                                ...singleMonth,
                                producibility: newProducibility
                              })
                            })
                          }

                          const newBaseline = {
                            percentageLoss: newPercentageLoss,
                            months: newMonths
                          }
                          dispatch(setBaseline(newBaseline))
                        }
                      }
                    }}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    error={Boolean(touched.endDate && errors.endDate)}
                    fullWidth
                    name='endDate'
                    disabled
                    size='small'
                    helperText={touched.endDate && errors.endDate}
                    label='Data fine Contratto'
                    value={endDate
                      ? endDate.includes('/')
                          ? endDate
                          : moment(endDate.split('/').join('-')).format('DD/MM/YYYY')
                      : moment().add(parseInt(contractDuration), 'y').subtract(1, 'd').format('DD/MM/YYYY')}
                    variant='outlined'
                  />
                </Grid>
              </Grid>
              <Box my={2}>
                <Typography variant='body2'>
                  Baseline
                </Typography>
              </Box>
              <BaselineTable baselineConfig={baselineConfig} baseline={baseline} />
            </Box>
          </form>
        )}
      </Formik>
    </>
  )
}
export default PlantContract
