import { useState } from 'react'
import clsx from 'clsx'
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, InputAdornment, makeStyles, SvgIcon, TextField, Tooltip, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Cancel'
import InfoIcon from '@material-ui/icons/Info'

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 99999999
  }
}))

function OrientationDialog ({ orientationToModify, setOrientationToModify, handleModifyOrientation, handleNewOrientation, open, onClose, className }) {
  const classes = useStyles()

  const [orientationName, setOrientationName] = useState('')
  const [azimut, setAzimut] = useState('')
  const [tilt, setTilt] = useState('')
  const [height, setHeight] = useState('')
  const [peakPower, setPeakPower] = useState('')

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      className={clsx(classes.root, className)}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography component='div' variant='h4'>
            Imposta le caratteristiche dell&apos;orientamento
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                size='small'
                variant='outlined'
                name='orientationName'
                fullWidth
                label='Nome Orientamento'
                value={orientationToModify ? orientationToModify.name : orientationName}
                onChange={e => {
                  e.persist()
                  orientationToModify
                    ? setOrientationToModify(prevOr => ({
                        ...prevOr,
                        name: e.target.value
                      }))
                    : setOrientationName(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size='small'
                variant='outlined'
                name='azimut'
                fullWidth
                type='number'
                label="Azimut - orientamento rispetto all'asse sud (deg)"
                value={orientationToModify ? orientationToModify.azimut : azimut}
                onChange={e => {
                  e.persist()
                  orientationToModify
                    ? setOrientationToModify(prevOr => ({
                        ...prevOr,
                        azimut: Number(e.target.value)
                      }))
                    : setAzimut(Number(e.target.value))
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size='small'
                variant='outlined'
                name='tilt'
                fullWidth
                type='number'
                label="Tilt - angolo rispetto all'orizzonte (deg)"
                value={orientationToModify ? orientationToModify.tilt : tilt}
                onChange={e => {
                  e.persist()
                  orientationToModify
                    ? setOrientationToModify(prevOr => ({
                        ...prevOr,
                        tilt: Number(e.target.value)
                      }))
                    : setTilt(Number(e.target.value))
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size='small'
                variant='outlined'
                name='height'
                fullWidth
                type='number'
                label='Altezza solare (m)'
                value={orientationToModify ? orientationToModify.height : height}
                onChange={e => {
                  e.persist()
                  orientationToModify
                    ? setOrientationToModify(prevOr => ({
                        ...prevOr,
                        height: Number(e.target.value)
                      }))
                    : setHeight(Number(e.target.value))
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size='small'
                variant='outlined'
                name='peakPower'
                fullWidth
                type='number'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Tooltip title='Inserendo qui il valore della potenza di picco, quella calcolata dalla distribuzione dei generatori verrà sovrascritta'>
                        <SvgIcon fontSize='small' color='action'>
                          <InfoIcon />
                        </SvgIcon>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
                label='Potenza di picco (kWp)'
                value={orientationToModify ? orientationToModify.peakPower : peakPower}
                onChange={e => {
                  e.persist()
                  orientationToModify
                    ? setOrientationToModify(prevOr => ({
                        ...prevOr,
                        peakPower: Number(e.target.value)
                      }))
                    : setPeakPower(Number(e.target.value))
                }}
              />
            </Grid>
          </Grid>
          <Box mt={2} mb={1} width='100%'>
            <Button
              disabled={orientationToModify
                ? !(orientationToModify.name && orientationToModify.name !== '')
                : !(orientationName && orientationName !== '')}
              onClick={orientationToModify
                ? handleModifyOrientation
                : () => {
                    handleNewOrientation({
                      name: orientationName,
                      azimut,
                      tilt,
                      height,
                      peakPower
                    })
                    setOrientationName('')
                    setAzimut('')
                    setTilt('')
                    setHeight('')
                    setPeakPower('')
                  }}
              fullWidth
              variant='contained'
              color='primary'
            >
              {orientationToModify ? 'Modifica Orientamento' : 'Aggiungi Orientamento'}
            </Button>
          </Box>
        </div>
      </DialogContent>
    </Dialog>
  )
}
export default OrientationDialog
