import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import useCharts from '@/hooks/useCharts'
import am4themes from '@amcharts/amcharts4/themes/frozen'
import { useEffect, useRef, useState } from 'react'
import LoadingCard from '../LoadingCard'
import { europeNum, getContrastColor } from '@/utils/general'
import moment from 'moment'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  }
}))

// Oggetto che contiene la mappatura di delle unità di misura del grafico
const uomNameMap = {
  kwh: 'kWh',
  mwh: 'MWh',
  kwhm: `kWh/m${String.fromCodePoint(0x00B2)}`,
  mwhm: `MWh/m${String.fromCodePoint(0x00B2)}`
}

function LinearRegressionChart ({ energyUom = 'kwh', irradiationUom = 'kwhm', title = null, isReport = false, labelsTheme = 'dark', onDataPointClick = null, data = [], width = null, height = null, className }) {
  // Oggetto che contiene i colori delle scritte del grafico in base al tema scelto
  const graphTheme = {
    light: '#263238',
    dark: '#ffffff'
  }

  const classes = useStyles()
  // Prendo le istanze di core e am4Charts disponibili nel context
  const { chartsCore, amCharts } = useCharts()
  // Se sto renderizzando il grafico in un report, utilizzo il tema di default della libreria grafica
  if (isReport) {
    chartsCore.unuseTheme(am4themes)
  }
  // Creo una ref del grafico
  const chartRef = useRef(null)
  // Variabile che determina la prima renderizzazione a schermo
  const [firstPaint, setFirstPaint] = useState(true)

  // Colore delle labels del grafico
  const labelsColor = graphTheme[labelsTheme] || '#ffffff'
  // Funzione per la formattazione dei dati nel formato richiesto
  const formatData = (dataObj) => {
    const seriesElementsArray = []
    dataObj.forEach((el, seriesIndex) => {
      el.points.forEach((point, pointIndex) => {
        const seriesElements = {}
        seriesElements[`points${seriesIndex + 1}x`] = point?.x || 0
        seriesElements[`points${seriesIndex + 1}y`] = point?.y || 0
        seriesElements[`lines${seriesIndex + 1}x`] = el.line?.length > 0 ? el.line[pointIndex]?.x || 0 : 0
        seriesElements[`lines${seriesIndex + 1}y`] = el.line?.length > 0 ? el.line[pointIndex]?.y || 0 : 0
        seriesElements.formattedDate = point.date ? moment(point.date).format('DD/MM/YYYY') : ''
        seriesElements.rawDate = point.date
        seriesElements[`formattedPoints${seriesIndex + 1}x`] = `${europeNum(point.x, 2) ? europeNum(point.x, 2) : 0} ${uomNameMap[irradiationUom] || ''}`
        seriesElements[`formattedPoints${seriesIndex + 1}y`] = `${europeNum(point.y, 2) ? europeNum(point.y, 2) : 0} ${uomNameMap[energyUom] || ''}`
        if (el.points.length - 1 === pointIndex) {
          seriesElements.r2 = el.r2
        }
        seriesElementsArray.push(seriesElements)
      })
    })

    return seriesElementsArray
  }

  // Funzione che restituisce il massimo valore dell'energia tra tutte le serie da graficare
  const getMaxEnergy = (dataObj) => {
    const maxValues = dataObj.map(el => el.max).filter(el => el && el !== undefined)

    return Math.max(...maxValues)
  }

  // Funzione che dai dati del grafico restituisce il valore maggiore per la chiave passata come parametro
  const getMinEnergy = (data) => {
    console.log(data)
    const lines = data.reduce((acc, el) => {
      const currentLine = el.line
      if (currentLine && currentLine?.length > 0) {
        acc.concat(currentLine)
      }

      return acc
    }, [])

    const points = data.reduce((acc, el) => {
      const currentPoints = el.points
      if (currentPoints && currentPoints?.length > 0) {
        acc.concat(currentPoints)
      }

      return currentPoints
    }, [])

    const yPoints = points.map(point => point.y)
    const yLines = lines.map(line => line.y)

    const allY = [...yPoints, ...yLines]

    console.log(lines)
    console.log(points)
    console.log(yPoints)
    console.log(yLines)
    console.log(allY)

    return allY.length > 0 ? Math.min(...allY) : 0
  }

  // Funzione che prende in ingresso il valore massimo tra quelli graficati e restituisce il valore aumentato di una quantità proporzionale al numero ricevuto in ingresso
  const addPaddingToValue = (maxValue = 0) => {
    const firstDigit = Number(`${maxValue}`.charAt(0) || 1)
    return firstDigit === 0 ? (maxValue + 0.3) : maxValue + (firstDigit * 1.1)
  }

  // Funzione che prende in ingresso il valore minimo tra quelli graficati e restituisce il valore diminuito di una quantità proporzionale al numero ricevuto in ingresso
  const removePaddingValue = (minValue = 0) => {
    const firstDigit = Number(`${minValue}`.charAt(0) || 1)
    return firstDigit === 0 ? (minValue - 0.3) : minValue - (firstDigit * 2)
  }

  // Funzione che crea gli assi del grafico
  const createAxis = (chartInstance) => {
    const irradiationAxis = chartInstance.xAxes.push(new amCharts.ValueAxis())
    irradiationAxis.title.text = `Irraggiamento (${uomNameMap[irradiationUom] || ''})`
    irradiationAxis.title.fontSize = 12
    irradiationAxis.title.fill = labelsColor
    irradiationAxis.title.fontFamily = 'Roboto, sans-serif'
    irradiationAxis.tooltip.disabled = true
    irradiationAxis.renderer.minGridDistance = 40
    irradiationAxis.renderer.labels.template.fontWeight = 400
    irradiationAxis.renderer.labels.template.fontFamily = 'Roboto, sans-serif'
    irradiationAxis.renderer.labels.template.fontSize = 12
    irradiationAxis.renderer.labels.template.maxWidth = 40
    irradiationAxis.renderer.labels.template.fill = labelsColor
    irradiationAxis.zoomable = false

    // Se lo schermo è piccolo, inclino le labels a 45 gradi
    irradiationAxis.events.on('sizechanged', function (ev) {
      const axis = ev.target
      const cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex)
      if (cellWidth < axis.renderer.labels.template.maxWidth) {
        axis.renderer.labels.template.rotation = -45
        axis.renderer.labels.template.horizontalCenter = 'right'
        axis.renderer.labels.template.verticalCenter = 'middle'
      } else {
        axis.renderer.labels.template.rotation = 0
        axis.renderer.labels.template.horizontalCenter = 'middle'
        axis.renderer.labels.template.verticalCenter = 'top'
      }
    })

    const energyMax = getMaxEnergy(data)
    const energyMin = getMinEnergy(data)

    // Create value axis
    const energyAxis = chartInstance.yAxes.push(new amCharts.ValueAxis())
    energyAxis.title.text = `Energia (${uomNameMap[energyUom] || ''})`
    energyAxis.title.fontSize = 12
    energyAxis.title.fill = labelsColor
    energyAxis.title.fontFamily = 'Roboto, sans-serif'
    energyAxis.tooltip.disabled = true
    energyAxis.renderer.labels.template.fontWeight = 400
    energyAxis.renderer.labels.template.fontFamily = 'Roboto, sans-serif'
    energyAxis.renderer.labels.template.fontSize = 12
    energyAxis.renderer.labels.template.fill = labelsColor
    energyAxis.min = energyMin <= 0 ? 0 : removePaddingValue(energyMin)
    energyAxis.max = addPaddingToValue(energyMax)
  }

  // Funzione che crea un tooltip unificato per tutte le serie da graficare
  const createTooltip = (bullet, serieIndex) => {
    // Abilito i tooltips
    bullet.tooltipText = ' '
    // definisco un adapter che componet il contenuto del tooltip
    bullet.adapter.add('tooltipText', function (ev) {
      const text = `[font-family: 'Roboto, sans-serif' font-size: 12px]Giorno:[/] [bold font-size: 12px font-family: 'Roboto, sans-serif']{formattedDate}[/]\n[font-family: 'Roboto, sans-serif' font-size: 12px]Energia:[/] [bold font-size: 12px font-family: 'Roboto, sans-serif']{formattedPoints${serieIndex + 1}y}[/]\n[font-family: 'Roboto, sans-serif' font-size: 12px]Irraggiamento:[/] [bold font-size: 12px font-family: 'Roboto, sans-serif']{formattedPoints${serieIndex + 1}x}[/]`
      return text
    })
  }

  // Funzione che per ogni bullet di tipo Circle, lo resetta alle sue impostazioni iniziali
  const resetBulletChild = (chartInstance) => {
    // Per ogni serie del grafico
    chartInstance.series.each((series, serieIndex) => {
      series.bullets.each((bullet) => {
        // Prendo la lista dei cloni del template di bullet
        const bulletClones = bullet.clones.values
        bulletClones.forEach(singleBullet => {
          // Prendo il 'child' da modificare
          const currentCircle = singleBullet.children.values[0]
          // Se il child è di tipo 'Circle' lo modifico resettandolo alle impostazioni iniziali del template
          if (currentCircle.className === 'Circle') {
            const strokeColor = serieIndex === 0 ? chartInstance.colors.getIndex(serieIndex + 1) : chartInstance.colors.getIndex(serieIndex + 3)

            currentCircle.stroke = strokeColor
            currentCircle.width = 12
            currentCircle.height = 12
            currentCircle.fillOpacity = 0.9
          }
        })
      })
    })
  }

  // Funzione in cui definisco tutte le seire da graficare
  const createSeries = (chartInstance) => {
    data.forEach((el, elIndex) => {
      const scatterSerie = chartInstance.series.push(new amCharts.LineSeries())
      if (!firstPaint) {
        scatterSerie.showOnInit = false
      }
      scatterSerie.dataFields.valueY = `points${elIndex + 1}y`
      scatterSerie.dataFields.valueX = `points${elIndex + 1}x`
      scatterSerie.strokeOpacity = 0
      // Aggiungo i punti
      const bullet = scatterSerie.bullets.push(new amCharts.Bullet())
      const point = bullet.createChild(chartsCore.Circle)

      // Definisco i colori in base all'indice delle serie
      const strokeColor = elIndex === 0 ? chartInstance.colors.getIndex(elIndex + 1) : chartInstance.colors.getIndex(elIndex + 3)
      const fillColor = elIndex === 0 ? chartInstance.colors.getIndex(elIndex) : chartInstance.colors.getIndex(elIndex + 2)

      bullet.children.values[0].applyOnClones = true
      point.horizontalCenter = 'middle'
      point.verticalCenter = 'middle'
      point.strokeWidth = 2
      point.stroke = strokeColor
      point.fill = fillColor
      point.opacity = 0.9
      point.direction = 'top'
      point.width = 12
      point.height = 12
      // Gestisco il click dei punti dello scatter
      bullet.events.on('hit', async function (event) {
        const currentDate = event.target.dataItem?.dataContext?.rawDate
        if (currentDate && onDataPointClick) {
          // Resetto tutti i bullets alle impostazioni iniziali
          resetBulletChild(chartInstance, strokeColor)
          // Modifico solo il bullet cliccato in maniera che sia evidenziato a schermo
          event.target.children.values[0].fillOpacity = 0.3
          event.target.children.values[0].stroke = chartsCore.color('#7cd44e')
          event.target.children.values[0].height = 20
          event.target.children.values[0].width = 20

          await onDataPointClick(currentDate)
        }
      })
      // Ingrandisco i punti dello scatter all'hover
      const hoverState = bullet.states.create('hover')
      hoverState.properties.scale = 1.7

      // creo i tooltip per i grafici scatter
      createTooltip(bullet, elIndex)

      // Aggiungo le rette di regressione
      const trend = chartInstance.series.push(new amCharts.LineSeries())
      if (!firstPaint) {
        trend.showOnInit = false
      }
      const r2Bullet = trend.bullets.push(new amCharts.LabelBullet())
      r2Bullet.disabled = true
      r2Bullet.propertyFields.disabled = 'hideBullet'
      r2Bullet.label.text = '{r2}'
      r2Bullet.label.background = new chartsCore.RoundedRectangle()
      r2Bullet.label.background.cornerRadius(16, 16, 16, 16)
      r2Bullet.label.background.fill = elIndex === 0 ? chartInstance.colors.getIndex(elIndex) : chartInstance.colors.getIndex(elIndex + 2)
      r2Bullet.label.background.stroke = elIndex === 0 ? chartInstance.colors.getIndex(elIndex + 1) : chartInstance.colors.getIndex(elIndex + 3)
      r2Bullet.label.background.strokeOpacity = 1
      r2Bullet.label.padding(4, 8, 4, 8)
      r2Bullet.label.fill = getContrastColor(elIndex === 0 ? chartInstance.colors.getIndex(elIndex).hex : chartInstance.colors.getIndex(elIndex + 2).hex, true)
      r2Bullet.label.fontSize = 12
      r2Bullet.label.fontFamily = 'Roboto, sans-serif'
      r2Bullet.label.fontWeight = 800
      r2Bullet.label.dy = -15

      r2Bullet.marginRight = 20
      r2Bullet.marginBottom = 5
      r2Bullet.label.adapter.add('text', function (text, target) {
        const r2Value = target?.dataItem?.dataContext.r2 || 0
        return `R${String.fromCodePoint(0x00B2)}: [bold]${europeNum(r2Value, 2)}[/]`
      })
      r2Bullet.horizontalCenter = 'right'
      r2Bullet.label.horizontalCenter = 'right'

      trend.dataFields.valueY = 'value2'
      trend.dataFields.valueX = 'value'
      trend.strokeWidth = 4
      trend.stroke = elIndex === 0 ? chartInstance.colors.getIndex(elIndex) : chartInstance.colors.getIndex(elIndex + 2)
      trend.strokeOpacity = 0.7
      trend.data = [
        { value: el.line?.length > 0 ? el.line[0].x : 0, value2: el.line?.length > 0 ? el.line[0].y : 0, r2: null, hideBullet: true },
        { value: el.line?.length > 0 ? el.line[el.line.length - 1].x : 0, value2: el.line?.length > 0 ? el.line[el.line.length - 1].y : 0, r2: el.r2, hideBullet: false }
      ]
    })
  }

  // Funzione che crea il titolo del grafico
  const createTitle = (chartInstance) => {
    if (title) {
      const r2 = data.length > 0 ? data[0].r2 || 0 : 0
      const topContainer = chartInstance.chartContainer.createChild(chartsCore.Container)
      topContainer.layout = 'absolute'
      topContainer.toBack()
      topContainer.paddingBottom = 15
      topContainer.width = chartsCore.percent(100)

      const axisTitle = topContainer.createChild(chartsCore.Label)
      axisTitle.text = title
      axisTitle.fontWeight = 400
      axisTitle.align = 'left'
      axisTitle.paddingLeft = 10
      axisTitle.fontSize = 14
      chartsCore.color(labelsColor)

      const dateTitle = topContainer.createChild(chartsCore.Label)
      dateTitle.text = `R${String.fromCodePoint(0x00B2)}: ${europeNum(r2, 2)}`
      dateTitle.fontWeight = 500
      axisTitle.fontSize = 14
      dateTitle.align = 'right'
      chartsCore.color(labelsColor)
    }
  }

  useEffect(() => {
    if (data.length > 0) {
      // Prendo il div con l'id indicato per creare il grafico
      const chart = chartsCore.create('linear-regression-chart', amCharts.XYChart)
      // Converto i separatori decimali e delle migliaia al formato europeo
      chart.language.locale._decimalSeparator = ','
      chart.language.locale._thousandSeparator = '.'
      // Se non è la prima renderizzazione, disabilito le animazioni
      if (!firstPaint) {
        chart.showOnInit = false
      }
      // Assegno i dati al grafico dopo averli formattati
      chart.data = formatData(data)
      // Aggiungo il cursore
      chart.cursor = new amCharts.XYCursor()
      // Aggiungo il titolo
      createTitle(chart)
      // disabilito il bottone di zoom out
      chart.zoomOutButton.disabled = true
      // Creo gli assi del grafico
      createAxis(chart)
      // Creo la serie da graficare
      createSeries(chart)
      // Assegno il grafico alla ref
      chartRef.current = chart
      // Dopo che è stato renderizzato la prima volta, setto la variabile a false
      if (firstPaint) {
        setFirstPaint(false)
      }
      return () => {
        chartRef.current.dispose()
      }
    }
  }, [data])
  return (
    <div className={clsx(classes.root, className)}>
      {
        data.length > 0
          ? (
            <div
              id='linear-regression-chart'
              style={{
                width: width || '100%',
                height: height || '95%'
              }}
            />)
          : <LoadingCard />
      }
    </div>
  )
}
export default LinearRegressionChart
