import { useState } from 'react'
import clsx from 'clsx'
import { Avatar, Box, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, MenuItem, TextField, Tooltip, Typography } from '@material-ui/core'
import { elementsSection } from './utils'
import { basicColors } from '@/theme'
import { Trash as TrashIcon } from 'react-feather'

const useStyles = makeStyles(theme => ({
  root: {},
  divider: {
    backgroundColor: basicColors.blueGrayLight
  },
  sectionTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  },
  listItem: {
    fontSize: 16,
    width: 28,
    height: 28,
    marginRight: 16
  },
  buttonText: {
    fontSize: 12
  }
}))

function StringAnalysisContent ({ devices = [], setFilters, filters, viewType, className, ...rest }) {
  const classes = useStyles()
  const [elementSelect, setElementSelect] = useState('')
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Typography variant='body2'>{elementsSection[viewType] ? elementsSection[viewType].description : ''}</Typography>
      {filters[viewType].map((el, elIndex) => (
        <div key={`stringAnalysis-${elIndex}`}>
          {/* <Box my={2} display='flex' alignItems='center' justifyContent='space-between'>
            <Typography component='div' variant='h6' className={classes.sectionTitle}>{`Grafico ${elIndex + 1}`}</Typography>
            {elIndex !== 0 && el.show
              ? (
                <Button
                  onClick={() => setFilters(prevFilters => {
                    const currentFilters = prevFilters[viewType]
                    currentFilters.splice(elIndex, 1, { show: false })

                    return {
                      ...prevFilters,
                      [viewType]: [...currentFilters]
                    }
                  })}
                  classes={{ text: classes.buttonText }}
                  size='small'
                  endIcon={<XIcon size={12} />}
                >
                  Nascondi
                </Button>)
              : null}
          </Box> */}
          <Box mt={2}>
            <TextField
              label='Aggiungi Elementi'
              size='small'
              variant='outlined'
              fullWidth
              select
              value={elementSelect}
              disabled={(filters[viewType] && filters[viewType][elIndex] && filters[viewType][elIndex].elements && filters[viewType][elIndex].elements.length >= 5)}
              onChange={(e) => {
                setFilters(prevFilters => {
                  const currentElementId = e.target.value
                  const currentElement = devices.find(el => el.uuid === currentElementId)
                  // Se l'elemento selezionato esiste e se non si trova già negli elementi aggiunti lo aggiungo
                  if (!(prevFilters[viewType] && prevFilters[viewType][elIndex] && prevFilters[viewType][elIndex].elements && prevFilters[viewType].find(el => el.uuid === currentElementId)) && currentElement) {
                    let newArray = []
                    if (currentElement.deviceType && currentElement.deviceType.category === 'Inverter') {
                      const invertersArray = prevFilters[viewType][elIndex].elements.filter(el => (el.deviceType && el.deviceType.category === 'Inverter'))
                      newArray = [...invertersArray, currentElement]
                    } else {
                      const notInvertersArray = prevFilters[viewType][elIndex].elements.filter(el => !(el.deviceType && el.deviceType.category === 'Inverter'))
                      newArray = [...notInvertersArray, currentElement]
                    }
                    const newFilter = {
                      ...prevFilters[viewType][elIndex],
                      elements: newArray
                    }
                    const allGraphsArray = prevFilters[viewType].map((el, index) => index === elIndex ? newFilter : el)
                    return {
                      ...prevFilters,
                      [viewType]: allGraphsArray
                    }
                  }

                  return prevFilters
                })
                setElementSelect('')
              }}
            >
              {devices.map(el => (
                <MenuItem key={el.uuid} value={el.uuid}>{el.name}</MenuItem>
              ))}
            </TextField>
            <Box mt={2}>
              <Typography variant='body2'>{`Elementi Aggiunti (${(filters[viewType] && filters[viewType][elIndex] && filters[viewType][elIndex].elements && filters[viewType][elIndex].elements.length) || 0}/5)`}</Typography>
            </Box>
            <Box mt={2}>
              <List>
                {filters[viewType] && filters[viewType][elIndex] && filters[viewType][elIndex].elements && filters[viewType][elIndex].elements.map((el, index) => (
                  <div key={el.uuid}>
                    <ListItem>
                      <Avatar className={classes.listItem}>{index + 1}</Avatar>
                      <ListItemText primary={el.name} />
                      <ListItemSecondaryAction>
                        <Tooltip title='Elimina'>
                          <IconButton
                            onClick={() => {
                              setFilters(prevFilters => {
                                const newAddedElements = prevFilters[viewType][elIndex].elements.filter(addedEl => addedEl.uuid !== el.uuid)
                                const newFilter = {
                                  ...prevFilters[viewType][elIndex],
                                  elements: newAddedElements
                                }
                                const allGraphsArray = prevFilters[viewType].map((el, index) => index === elIndex ? newFilter : el)
                                return {
                                  ...prevFilters,
                                  [viewType]: allGraphsArray
                                }
                              })
                            }} size='small' edge='end'
                          >
                            <TrashIcon color={basicColors.darkRed} size={20} />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider className={classes.divider} />
                  </div>
                ))}
              </List>
            </Box>
          </Box>
        </div>
      ))}
    </div>
  )
}
export default StringAnalysisContent
