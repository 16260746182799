import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'react-perfect-scrollbar/dist/css/styles.css'
// import 'prismjs/prism'
// import 'prismjs/components/prism-bash'
// import 'prismjs/components/prism-javascript'
// import 'prismjs/components/prism-jsx'
import 'nprogress/nprogress.css'
import '@/__mocks__'
// import '@/assets/css/prism.css'
// import '@/mixins/chartjs'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { enableES5 } from 'immer'
// import * as serviceWorker from '@/serviceWorker'
import * as Sentry from '@sentry/browser'
import store from '@/store'
import { SettingsProvider } from '@contexts/SettingsContext'
import App from '@/App'
import { configuration } from '@/config'
import { changeLogConfig } from '@pelv/frontlog'

enableES5()

// console.log('import.meta.env => ', import.meta.env)

if (configuration.sentry.environment === 'production') {
  Sentry.init({ dsn: configuration.sentry.dsn, environment: configuration.sentry.environment })
}

if (configuration.environment === 'production') {
  changeLogConfig({
    sendLogs: false
  })
}

render(
  <Provider store={store}>
    <SettingsProvider>
      <App />
    </SettingsProvider>
  </Provider>,
  document.getElementById('root')
)

// serviceWorker.register()
