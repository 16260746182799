import clsx from 'clsx'
import { makeStyles, Box, Typography } from '@material-ui/core'
import useCharts from '@hooks/useCharts'
import { memo, useEffect, useRef, useState } from 'react'
import LoadingCard from '../LoadingCard'
import { europeNum } from '@utils/general'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  }
}))

function PrDeviation ({
  hasGlass = false,
  data = {},
  height = 350,
  width = 350,
  className,
  hideGraph = false,
  hideMessage = 'Impossibile visualizzare il grafico',
  ...rest
}) {
  const classes = useStyles()
  // Prendo le istanze di core e am4Charts disponibili nel context
  const { chartsCore, amCharts } = useCharts()
  // Creo una ref del grafico
  const chartRef = useRef(null)
  // Variabile che determina la prima renderizzazione a schermo
  const [firstPaint, setFirstPaint] = useState(true)

  const colorMapping = {
    produzione: chartsCore.color('#50af57'),
    pr: chartsCore.color('#fcae1a')
  }

  // Funzione per la formattazione dei dati nel formato richiesto dalla libreria grafica
  const formatData = (dataObj) => {
    return Object.keys(dataObj).map(key => ({
      category: `${dataObj[key].category} (${europeNum(dataObj[key].value - 100)}%)`,
      value: dataObj[key].value,
      realValue: dataObj[key].realValue,
      name: dataObj[key].name,
      full: 100
    }))
  }

  // Funzione per creare una label dinamicamente
  const createLabel = ({ chart = null, text = '', opts = {} }) => {
    if (chart) {
      // Creo la label
      const label = chart.radarContainer.createChild(chartsCore.Label)
      // imposto i valori di default
      label.text = text
      label.fontFamily = 'Roboto, sans-serif'
      label.fill = '#ffffff'
      // Setto le opzioni restanti se ci sono
      if (Object.keys(opts).length > 0) {
        Object.keys(opts).forEach(key => {
          label[key] = opts[key]
        })
      }
      return label
    }

    return null
  }

  // Funzione per settare la label centrale del grafico con i valori iniziali
  const setCenterLabel = (chartInstance) => {
    const nameLabel = createLabel({
      chart: chartInstance,
      text: 'PR Reale',
      opts: {
        fontSize: 12,
        horizontalCenter: 'middle',
        verticalCenter: 'bottom'
      }
    })

    const valueLabel = createLabel({
      chart: chartInstance,
      text: data.pr?.realValue ? europeNum(data.pr?.realValue, 2) : '-',
      opts: {
        fontSize: 35,
        fontWeight: 800,
        horizontalCenter: 'middle',
        verticalCenter: 'top'
      }
    })

    return { nameLabel, valueLabel }
  }

  // Funzione che crea gli assi del grafico
  const createAxis = (chartInstance) => {
    // Creo l'asse delle categorie (PR e Produzione)
    const categoryAxis = chartInstance.yAxes.push(new amCharts.CategoryAxis())
    // Faccio il bind dei dati da mostrare (quello che si trova nel campo 'category' dei dati passati al grafico)
    categoryAxis.dataFields.category = 'category'
    // Imposto gli stili per l'asse
    categoryAxis.renderer.grid.template.location = 0
    categoryAxis.renderer.grid.template.strokeOpacity = 0
    categoryAxis.renderer.labels.template.horizontalCenter = 'right'
    categoryAxis.renderer.labels.template.fontWeight = 500
    categoryAxis.renderer.labels.template.fontFamily = 'Roboto, sans-serif'
    categoryAxis.renderer.labels.template.fontSize = 12
    categoryAxis.renderer.labels.template.adapter.add('fill', function (fill, target) {
      if (target.dataItem.index < 0) return fill

      const colorKey = target.dataItem?.dataContext?.category?.split('(')?.[0]?.trim().toLowerCase() || ''
      const serieColor = colorMapping[colorKey] || chartInstance.colors.getIndex(target.dataItem.index)
      return serieColor
    })
    categoryAxis.renderer.minGridDistance = 10
    // Creo l'asse dei valori
    const valueAxis = chartInstance.xAxes.push(new amCharts.ValueAxis())
    // Imposto gli stili per l'asse
    valueAxis.renderer.labels.template.disabled = true
    valueAxis.renderer.grid.template.strokeOpacity = 0
    valueAxis.renderer.labels.template.fill = '#ffffff'
    valueAxis.renderer.labels.template.fontFamily = 'Roboto, sans-serif'
    valueAxis.renderer.labels.template.fontSize = 10
    valueAxis.min = 0
    valueAxis.max = 100
    valueAxis.strictMinMax = true
    // Disattivo i tooltip
    valueAxis.cursorTooltipEnabled = false
    // Disattivo lo zoom
    valueAxis.zoomable = false
  }

  // Funzione in cui definisco tutte le serie da graficare
  const createSeries = (chartInstance, nameLabel, valueLabel) => {
    // Creo la prima serie, che sarà lo sfondo delle barre con il valore reale
    const series1 = chartInstance.series.push(new amCharts.RadarColumnSeries())
    if (!firstPaint) {
      series1.showOnInit = false
    }
    // Faccio il bind dei dati
    series1.dataFields.valueX = 'full'
    series1.dataFields.categoryY = 'category'
    // Permetto alle serie di essere una sotto l'altra
    series1.clustered = false
    // Assegno un colore alternativo alle barre e setto gli stili della serie
    series1.columns.template.fill = new chartsCore.InterfaceColorSet().getFor('alternativeBackground')
    series1.columns.template.fillOpacity = 0.2
    series1.columns.template.strokeWidth = 0
    series1.columns.template.radarColumn.cornerRadius = 20

    // Creo la serie che contiene i valori veri e propri da mostrare
    const series2 = chartInstance.series.push(new amCharts.RadarColumnSeries())
    if (!firstPaint) {
      series2.showOnInit = false
    }
    // Faccio il bind dei dati
    series2.dataFields.valueX = 'value'
    series2.dataFields.categoryY = 'category'
    // Assegno un ulteriore proprietà da mostrare all'over nella label centrale
    series2.propertyFields.name = 'name'
    // Setto gli stili della serie
    series2.clustered = false
    series2.columns.template.strokeWidth = 0
    series2.tooltip.disabled = true
    series2.columns.template.radarColumn.cornerRadius = 20
    series2.columns.template.adapter.add('fill', function (fill, target) {
      const colorKey = target.dataItem?.dataContext?.category?.split('(')?.[0]?.trim().toLowerCase() || ''
      const serieColor = colorMapping[colorKey] || chartInstance.colors.getIndex(target.dataItem.index)
      return serieColor
    })
    // Definisco la funzione da richiamare all'over del mouse sulla barra del grafico
    const overDisposer = series2.columns.template.events.on('over', (e) => {
      nameLabel.text = e.target?.column?.dataItem?.properties?.name || ''
      valueLabel.text = e.target?.column?.dataItem?.valueX ? `${e.target.column.dataItem.valueX}%` : ''
    }, this)
    // Definisco la funzione da richiamare all'out del mouse sulla barra del grafico
    const outDisposer = series2.columns.template.events.on('out', (e) => {
      nameLabel.text = 'PR Reale'
      valueLabel.text = data.pr?.realValue ? europeNum(data.pr?.realValue, 2) : '-'
    }, this)

    return { overDisposer, outDisposer }
  }

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      // Prendo il div con l'id indicato per creare il grafico Radar
      const chart = chartsCore.create('pr-deviation-chart', amCharts.RadarChart)
      if (!firstPaint) {
        chart.showOnInit = false
      }
      // Assegno i dati al grafico dopo averli formattati
      chart.data = formatData(data)

      // Setto il grafico per essere 3/4 di circonferenza
      chart.startAngle = -90
      chart.endAngle = 180
      // Setto lo spessore delle barre del grafico
      chart.innerRadius = chartsCore.percent(60)

      // Creo le labels centrali mostrando i valori di default del PR reale
      const { nameLabel, valueLabel } = setCenterLabel(chart)
      // Aggiungo il cursore
      chart.cursor = new amCharts.RadarCursor()

      // Creo gli assi del grafico
      createAxis(chart)

      // Creo le serie da graficare
      const { overDisposer, outDisposer } = createSeries(chart, nameLabel, valueLabel)

      chartRef.current = chart

      if (firstPaint) {
        setFirstPaint(false)
      }
      return () => {
        overDisposer.dispose()
        outDisposer.dispose()
        chartRef.current.dispose()
      }
    }
  }, [data])

  if (hideGraph) {
    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        style={{
          width: width || 350,
          height: height || 350
        }}
      >
        <Typography component='span' variant='body1' align='center'>{hideMessage}</Typography>
      </Box>
    )
  }

  return Object.keys(data).length > 0
    ? (
      <div
        id='pr-deviation-chart'
        style={{
          width: width || 350,
          height: height || 350
        }}
        className={clsx(classes.root, className)}
        {...rest}
      />
      )
    : <LoadingCard glass={hasGlass} />
}
export default memo(PrDeviation)
