import clsx from 'clsx'
import { Box, Divider, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { basicColors } from '@/theme'
import {
  CheckCircle as OkIcon,
  AlertTriangle as WarningIcon,
  Slash as ErrorIcon,
  ExternalLink as ExitToAppIcon
} from 'react-feather'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 360,
    maxHeight: 420,
    overflowY: 'auto',
    overflowX: 'hidden',
    // padding: theme.spacing(1),
    // margin: '-15px -21px',
    borderRadius: '2p 2px 0 0'
  },
  label: {
    fontWeight: 500,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  title: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.background.default,
    fontWeight: 700,
    // paddingRight: theme.spacing(4),
    borderRadius: '8px 8px 0 0'
  },
  divider: {
    backgroundColor: basicColors.blueGrayLight,
    marginLeft: -7,
    marginRight: -7
  },
  ok: {
    backgroundColor: basicColors.lightGreen
  },
  error: {
    backgroundColor: basicColors.lightRed
  },
  warning: {
    backgroundColor: basicColors.lightYellow
  },
  maintenance: {
    backgroundColor: basicColors.lightYellow
  },
  clickable: {
    cursor: 'pointer'
  }
}))

function ClusterPopover ({ onClose, onItemClick, data = [], className, ...rest }) {
  const classes = useStyles()

  const errorData = data.filter(el => el.status === 'error')
  const warningData = data.filter(el => (el.status === 'warning' || el.status === 'maintenance'))
  const okData = data.filter(el => el.status === 'ok')

  const allData = errorData.concat(warningData, okData)

  const iconByStatus = {
    ok: <OkIcon style={{ width: 16, height: 16 }} color={basicColors.darkGreen} />,
    warning: <WarningIcon style={{ width: 16, height: 16 }} color={basicColors.darkYellow} />,
    maintenance: <WarningIcon style={{ width: 16, height: 16 }} color={basicColors.darkYellow} />,
    error: <ErrorIcon style={{ width: 16, height: 16 }} color={basicColors.darkRed} />
  }

  const iconColor = {
    ok: basicColors.darkGreen,
    warning: basicColors.darkYellow,
    maintenance: basicColors.darkYellow,
    error: basicColors.darkRed
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {allData.length > 0
        ? (
          <div>
            <Box className={classes.title} mt={-0.875} pt={2} pb={1} pr={1} pl={1} width='100%' display='flex' justifyContent='space-between' alignItems='center'>
              <Typography noWrap component='div' variant='h5'>
                {`Impianti Aggregati: ${allData.length}`}
              </Typography>
              <IconButton onClick={onClose} style={{ marginLeft: 16, display: 'flex', alignItems: 'center', justifyContent: 'center' }} size='small'>
                <CloseIcon style={{ width: 16, height: 16 }} />
              </IconButton>
            </Box>
            {
              allData.map((el, index) => (
                <div id={`plant-${index}`} className={classes[el.status]} key={`popup-element${index}`}>
                  <Box px={1} py={0.5} width='100%' display='flex' alignItems='center' justifyContent='space-between'>
                    <Box onClick={() => onItemClick(el.position)} className={classes.clickable} display='flex' alignItems='center'>
                      {iconByStatus[el.status] || ''}
                      <Typography className={classes.label} component='div' variant='body2' color='secondary'>
                        {el.plantName}
                      </Typography>
                    </Box>
                    <Tooltip title="Vai all' impianto">
                      <IconButton size='small' onClick={() => el.onClick()}>
                        <ExitToAppIcon style={{ width: 20, height: 20 }} color={iconColor[el.status] || 'inherit'} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  {index !== (Object.keys(allData).length - 1)
                    ? <Divider className={classes.divider} />
                    : null}
                </div>
              ))
            }
          </div>
          )
        : null}
    </div>
  )
}
export default ClusterPopover
