import clsx from 'clsx'
import { Box, makeStyles, useMediaQuery } from '@material-ui/core'
import { memo, useEffect, useRef, useState } from 'react'
import LoadingCard from '../LoadingCard'
import useCharts from '@/hooks/useCharts'
import { europeNum } from '@/utils/general'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    position: 'relative'
  },
  dataLoading: {
    position: 'absolute',
    zIndex: 100,
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const rawValues = ['expectedEnergy', 'producedEnergy', 'exportedEnergy']
const cumulatedValues = ['expectedEnergyCumulated', 'producedEnergyCumulated', 'exportedEnergyCumulated']
const irradiationValues = ['irradiation']

function EnergyGraph ({ showAxis = true, hasGlass = false, hasIrradiation = false, data = {}, canZoom = true, width = null, height = null, showToolbar = true, isLoading, recalculatePeriod = null, className, ...rest }) {
  const classes = useStyles()
  // Prendo le istanze di core e am4Charts disponibili nel context
  const { chartsCore, amCharts, chartItLocale } = useCharts()
  // Creo una ref del grafico
  const chartRef = useRef(null)
  // Variabile che determina la prima renderizzazione a schermo
  const [firstPaint, setFirstPaint] = useState(true)
  // Variabile che contiene il valore minimo di zoom
  const [min, setMin] = useState(null)
  // Variabile che contiene il valore massimo di zoom
  const [max, setMax] = useState(null)

  const categoriesMap = {
    expectedEnergy: { name: 'E. Attesa', color: chartsCore.color('#5f93d1') },
    producedEnergy: { name: 'E. Prodotta', color: chartsCore.color('#50af57') },
    exportedEnergy: { name: 'E. Immessa', color: chartsCore.color('#713e8d') },
    irradiation: { name: 'Irraggiamento', color: chartsCore.color('#ffffff') },
    expectedEnergyCumulated: { name: 'E. Attesa Cumulata', color: chartsCore.color('#7eb4e7') },
    producedEnergyCumulated: { name: 'E. Prodotta Cumulata', color: chartsCore.color('#ceff5d') },
    exportedEnergyCumulated: { name: 'E. Immessa Cumulata', color: chartsCore.color('#eb6eb0') }
  }

  // Variabile utilizzata per mostrare o meno i grafici in caso di schermi mobile
  const isExtraSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
  // Funzione che dai dati del grafico restituisce il valore maggiore per la chiave passata come parametro
  const getPeakValue = (chartInstance, valueKey, sign = 'max') => {
    if (valueKey === 'cumulated') {
      // Valori delle energie cumulate
      const cumulatedValues = chartInstance.data.reduce((acc, el) => {
        acc.push(el.expectedEnergyCumulated)
        acc.push(el.producedEnergyCumulated)
        acc.push(el.exportedEnergyCumulated)

        return acc
      }, []).filter(el => el && el !== undefined)

      return sign === 'min' ? Math.min(...cumulatedValues) : Math.max(...cumulatedValues)
    } else if (valueKey === 'irradiation') {
      const allValues = chartInstance.data.map(el => el[valueKey] || 0)

      return sign === 'min' ? Math.min(...allValues) : Math.max(...allValues)
    } else {
      // Valori delle energie cumulate
      const energyValues = chartInstance.data.reduce((acc, el) => {
        acc.push(el.expectedEnergy)
        acc.push(el.producedEnergy)
        acc.push(el.exportedEnergy)

        return acc
      }, []).filter(el => el && el !== undefined)

      return sign === 'min' ? Math.min(...energyValues) : Math.max(...energyValues)
    }
  }

  // Funzione che prende in ingresso il valore massimo tra quelli graficati e restituisce il valore aumentato di una quantità proporzionale al numero ricevuto in ingresso
  const addPaddingToValue = (maxValue = 0) => {
    const firstDigit = Number(`${maxValue}`.charAt(0) || 1)
    return firstDigit === 0 ? (maxValue + 0.3) : maxValue + (maxValue * 0.1)
  }

  // Funzione che preso in ingresso l'oggetto data, lo ritorna aggiungendo le somme dei valori precedenti
  const calculateCumulativeEnergies = (series) => {
    const cumulated = {
      0: {
        data: [0],
        name: 'E. Attesa Cumulata'
      },
      1: {
        data: [0],
        name: 'E. Prodotta Cumulata'
      },
      2: {
        data: [0],
        name: 'E. Immessa Cumulata'
      }
    }

    series.filter(el => el.name !== 'Irraggiamento').forEach((serie, index) => {
      const totalLength = serie.data.length
      // const productionEnergyLength = series.find(el => el.name === 'E. Prodotta') ? series.find(el => el.name === 'E. Prodotta').data.filter(el => el !== null).length : 0
      for (let n = 0; n < totalLength; n++) {
        if (n !== 0) {
          const prevNumber = cumulated[String(index)].data[n - 1]
          const currentNumber = series[index].data[n]
          cumulated[String(index)].data[n] = Number(prevNumber) + Number(currentNumber)
          /* if (n < productionEnergyLength) {
            cumulated[String(index)].data[n] = Number(cumulated[String(index)].data[n - 1]) + Number(series[index].data[n])
          } else {
            cumulated[String(index)].data[n] = null
          } */
        } else {
          cumulated[String(index)].data[n] = series[index].data[n] ? Number(series[index].data[n]) : null
        }

        const fixedNumber = cumulated[String(index)].data[n] !== null ? Number(cumulated[String(index)].data[n]).toFixed(1) : null

        cumulated[String(index)].data[n] = fixedNumber !== null ? Number(fixedNumber) : null
      }
    })

    return [...series, ...Object.keys(cumulated).map(key => cumulated[key])]
  }
  // Funzione per formattare i valori numerici dei tooltips
  const formatTooltipValues = (type, number) => {
    const typeMap = {
      expectedEnergy: 'kWh',
      producedEnergy: 'kWh',
      exportedEnergy: 'kWh',
      expectedEnergyCumulated: 'kWh',
      producedEnergyCumulated: 'kWh',
      exportedEnergyCumulated: 'kWh'
    }

    if (hasIrradiation) {
      typeMap.irradiation = `Wh/m${String.fromCodePoint(0x00B2)}`
    }

    return number !== null && number !== undefined ? europeNum(number, 2) ? `${europeNum(number, 2)} ${typeMap[type] || ''}` : '-' : '-'
  }
  // Funzione per la formattazione dei dati nel formato richiesto dalla libreria grafica
  const formatData = (dataObj) => {
    const labels = dataObj.labels
    const series = calculateCumulativeEnergies(dataObj.series) || []

    if (labels) {
      if (hasIrradiation) {
        return labels.map((label, index) => ({
          category: label,
          expectedEnergy: series[0].data.length > 0 ? series[0].data[index] : null,
          producedEnergy: series[1].data.length > 0 ? series[1].data[index] : null,
          exportedEnergy: series[2].data.length > 0 ? series[2].data[index] : null,
          irradiation: series[3].data.length > 0 ? series[3].data[index] : null,
          expectedEnergyCumulated: series[4].data.length > 0 ? series[4].data[index] : null,
          producedEnergyCumulated: series[5].data.length > 0 ? series[5].data[index] : null,
          exportedEnergyCumulated: series[6].data.length > 0 ? series[6].data[index] : null,
          expectedEnergyFormatted: formatTooltipValues('expectedEnergy', series[0].data.length > 0 ? series[0].data[index] : null),
          producedEnergyFormatted: formatTooltipValues('producedEnergy', series[1].data.length > 0 ? series[1].data[index] : null),
          exportedEnergyFormatted: formatTooltipValues('exportedEnergy', series[2].data.length > 0 ? series[2].data[index] : null),
          irradiationFormatted: formatTooltipValues('irradiation', series[3].data.length > 0 ? series[3].data[index] : null),
          expectedEnergyCumulatedFormatted: formatTooltipValues('expectedEnergyCumulated', series[4].data.length > 0 ? series[4].data[index] : null),
          producedEnergyCumulatedFormatted: formatTooltipValues('producedEnergyCumulated', series[5].data.length > 0 ? series[5].data[index] : null),
          exportedEnergyCumulatedFormatted: formatTooltipValues('exportedEnergyCumulated', series[6].data.length > 0 ? series[6].data[index] : null)
        }))
      } else {
        return labels.map((label, index) => ({
          category: label,
          expectedEnergy: series[0].data.length > 0 ? series[0].data[index] : null,
          producedEnergy: series[1].data.length > 0 ? series[1].data[index] : null,
          exportedEnergy: series[2].data.length > 0 ? series[2].data[index] : null,
          expectedEnergyCumulated: series[3].data.length > 0 ? series[3].data[index] : null,
          producedEnergyCumulated: series[4].data.length > 0 ? series[4].data[index] : null,
          exportedEnergyCumulated: series[5].data.length > 0 ? series[5].data[index] : null,
          expectedEnergyFormatted: formatTooltipValues('expectedEnergy', series[0].data.length > 0 ? series[0].data[index] : null),
          producedEnergyFormatted: formatTooltipValues('producedEnergy', series[1].data.length > 0 ? series[1].data[index] : null),
          exportedEnergyFormatted: formatTooltipValues('exportedEnergy', series[2].data.length > 0 ? series[2].data[index] : null),
          expectedEnergyCumulatedFormatted: formatTooltipValues('expectedEnergyCumulated', series[3].data.length > 0 ? series[3].data[index] : null),
          producedEnergyCumulatedFormatted: formatTooltipValues('producedEnergyCumulated', series[4].data.length > 0 ? series[4].data[index] : null),
          exportedEnergyCumulatedFormatted: formatTooltipValues('exportedEnergyCumulated', series[5].data.length > 0 ? series[5].data[index] : null)

        }))
      }
    }

    return []
  }

  // Funzione che allo zoom riprende i dati in base al nuovo periodo di tempo
  const categoryAxisZoomed = async (start, end) => {
    if (recalculatePeriod) {
      await recalculatePeriod(start, end, data)
    }
  }
  // Funzione che fa il set dei valori di min e max dello zoom
  const setMinMaxZoom = (ev) => {
    const axis = ev.target
    const start = axis.positionToIndex(axis.start) + 1
    const end = axis.positionToIndex(axis.end) + 1

    setMin(start)
    setMax(end)
  }

  // Funzione che crea gli assi del grafico
  const createAxis = (chartInstance) => {
    // Asse delle date
    const dateAxis = chartInstance.xAxes.push(new amCharts.CategoryAxis())
    if (isExtraSmall) {
      dateAxis.tooltip.disabled = false
      dateAxis.renderer.grid.template.disabled = true
      dateAxis.renderer.labels.template.disabled = true
    } else {
      dateAxis.tooltip.disabled = true
    }
    dateAxis.renderer.cellStartLocation = 0.1
    dateAxis.renderer.cellEndLocation = 0.9
    dateAxis.tooltip.fontFamily = 'Roboto, sans-serif'
    dateAxis.tooltip.fontSize = 12
    dateAxis.tooltip.fill = '#ffffff'
    dateAxis.renderer.minGridDistance = 10
    dateAxis.dataFields.category = 'category'
    dateAxis.renderer.labels.template.fontWeight = 400
    dateAxis.renderer.labels.template.dx = 10
    dateAxis.renderer.labels.template.fontFamily = 'Roboto, sans-serif'
    dateAxis.renderer.labels.template.fontSize = 12
    dateAxis.renderer.labels.template.fill = '#ffffff'
    dateAxis.renderer.labels.template.maxWidth = 40
    // Se lo schermo è piccolo, inclino le labels a 45 gradi
    dateAxis.events.on('sizechanged', function (ev) {
      const axis = ev.target
      const cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex)
      if (cellWidth < axis.renderer.labels.template.maxWidth + 15) {
        axis.renderer.labels.template.rotation = -45
        axis.renderer.labels.template.horizontalCenter = 'right'
        axis.renderer.labels.template.verticalCenter = 'middle'
      } else {
        axis.renderer.labels.template.rotation = 0
        axis.renderer.labels.template.horizontalCenter = 'middle'
        axis.renderer.labels.template.verticalCenter = 'top'
      }
    })

    // Aggiungo il listener solo se lo zoom è abilitato
    if (canZoom) {
      // Se faccio zoom sul grafico devo richiamare la funzione che prende i dati in base al nuovo periodo di dettaglio
      dateAxis.events.on('endchanged', setMinMaxZoom)
    }

    // Calcolo i valori massimi
    const valueMax = getPeakValue(chartInstance, 'energy')
    const cumulatedMax = getPeakValue(chartInstance, 'cumulated')

    /* // Calcolo i valori minimi
    const valueMin = getPeakValue(chartInstance, 'energy', 'min')
    const cumulatedMin = getPeakValue(chartInstance, 'cumulated', 'min') */

    // Assi dei valori
    const valueAxis = chartInstance.yAxes.push(new amCharts.ValueAxis())
    valueAxis.min = 0
    valueAxis.max = addPaddingToValue(valueMax)
    valueAxis.renderer.minGridDistance = 20
    valueAxis.strictMinMax = true
    // valueAxis.renderer.grid.template.disabled = true
    valueAxis.title.text = showAxis ? 'Energia (kWh)' : ''
    valueAxis.title.fontSize = 12
    valueAxis.title.fontWeight = 500
    valueAxis.title.fontFamily = 'Roboto, sans-serif'
    valueAxis.title.fill = '#ffffff'
    valueAxis.renderer.labels.template.disabled = !showAxis
    valueAxis.renderer.labels.template.fontWeight = 400
    valueAxis.renderer.labels.template.fontFamily = 'Roboto, sans-serif'
    valueAxis.renderer.labels.template.fontSize = 12
    valueAxis.renderer.labels.template.fill = '#ffffff'
    // valueAxis.renderer.opposite = true
    valueAxis.tooltip.disabled = true

    const cumulatedAxis = chartInstance.yAxes.push(new amCharts.ValueAxis())
    cumulatedAxis.min = 0
    cumulatedAxis.max = addPaddingToValue(cumulatedMax)
    cumulatedAxis.renderer.minGridDistance = 20
    cumulatedAxis.strictMinMax = true
    cumulatedAxis.renderer.grid.template.disabled = true
    cumulatedAxis.title.text = showAxis ? 'Energia Cumulata (kWh)' : ''
    cumulatedAxis.title.fontSize = 12
    cumulatedAxis.title.fontWeight = 500
    cumulatedAxis.title.fontFamily = 'Roboto, sans-serif'
    cumulatedAxis.title.fill = '#ffffff'
    cumulatedAxis.renderer.labels.template.disabled = !showAxis
    cumulatedAxis.renderer.labels.template.fontWeight = 400
    cumulatedAxis.renderer.labels.template.fontFamily = 'Roboto, sans-serif'
    cumulatedAxis.renderer.labels.template.fontSize = 12
    cumulatedAxis.renderer.labels.template.fill = '#ffffff'
    cumulatedAxis.renderer.opposite = false
    cumulatedAxis.tooltip.disabled = true

    const irradiationAxis = hasIrradiation ? chartInstance.yAxes.push(new amCharts.ValueAxis()) : null
    if (irradiationAxis) {
      const irradiationMax = getPeakValue(chartInstance, 'irradiation')
      const irradiationMin = getPeakValue(chartInstance, 'irradiation', 'min')
      irradiationAxis.min = irradiationMin < 0 ? 0 : irradiationMin
      irradiationAxis.max = addPaddingToValue(irradiationMax)
      irradiationAxis.renderer.minGridDistance = 20
      irradiationAxis.strictMinMax = true
      irradiationAxis.renderer.grid.template.disabled = true
      irradiationAxis.title.text = showAxis ? `Irraggiamento (Wh/m${String.fromCodePoint(0x00B2)})` : ''
      irradiationAxis.title.fontSize = 12
      irradiationAxis.title.fontWeight = 500
      irradiationAxis.title.fontFamily = 'Roboto, sans-serif'
      irradiationAxis.title.fill = '#ffffff'
      irradiationAxis.renderer.labels.template.disabled = !showAxis
      irradiationAxis.renderer.labels.template.fontWeight = 400
      irradiationAxis.renderer.labels.template.fontFamily = 'Roboto, sans-serif'
      irradiationAxis.renderer.labels.template.fontSize = 12
      irradiationAxis.renderer.labels.template.fill = '#ffffff'
      irradiationAxis.renderer.opposite = true
      irradiationAxis.tooltip.disabled = true
      irradiationAxis.zoomable = canZoom
    }

    // Se lo zoom è disabilitato, disabilito gli zoom sugli assi
    valueAxis.zoomable = canZoom
    cumulatedAxis.zoomable = canZoom
    dateAxis.zoomable = canZoom

    // Nascondo le labels, il titolo e le griglie degli assi se non voglio mostrare gli assi
    if (isExtraSmall) {
      valueAxis.renderer.grid.template.disabled = true
      valueAxis.renderer.labels.template.disabled = true
      cumulatedAxis.renderer.grid.template.disabled = true
      cumulatedAxis.renderer.labels.template.disabled = true
      valueAxis.title.disabled = true
      cumulatedAxis.title.disabled = true

      if (irradiationAxis) {
        irradiationAxis.renderer.grid.template.disabled = true
        irradiationAxis.renderer.labels.template.disabled = true
        irradiationAxis.title.disabled = true
      }
    }

    // Oggetto da ritornare che contiene gli assi creati
    const returnObject = {
      valueAxis,
      cumulatedAxis,
      dateAxis
    }
    if (irradiationAxis) {
      returnObject.irradiationAxis = irradiationAxis
    }

    return returnObject
  }

  // Funzione che crea un tooltip unificato per tutte le serie da graficare
  const createTooltip = (chartInstance, currentSerie) => {
    // Dato che sto creando un unico tooltip per tutte le serie, lo creo una volta sola sulla prima serie, tenendo conto dei dati di tutte
    const availableSerieName = data.series.find(serie => serie.data.some(el => !!el))?.name || null
    const canCreateTooltip = availableSerieName?.includes('Cumulata') ? !!availableSerieName : `${availableSerieName} Cumulata` === currentSerie.properties.name
    if (canCreateTooltip) {
      // Abilito i tooltips
      currentSerie.tooltipText = ['[bold]serie:[/] {valueY}']
      // definisco un adapter che compone il contenuto del tooltip
      currentSerie.adapter.add('tooltipText', function (ev) {
        let text = '[bold]{category}[/]\n\n'

        chartInstance.series.each(function (item) {
          if (!item.isHidden && canCreateTooltip) {
            text += '[' + item.fill.hex + ']● [/]' + item.name + ': [bold]{' + item.dataFields.formattedValue + '}[/]\n'
          }
        })
        return text
      })
      // Definisco una grandezza per il tooltip e lo faccio andare a capo in caso di testi più lunghi
      currentSerie.tooltip.label.width = 250
      currentSerie.tooltip.label.wrap = true
      // Disabilito il colore del tooltip in base al colore della serie e lo definisco in maniera programmatica
      currentSerie.tooltip.getFillFromObject = false
      currentSerie.tooltip.background.fill = new chartsCore.InterfaceColorSet().getFor('alternativeBackground')
      currentSerie.tooltip.background.stroke = new chartsCore.InterfaceColorSet().getFor('alternativeBackground')
      currentSerie.tooltip.background.opacity = 0.8
      // Definisco gli stili per il testo all'interno del tooltip
      currentSerie.tooltip.label.fill = chartsCore.color('#fff')
      currentSerie.tooltip.label.fontFamily = 'Roboto, sans-serif'
      currentSerie.tooltip.label.fontSize = 12
    }
  }

  // Funzione che separa le colonne in gruppi con un elemento per ogni valore
  const arrangeColumns = (allSeries, categoryAxis) => {
    const series = allSeries?.[0] || []

    if (allSeries.length <= 0) {
      return
    }

    const w = 1 - categoryAxis.renderer.cellStartLocation - (1 - categoryAxis.renderer.cellEndLocation)
    if (series.dataItems.length > 1) {
      const x0 = categoryAxis.getX(series.dataItems.getIndex(0), 'categoryX')
      const x1 = categoryAxis.getX(series.dataItems.getIndex(1), 'categoryX')
      const delta = ((x1 - x0) / allSeries.length) * w
      if (chartsCore.isNumber(delta)) {
        const middle = allSeries.length / 2

        let newIndex = 0
        allSeries.each(function (series) {
          if (!series.isHidden && !series.isHiding) {
            series.dummyData = newIndex
            newIndex++
          } else {
            series.dummyData = allSeries.indexOf(series)
          }
        })
        const visibleCount = newIndex
        const newMiddle = visibleCount / 2

        allSeries.each(function (series) {
          const trueIndex = allSeries.indexOf(series)
          const newIndex = series.dummyData

          const dx = (newIndex - trueIndex + middle - newMiddle) * delta

          series.animate({ property: 'dx', to: dx }, series.interpolationDuration, series.interpolationEasing)
          series.bulletsContainer.animate({ property: 'dx', to: dx }, series.interpolationDuration, series.interpolationEasing)
        })
      }
    }
  }

  // Funzione in cui definisco tutte le serie da graficare
  const createSeries = (chartInstance, valueAxis, cumulatedAxis, irradiationAxis, categoryAxis) => {
    if (irradiationAxis && irradiationAxis !== undefined) {
      // Creo la serie per l'irraggiamento
      irradiationValues.forEach((key, index) => {
        const series = chartInstance.series.push(new amCharts.LineSeries())

        series.showOnInit = false
        series.dataFields.categoryX = 'category'
        series.name = categoriesMap[key]?.name
        series.dataFields.valueY = `${key}`
        series.dataFields.formattedValue = `${key}Formatted`
        // Disabilito il colore del tooltip in base al colore della serie e lo definisco in maniera programmatica
        series.tooltip.getFillFromObject = false
        series.tooltip.getStrokeFromObject = false
        series.yAxis = irradiationAxis
        series.tooltip.background.fill = chartsCore.color('#FFF')
        series.tooltip.background.strokeWidth = 3
        series.fillOpacity = 0.2
        series.strokeWidth = 2
        series.tensionX = 0.70
        series.fill = categoriesMap[key]?.color
        series.stroke = categoriesMap[key]?.color
      })
    }
    // Creo le serie per le energie
    const columnSeries = rawValues.map(key => {
      const series = chartInstance.series.push(new amCharts.ColumnSeries())
      series.showOnInit = false
      series.dataFields.categoryX = 'category'
      series.name = categoriesMap[key]?.name
      series.dataFields.valueY = `${key}`
      series.dataFields.formattedValue = `${key}Formatted`
      // Disabilito il colore del tooltip in base al colore della serie e lo definisco in maniera programmatica
      series.tooltip.getFillFromObject = false
      series.tooltip.getStrokeFromObject = false
      series.yAxis = valueAxis
      series.tooltip.background.fill = chartsCore.color('#FFF')
      series.tooltip.background.strokeWidth = 3
      series.tooltip.getFillFromObject = false
      series.strokeWidth = 2
      series.fill = categoriesMap[key]?.color
      series.stroke = categoriesMap[key]?.color

      return series
    })

    columnSeries.forEach(serie => {
      serie.events.on('hidden', arrangeColumns(columnSeries, categoryAxis))
      serie.events.on('shown', arrangeColumns(columnSeries, categoryAxis))
    })
    // Creo le serie per le energie cumulate
    cumulatedValues.forEach((key) => {
      const series = chartInstance.series.push(new amCharts.LineSeries())
      series.showOnInit = false
      series.dataFields.categoryX = 'category'
      series.name = categoriesMap[key]?.name
      series.dataFields.valueY = `${key}`
      series.dataFields.formattedValue = `${key}Formatted`
      // Disabilito il colore del tooltip in base al colore della serie e lo definisco in maniera programmatica
      series.tooltip.getFillFromObject = false
      series.tooltip.getStrokeFromObject = false
      series.yAxis = cumulatedAxis
      series.tooltip.background.fill = chartsCore.color('#FFF')
      series.tooltip.background.strokeWidth = 3
      series.strokeWidth = 3
      series.fill = categoriesMap[key]?.color
      series.stroke = categoriesMap[key]?.color
      // Creo il tooltip da mostrare
      createTooltip(chartInstance, series)
    })
  }

  // Funzione che crea la legenda del grafico
  const createLegend = (chartInstance) => {
    // Istanzio un nuovo oggetto Legend
    chartInstance.legend = new amCharts.Legend()
    // Uso il marker di base
    chartInstance.legend.useDefaultMarker = true
    // Definisco larghezza massima e posizione della legenda
    chartInstance.legend.position = 'bottom'
    chartInstance.legend.itemContainers.template.marginTop = -5
    // Definisco gli stili delle labels della legenda
    chartInstance.legend.labels.template.fill = chartsCore.color('#ffffff')
    chartInstance.legend.labels.template.fontFamily = 'Roboto, sans-serif'
    chartInstance.legend.labels.template.fontSize = 12
    // Modifico il marker di default con delle modifiche custom
    const markerTemplate = chartInstance.legend.markers.template
    const marker = markerTemplate.children.getIndex(0)
    markerTemplate.width = 12
    markerTemplate.height = 12
    marker.cornerRadius(12, 12, 12, 12)
  }

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      // Prendo il div con l'id indicato per creare il grafico
      const chart = chartsCore.create('energy-graph-chart', amCharts.XYChart)
      // Se non è la prima renderizzazione, disabilito le animazioni
      if (!firstPaint) {
        chart.showOnInit = false
      }
      // Assegno i dati al grafico dopo averli formattati
      chart.data = formatData(data)

      chart.language.locale = chartItLocale
      chart.numberFormatter.language = new chartsCore.Language()
      chart.numberFormatter.language.locale = chartItLocale

      // Aggiungo il cursore
      chart.cursor = new amCharts.XYCursor()
      // Disabilito il bottone di zoom out
      chart.zoomOutButton.disabled = true
      // Aggiungo la legenda
      createLegend(chart)
      // Creo gli assi del grafico
      const { valueAxis, cumulatedAxis, irradiationAxis, dateAxis } = createAxis(chart)
      // Creo la serie da graficare
      createSeries(chart, valueAxis, cumulatedAxis, irradiationAxis, dateAxis)
      // Assegno il grafico alla ref
      chartRef.current = chart
      // Dopo che è stato renderizzato la prima volta, setto la variabile a false
      if (firstPaint) {
        setFirstPaint(false)
      }
      return () => {
        chartRef.current.dispose()
      }
    }
  }, [data])

  useEffect(() => {
    if (canZoom) {
      const delayDebounceFn = setTimeout(async () => {
        await categoryAxisZoomed(min, max)
      }, 1500)

      return () => clearTimeout(delayDebounceFn)
    }
  }, [min, max, canZoom])

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {
        Object.keys(data).length > 0 && isLoading
          ? <Box width='100%' height='100%' className={classes.dataLoading} />
          : null
      }
      {Object.keys(data).length > 0
        ? (
          <div
            id='energy-graph-chart'
            style={{
              width: width || '100%',
              height: height || 300
            }}
          />)
        : <LoadingCard glass={hasGlass} />}
    </div>
  )
}

export default memo(EnergyGraph)
