import { Box, Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core'
import PerfectScrollbar from 'react-perfect-scrollbar'

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2)
  }
}))

const getNestedObjectValue = (nestedKey = '', resource = {}) => {
  const arrayKeys = nestedKey.split('.')

  const value = arrayKeys.reduce((acc, key, index) => {
    if (index === 0) {
      acc = resource[key]
    }

    const nestedObj = { ...acc }
    if (nestedObj[key] !== undefined && nestedObj[key] !== null) {
      acc = nestedObj[key]
    }

    return acc
  }, '')

  return typeof value !== 'object' ? value : '-'
}

const TableView = ({ header = [], data = [], rowAction = null }) => {
  const classes = useStyles()

  if (header?.length === 0 && data?.length === 0) return null

  return (
    <Box className={classes.root}>
      <PerfectScrollbar
        containerRef={ref => {
          if (ref) {
            // https://github.com/mdbootstrap/perfect-scrollbar/pull/934/files
            // injecting a fix for this issue
            ref._getBoundingClientRect = ref.getBoundingClientRect

            ref.getBoundingClientRect = () => {
              const original = ref._getBoundingClientRect()

              return {
                bottom: original.bottom,
                left: original.left,
                right: original.right,
                top: original.top,
                width: Math.round(original.width),
                _width: original.width,
                height: Math.round(original.height),
                _height: original.height,
                x: original.x,
                y: original.y
              }
            }
          }
        }}
      >
        <Box width='100%'>
          <Table size='small'>
            <TableHead>
              <TableRow>
                {header?.map(el => (
                  <TableCell key={`header-${el.value}`}>
                    {el.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((dataEl, index) => (
                <TableRow style={{ cursor: 'pointer' }} key={`row-${index}`} hover onClick={() => rowAction ? rowAction(dataEl) : null}>
                  {header?.map(el => {
                    const value = getNestedObjectValue(el.value, dataEl)
                    const formattedValue = el.formatter && value !== '-' ? el.formatter(value) : value
                    return <TableCell key={`body-${el.value}`}>{formattedValue}</TableCell>
                  }
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Box>
  )
}

export default TableView
